import React, { useState, useEffect } from 'react';
import { Select, message, Typography } from 'antd'
import axios from "axios"

const { Option } = Select
const { Text } = Typography;
/**
 * @const SelectOrdenes
 * @description Select para las ordenes registrados en el sistema
 */
const SelectOrdenes = (props) => {

    const {
        value,
        onChange,
        placeholder = "Seleccione un pedido",
        onSelect = () => { },
        disabled = false,
        className = "",
        params = {},
        bordered,
        allowClear = true,
        onClear = () => { },
        mode = null,
        labelInValue
    } = props

    const [ordenes, setOrdenes] = useState({
        data: [],
        page: 1,
        limit: 10,
        total: 0,
        search: null,
    })


    /**
     * @const getOrdenes
     * @description Obitiene los usaurios
     */
    const getOrdenes = ({
        page,
        limit,
        search,
    } = ordenes) => {

        axios.get('/ordenes', {
            params: {
                page,
                limit,
                search,
                ...params,
            },
            headers: { Authorization: sessionStorage.getItem('token') }
        }).then(({ data }) => {
            setOrdenes(data)
        }).catch(error => {
            console.log("ERR, error", error)
            message.error(error?.response?.data?.message ?? 'Error al obtener los pedidos')
        })
    }

    //componentDidMount
    useEffect(() => {
        getOrdenes()
    }, [])

    useEffect(() => {
        getOrdenes()
    }, [params])

    useEffect(() => {
        if (value)
            onChange(value?.value ?? value)
    }, [value])


    return (
        <Select
            bordered={bordered}
            className={className}
            disabled={disabled}
            placeholder={placeholder}
            allowClear={allowClear}
            showSearch
            filterOption={false}
            labelInValue
            mode={mode}
            onSearch={(search) => getOrdenes({ search })}
            onSelect={(area) => {
                if (mode === null && !labelInValue) {
                    onChange(area.value)
                    onSelect(area.value)
                }
                if (mode === null && labelInValue) {
                    onChange(area)
                    onSelect(area)
                }
            }}
            onChange={(values) => {
                if (mode === "multiple" && !labelInValue) {
                    let arr = values.map(e => e.value)
                    onChange(arr)
                    onSelect(arr)
                }

                if (mode === "multiple" && labelInValue) {
                    onChange(values)
                    onSelect(values)
                }
            }}
            value={value}
            onClear={() => {
                onClear()
                onChange(undefined)
                onSelect(undefined)
            }}
        >
            {
                ordenes?.data?.map(({ _id, folio, shopify_folio, total, monto_pendiente }) => <Option value={_id} key={_id} >
                    <span className='flex flex-left '>{shopify_folio ?? "-"}</span>
                    <div className="flex flex-between ">
                        <small className='flex flex-right text-gray-dark' >$ {total?.toMoney(true) ?? ""} MXN</small>
                        <small className='flex flex-right text-gray ' style={{ display: 'none' }}> / $ {monto_pendiente?.toMoney(true) ?? ""} MXN</small>
                    </div>
                </Option>)
            }
        </Select>
    );
}



export default SelectOrdenes;