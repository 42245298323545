import React, { Component } from 'react';
import { Row, Col, Typography, Button, Modal, Layout, Space, message, List, Card, Dropdown, Tag, Menu, Select, Checkbox, Tooltip, Input } from 'antd'
import { Link, useNavigate } from "react-router-dom";
import axios from 'axios';
import moment from 'moment';
import { DeleteOutlined, FilterOutlined, PlusOutlined, WarningFilled } from '@ant-design/icons';

//componentes
import User from '../../../Hooks/Logged';
import usePermissions from '../../../Hooks/usePermissions';
import PageHeader from '../../Widgets/PageHeader/PageHeader';
import { ButtonDelete, ButtonEdit } from '../../Widgets/Buttons';
import FloatingButton from '../../Widgets/Floating Button/FloatingButton';

//css
import '../../../Styles/Modules/Admin/clientes.scss'


const { Content } = Layout;
const { Text } = Typography
const { Option } = Select
const { confirm } = Modal


/**
 * @export
 * @class Clientes
 * @extends {Component}
 * @description Vista donde se listan todos los Clientes registrados en Shopify
 */
class Clientes extends Component {



    /**
     *Creates an instance of Clientes.
     * @param {*} props
     * @memberof Clientes
     */
    constructor(props) {
        super(props)
        this.state = {
            loading: false,

            clientes: {
                data: [],
                page:  1,
                limit:  30,
                filters: [],
                total: 0,
            },
           
        }
    }


    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token')
        this.getClientes()
    }

    /**
    * @memberof Clientes
    * @description Obtiene una lista de clientes provenientes directamente del api de shopify
    */
    getClientes = ({
        limit = this.state.clientes.limit,
        pageQuery = this.state.clientes.pageQuery,
    } = this.state.clientes) => {

        this.setState({ loading: true })
        axios.get('/shopify/clientes', {
            params:{
                limit,
                pageQuery
            }
        }).then(({ data }) => {
            this.setState({
                clientes:{
                    ...this.state.clientes,
                    data: data.data,
                    total: data.count,
                    pageInfo: data.pageInfo,
                    pageQuery
                }
            })
            
        }).catch(error => {
            message.error(error.response?.data?.message ?? 'Error al obtener los clientes')
        }).finally(()=>this.setState({loading: false}))

    }

    render() {
        const { clientes, loading } = this.state
        return (
            <>
                <PageHeader
                    className="admin-page-header"
                    title={"Clientes"}
                />
                <Content
                    className="admin-content content-bg clientes pd-1"
                    id="clientes-layout"
                >
                    <Row gutter={[24, 24]}>
                        <Col xs={24}>
                            <List
                                loading={this.state.loading}
                                className="component-list"
                                itemLayout="horizontal"
                                locale={{ emptyText: "Sin Clientes" }}
                                dataSource={clientes.data}
                                header={ 
                                    <Row className="width-100 " align="middle" gutter={[0, 12]}>
                                        <Col xs={10} className="center" style={{ color: "gray" }} >
                                            <Text ellipsis strong>Nombre del Cliente</Text>
                                        </Col>
                                        <Col xs={5} className="center" >
                                            <Text ellipsis strong>Correo Electrónico</Text>
                                        </Col>
                                       
                                        <Col xs={5} className="center" >
                                            <Text ellipsis strong>Fecha Registro</Text>
                                        </Col>
                                        <Col xs={4} className="center" >
                                            
                                        </Col>
                                    </Row>
                                        
                                }

                                renderItem={item => (
                                    <List.Item className="component-list-item">
                                        <Card className="card-list">
                                            <Row align="middle">
                                                <Col flex="auto">
                                                    <Row className="width-100 " align="middle" gutter={[0, 12]}>
                                                        <Col xs={10} className="center" >
                                                            <Text ellipsis strong>{item.first_name} {item.last_name}</Text>
                                                        </Col>
                                                        <Col xs={5} className="center" >
                                                            <Text ellipsis >{item.email}</Text>
                                                        </Col>
                                                        <Col xs={5} className="center" >
                                                            <Text ellipsis >{moment(item.created_at).format("DD/MM/YYYY")}</Text>
                                                        </Col>
                                                        <Col xs={4} className="center" >
                                                            <Space wrap>
                                                                
                                                                {/*<ButtonEdit
                                                                    disabled={!this.props.canEdit}
                                                                    onClick={() => this.setState({ modalCliente: true, cliente_id: item._id })}/>
                                                                <ButtonDelete 
                                                                    disabled={!this.props.canDelete}
                                                                    onConfirm={() => {
                                                                        confirm({
                                                                            title: "¿Quieres eliminar este cliente?",
                                                                            icon: <WarningFilled />,
                                                                            content: "Se eliminará todo lo relacionado al cliente (inversiones, transacciones, etc.)",
                                                                            okText: "Eliminar",
                                                                            cancelText: "Cancelar",
                                                                            onOk: () => axios.delete('/clientes', { params: { cliente_id: item._id } })
                                                                                .then((response) => {
                                                                                    message.success("Se elimino correctamente")
                                                                                    this.getClientes()
                                                                                })
                                                                                .catch((error) => {
                                                                                    message.error("No se pudo eliminar");
                                                                                    this.getClientes()
                                                                                }),
                                                                            onCancel: () => { }
                                                                        })
                                                                }}/>*/}
                                                                
                                                            </Space>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </Row>
                                        </Card>
                                    </List.Item>
                                )}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <Space>
                                <Button
                                    disabled={!this.state.clientes?.pageInfo?.prevPage}
                                    onClick={()=>{
                                        this.getClientes({pageQuery:this.state.clientes?.pageInfo?.prevPage})
                                    }}
                                >
                                    Anterior
                                </Button> 
                                <Button
                                    disabled={!this.state.clientes?.pageInfo?.nextPage}
                                    onClick={()=>{
                                        this.getClientes({pageQuery:this.state.clientes?.pageInfo?.nextPage})
                                    }}
                                >
                                    Siguiente
                                </Button>
                            </Space>
                        </Col>
                    </Row>
                </Content>
                
            </>
        )
    }
}



export default function (props) {

    const user = React.useContext(User)
    const permisos = usePermissions(user?.rol_id?.permisos, {
        canCreate: ["clientes", "create"],
        canEdit: ["clientes", "edit"],
        canDelete: ["clientes", "delete"],
        canViewDetails: ["clientes", "cliente-detalles", "access"],
        canAssignAsesor: ["clientes", "assign-asesor"]
    })

    const navigate = useNavigate();


    return <Clientes {...props}
        {...permisos}
        navigate={navigate}
    />
}