import React, { Component, useContext } from 'react'
import { Row, Col, Form, Input, Button, Typography, Modal, message, Checkbox } from 'antd';
import axios from 'axios'
import { FaFacebook } from "react-icons/fa";
import { Navigate, Link, useNavigate } from 'react-router-dom';

import { User, SetUser } from '../../Hooks/Logged';
//css
import '../../Styles/Global/auth.scss';

const { Title, Text } = Typography

/**
 * 
 * @class Login
 * @extends {Component}
 * @description Componente Login para inicio de sesion
 */
class Login extends Component {
	constructor(props) {
		super(props);
		this.state = {

		}
	}

	/**
	 * @memberof Login
	 *
	 * @method handleSubmit
	 * @description  Envia los datos del formulario al Servidor
	 *
	 * @param values (array)
	 * Contiene los campos del formulario para registrar al usuario
	 *
	 * @returns response (array)
	 **/
	handleSubmit = (values) => {
		this.setState({ loading: true })
		axios.post('/login', {
			email: values.email,
			password: values.password
		})
			.then(({ data, headers }) => {
				const { setUser } = this.props;

				axios.defaults.headers.post["Authorization"] = headers.authorization;

				sessionStorage.setItem('token', headers.authorization);
				axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');

				setUser(data.user);
				this.props.navigate('/admin/dashboard');
			})
			.catch((error) => {

				Modal.error({ title: "Credenciales incorrectas" })

				this.setState({ loading: false });
			})
		//this.redirectTo('/admin/dashboard');
	};





	

	cerrarSesion = () => {
		this.props.setUser(undefined);
		sessionStorage.clear();
		this.props.navigate("/")

		if(window.FB){
        	window.FB.logout(function(response) {
        		console.log("response", response);
			});
        }

	};


	render() {
		return (
			<Row className='layout-login'>
				<Col xs={24} lg={10} className="col-welcome">
					<Row>
						<div>
							<img width="400" src="/logo.svg" alt="Vapemate" className='logo-login' />
						</div>
					</Row>
				</Col>
				<Col xs={24} lg={14}>
					{
						this.props.user ? (
							<div className="login-form-container">
								<div className="login-form">
									<Title level={3} className="h3-title" style={{ marginBottom: '3em' }}>Bienvenido de vuelta!</Title>
									<Title align="center" style={{ marginTop: '1em' }}>{this.props?.user?.nombre} {this.props?.user?.apellidos}</Title>
									<Title level={4} align="center" style={{ marginBottom: '1em' }}>{this.props?.user?.email}</Title>
									<Button type="primary" htmlType="submit" block size="large" onClick={() => this.props.navigate(this.props.user?.cliente_id ? '/customer/compras' : '/admin/dashboard')} >Continuar</Button>
									<Button type="link" htmlType="submit" block size="large" onClick={() => this.cerrarSesion()}>Cerrar Sesión</Button>
								</div>
							</div>
						) : (
							<div className='login-form-container'>
								<Form onFinish={this.handleSubmit} layout={"vertical"} requiredMark={false} className='login-form'>
									<Title level={3} className="h3-title">¡Bienvenido de Vuelta!</Title>
									<Form.Item
										className='input-email'
										name="email"
										label="Correo electrónico"
										rules={[
											{ required: true, message: 'Ingrese su correo' },
											{
												type: 'email',
												message: 'Email no valido',
											},
										]}>
										<Input placeholder="ejemplo@mail.com" size="large" className="input-login" />
									</Form.Item>
									<Form.Item
										label="Contraseña"
										name="password"
										rules={[{ required: true, message: 'Ingrese su contraseña' }]}>
										<Input.Password placeholder="********" size="large" />
									</Form.Item>
									<Form.Item
										name="keep_session"
									>
										<Checkbox>Recuerdame</Checkbox>
									</Form.Item>
									<Form.Item>
										<Button type="primary" htmlType="submit" block size="large" >Iniciar sesión</Button>
									</Form.Item>
									
									<Text className="mt-1">¿No tienes cuenta? <Link to="/register">Registrate</Link></Text>
								</Form>
							</div>
						)
					}
				</Col>
				{
					!this.props.user && <div className='link-password-recovery'>
						¿Olvido su contraseña? <Link style={{ color: '#5452F6' }} to='/password/recovery'>Restaurar aquí</Link>
					</div>
				}
			</Row>
		)
	}
}

export default function View(props) {

	let user = useContext(User)
	const setUser = useContext(SetUser)

	return <Login {...props} user={user} setUser={setUser} navigate={useNavigate()} />
}