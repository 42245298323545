import React from 'react';
import { Drawer, Form, Select,  Button, TreeSelect, DatePicker } from 'antd';
import axios from 'axios';
import moment from 'moment';
import { DownloadOutlined } from '@ant-design/icons';

//Componentes
import SelectAreas from '../Inputs/SelectAreas';
import SelectRubros from '../Inputs/SelectRubros';
import SelectCuentas from '../Inputs/SelectCuentas';
import SelectUsuario from '../Inputs/SelectUsuarios';
import SelectClientes from '../Inputs/SelectClientes';
import SelectProductos from '../Inputs/SelectProductos';
import SelectVariantes from '../Inputs/SelectVariantes';

const { RangePicker } = DatePicker

/**
 * @export
 * @class FiltrosDrawer
 * @extends {React.Component}
 * 
 * @description Permite filtrar según diversa información de la vista en la que se implemente
 */
class FiltrosDrawer extends React.Component {

 
    state = {

        filtros: null,
        estatus: {
            data: [],
            dictionary: {}
        }
    }

    formFiltros = React.createRef()


    /**
    * @method loadEstatus
    * @description Este método procesa los datos de estatus provenientes de las props
    * y los carga en el estado del componente.
    */
    loadEstatus = () => {
        const estatus = {}

        if (this.props.estatus.type !== 'tree') {
            estatus.data = this.props.estatus.data
            estatus.dictionary = {}
            estatus.data.map(d => estatus.dictionary[d._id] = d)
        }

        if (this.props.estatus.type === 'tree') {
            estatus.data = this.props.estatus.data
            estatus.dictionary = {}
            estatus.data.map(d => estatus.dictionary[d.value] = d)
        }

        this.setState({ estatus })
    }

    /**
     * @method getParent
     * @description Método para obtener el padre de un elemento dado su nombre y elemento.
     * @param {string} name - El nombre del estado que contiene el diccionario.
     * @param {string} element - El valor del elemento para el que se busca el padre.
     * @returns {object|null} - El objeto que representa al padre del elemento, o null si no se encuentra.
     */
    getParent = (name, element) => {

        let dictionary = this.state[name].dictionary
        let parent = null

        for (let key in dictionary) {
            let { children } = dictionary[key]
            if (!children) continue

            let child = children.find(child => child.value === element)
            if (!child) continue

            parent = {
                ...dictionary[key],
                children: [child]
            }

            break
        }

        return parent
    }

    /**
     *
     *
     * @memberof FiltrosDrawer
     * @method submit
     * 
     * @description Permite obtener el arreglo para poder mostrarlo en la lista de guardados. 
     */
    submit = (values) => {

        let { clientes, estatus, fechas, asesores, cuentas, areas, shopify_financial_status, shopify_fulfillment_status, productos, variantes } = values;

        let filtros = []

        //Metodo para añadir al areglo de filtros.
        let addToFilter = (name, objectName, array) => array.map(({value, label}) => filtros.push({
            _id: value,//clasificador,
            name: name,
            objectName,
            label: Array.isArray(label) ? label.join('') : label
        }))

        if (areas) addToFilter("area_id", "areas", areas)
        if (estatus) addToFilter("estatus", "estatus", estatus)
        if (cuentas) addToFilter("cuenta_id", "cuentas", cuentas)
        if (asesores) addToFilter("asesor_id", "usuarios", asesores)
        if (clientes) addToFilter("cliente_id", "clientes", clientes)
        if (productos) addToFilter("producto_id", "productos", productos)
        if (variantes) addToFilter("variante_id", "variantes", variantes)
        if (shopify_financial_status) addToFilter("shopify_financial_status", "Estatus Pago", shopify_financial_status)
        if (shopify_fulfillment_status) addToFilter("shopify_fulfillment_status", "Estatus Pedido", shopify_fulfillment_status)

        if (fechas) filtros.push({
            name: "fecha",
            objectName: "fechas",
            filter: {
                range: fechas.map(f => f.format("YYYY-MM-DD"))
            }
        })

    
        if (this.props.updateFilters)
            this.props.updateFilters(filtros)
    }


    render() {

        const { onClose, visible } = this.props
        const { clientes, estatus, asesores, fechas, cuentas, areas, shopify_financial_status, shopify_fulfillment_status, productos } = this.props

        return (<Drawer
            title={this.props.title}
            placement="right"
            onClose={onClose}
            open={visible}
        >


            <Form
                ref={this.formFiltros}
                layout='vertical'
                onValuesChange={(x, values) => {
                    this.submit(values)
                }}
                //onFinish={this.submit}
            >
                {(fechas) && (
                    <Form.Item label="Rango de Fechas" name="fechas" >
                        <RangePicker placeholder={["Fecha Inicial", "Fecha Final"]} />
                    </Form.Item>
                )}
                {(clientes) ?
                    <Form.Item label="Clientes" name="clientes">
                        <SelectClientes
                            mode="multiple"
                            labelInValue={true}
                        />
                    </Form.Item> : null}
                {(asesores) ?
                    <Form.Item label="Asesores" name="asesores">
                        <SelectUsuario
                            mode="multiple"
                            labelInValue={true}
                        />
                    </Form.Item> : null}
                {(cuentas) ?
                    <Form.Item label="Cuentas" name="cuentas">
                        <SelectCuentas
                            mode="multiple"
                            labelInValue={true}
                        />
                    </Form.Item> : null}
                {(areas) ?
                    <Form.Item label="Areas" name="areas">
                        <SelectAreas
                            mode="multiple"
                            labelInValue={true}
                            onSelect={(areas)=>{
                                if(areas.length > 0)
                                    this.setState({areas_id: areas.map(e => e.value) })
                                else{
                                    this.setState({areas_id: undefined})
                                    this.formFiltros.current.setFieldsValue({ rubros: undefined })
                                }
                            }}
                        />
                    </Form.Item> : null}
                {(areas) ?
                    <Form.Item label="Rubros" name="rubros">
                        <SelectRubros
                            mode="multiple"
                            labelInValue={true}
                            disabled={!this.state.areas_id}
                            params={{ areas_id: this.state.areas_id }}
                        />
                    </Form.Item> : null}
                {(estatus) &&
                    (estatus?.type === "tree" ? (
                        <Form.Item label="Estatus" name="estatus">
                            <TreeSelect
                                dropdownStyle={{
                                    maxHeight: 400,
                                    overflow: 'auto',
                                }}
                                treeData={estatus.data}
                                treeDefaultExpandAll
                                multiple
                            />
                        </Form.Item>
                    ) : (
                        <Form.Item label="Estatus" name="estatus">
                            <Select
                                filterOption={false}
                                mode="multiple"
                                labelInValue
                                options={estatus.data.map(o => ({value: o._id, label: o.label}))}
                            />
                        </Form.Item>
                    ))
                }
                {(productos) ?
                    <Form.Item label="Productos" name="productos">
                        <SelectProductos
                            mode="multiple"
                            labelInValue={true}
                            onSelect={(productos)=>{
                                if(productos?.length > 0)
                                    this.setState({productos_id: productos.map(e => e.value) })
                                else{
                                    this.setState({productos_id: undefined})
                                    this.formFiltros.current.setFieldsValue({ variantes: undefined })
                                }
                            }}
                        />
                    </Form.Item> : null}
                 {(productos) ?
                    <Form.Item label="Variantes" name="variantes">
                        <SelectVariantes
                            mode="multiple"
                            labelInValue={true}
                            showProductos={true}
                            disabled={!this.state.productos_id}
                            params={{ productos_id: this.state.productos_id }}
                        />
                    </Form.Item> : null}
                {
                    shopify_financial_status?.length > 0 ? <Form.Item label="Estatus Pago" name="shopify_financial_status">
                        <Select
                            filterOption={false}
                            mode="multiple"
                            labelInValue
                            options={shopify_financial_status.map(o => ({value: o._id, label: o.label}))}
                        />
                    </Form.Item> : null
                }

                {
                    shopify_fulfillment_status?.length > 0 ? <Form.Item label="Estatus Pedido" name="shopify_fulfillment_status">
                        <Select
                            filterOption={false}
                            mode="multiple"
                            labelInValue
                            options={shopify_fulfillment_status.map(o => ({value: o._id, label: o.label}))}
                        />
                    </Form.Item> : null
                }
                
            </Form>
        </Drawer >)
    }
};


export default React.forwardRef((props, ref) => {

    return <FiltrosDrawer ref={ref} {...props}  />
})