import React, { Component } from "react";
import { Row, Col, Modal, Form, Input, message, Spin, Select, Switch, DatePicker } from 'antd';
import dayjs from "dayjs";

import { AvatarLoader } from "../../Widgets/Uploaders";
import axios from "axios"
import { getResponseError } from "../../Utils";
import ReglaList from "./ReglasList";
const { Option } = Select;



/**
 * @class ModalUsuarios
 * @description Modal para el CRUD de Usuarios
 */
class ModalUsuarios extends Component {

    constructor(props) {
        super(props)
        this.state = {
            loading: false,

            is_asesor: false,
            roles: {
                data: [],
                limit: 10,
                page: 1,

                total: 0,
                pages: 0,
            },
            usuario: {
                reglas: []
            },
            errores: [],
            disabled_rules: true
        }
    }

    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token')
        this.getRoles()
        if (this.props.usuario_id)
            this.get()

    }

    formRef = React.createRef();

    /**
    * @method get
    * @description Obtiene un registro de usuario
    */
    get = () => {
        this.setState({ loading: true })
        axios.get('/usuarios/' + this.props.usuario_id)
            .then(({ data }) => {

                let antiguedad_fecha = null
                if(data.antiguedad_fecha) antiguedad_fecha = dayjs(data.antiguedad_fecha)

                this.formRef.current.setFieldsValue({
                    ...data,
                    is_asesor: data.is_asesor,
                    reglas: data.reglas,
                    antiguedad_fecha: antiguedad_fecha,
                    avatar: data.avatar ? {
                        uid: data.avatar.file,
                        name: data.avatar.name,
                        status: 'done',
                        fromDB: true,
                        url: axios.defaults.baseURL + "/usuarios/" + data._id + "?avatar=" + data.avatar.file + "&Authorization=" + sessionStorage.getItem("token")
                    } : null,
                })
                this.setState({ is_asesor: data.is_asesor, disabled_rules: false })
            }).catch(error => {
                message.error('Error al obtener el usuario')
            }).finally(() => this.setState({ loading: false }))
    }

    /**
         * @methodOf  Usuarios
         * @method getAreas
         *
         * @description Obtiene los roles
         * */
    getRoles = ({
        page = this.state.roles.page,
        limit = this.state.roles.limit,
        search = this.props.search
    } = this.state.roles) => {
        this.setState({ loading: true, roles: { ...this.state.roles, page, limit } })
        axios.get('/roles', {
            params: {
                page,
                limit,
                search
            }
        }).then(({ data }) => {
            this.setState({
                roles: { ...this.state.roles, ...data },
            });
        }).catch(error => {
            console.log(error)
            message.error('Error al traer la información')
        }).finally(() => {
            this.setState({ loading: false })
        })
    }

    /**
    * @method add
    * @description Añade un nuevo registro de usuario
    */
    add = (values) => {
        this.setState({ loading: true })
        axios.post('/usuarios', values, {
            headers: {
                "Content-Type": 'multipart/form-data'
            }
        })
            .then(response => {
                message.success('¡Usuario Creado!')
                this.props.onClose()
            }).catch(error => {
                console.log("error", error);
                message.error(getResponseError(error.response, 'Error al crear el Usuario'))
            }).finally(() => this.setState({ loading: false }))
    }

    /**
    * @method updateUser
    * @description Actualiza un nuevo registro de usuario
    */
    update = (values) => {

        console.log('update', values)
        this.setState({ loading: true })
        axios.put('/usuarios', values, {
            headers: {
                "Content-Type": 'multipart/form-data'
            }
        })
            .then(response => {
                message.success('¡Usuario Actualizado!')
                this.props.onClose()
            }).catch(error => {
                console.log("error", error);
                message.error(getResponseError(error.response, 'Error al actualizar el Usuario'))
            }).finally(() => this.setState({ loading: false }))
    }

    /**
     *
     *
     * @memberof ModalUsuarios
     * @method verificarReglas
     * @description cada vez que se cambia un valor de un formulario se validan las reglas
     */
    verificarReglas = () => {

        const { reglas } = this.formRef.current?.getFieldsValue();

        if (reglas && Array.isArray(reglas))
            reglas.forEach((regla, index) => {
                if (index >= 0 && regla) {
                    this.onChangeLimites(regla, reglas[index - 1], index)
                }
            });
    }

    /**
     *
     *
     * @memberof ModalUsuarios
     * @description verifica los limites de las reglas
     * @param {*} regla_actual
     * @param {*} regla_anterior 
     * @param {Number} index elemento de la lista
     */
    onChangeLimites = (regla_actual, regla_anterior, index) => {
        let li = regla_actual.limite_inferior;
        let ls = regla_actual.limite_superior;
        let ls_anterior = regla_anterior?.limite_superior ?? 0;

        let errores = this.state.errores;

        errores[index] = {};

        //reglas de validaciones para limites superiores e inferiores
        if (li == undefined || (index === 0 && li < 0)) {
            errores[index]["limite_inferior"] = 'Ingrese el mínimo';
        }
        if (ls != undefined) {
            if (li > ls) {
                errores[index]["limite_inferior"] = 'El valor del límite inferior debe ser menor al límite superior';
            }

            if (ls < li) {
                errores[index]["limite_superior"] = 'El valor del límite superior debe ser mayor al límite inferior';
            }
            if (li == ls) {
                errores[index]["limite_inferior"] = 'Los límite deben ser diferentes';
                errores[index]["limite_superior"] = 'Los límite deben ser diferentes';
            }
        }

        if (li < ls_anterior) {
            errores[index]["limite_inferior"] = 'El valor del límite inferior debe ser mayor al límite superior anterior';
        }
        /*if (index > 0 && li != ls_anterior + 1) {
            errores[index]["limite_inferior"] = 'El valor del límite inferior debe ser la continuación al límite superior anterior';
        }*/



        //almacen de errores en el state para renderizarlos en cada regla
        if (Object.keys(errores[index]).length > 0) {
            this.setState({ errores: errores })
            return
        }
        else {
            this.setState({ errores: [] })

        }


    }

    /**
     * @method onFinish
     * @description Se ejecuta al hacer submit al formulario
     */
    onFinish = (values) => {
        let data = values;
        const formData = new FormData()

        if (values.reglas) {
            formData.append('reglas', JSON.stringify(values.reglas));
        }
        delete data.reglas
        formData.appendMultiple({
            ...data,
            id: this.props.usuario_id,
            cliente_id: this.props.cliente_id
        })
        if (this.props.usuario_id) {
            this.update(formData)
        } else {
            this.add(formData)
        }
    }

    onChangeAsesor = (e) => {
        this.setState({ is_asesor: e });
    };

    onValuesChange = (changedValues, allValues) => {
        console.log("changedValues", changedValues);
        console.log("allValues", allValues);
        this.setState({ disabled_rules: (allValues.antiguedad_fecha === null || allValues.antiguedad_fecha === undefined) })
        this.verificarReglas()
    }
    render() {

        return (
            <Spin spinning={this.state.loading}>
                <Form
                    id="form-usuario"
                    layout={"vertical"}
                    className="pd-1"
                    onValuesChange={this.onValuesChange}
                    onFinish={this.onFinish}
                    ref={this.formRef}
                >
                    <Row>
                        <Col span={24}>
                            <Row justify={"center"}>
                                <Form.Item
                                    name="avatar"
                                >
                                    <AvatarLoader
                                        imageCrop={true}
                                        onRemove={() => {
                                            axios.put('/usuarios/', {
                                                id: this.props.usuario_id,
                                                delete_avatar: true
                                            })
                                        }}

                                    />
                                </Form.Item>
                            </Row>

                            <Form.Item
                                name="nombre"
                                label="Nombre"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Ingrese el nombre',
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>

                            <Form.Item
                                name="apellidos"
                                label="Apellidos"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Ingrese los apellidos',
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>

                            <Form.Item
                                name="email"
                                label="E-mail"
                                rules={[
                                    {
                                        type: 'email',
                                        message: 'Email no valido',
                                    },
                                    {
                                        required: true,
                                        message: 'Ingrese el Email',
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>

                            {this.props.cliente ? null : <Form.Item
                                name="rol_id"
                                label="Rol"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Seleccione el rol asignado',
                                    },
                                ]}
                            >
                                <Select
                                    allowClear
                                    showSearch
                                    filterOption={false}
                                    onSearch={search => this.getRoles({ search, page: 1 })}
                                >
                                    {this.state.roles.data.map(rol => <Option key={rol._id} value={rol._id} rol={rol}>{rol.nombre}</Option>)}
                                </Select>
                            </Form.Item>}
                            <Form.Item
                                name="is_asesor"
                                label="Es asesor"
                                valuePropName="checked"
                            >
                                <Switch onChange={this.onChangeAsesor} />
                            </Form.Item>

                        </Col>
                    </Row>
                    {this.state.is_asesor == true ?
                        <Row justify="center" className="center">
                            <Col span={24}>
                                <Form.Item 
                                    name="antiguedad_fecha" 
                                    label={"Clientes creados a partir de:"}
                                >
                                    <DatePicker className="width-100" picker="month" placeholder="Antiguedad" />
                                </Form.Item>
                            </Col>

                            <Col span={24}>
                                <ReglaList
                                    name="reglas"
                                    errores={this.state.errores}
                                    verificarReglas={this.verificarReglas}
                                    reglas={this.state.usuario?.reglas}
                                    antiguedad_fecha={this.formRef.current?.getFieldValue('antiguedad_fecha')}
                                    disabled={this.state.disabled_rules}
                                />
                            </Col>
                        </Row>
                        : null}
                </Form>
            </Spin>
        )
    }


}



export default function Modals(props) {

    const { visible = false, onClose = () => { }, usuario_id } = props

    return <Modal
        open={visible}
        onCancel={onClose}
        title={usuario_id ? "Editar Usuario" : "Crear Usuario"}
        closable={true}
        destroyOnClose={true}
        zIndex={1000}
        cancelText="Cancelar"
        okText="Guardar"
        okButtonProps={{ form: 'form-usuario', key: 'submit', htmlType: 'submit' }}
    >
        <ModalUsuarios  {...props} />
    </Modal>

}