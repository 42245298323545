import React, { Component } from "react";
import { Row, Col, Space, Modal, List, Typography, Form, DatePicker, Input, InputNumber, Collapse, Select, message, Spin, Cascader, Tag, Tooltip, Divider } from 'antd';
import { ExclamationCircleFilled } from '@ant-design/icons';
import axios from "axios"

//Componentes
import SelectClientes from "../../Widgets/Inputs/SelectClientes";
import SelectUsuarios from "../../Widgets/Inputs/SelectUsuarios";
import SelectDireccion from '../../Widgets/Inputs/SelectDirecciones';
import { renderEstatusOrdenPago, renderEstatusOrdenEnvio } from "../../Utils";
import ImageProducto from "../../Widgets/ImageProducto"


const { Title, Text, Paragraph } = Typography;
const { Option } = Select;
const moment = require('moment');
const Decimal = require('decimal.js');

class ModalTransacciones extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            orden: {
                ordenes_productos_ids: []
            }
        }
    }

    modalRef = React.createRef();

    componentDidMount() {
        if(this.props.orden_id){
            this.getOrden()
        }
    }

    /**
    * @method addUser
    * @description Obtiene la información de la orden
    */
    getOrden = () => {
        this.setState({loading:true})
        axios.get('/orden/'+this.props.orden_id)
        .then(({data})=>{
            console.log("data", data);
            this.setState({orden: data})

        }).catch((error)=>{
            message.error(error?.response?.data?.message ?? "Error al obtener la información del pedido")
        }).finally(()=>this.setState({loading: false}))
    }


    render() {

        const { orden } = this.state;

        return (
            <Spin spinning={this.state.loading}>
                <Form
                    layout="vertical"

                    name="form-transaccion"
                    id="form-transaccion"
                    ref={this.modalRef}
                    onFinish={this.onFinish}
                >
                    <Row gutter={[4,8]}>
           				<Col span={24}>
           					<Space>
           						<Title level={4} className="m-0">{orden.shopify_folio} <small className="text-gray">{orden.folio}</small></Title>
           					</Space>
           				</Col>
           				<Col span={24}>
           					<Space>
           						<small className="text-gray">{moment(orden.fecha).format("DD/MM/YYYY HH:mm")}</small>
           					</Space>
           				</Col>
                        { !orden.cliente_id?._id && orden.shopify_cliente ? <Col span={24} className="flex-left-column">
                            <Text>Cliente: {orden.shopify_cliente?.first_name ?? "-"} {orden.shopify_cliente?.last_name ?? ""}
                                <Tooltip placement="topRight" title={"Cliente no registrado"}>
                                    <ExclamationCircleFilled style={{color: "#ff4d4f", marginLeft: "0.5rem"}}/>
                                </Tooltip>
                            </Text>
                            <Text>{orden.shopify_cliente?.email ?? "No email"} {orden.shopify_cliente?.phone ?? ""}</Text>
                        </Col> : null }
                        { orden.cliente_id?._id && !orden.shopify_cliente ? <Col span={24} className="flex-left-column">
                            <Text>Cliente: {orden.cliente_id?.nombre ?? "-"} {orden.cliente_id?.apellido_paterno ?? ""}</Text>
                            <Text>{orden.cliente_id?.email ?? "No email"} {orden.cliente_id?.telefono ?? ""}</Text>
                        </Col> : null }

                        { orden.cliente_id?._id && orden.shopify_cliente ? <Col span={24} className="flex-left-column">
                            <Text>Cliente: {orden.cliente_id?.nombre ?? "-"} {orden.cliente_id?.apellido_paterno ?? ""}</Text>
                            <Text>{orden.cliente_id?.email ?? "No email"} {orden.cliente_id?.telefono ?? ""}</Text>
                        </Col> : null }

                        { orden.notas ? <Col span={24}>
                            <Paragraph strong style={{fontSize: "15px"}}>Notas: {orden.notas}</Paragraph>
                        </Col> : null }
                        <Col span={24}>
                            {renderEstatusOrdenEnvio(orden.shopify_fulfillment_status)}
                        </Col>
                        {
                            orden.shopify_fulfillments?.length > 0 ? orden.shopify_fulfillments.map(e => <Col span={24} className="flex-left-column">
                                <Text className="text-gray">{e.tracking_company}</Text>
                                <a href={e.tracking_url} target="_blank">{e.tracking_number}</a>
                            </Col>) : <Col span={24} className="center">
                                <Title level={3} className="text-gray"> Información de envío no disponible </Title>
                            </Col>
                        }

                        {
                            !orden.direccion_id && orden.shopify_shipping_address ? <Col span={24}>
                                <Collapse 
                                    size="small"
                                    items={[
                                        {
                                            key: '1',
                                            label: 'Dirección de envio',
                                            children: <Paragraph className="flex-left-column">
                                                <Text>{orden.shopify_shipping_address?.first_name ?? ""} {orden.shopify_shipping_address?.last_name ?? ""}</Text>
                                                <Text>{orden.shopify_shipping_address?.company ?? ""}</Text>
                                                <Text>{orden.shopify_shipping_address?.address1 ?? ""}</Text>
                                                <Text>{orden.shopify_shipping_address?.address2 ?? ""}</Text>
                                                <Text>{orden.shopify_shipping_address?.zip ?? ""} {orden.shopify_shipping_address?.city ?? ""} {orden.shopify_shipping_address?.province ?? ""}</Text>
                                                <Text>{orden.shopify_shipping_address?.country ?? ""}</Text>
                                            </Paragraph>,
                                        },
                                    ]}
                                />
                            </Col> : null
                        }
                        {
                            orden.direccion_id ? <Col span={24}>
                                <Collapse 
                                    size="small"
                                    items={[
                                        {
                                            key: '1',
                                            label: 'Dirección de envio',
                                            children: <Paragraph className="flex-left-column">
                                                <Text>{orden.direccion_id?.nombre ?? ""}</Text>
                                                <Text>{orden.direccion_id?.calles ?? ""}</Text>
                                                <Text>{orden.direccion_id?.colonia ?? ""}</Text>
                                                <Text>{orden.direccion_id?.codigo_postal ?? ""} {orden.direccion_id?.ciudad ?? ""} {orden.direccion_id?.estado_id?.nombre ?? ""}</Text>
                                                <Text>{orden.direccion_id?.pais_id?.nombre ?? ""}</Text>
                                                <Text>{orden.direccion_id?.especificaciones ?? ""}</Text>
                                            </Paragraph>,
                                        },
                                    ]}
                                />
                            </Col> : null
                        }
           				
           				{orden.shopify_tags ? <Row className="width-100">
           					<Col span={24}>
           						<Text><strong>Etiquetas</strong>: {orden?.shopify_tags?.split(", ")?.map(e => <Tag>{e}</Tag>)}</Text>
           					</Col>
           				</Row> : null}
           				{orden.note ? <Row className="width-100">
           					<Col span={24}>
           						<Paragraph><Text className="text-gray">Notas:</Text> {orden.note}</Paragraph>
           					</Col>
           				</Row> : null}
           				<Divider/>
           			</Row>
                    
                </Form>
            </Spin>
        )
    }
}



export default function (props) {
    const { open = false, onClose = () => { }, transaccion_id } = props

    return <Modal
        open={open}
        onCancel={onClose}
        title={"Detalles de envío"}
        closable={true}
        maskClosable={true}
        destroyOnClose={true}
        zIndex={1000}
        okButtonProps={{ form: 'form-transaccion', key: 'submit', htmlType: 'submit' }}
    >
        <ModalTransacciones {...props} />
    </Modal>

}