import React, { Component } from "react";
import {
	Row,
	Col,
	Modal,
	Typography,
	Form,
	DatePicker,
	Input,
	InputNumber,
	Select,
	message,
	Spin,
} from "antd";
import axios from "axios";

import CustomAvatar from "../../../Widgets/Avatar/Avatar";
import SelectClientes from "../../../Widgets/Inputs/SelectClientes";
import SelectAreas from "../../../Widgets/Inputs/SelectAreas";
import SelectRubros from "../../../Widgets/Inputs/SelectRubros";
import SelectCuentas from "../../../Widgets/Inputs/SelectCuentas";
import SelectOrdenes from "../../../Widgets/Inputs/SelectOrdenes";
import { ImagesUploader } from "../../../Widgets/Uploaders";

const { Text } = Typography;
const { Option } = Select;
const moment = require("moment");


class ModalTransacciones extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: false,
			filters: {},
			tipo_cambio: 0,
			cuentaSeleccionada: "",
			disabled: false,
			transaccion: {},
			cliente_id: undefined,
			seleccionadas: {},
			concepto_unico: false,
		};
	}

	modalRef = React.createRef();

	componentDidMount() {
		if (this.props.transaccion_id !== undefined || this.props.transaccion_id != null) {
			this.getTransaccion();
		} 

		if (this.props.seleccionadas) {
			this.setState({ seleccionadas: this.props.seleccionadas });
		}
	}

	/**
	 * @memberof ModalTransacciones
	 * @method onFinish
	 * @description Se ejecuta al dar enter al formulario
	 *
	 */
	onFinish = (values) => {
		if (values.area_id?.value) values.area_id = values.area_id.value;
		if (values.rubro_id?.value) values.rubro_id = values.rubro_id.value;
		if (values.cliente_id?.value) values.cliente_id = values.cliente_id.value;
		if (values.cuenta_id?.value) values.cuenta_id = values.cuenta_id.value;
		values.monto_pesos = values.monto_pesos?.toMoney() || 0;
		values.monto_dolar = values.monto_dolar?.toMoney() || 0;
		values.tipo_cambio = values.tipo_cambio?.toMoney() || 0;

		const formData = new FormData();
		formData.appendMultiple({
			...values,
			comprobantes: undefined,
		});

		if (values.comprobantes) {
			values.comprobantes.forEach((comprobante) => {
				if (!comprobante.fromDB)
					formData.append("comprobantes", comprobante, comprobante.name);
			});
		}

		if (this.props.transaccion_id) this.updateTransaccion(formData);
		else this.addTransaccion(formData);
	};

	/**
	 * @memberof ModalTransacciones
	 * @method getTransaccion
	 * @description
	 *
	 */
	getTransaccion = () => {
		this.setState({ loading: true });

		axios
			.get("/transacciones/" + this.props.transaccion_id)
			.then(({ data }) => {
				this.modalRef.current?.setFieldsValue({
					...data,
					fecha: moment(data.fecha),
					cuenta_id: {
						value: data?.cuenta_id?._id,
						label: data?.cuenta_id?.nombre,
					},
					area_id: {
						value: data?.area_id?._id,
						label: data?.area_id?.nombre,
					},
					rubro_id: {
						value: data?.rubro_id?._id,
						label: data?.rubro_id?.nombre,
					},
					cliente_id: data?.cliente_id?._id
						? {
							value: data?.cliente_id?._id,
							label: data?.cliente_id?.alias,
						}
						: null,
					comprobantes: data.comprobantes
						? data.comprobantes.map((f) => ({
							uid: f.file,
							name: f.name,
							status: "done",
							fromDB: true,
							url:
								axios.defaults.baseURL +
								"/transacciones/" +
								data._id +
								"?comprobante=" +
								f.file +
								"&Authorization=" +
								sessionStorage.getItem("token"),
						}))
						: null,
				});
				this.setState({ loading: false, disabled: true, cliente_id: data?.cliente_id?._id ?? undefined });
			})
			.catch((error) => {
				console.log("error", error);
				this.setState({ loading: false });
			});
	};

	/**
	 * @memberof ModalTransacciones
	 * @method getTipoCambio
	 * @description Trae el ulitmo tipo de cambio utilizado
	 *
	 */
	getTipoCambio = () => {
		this.modalRef.current?.setFieldsValue({
			tipo_cambio: 19.5,
		});
		this.setState({
			tipo_cambio: 19.5,
		});
	};

	/**
	 * @memberof ModalTransacciones
	 * @method addTransaccion
	 * @description Trae las cuentas
	 *
	 */
	addTransaccion = (values) => {
		this.setState({ loading: true });
		axios
			.post("/transacciones", values, {
				headers: {
					"Content-Type": "multipart/form-data",
				},
			})
			.then((response) => {
				message.success("Transacción creada");
				this.props.onClose(true);
			})
			.catch((error) => {
				console.log(error);
				message.error(
					error?.response?.data?.message ?? "Error al crear la transacción",
				);
			})
			.finally(() => this.setState({ loading: false }));
	};

	/**
	 * @memberof ModalTransacciones
	 * @method updateTransaccion
	 * @description Trae las cuentas
	 *
	 */
	updateTransaccion = (values) => {
		this.setState({ loading: true });
		axios
			.put("/transacciones/" + this.props.transaccion_id, values, {
				headers: {
					"Content-Type": "multipart/form-data",
				},
			})
			.then((response) => {
				message.success("Transacción Actualizada");
				this.props.onClose(true);
			})
			.catch((error) => {
				console.log(error);
				message.error(
					error?.response?.data?.message ??
					"Error al actualziar la transacción",
				);
			})
			.finally(() => this.setState({ loading: false }));
	};

	/**
	 * @memberof ModalTransacciones
	 * @method onChangePesos
	 * @description Hace la conversion de pesos a dolares para actualizar el campo de dolares
	 *
	 */
	onChangePesos = (value) => {
		let tipo_cambio = this.modalRef.current?.getFieldValue("tipo_cambio");
		this.modalRef.current?.setFieldsValue({
			monto_dolar: parseFloat(value / tipo_cambio).toMoney(),
		});
	};

	/**
	 * @memberof ModalTransacciones
	 * @method onChangeDolar
	 * @description Hace la conversion de dolares a pesos para actualizar el campo de pesos
	 *
	 */
	onChangeDolar = (value) => {
		let tipo_cambio = this.modalRef.current?.getFieldValue("tipo_cambio");
		this.modalRef.current?.setFieldsValue({
			monto_pesos: parseFloat(value * tipo_cambio).toMoney(),
		});
	};

	/**
	 * @memberof ModalTransacciones
	 * @method onChangeTipo
	 * @description Hace la conversion de pesos a dolares para actualizar el campo de dolares, usando el nuevo tipo de cambio
	 */
	onChangeTipo = (value) => {
		let monto_pesos = this.modalRef.current?.getFieldValue("monto_pesos");
		this.modalRef.current?.setFieldsValue({
			monto_dolar: parseFloat(monto_pesos / value).toMoney(),
		});
		this.setState({ tipo_cambio: value });
	};

	render() {
		const { disabled } = this.state;

		return (
			<Form
				layout="vertical"
				name="form-transaccion"
				id="form-transaccion"
				ref={this.modalRef}
				onFinish={this.onFinish}
				initialValues={{
					...this.props.initialValues,
					fecha: moment(),
					cliente_id: this.props.cliente
						? {
							value: this.props.cliente._id,
							label: `${this.props.cliente.nombre} ${this.props.cliente.apellido}`,
						}
						: null,
				}}
				onValuesChange={(value) => {
					if (value.tipo_cambio) this.onChangeTipo(value.tipo_cambio);
					if (value.monto_pesos) this.onChangePesos(value.monto_pesos);
					if (value.monto_dolar) this.onChangeDolar(value.monto_dolar);
				}}
			>
				<Spin spinning={this.state.loading}>
					<Row gutter={[16, 0]}>
						<Col xs={24} lg={12}>
							<Form.Item
								label="Concepto"
								name="concepto"
								rules={[
									{
										required: true,
										message: "Por favor, ingrese el concepto",
									},
								]}
							>
								<Input placeholder="Concepto"></Input>
							</Form.Item>
						</Col>
						<Col xs={24} lg={12}>
							<Form.Item
								label="Fecha"
								name="fecha"
								rules={[
									{
										required: true,
										message: "Por favor, ingrese la fecha",
									},
								]}
							>
								<DatePicker className="width-100" disabled={disabled} />
							</Form.Item>
						</Col>
						<Col xs={24} lg={24}>
							<Form.Item label="Descripción" name="descripcion">
								<Input.TextArea placeholder="Descripción"></Input.TextArea>
							</Form.Item>
						</Col>
						<Col xs={24} lg={12}>
							<Form.Item
								label="Área"
								name="area_id"
								rules={[
									{
										required: true,
										message: "Seleccione el área",
									},
								]}
							>
								<SelectAreas
									onSelect={(area_id) => this.setState({ area_id })}
								/>
							</Form.Item>
						</Col>
						<Col xs={24} lg={12}>
							<Form.Item
								label="Rubro"
								name="rubro_id"
								rules={[
									{
										required: true,
										message: "Seleccione el rubro",
									},
								]}
							>
								<SelectRubros
									params={{
										area_id: this.state.area_id,
									}}
								/>
							</Form.Item>
						</Col>

						<Col xs={24} lg={12}>
							<Form.Item label="Cliente" name="cliente_id">
								<SelectClientes
									disabled={this.props.cliente}
									onSelect={(cliente_id) => this.setState({ cliente_id })}
								/>
							</Form.Item>
						</Col>
						<Col xs={24} lg={12}>
							<Form.Item
								label="Cuenta"
								name="cuenta_id"
								rules={[
									{
										required: true,
										message: "Seleccione la cuenta",
									},
								]}
							>
								<SelectCuentas />
							</Form.Item>
						</Col>
						<Col xs={24} lg={12}>
							<Form.Item
								label="Tipo"
								name="tipo"
								rules={[
									{
										required: true,
										message: "Seleccione el tipo",
									},
								]}
							>
								<Select
									onSelect={(value) => {
										this.setState({ tipoTransaccion: value });
									}}
									disabled={this.state.disabled}
									placeholder="Seleccione tipo"
								>
									<Option value={1}>Ingreso</Option>
									<Option value={2}>Egreso</Option>
									<Option value={3}>Traspaso</Option>
									<Option value={4}>
										Nulo{" "}
										<Text type="secondary" size="small">
											(No afecta saldos)
										</Text>
									</Option>
								</Select>
							</Form.Item>
						</Col>

						<Col xs={24} lg={12}>
							<Form.Item label="Tipo de pago" name="tipo_pago">
								<Select placeholder="Seleccione tipo">
									<Option value={1}>Efectivo</Option>
									<Option value={2}>Crédito</Option>
									<Option value={3}>Pago en especie</Option>
									<Option value={4}>Transferencia</Option>
									<Option value={5}>Saldo a favor</Option>
								</Select>
							</Form.Item>
						</Col>

						<Col xs={24} lg={12}>
							<Form.Item label="Pedidos" name="ordenes_id">
								<SelectOrdenes
									mode="multiple"
									params={{
										cliente_id: this.state.cliente_id,
										pendientes: true
									}}
								/>
							</Form.Item>
						</Col>

						<Col xs={24} lg={12}>
							<Form.Item
								label="Tipo Cambio"
								name="tipo_cambio"
							 
							>
								<InputNumber
									min={1}
									defaultValue={0}
									className="width-100"
									formatter={(value) =>
										`$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
									}
									parser={(value) =>
										value ? value.replace(/\$\s?|(,*)/g, "") : 0
									}
								/>
							</Form.Item>
						</Col>
						<Col xs={24} lg={12}>
							<Form.Item
								label="Monto (Pesos)"
								name="monto_pesos"
								rules={[
									{
										required: true,
										message: "Ingrese el monto",
									},
								]}
							>
								<InputNumber
									disabled={this.props.transaccion_id}
									min={0.01}
									defaultValue={0}
									className="width-100"
									formatter={(value) =>
										`$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
									}
									parser={(value) =>
										value ? value.replace(/\$\s?|(,*)/g, "") : 0
									}
								/>
							</Form.Item>
						</Col>
						<Col xs={24} lg={12}>
							<Form.Item
								label="Monto (Dolar)"
								name="monto_dolar"
								rules={[
									/*{
										required: true,
										message: "Ingrese el monto",
									},*/
								]}
							>
								<InputNumber
									formatter={(value) =>
										`$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
									}
									parser={(value) =>
										value ? value.replace(/\$\s?|(,*)/g, "") : 0
									}
									// disabled={this.props.transaccion_id}

									min={0.01}
									defaultValue={0}
									className="width-100"
								/>
							</Form.Item>
						</Col>
					</Row>

					{this.state.tipoTransaccion === 3 ? (
						<Row>
							<Col xs={24}>
								<Form.Item
									label="Cuenta"
									name="cuenta_destino_id"
									rules={[
										{
											required: true,
											message: "Por favor, seleccione la cuenta",
										},
									]}
								>
									<Select placeholder="Seleccione cuenta">
										{this.state.cuentas.data.map(
											({ _id, nombre, logo, color }, index) => {
												if (
													this.state.cuentaSeleccionada.toString() ===
													_id.toString()
												)
													return null;
												else
													return (
														<Option
															style={{ margin: "2px 0 2px 0" }}
															value={_id}
														>
															<CustomAvatar
																image={logo}
																name={nombre}
																color={color}
																size="small"
																style={{
																	marginRight: "5px",
																}}
															/>
															{nombre}
														</Option>
													);
											},
										)}
									</Select>
								</Form.Item>
							</Col>
						</Row>
					) : null}

					<Row>
						<Col xs={24}>
							<Form.Item name="comprobantes" label="Comprobantes">
								<ImagesUploader
									onRemove={({ name, fromDB }) => {
										if (fromDB)
											axios.put(`/transacciones/` + this.props.transaccion_id, {
												delete_comprobante: name,
											});
									}}
								/>
							</Form.Item>
						</Col>
					</Row>
				</Spin>
			</Form>
		);
	}
}

export default function (props) {
	const {
		visible = false,
		onClose = () => { },
		transaccion_id,
	} = props;

	return (
		<Modal
			open={visible}
			onCancel={onClose}
			title={transaccion_id ? "Editar Transacción" : "Nueva Transacción"}
			closable={true}
			maskClosable={true}
			destroyOnClose={true}
			zIndex={1000}
			okText="Guardar"
			cancelText="Cancelar"
			okButtonProps={{
				form: "form-transaccion",
				key: "submit",
				htmlType: "submit",
			}}
		>
			<ModalTransacciones {...props} />
		</Modal>
	);
}
