import React, { Component } from "react";
import { Row, Col, Space, Modal, List, Upload, Button, Typography, Form, DatePicker, Input, InputNumber, Select, message, Spin, Cascader, Tag, AutoComplete, Divider } from 'antd';


import { FaFileCsv } from "react-icons/fa";

import { IconTemplate } from "../../Widgets/Iconos";


import axios from "axios"
const { Title, Text, Paragraph } = Typography;

/**
 *
 *
 * @class ModalCSV
 * @extends {Component}
 * @description Agregar multiples transacciones 
 */
class ModalCSV extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false
        }
    }


    /**
     *
     *
     * @param {*} info
     * @memberof ModalCSV
     * @description Cuando el archivo se sube o hay un error, queda almacenado aquí
     */
    onChangeUpload = (info) => {
        const { status } = info.file;
        if (status === 'done' || status === 'error') {
            if (status === 'done')
                Modal.success({
                    title: "Hemos subido el archivo exitosamente.",
                    content: "El archivo ha sido subido y los productos han sido guardado exitosamente",
                    onOk: (...event) => {
                        this.props.onSuccess(true)
                    }
                })
            else 
                Modal.warning({
                    title: "Hemos subido el archivo con algunos errores.",
                    content: "Algunos de los productos no se han podido guardar exitosamente, revise el documento",

                    okText: "Cerrar",
                    onOk: () => {
                        this.props.onSuccess(false)
                    }
                })
        } 
    }

    render() {


        return (
            <Form
                layout="vertical"

                name="form-productos"
                id="form-productos"
                ref={this.modalRef}
                onFinish={this.onFinish}
            >
                <Spin spinning={this.state.loading}>
                    <Title className="mb-2 mt-1" level={5} style={{ textAlign: "center" }}> Toma en cuenta las siguientes consideraciones.</Title>
                    <List
                        bordered={false}
                        size="small"
                        dataSource={[
                            "Disminuir el uso de acentos, o evitarlo para prevenir una mala lectura",
                            "Eliminar el formato de moneda ($123)",
                            "Asegurarse que el dato esté escrito correctamente igual que en el sistema (incluyendo mayúsculas y acentos)",
                            "Descarga el template CSV para subir múltiples productos."
                        ]}
                        renderItem={item => <List.Item>{item}</List.Item>}
                    />
                    <div style={{ marginTop: "2em", textAlign: "center" }}>
                        <a download href="/files/template_productos.csv"> 

                            <IconTemplate />
                        </a>
                    </div>

                    <Row style={{ width: "100%" }} className="mt-2 mb-2">
                        <Upload.Dragger
                            {...{
                                name: 'file',
                                multiple: false,
                                maxCount: 1,
                                action: axios.defaults.baseURL + "/productos/csv",
                                headers: {
                                    Authorization: sessionStorage.getItem("token")
                                },
                                onChange: this.onChangeUpload
                                // onDrop(e) {
                                //     console.log('Dropped files', e.dataTransfer.files);
                                // },
                            }}>
                            <div style={{ margin: "0 1em" }}>
                                <p className="ant-upload-drag-icon">
                                    <FaFileCsv style={{ fontSize: "3em" }} />
                                </p>
                                <p className="ant-upload-text">Haga clic o arrastre el archivo CSV a esta área para cargarlo</p>
                                <p className="ant-upload-hint">Solo es posible subir archivos de tipo CSV</p>
                            </div>
                        </Upload.Dragger>
                    </Row>
                </Spin>
            </Form>
        )
    }
}



export default function (props) {
    const { visible = false, onClose = () => { }, transaccion_id, clasificacion } = props

    return <Modal
       open={visible}
        onCancel={onClose}

        title={"Agregar Multiples Productos"}
        // footer={null}
        closable={true}
        maskClosable={true}
        destroyOnClose={true}
        zIndex={1000}
        okText="Subir Productos"
        okButtonProps={{ form: 'form-transaccion', key: 'submit', htmlType: 'submit' }}
    >
        <ModalCSV {...props} />
    </Modal>

}