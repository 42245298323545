import React, { Component } from "react";
import { Empty, Menu, Layout, Row, Col, Divider, Form, message, Modal, Pagination, List, Input, Checkbox, Spin, Card } from "antd";
import axios from 'axios'
import { SyncOutlined } from '@ant-design/icons'


//componentes
import { User } from '../../../Hooks/Logged';
import Avatar from '../../Widgets/Avatar/Avatar';
import PageHeader from '../../Widgets/PageHeader/PageHeader';
import OrdenDrawer from './OrdenDrawer';
import { CardTaskStep, CardTaskClient } from '../../Widgets/Cards';

//css



const { Content } = Layout;

/**
 * @export
 * @class DashboardLogistica
 * @extends {Component}
 */
class DashboardLogistica extends Component {

    constructor(props) {

        super(props)
        this.state = {
            loading: false,
            data: [],
        }
    }


    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        window.addEventListener('scroll', this.handleScroll)
    }

    componentDidUpdate = (prevProps, prevState) => {

    }

    componentWillUnmount = () => {
        window.removeEventListener('scroll', this.handleScroll, true)
    }

    /**
    * @memberof Cuentas
    * @method handleScroll
    * @description Se ejecuta al hacer scroll hasta abajo, para obtener mas registros
    */
    handleScroll = () => {
        const windowHeight = window.innerHeight;
        const scrollY = window.scrollY || window.pageYOffset;
        const bodyHeight = document.body.offsetHeight;

        if (windowHeight + scrollY >= bodyHeight && !this.state.loading) {
            this.setState({loading: true})
            console.log("loading"); 
        }
    }

    render() {

        const { data } = this.state;


        return (
            <>  
                <PageHeader
                    className="admin-page-header"
                    title="Packing List"
                />
                <Content className="content-bg pd-1">
                    <Row gutter={[8, 8]} className="witdh-100" align="start">
                        <Col xs={24} sm={24} md={12} lg={12} xl={6} xxl={6} >
                            <div className="col-list-task-margin col-list-task-bg">
                                <List>
                                    <CardTaskStep
                                        color={'#ED0000'}
                                        title={"Nuevas"}
                                        cantidad={0}
                                    />
                                    {
                                        data.map(orden => <CardTaskClient
                                            color={'#ED0000'}
                                            monto={263045}
                                            tags={['Pago Pendiente']}
                                            onClick={()=>this.setState({ drawer_orden_vidible: true })}
                                        />)
                                    }
                                </List>
                            </div>
                        </Col>

                        <Col xs={24} sm={24} md={12} lg={12} xl={6} xxl={6} >
                            <div className="col-list-task-margin col-list-task-bg">
                                <List>
                                    <CardTaskStep
                                        color={'#DBC500'}
                                        title={"En proceso"}
                                        cantidad={0}
                                    />
                                    {
                                        data.map(orden => <CardTaskClient
                                            color={'#DBC500'}
                                            monto={2630}
                                            tags={['Pagado', 'En Picking' ]}
                                        />)
                                    }
                                </List>
                            </div>
                        </Col>
                        <Col xs={24} sm={24} md={12} lg={12} xl={6} xxl={6} >
                            <div className="col-list-task-margin col-list-task-bg">
                                <List>
                                    <CardTaskStep
                                        color={'#1DD200'}
                                        title={"Completadas"}
                                        cantidad={0}
                                    />
                                    {
                                        data.map(orden => <CardTaskClient
                                            color={'#1DD200'}
                                            monto={2630}
                                            
                                        />)
                                    }
                                </List>
                            </div>
                        </Col>
                    </Row>
                    <Row className="mt-3 mb-3">
                        {<Spin indicator={<SyncOutlined spin style={{ display: "block", margin: "auto", fontSize: 30 }} />} spinning={this.state.loading} size={"large"} style={{ display: "block", margin: "auto" }} />}
                    </Row>


                </Content>
                <OrdenDrawer
                	visible={this.state.drawer_orden_vidible}
                	onClose={()=>{
                		this.setState({ drawer_orden_vidible: false })
                	}}
                />
            </>
        )
    }
}


export default function (props) {

    return <DashboardLogistica {...props}/>
}
