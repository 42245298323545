import React, { Component } from "react";
import { Row, Col, Modal, Form, Input, message, Spin, InputNumber } from 'antd';
import axios from "axios"
import { ImagesUploader } from "../../Widgets/Uploaders";


class ModalProveedores extends Component {


    constructor(props) {
        super(props);
        this.state = {
            accionModal: '',
            loading: false,
            datosConsultados: false,
            return: false,
            tipo: 1,
        }
    }

    formRef = React.createRef();

    componentDidMount() {
        if (this.props.proveedor_id) {
            this.get()
        }
    }

    /**
     * @memberof ModalProveedores
     * @method onFinish
     * @description Se ejecuta al dar enter al formulario
     * 
     */
    onFinish = (values) => {

        const formData = new FormData()

        formData.appendMultiple({
            ...values,
            imagenes: undefined,
        })

        if(values.imagenes){
            values.imagenes.forEach(img => {
                if(!img.fromDB) formData.append('imagenes', img, img.name)
            })
        }

        if (this.props.proveedor_id) {
            this.update(formData)
        } else {
            this.add(formData)
        }

    }

    /**
     * @memberof ModalProveedores
     * @method add
     * @description Añade un proveedor a la BD
     *
     */
    add = (values) => {
        axios.post('/proveedores', values,{
            headers: {
                "Content-Type": 'multipart/form-data'
            }
        }).then(response => {
            message.success('Proveedor creado')
            this.props.onClose()
        }).catch(error => {
            message.error(error?.response?.data?.message ?? 'Error al crear el proveedor')
            console.log(error)
        })
    }

    /**
     * @memberof ModalProveedores
     * @method get
     * @description Obtiene un proveedor de la DB
     *
     */
    get = () => {
        this.setState({ loading: true })
        axios.get('/proveedores/'+ this.props.proveedor_id).then(({data}) => {
            
            this.formRef.current.setFieldsValue({
                ...data,
                imagenes: data.imagenes ? data.imagenes.map(f => ({
                    uid: f.file,
                    name: f.name,
                    status: 'done',
                    fromDB: true,
                    url: axios.defaults.baseURL + "/proveedores/" + data._id + "?imagen=" + f.file + "&Authorization=" + sessionStorage.getItem("token")
                })) : null
            })

        }).catch(error => {
            message.error('Error al obtener el proveedor')
            console.log(error)
        }).finally(() => this.setState({ loading: false }))
    }

    /**
     * @memberof ModalProveedores
     * @method update
     * @description Actualiza la información de un proveedor
     * 
     */
    update = (values) => {
        this.setState({ loading: true })
        axios.put('/proveedores/' + this.props.proveedor_id, values, {
            headers: {
                "Content-Type": 'multipart/form-data'
            }
        }).then(response => {
            message.success('Proveedor Actualizado')
            this.props.onClose()
        }).catch(error => {
            message.error(error?.response?.data?.message ?? 'Error al actualizar el proveedor')
            console.log(error)
        }).finally(() => this.setState({ loading: false }))
    }

    render() {

        return (
            <Spin spinning={this.state.loading}>
                <Form
                    id="form-proveedor"
                    layout={"vertical"}
                    onFinish={this.onFinish}
                    ref={this.formRef}
                    className="mt-2"
                >
                    <Row justify="center" className="center">

                        <Col span={20}>
                            
                            <Form.Item
                                name="nombre"
                                label="Nombre"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Ingrese el nombre',
                                    }
                                ]}
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item
                                name="razon_social"
                                label="Razón Social"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Ingrese la razón social',
                                    }
                                ]}
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item
                                name="rfc"
                                label="RFC"
                                rules={[
                                    { 
                                        pattern: /^([A-ZÑ\x26]{3,4}([0-9]{2})(0[1-9]|1[0-2])(0[1-9]|1[0-9]|2[0-9]|3[0-1]))([A-Z\d]{3})?$/, 
                                        message: "El RFC es inválido" 
                                    }
                                ]}
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item
                                name="contacto"
                                label="Contacto"
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item
                                name="email"
                                label="Correo Contacto"
                                rules={[
                                    {
                                        type: "email",
                                        message: "Ingrese un correo electronico válido"
                                    }
                                ]}
                            >
                                <Input />
                            </Form.Item>
                           

                            


                            

                            {/*<Form.Item
                                name="imagenes"
                                label="Imagenes"
                            >
                                <ImagesUploader
                                    onRemove={({name, fromDB}) => {
                                        if(fromDB) axios.put(`/proveedores/${this.props.proveedor_id}`, {
                                            delete_image: name
                                        })
                                    }}
                                />
                            </Form.Item>*/}
                        </Col>
                    </Row>
                </Form>
            </Spin>
        )
    }
}



export default function Modals(props) {
    const { visible = false, onClose = () => { }, proveedor_id } = props

    return <Modal
        visible={visible}
        onCancel={onClose}
        title={proveedor_id ? "Editar Proveedor" : "Crear Proveedor"}
        destroyOnClose={true}
        cancelText="Cancelar"
        okText="Guardar"
        okButtonProps={{ form: 'form-proveedor', key: 'submit', htmlType: 'submit' }}
    >
        <ModalProveedores {...props} />
    </Modal>

}