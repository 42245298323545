import React, { Component,useContext } from "react";
import { Layout, Row, Col, message, List, Spin, } from "antd";
import axios from 'axios'
import { SyncOutlined } from '@ant-design/icons'
import { Link, useNavigate, useParams } from "react-router-dom";
import User from "../../../Hooks/Logged";
import usePermissions from "../../../Hooks/usePermissions";
import useSearch from '../../../Hooks/useSearch';


//componentes
import PageHeader from '../../Widgets/PageHeader/PageHeader';
import { CardTaskStep, CardTaskClient } from '../../Widgets/Cards';
import OrdenDrawer from './OrdenDrawer';

const { Content } = Layout;

/**
 * @export
 * @class DashboardLogistica
 * @extends {Component}
 */
class DashboardLogistica extends Component {

    constructor(props) {

        super(props)
        this.state = {
            loading: false,
            data: [],
            limit: 5,
            page: 1,
            nuevas: [],
            proceso: [],
            enviadas: [],
            completadas: []
        }
    }


    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        window.addEventListener('scroll', this.handleScroll)
        this.getOrdenes()
    }

    componentDidUpdate = (prevProps, prevState) => {

    }

    componentWillUnmount = () => {
        window.removeEventListener('scroll', this.handleScroll, true)
    }

    /**
    * @memberof Cuentas
    * @method getOrdenes
    * @description Obtiene las ordenes agrupadas por estatus de logistica
    */
    getOrdenes = ({
        page = this.state.page,
        limit = this.state.limit,
    } = this.state) => {
        this.setState({ loading: true })
        axios.get('/ordenes', {
            params: {
                limit,
                page,
                group_logistica: true
            }
        }).then(({ data }) => {
            console.log("data", data);

            let ordenes_nuevas = data.data.find(ordenes => ordenes._id === 0)?.ordenes ?? []
            let ordenes_nuevas_count = data.count_group.find(ordenes => ordenes._id === 0)?.total ?? 0

            let ordenes_proceso = data.data.find(ordenes => ordenes._id === 1)?.ordenes ?? []
            let ordenes_proceso_count = data.count_group.find(ordenes => ordenes._id === 1)?.total ?? 0

            let ordenes_enviadas = data.data.find(ordenes => ordenes._id === 2)?.ordenes ?? []
            let ordenes_enviadas_count = data.count_group.find(ordenes => ordenes._id === 2)?.total ?? 0

            let ordenes_completadas = data.data.find(ordenes => ordenes._id === 3)?.ordenes ?? []
            let ordenes_completadas_count = data.count_group.find(ordenes => ordenes._id === 3)?.total ?? 0


            this.setState(prevState => ({
                getOrdenes: false,
                page,
                limit,
                total: data.total,
                ordenes_nuevas_count,
                ordenes_proceso_count,
                ordenes_enviadas_count,
                ordenes_completadas_count,
                nuevas: page === 1 ? ordenes_nuevas : [...prevState.nuevas, ...ordenes_nuevas],
                proceso: page === 1 ? ordenes_proceso : [...prevState.proceso, ...ordenes_proceso],
                enviadas: page === 1 ? ordenes_enviadas : [...prevState.enviadas, ...ordenes_enviadas],
                completadas: page === 1 ? ordenes_completadas : [...prevState.completadas, ...ordenes_completadas],
            }));


        }).catch(error => {
            message.error(error.response?.data?.message ?? 'Error al obtener los pedidos')
        }).finally(() => this.setState({ loading: false }))

    }

    /**
    * @memberof Cuentas
    * @method handleScroll
    * @description Se ejecuta al hacer scroll hasta abajo, para obtener mas registros
    */
    handleScroll = () => {
        const windowHeight = window.innerHeight;
        const scrollY = window.scrollY || window.pageYOffset;
        const bodyHeight = document.body.offsetHeight;

        let count = (this.state.page * this.state.limit)

        if (windowHeight + scrollY >= bodyHeight && this.state.loading === false && count < this.state.total) {
            this.state.loading = true
            this.setState({ loading: true })
            console.log("loading");
            this.getOrdenes({ page: this.state.page + 1 })
        }
    }

    render() {

        const { data, nuevas, proceso, enviadas, completadas } = this.state;


        return (
            <>
                <PageHeader
                    className="admin-page-header"
                    title="Logistica"
                />
                <Content className="content-bg pd-1">
                    <Row gutter={[8, 8]} className="witdh-100" align="start" justify="space-between">
                        <Col xs={24} sm={24} md={12} lg={12} xl={6} xxl={6} >
                            <div className="col-list-task-margin col-list-task-bg">
                                <List>
                                    <CardTaskStep
                                        color={'#ED0000'}
                                        title={"Nuevas"}
                                        cantidad={this.state.ordenes_nuevas_count ?? 0}
                                    />
                                    {
                                        nuevas.map(orden => <CardTaskClient
                                            color={'#ED0000'}
                                            {...orden}
                                            onClick={() => this.setState({ drawer_orden_vidible: true, orden_id: orden._id })}
                                        />)
                                    }
                                </List>
                            </div>
                        </Col>

                        <Col xs={24} sm={24} md={12} lg={12} xl={6} xxl={6} >
                            <div className="col-list-task-margin col-list-task-bg">
                                <List>
                                    <CardTaskStep
                                        color={'#DBC500'}
                                        title={"En proceso"}
                                        cantidad={this.state.ordenes_proceso_count ?? 0}
                                    />
                                    {
                                        proceso.map(orden => <CardTaskClient
                                            color={'#DBC500'}
                                            {...orden}
                                            onClick={() => this.setState({ drawer_orden_vidible: true, orden_id: orden._id })}
                                        />)
                                    }
                                </List>
                            </div>
                        </Col>
                        <Col xs={24} sm={24} md={12} lg={12} xl={6} xxl={6} >
                            <div className="col-list-task-margin col-list-task-bg">
                                <List>
                                    <CardTaskStep
                                        color={'#5C54E0'}
                                        title={"Enviadas"}
                                        cantidad={this.state.ordenes_enviadas_count ?? 0}
                                    />
                                    {
                                        enviadas.map(orden => <CardTaskClient
                                            color={'#5C54E0'}
                                            {...orden}
                                            onClick={() => this.setState({ drawer_orden_vidible: true, orden_id: orden._id })}
                                        />)
                                    }
                                </List>
                            </div>
                        </Col>

                        <Col xs={24} sm={24} md={12} lg={12} xl={6} xxl={6} >
                            <div className="col-list-task-margin col-list-task-bg">
                                <List>
                                    <CardTaskStep
                                        color={'#1DD200'}
                                        title={"Completadas"}
                                        cantidad={this.state.ordenes_completadas_count ?? 0}
                                    />
                                    {
                                        completadas.map(orden => <CardTaskClient
                                            color={'#1DD200'}
                                            {...orden}
                                            onClick={() => this.setState({ drawer_orden_vidible: true, orden_id: orden._id })}
                                        />)
                                    }
                                </List>
                            </div>
                        </Col>
                    </Row>
                    <Row className="mt-3 mb-3">
                        {<Spin indicator={<SyncOutlined spin style={{ display: "block", margin: "auto", fontSize: 30 }} />} spinning={this.state.loading} size={"large"} style={{ display: "block", margin: "auto" }} />}
                    </Row>


                </Content>
                <OrdenDrawer
                    visible={this.state.drawer_orden_vidible}
                    onClose={() => {
                        this.setState({
                            drawer_orden_vidible: false
                        })

                        if (this.state.getOrdenes === true) {
                            this.getOrdenes({ page: 1 })
                        }
                    }}
                    orden_id={this.state.orden_id}
                    getOrdenes={() => this.setState({ getOrdenes: true })}
                />
            </>
        )
    }
}


export default function (params) {
    const user = useContext(User);
    const { search, setShow } = useSearch()

    const permisos = usePermissions(user?.rol_id?.permisos, {
        canAccess: ["logistica",  "access"],

    });
    return <DashboardLogistica
        {...params}
        params={useParams()}
        navigate={useNavigate()}
        {...permisos}
        search={search}
        setShowSearch={setShow} />
}
