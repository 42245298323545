import React, { Component } from "react";
import { Row, Col , Form, message, InputNumber, Typography } from 'antd';
import axios from "axios"
import {  MinusCircleOutlined,  } from "@ant-design/icons";

//componentes
// import SelectCliente from '../../Widgets/Inputs/SelectClientes';
// import SelectProducto from '../../Widgets/Inputs/SelectProductos';

// import User from "../../../Hooks/Logged";
// import { getResponseError } from "../../Utils";
// import usePermissions from "../../../Hooks/usePermissions";
// import { AvatarLoader, ImagesUploader } from "../../Widgets/Uploaders";
// import SelectVariantes from "../../Widgets/Inputs/SelectVariantes";
import ImageProducto from "../../Widgets/ImageProducto"

// const { Option } = Select;
const {Text} = Typography
const Decimal = require('decimal.js');

/**
 * @class ProductoForm
 * @description Modal para el CRUD de Productos
 */
export default class ProductoForm extends Component {

    constructor(props) {
        super(props)
        this.state = {
        	producto: this.props.data?.producto_id ?? null,
            variante: this.props.data?.variante_id ?? null,
        	total: this.props.data?.total ?? 0,
        	cantidad: this.props.data?.cantidad ?? 0,
            producto_id: null
        }
    }

    componentDidMount() {
        console.clear()
        console.log(this.props)
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token')
    }

    formRef = React.createRef();


    /**
     * @method getProducto
     * @description Obtiene información del producto seleccionado
     */
    getVariante = (variante_id) => {
    	if(variante_id)
	    	axios.get('/variantes/' + variante_id)
	        .then(({ data }) => {
	            this.setState({ variante: data },()=>this.onChange(this.state.cantidad))
	        }).catch(error => {
	            message.error('Error al obtener el producto')
	        })
	    else
	    	this.setState({variante_id: null, variante: null})
    }




    onChange = (cantidad) => {
    	let total = 0
    	if(this.state.variante?.precio && cantidad > 0) 
    		total = Decimal(cantidad).mul(this.state.variante.precio).toDecimalPlaces(2).toNumber();

    	this.setState({total, cantidad})
    	this.props.updateTotal(this.props.name, cantidad)
    }

    render() {

        let { producto, variante } = this.state;
        let { data } = this.props;

        return (
            <Row gutter={[8,8]} className="mb-1">
                <Col span={11} className="">
                    <Form.Item
                        {...this.props.restField}
                        name={[this.props.name, 'precio']}
                        className="m-0"
                        hidden
                    />
                    <Form.Item
                        {...this.props.restField}
                        name={[this.props.name, 'variante_id']}
                        className="m-0 flex-left-column"
                    >   
                        <div className="flex-left">
                            <ImageProducto
                                producto={producto}
                                variante={variante}
                            />
                            <div className="flex-left-column" style={{ marginLeft: "4px"}}>
                                <Text strong>{producto?.modelo}</Text>
                                <Text>{variante?.sabor}</Text>
                                <small>$ {variante?.precio?.toMoney(true)} MXN</small>
                                {data.descuento_item ? <small className="text-red">- $ {data?.descuento_item?.toMoney(true)} MXN</small> : null }
                                {data?.precio_actual && data.descuento_item ? <small>$ {data?.precio_actual?.toMoney(true)} MXN</small> : null }
                            </div>
                        </div>

                    </Form.Item>
                </Col>
               
                <Col span={5} className=" center">
                    <Form.Item
                        {...this.props.restField}
                        className="m-0"
                        name={[this.props.name, 'cantidad']}
                        rules={[
                            {
                                required: true,
                                message: 'Ingresa la cantidad',
                            },
                        ]}
                    >
                        <InputNumber 
                        	placeholder="Cant."
                            min={1}
                        	defaultValue={this.state.cantidad}
                        	onChange={this.onChange}
                            max={this.props.data?.disponible}
                            disabled={this.props.disabled}
                        />
                    </Form.Item>
                </Col>
                 <Col span={7} className="flex-right ">
                    <Text>$ {this.state.total.toMoney(true,2)}<small>MXN</small></Text>
                </Col>
                <Col span={1} className="center">
                    <MinusCircleOutlined onClick={() => {
                        if(!this.props.disabled)
                            this.props.remove()
                    }} />
                </Col>
            </Row>                       
        )
    }
}
