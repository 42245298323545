import React, { Component, useContext } from 'react';
import { Layout, Row, Col, Tooltip, Button, List, Typography, Card, message, Spin, Popconfirm, Statistic, Checkbox, Modal, Space } from "antd";

import axios from 'axios';
import { Link, useNavigate, useParams } from "react-router-dom";
import StpLogin from 'stp-widget/dist/bxnk-banking-widget.es.js';

//Componentes
import User from "../../../Hooks/Logged";
import { IconCheckGreen } from "../../Widgets/Iconos";
import usePermissions from "../../../Hooks/usePermissions";
import PageHeader from '../../Widgets/PageHeader/PageHeader';
import FloatingButton from "../../Widgets/Floating Button/FloatingButton";

//Modales
import ModalClasificacionOrdenes from "./ModalClasificacionOrdenes"

const { Content } = Layout;
const { Text } = Typography;

const moment = require('moment')

/**
 *
 *
 * @export
 * @class Ordenes
 * @extends {Component}
 * @description Vista del listado de Ordenes de Shopify
 */
class Ordenes extends Component {

    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            cuenta: {},
            ordenes: {
            	data: [],
            	limit: 100,
            	pageInfo: undefined,
            	total: 0
            },
            seleccionadas: {},

        }
    }

    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        this.getOrdenes()
    }

    /**
    * @memberof Ordenes
    * @description Obtiene una lista de ordenes provenientes directamente del api de shopify
    */
    getOrdenes = ({
    	limit = this.state.ordenes.limit,
        pageQuery = this.state.ordenes.pageQuery,
    } = this.state.ordenes) => {

        this.setState({ loading: true })
        axios.get('/shopify/ordenes', {
        	params:{
        		limit,
                pageQuery
        	}
        }).then(({ data }) => {
        	console.log("data", data);
        	this.setState({
        		ordenes:{
        			...this.state.ordenes,
        			data: data.data,
        			total: data.count,
        			pageInfo: data.pageInfo,
                    pageQuery
        		}
        	})
            
        }).catch(error => {
            message.error(error.response?.data?.message ?? 'Error al obtener los pedidos')
        }).finally(()=>this.setState({loading: false}))

    }

    /**
    *
    * @memberof Ordenes
    * 
    * @method clasificarTransacciones
    * @description Abre el modal si se seleccionaron ordenes
    */
    clasificarTransacciones = () => {
        const selecionadasLenght = Object.keys(this.state.seleccionadas).length
        if (selecionadasLenght === 0)
            return Modal.error({
                title: "Debe seleccionar algunos pedidos",
                content: "Para poder agregar pedidos "
            })
        this.setState({ modalClasificarVisible: true })
    }


    render() {

    	const { cuenta } = this.state;

        return (
            <>
                <Spin spinning={false}>
                    <PageHeader
                        className="admin-page-header"
                        title={`Lista de Pedidos de Shopify`}
                    />
                   
                    <Content className="admin-content content-bg pd-1">
                        <List
                            loading={this.state.loading}
                            className="component-list"
                            itemLayout="horizontal"
                            dataSource={this.state.ordenes.data}
                           
                            header={<Row className="header-list width-100 pl-1 pr-1">

                                <Col span={1} className=" center">
                                    <Text strong></Text>
                                </Col>
                                <Col span={3} className=" center">
                                    <Text strong>Pedido</Text>
                                </Col>
                                <Col xs={6} className="center">
                                    <Text strong>Artículos</Text>
                                </Col>
                                <Col xs={5} className="center">
                                    <Text strong>Cliente</Text>
                                </Col>
                                <Col xs={4} className="center">
                                    <Text strong>Fecha</Text>
                                </Col>
                                <Col xs={4} className="center">
                                    <Text strong>Monto</Text>
                                </Col>
                                <Col xs={1} className="center">
                                    <Text strong></Text>
                                </Col>

                            </Row>
                            }

                            renderItem={item => {

                                const { total_price, customer, created_at, line_items, transaccion, id_transaction, id, referencia_numerica, _id, name } = item;


                                return <List.Item className="component-list-item">
                                    <Tooltip  trigger={(item.orden_id) ? ["hover"] : []} title=" Este pedido se clasificó previamente" placement="topLeft">
                                        <Card
                                            style={{ ...(item.orden_id) ? { background: "rgba(0,0,0,0.1)" } : {} }}
                                            className="card-list">
                                            <Row className="width-100">
                                                <Col span={1} className="center">

                                                    <Checkbox className="radio-purple"
                                                        disabled={(item.orden_id)}
                                                        checked={(item.transaccion == null) ? this.state.seleccionadas[id] : true}
                                                        onChange={value => {
                                                            this.setState(state => {
                                                                if (value.target.checked === true)
                                                                    state.seleccionadas[id] = item
                                                                else
                                                                    delete state.seleccionadas[id]
                                                                return state;
                                                            })
                                                        }}
                                                    ></Checkbox>

                                                </Col>
                                                <Col span={3} className="center">
                                                    <Text>{name}</Text>
                                                </Col>
                                                <Col span={6} className="center">
                                                    <Text>{line_items.length} artículos</Text>
                                                </Col>
                                                <Col span={5} className="center">
                                                    <Text>{customer?.first_name ?? '-'} {customer?.last_name ?? ""}</Text>
                                                </Col>
                                                <Col span={4} className="center">
                                                    <Text strong>{moment(created_at).format('DD-MM-YYYY HH:mm')}</Text>
                                                </Col>
                                                <Col span={4} className="center">
                                                    <Statistic 
                                                    	value={total_price} 
                                                    	valueStyle={{ fontSize: '16px', fontWeight: 'bold', color: '#2c9d37' }} 
                                                    	prefix={'$'} suffix={"MXN"} />
                                                </Col>
                                               
                                            </Row>
                                        </Card>
                                    </Tooltip>
                                </List.Item>
                            }}
                        />
                        <Row>
                            <Col span={24}>
                                <Space>
                                    <Button
                                        disabled={!this.state.ordenes?.pageInfo?.prevPage}
                                        onClick={()=>{
                                            this.getOrdenes({pageQuery:this.state.ordenes?.pageInfo?.prevPage})
                                        }}
                                    >
                                        Anterior
                                    </Button> 
                                    <Button
                                        disabled={!this.state.ordenes?.pageInfo?.nextPage}
                                        onClick={()=>{
                                            this.getOrdenes({pageQuery:this.state.ordenes?.pageInfo?.nextPage})
                                        }}
                                    >
                                        Siguiente
                                    </Button>
                                </Space>
                            </Col>
                        </Row>

                        <FloatingButton title="Clasificar" icon={<IconCheckGreen />} onClick={() => this.clasificarTransacciones()} />
                    </Content>
                </Spin>

                <ModalClasificacionOrdenes
                    visible={this.state.modalClasificarVisible}
                    onClose={(flag) => {
                        this.setState({modalClasificarVisible: false})
                        if(flag === true){
                            this.setState({seleccionadas: {}})
                            this.getOrdenes()
                        }
                    }}
                    seleccionadas={this.state.seleccionadas}
                />
                
            </>
        )
    }
}


export default function (params) {

    const user = useContext(User);

    const permisos = usePermissions(user?.rol_id?.permisos, {
        editarRubros: ["rubros", "edit"],
        deleteRubros: ["rubros", "delete"],
        createRubros: ["rubros", "create"],
    });

    return <Ordenes {...params} params={useParams()} navigate={useNavigate()} {...permisos}/>
}