
import React, { Component, createRef } from 'react';
import { Button, Space, Image, Typography, InputNumber, Table, Form, message, Modal } from 'antd';
import axios from 'axios';

import { DeleteOutlined, EditOutlined } from '@ant-design/icons'
import { renderTransferenciasFolio } from "../../Utils";
import { FaShopify } from 'react-icons/fa';

import ModalInventarios from './ModalInventarios';
import ImageProducto from '../../Widgets/ImageProducto';
import { Rappi } from '../../Widgets/Iconos';
const { Text } = Typography

/**
 *
 *
 * @export
 * @class TableInventarioVariantes
 * @extends {Component}
 * 
 * @description Permite gestionar la tabla de variantes del inventario de un almacen 
 */
export default class TableInventarioVariantes extends Component {

    /**
     *
     *
     * @memberof TableInventarioVariantes
     * @state orden Almacen la orden Actual
     * @state dataSource Lista de elementos de la tabla
     */
    state = {
        producto_id: this.props.producto_id,
        inventario_id: this.props.id,
        id: null,
        inventario: {
            data: [],
            page: 1,
            total: 0,
        },
        dataSource: [],
        modalVisible: false,
    }

    /**
     *
     *
     * @memberof TableInventarioVariantes
     * @description Asignamos a la tabla las variantes
     */
    componentDidMount() {
        console.log('mmontando tabla')
        this.getVariantes()
    }

    /**
     *
     *
     * @memberof TableInventarioVariantes
     * @description Asignamos a la tabla las variantes
     */
    componentDidUpdate(prevProps) {
        if (this.props.almacen_id !== prevProps.almacen_id) {
            this.getVariantes({ page: 1, almacen_id: this.props.almacen_id })
        }
    }


    setData = (data) => {
        this.setState({ dataSource: data })
        this.formTableRef.current.setFieldsValue(this.setDetalle(data))
    }


    getVariantes = ({
        page = this.state.inventario.page,
        limit = this.state.inventario.limit,
        search = this.props.search,
        almacen_id = this.props.almacen_id,
        producto_id = this.props.id
    } = this.state.inventario) => {
        this.setState({ loading: true })

        console.log('cargando...', almacen_id)
        axios.get('/inventarios', {
            params: {
                limit,
                page,
                search,
                grupo_by_product: true,
                almacen_id,
                producto_id
            }
        })
            .then(({ data }) => {
                console.log('get inv variantes', data.data[0])
                this.setData(data.data[0]?.inventario)

            })
            .catch(res => {
                message.error(res.response.data ?? "No fue posible obtener el inventario")
            })
            .finally(() => this.setState({ loading: false }))
    }
    /**
        *
        *
        * @memberof TableOrdenCompra
        * @description Formatea el arreglo a un objeto de indices para que pueda ser interpretado por el formulario.
        */
    setDetalle = (arreglo = []) => {
        let values = {}
        for (let index = 0; index < arreglo.length; index++) {
            const element = arreglo[index]
            values[index] = element
        }

        return values
    }



    /**
      *
      *
      * @memberof TableInventarioVariantes
      * @description Eliminamos el producto.
      */
    delete = (id) => {
        Modal.confirm({
            title: "¿Desea eliminar el inventario de esta variante?",
            onOk: () => axios.delete('/inventarios/' + id, {

            })
                .then(({ data }) => {
                    this.props.refresh()
                    message.success("Inventario eliminado")
                })
                .catch(e => {
                    message.error("Error al eliminar")
                })
        })
    }
    /**
     *
     *
     * @memberof TableInventarioVariantes
     * @description Eliminamos el producto.
     */
    edit = (id) => {
        this.setState({ modalVisible: true, id: id })

    }

    /**
     *
     *
     * @memberof TableInventarioVariantes
     * @description Al cambiar el valor de cada inventario se actualiza en el back
     */
    onSave = (id, data) => {
        this.setState({ loading: true })
        axios.put('/inventarios/' + id, {
            ...data
        })
            .then(({ data }) => {
                this.getVariantes()
                message.success("Actualizado exitosamente.")
            })
            .catch(e => {
                console.log('datadatadata', e)
                message.error("Error al actualizar  inventario")
            })
    }


    setVariante = (item_modificado) => {
        let dataSource = this.state.dataSource;
        let index = Object.keys(item_modificado)[0];
        this.onSave(dataSource[index]._id, item_modificado[index])
    }
    /**
     *
     *
     * @memberof TableInventarioVariantes
     * @description Permite mostarar y actualizar los valores de la tabla
     */
    columns = [
        {
            title: 'Variante',
            dataIndex: ['variante_id', 'nombre'],
            key: 'name',
            width: "30%",
            render: (value, item) => {
                let variante = item?.variante_id;
                if (variante)
                    return (
                        <Space direction='horizontal' size={"middle"}>
                            <ImageProducto variante={variante}/>
                            <Text ellipsis>{item.tienda_rappi? <Rappi width='20' height="20" color="#000000"/>: null} {variante.shopify_variante_id ? <FaShopify /> : null} {variante.sabor ?? "-"}</Text>
                        </Space>
                    );
            },
        },
        {
            title: "SKU",
            dataIndex: ['variante_id', 'sku'],
            key: "sku",
            width: "20%",
            render: (value, index) => (
                <span>
                    {value}
                </span>
            ),
        },
        {
            title: "Disponible",
            key: "disponible",
            width: "15%",
            shouldCellUpdate: () => true,
            render: (value, record, index) => {
                return (<Form.Item
                    name={[index, "disponible"]}
                    className="m-0"
                >
                    <InputNumber
                        className="width-100"
                        formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                        parser={value => value.replace(/\$\s?|(,*)/g, '')}
                        min={0}
                        controls={false}
                    />
                </Form.Item>
                )
            }
        },
        {
            title: "Consignado",
            key: "consignado",

            width: "15%",
            shouldCellUpdate: () => true,
            render: (value, record, index) => {

                return (<Form.Item
                    name={[index, "consignado"]}
                    className="m-0"

                >
                    <InputNumber
                        className="width-100"
                        formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                        parser={value => value.replace(/\$\s?|(,*)/g, '')}
                        min={0}
                        controls={false}
                    />
                </Form.Item>)
            },
        },
        {
            title: "Futuro",
            key: "futuro",
            width: "15%",
            shouldCellUpdate: () => true,
            render: (value, record, index) => {
                return (
                    <>
                        <Form.Item
                            name={[index, "futuro"]}
                            className="m-0"
                        >
                            <InputNumber
                                className="width-100"
                                formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                min={0}
                                controls={false}
                            />
                        </Form.Item>
                        <Space className='mt-05'>{renderTransferenciasFolio(record.transferencias)}</Space>
                    </>
                );
            },
        },


        {
            key: "acciones",
            width: "15%",
            render: (value, record, index) => {
                return <Space>
                    <Button
                        disabled={!this.props.canEdit}
                        onClick={() => this.edit(record._id)}
                        type="primary"
                        size='small'
                        icon={<EditOutlined style={{ color: "white" }} />}
                    />
                    <Button
                        disabled={!this.props.canDelete}
                        onClick={() => this.delete(record._id)}
                        danger
                        type="primary"
                        size='small'
                        icon={<DeleteOutlined style={{ color: "white" }} />}
                    />

                </Space>
            },
        },
    ];

    formTableRef = createRef()

    render() {

        const { columns, formTableRef } = this
        return (
            <>
                <Form
                    ref={formTableRef}
                    rowKey={record => record._id}
                    loading={this.state.loading}
                    onValuesChange={(values, record) => {
                        this.setVariante(values)
                    }}
                >
                    <Table
                        className="table-inventarios-variantes"
                        dataSource={this.state.dataSource}
                        columns={columns}
                        loading={this.state.loading}
                        pagination={false}
                    />
                </Form>
                <ModalInventarios
                    visible={this.state.modalVisible}
                    onClose={() => {
                        this.getVariantes()
                        this.setState({ modalVisible: false })

                    }}
                    almacen_id={this.props.almacen_id}
                    inventario_id={this.state.id}
                />
            </>
        )
    }
}