
import React, { Component, createRef } from 'react';
import { Button, Space, Typography, InputNumber, Table, Form, message, Modal } from 'antd';
import axios from 'axios';

import { DeleteOutlined } from '@ant-design/icons'
import ModalProductos from './ModalProductos';



const Decimal = require('decimal.js');
const { Text } = Typography

/**
 *
 *
 * @export
 * @class TableOrdenCompra
 * @extends {Component}
 * 
 * @description Permite gestionar la tabla de productos de la orden de Compra
 */
export default class TableOrdenCompra extends Component {

	  /**
	   *
	   *
	   * @memberof TableOrdenCompra
	   * @state orden Almacen la orden Actual
	   * @state dataSource Lista de elementos de la tabla
	   * @state visibleModalAgregarProductos Permite agregar y eliminar productos. 
	   */
	  state = {
	    orden: {},
	    dataSource: [],
	    visibleModalAgregarProductos: false
	  }

  /**
   *
   *
   * @memberof TableOrdenCompra
   * @description Obtenemos la orden de compra
   */
  	componentDidMount() {
    	this.getOrdenCompra()
  	}

	  /**
	 *
	 *
	 * @memberof TableOrdenCompra
	 * @description Formatea el arreglo a un objeto de indices para que pueda ser interprestado por el formulario.
	 */
	  setOrdenesDetalles = (arreglo = []) => {
	    let values = {}
	    for (let index = 0; index < arreglo.length; index++) {
	      const data_orden_detalle = arreglo[index]
	      values[index] = data_orden_detalle
	    }
	    return values
	  }

  /**
   *
   *
   * @memberof TableOrdenCompra
   * @description Obtenemos la orden de compra
   */
  	getOrdenCompra = () => {
  		this.setState({loading: true})
    	axios.get('/orden-compra/'+ this.props.orden_compra_id)
     	.then(({ data }) => {
     		console.log("data", data.orden_detalles);
     		this.setState({
     			dataSource: data.orden_detalles,
     			orden: data,
     		})
	         this.formTableOrdenCompra.current.setFieldsValue(this.setOrdenesDetalles(data.orden_detalles))
      })
      .catch(e => {
        console.log('datadatadata', e)
      })
  }


  	/**
   	*
   	*
   	* @memberof TableOrdenCompra
   	* @description Eliminamos el producto.
   	*/
  	deleteProduct = (id) => {
    	Modal.confirm({
      		title: "¿Desea eliminar este producto de la orden de compra?",
      		onOk: () => axios.delete('/orden-compra/delete/producto', {
        		params: { id } 
        	})
	        .then(({ data }) => {
	          	message.success("Producto eliminado")
	          	this.getOrdenCompra()
	        })
	        .catch(e => {
	        	message.error("Error al eliminar")
	          	this.getOrdenCompra()
	        })
    	})
  	}



	/**
	 *
	 *
	 * @memberof TableOrdenCompra
	 * @description Este metodo se encarga de calcular las ordenes de compra.
	 */
	handleSave = (row) => {
    	const { orden } = this.state

    	try{

    		let cantidad = Decimal(row.cantidad).toDecimalPlaces(2).toNumber();
        	let costo_unitario = Decimal(row.costo_unitario).toDecimalPlaces(2).toNumber();
        	let utilidad_unitaria = Decimal(row.utilidad_unitaria).toDecimalPlaces(2).toNumber();
    		row.costo_total = Decimal(costo_unitario).mul(cantidad).toDecimalPlaces(2).toNumber();
        	row.utilidad_total = Decimal(utilidad_unitaria).mul(cantidad).toDecimalPlaces(2).toNumber();
        	row.precio =  Decimal(costo_unitario).add(utilidad_unitaria).toDecimalPlaces(2).toNumber();
    	
    	}catch(e){

    	}
    	
    	return row;
  	}

  	/**
	 *
	 *
	 * @memberof TableOrdenCompra
	 * @description Este metodo se encarga de calcular las ordenes de compra.
	 */
	onSave = () => {
		this.setState({loading: true})
    	axios.put('/orden-compra/update/producto', {
      		orden_compra_id: this.props.orden_compra_id,
      		orden_detalles: this.state.dataSource
    	})
      	.then(({ data }) => {
        	message.success("La orden de compra" + this.props.folio + " ha sido guardada exitosamente.")
        	this.props.onSave()
      	})
      	.catch(e => {
        	console.log('datadatadata', e)
        	message.error("Error al actualizar la compra")
      	})
  	}


  /**
   *
   *
   * @memberof TableOrdenCompra
   * @description Permite mostarar y actualizar los valores de la tabla
   */
  	columns = [
    	{
      		title: 'Producto',
      		dataIndex: ['producto_id', 'nombre'],
      		key: 'name',
      		width: "25%",
      		render: (value, item) => {
        		let producto = item?.producto_id;
    			return (
    				<Space direction="vertical" size={0}>
      					<Text className="product-text-name">
        					{producto ? producto.modelo : ""}
      					</Text>
      					<Text className="product-text">
        					{producto ? producto.marca : ""}
      					</Text>
      					<Text className="product-text">
        					{ producto?.sabor ? producto?.sabor : "" }
      					</Text>
    				</Space>
    			);
      		},
   		},
	    {
	    	title: "Cantidad",
	    	dataIndex: "cantidad",
	    	key: "cantidad",
	    	render: (value, record, index) => (
	        	<Form.Item
	          		className="m-0"
	          		name={[index, "cantidad"]}
	          		hasFeedback
	          		tooltip
	          		rules={[
	            		{
	              			required: true,
	              			message: "Ingrese la cantidad de producto",
	            		},
	          		]}
	        	>
	          		<InputNumber
	            		controls={false}
	            		className="width-100"
	          		/>
	        	</Form.Item>
	      	),
	    },
	    {
	    	title: "Costo Unitario",
	      	key: "otros",
	      	render: (value, record, index) => (
	        	<Form.Item
	          		name={[index, "costo_unitario"]}
	          		className="m-0"
	          		
	          		rules={[
			            {
			            	required: true,
			            	message: "Ingrese el Costo Unitario",
			            },
			            /*{
			             	validator: (_, value) => {
			                	if (value > 0)
			                  		return Promise.resolve()
			                	return Promise.reject(new Error('El costo no es valido.'))
			              	}
			            },*/
	          		]}
	        	>
			        <InputNumber
			          	className="width-100"
		                formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
		                parser={value => value.replace(/\$\s?|(,*)/g, '')}
		                min={0}
			            controls={false}
			         />
	        	</Form.Item>
	      	),
	    },
	    {
	    	title: "Utilidad Unitaria",
	      	key: "otros",
	      	render: (value, record, index) => (
	        	<Form.Item
	          		name={[index, "utilidad_unitaria"]}
	          		className="m-0"
	          		hasFeedback
	          		rules={[
			            {
			            	required: true,
			            	message: "Ingrese el Costo Unitario",
			            },
			            /*{
			             	validator: (_, value) => {
			                	if (value > 0)
			                  		return Promise.resolve()
			                	return Promise.reject(new Error('El costo no es valido.'))
			              	}
			            },*/
	          		]}
	        	>
			        <InputNumber
			          	className="width-100"
		                formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
		                parser={value => value.replace(/\$\s?|(,*)/g, '')}
		                min={0}
			            controls={false}
			         />
	        	</Form.Item>
	      	),
	    },
	    {
	    	title: "Precio Unitario",
	      	key: "precio",
	      	width: "150px",
	      	shouldCellUpdate: () => true,
	      	render: (value, record, index) => {
	        	return (
	          		<span>
	            		$ {(this.state.dataSource[index]?.precio) ? this.state.dataSource[index]?.precio.toMoney(true) : "0"}
	          		</span>
	        	);
	      	},
	    },
	    {
	    	title: "Costo Total",
	      	key: "costo_total",
	      	width: "150px",
	      	shouldCellUpdate: () => true,
	      	render: (value, record, index) => {
	        	return (
	          		<span>
	            		$ {(this.state.dataSource[index]?.costo_total) ? this.state.dataSource[index]?.costo_total.toMoney(true) : "0"}
	          		</span>
	        	);
	      	},
	    },
	    {
	    	title: "Utilidad Total",
	      	key: "utilidad_total",
	      	shouldCellUpdate: () => true,
	      	render: (value, record, index) => {
	        	return (
	          		<span>
	            		$ {(this.state.dataSource[index]?.utilidad_total) ? this.state.dataSource[index]?.utilidad_total.toMoney(true) : "0"}
	          		</span>
	        	);
	      	},
	    },
	    {
	     	key: "acciones",
	      	render: (value, record, index) => {
	        	return <Button 
	        		onClick={() => this.deleteProduct(record._id)}
	        		danger 
	        		type="primary" 
	        		size='small' 
	        		icon={<DeleteOutlined style={{ color: "white" }} />}
	        	/>
	      	},
	    },
  	];

  	formTableOrdenCompra = createRef()

  	refTableOrdenCompra = createRef()

  	render() {

    	const { dataSource, orden, visibleModalAgregarProductos } = this.state
    	const { columns, formTableOrdenCompra, refTableOrdenCompra } = this

	    return (
	      	<>
	        	<Form
	          		ref={formTableOrdenCompra}
	          		rowKey={record => record._id}
	          		onFinish={this.onSave}
	          		onValuesChange={(values, record) => {
	            		let index = Object.entries(values)[0][0]
	            		let recordTemp = this.formTableOrdenCompra.current.getFieldValue(index)
	            		this.setState(state => {
	              			state.dataSource[index] = this.handleSave(recordTemp)
	              			return state;
	            		})
	          		}}
	          	>
	          		<Table
	            		className="table-orden-compra"
	            		dataSource={dataSource}
	            		columns={columns}
	            		pagination={false}
	            		footer={() => <Button block onClick={() => this.setState({ visibleModalAgregarProductos: true })}>Agregar un nuevo Registro +</Button>}
	          		/>
	        	</Form>
	        	<ModalProductos
	          		visible={visibleModalAgregarProductos}
	          		onClose={(flag) => {
	          			this.setState({ visibleModalAgregarProductos: false })
	          			if(flag === true){
	          				this.getOrdenCompra()
	          			}
	          		}}
	          		orden_compra_id={this.props.orden_compra_id}
	        	/>
	      	</>
	    )
  	}
}