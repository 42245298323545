import React, { useContext, useState } from 'react';
import { Space, Dropdown, Menu, Col, Row, Layout, Input } from 'antd';
import { useNavigate } from "react-router-dom";
import { DownOutlined } from '@ant-design/icons';


//componentes
import { User, SetUser } from '../../../Hooks/Logged';

import '../../../Styles/Global/headers.scss'
import useSearch from '../../../Hooks/useSearch';
const { Header } = Layout;
const { Search } = Input;

/**
 * @const Navbar
 * @description Header del sistema
 * @param {*} {  showResponsiveSidebar, showSearch, setFilterSearch, filterSearchVal }
 */
const Navbar = ({ showResponsiveSidebar, showSearch, setFilterSearch, filterSearchVal, admin }) => {


	let user = useContext(User)
	const { setSearch, show } = useSearch()
	let setUser = React.useContext(SetUser)
	const [redirect, setRedirect] = useState(false)
	const navigate = useNavigate()

	/**
	 * @const cerrarSesion
	 * @description Cierra la sesion
	 */
	const cerrarSesion = () => {
		setUser(undefined);
		sessionStorage.clear();
		navigate('/login')

	};

	/**
	* @const UserMenu
	* @description Muestra el Menu del dashboard
	*/
	const UserMenu = () => {
		return (
			<Col flex="auto" className="header-content" style={{ display: "flex", flex: 1, columnGap: '1em', justifyContent: show ? 'space-between' : 'flex-end', padding: "0 1em" }} >
				{
					show && (
						<Search
							placeholder="Buscar"
							onSearch={(e) => { setSearch(e) }}
							id="inputFilterSearch"
							allowClear
							enterButton
							size='large'
							className="input-global-search"
						/>
					)
				}
				<Space size={"large"}>
					{/* <Notifications /> */}
					<Dropdown
						arrow={true}
						overlay={
							<Menu>
								<Menu.Item key="0" onClick={cerrarSesion}>
									Cerrar Sesión
								</Menu.Item>
							</Menu>
						} trigger={['click']}>
						<Space>
							{user?.nombre} <DownOutlined />
						</Space>
					</Dropdown>
				</Space>
			</Col>
		)
	}

	return (
		<Header className="header" >
			<Row type="flex" className="row-header-content" >
				<Col className="header-logo" sm={24} xs={24}>
					<div className="logo">
						<img src={'/logo.svg'} alt="logo" />
					</div>
				</Col>
				{UserMenu()}
			</Row>
		</Header>
	);

}

export default Navbar;