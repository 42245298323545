
import React, { Component } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { User, SetUser } from './Hooks/Logged';
import axios from 'axios'
import Routes from './Routes';
import { ConfigProvider, theme } from 'antd';
import Socket, { SetSocketContext } from './Hooks/Socket';
import io from "socket.io-client";

axios.defaults.baseURL = process.env.REACT_APP_WEB_SERVICE
axios.defaults.withCredentials = true
axios.defaults.headers.common["Content-Type"] = "application/json";

// Add a response interceptor
axios.interceptors.response.use(function (response) {
  if (response?.headers?.authorization) {
    sessionStorage.setItem("token", response?.headers?.authorization)
  }
  return response
}, function (error) {
  return Promise.reject(error);
})

axios.interceptors.request.use(function (config) {
  config.headers.Authorization = sessionStorage.getItem("token")
  return config;
})

function createSocket(oldSocket) {

  if (oldSocket) {
    oldSocket?.disconnect();
    oldSocket?.close();
  }

  let socket = io(process.env.REACT_APP_WEB_SERVICE, {
    extraHeaders: {
      "Authorization": sessionStorage.getItem('token'),
    },
    withCredentials: true,
  })

  return socket
}



/**
 * 
 * @class App
 * @extends {Component}
 */
class App extends Component {

  constructor(props) {
    super(props);
    this.state = {
      user: 0,
      socket: createSocket()
    }
  }

  componentDidMount() {
    axios.get('/user/logged', {
      headers: { Authorization: sessionStorage.getItem('token') }
    }).then(({ data }) => {
      this.setUser(data.data)
    }).catch((error) => {
      console.log('error', error.response)
    })
  }

  setUser = (user) => this.setState({ user })
  setSocket = (socket = this.state.socket) => this.setState({ socket: createSocket(socket) })


  render() {
    const { setUser } = this;
    const { setSocket } = this
    const { user, socket } = this.state;



    return (
      <ConfigProvider
        theme={{
          colorPrimary: 'red',
          token: {
            algorithm: theme.darkAlgorithm,

            fontFamily: "Poppins",
            // colorPrimaryBg: '#fccb52',
            colorPrimary: '#fccb52',
            // colorBgBase: '#fccb52',
            // borderRadius: 2,
          },
        }}
      >
        <BrowserRouter>
          <SetSocketContext.Provider value={setSocket}>
            <Socket.Provider value={socket}>
              <User.Provider value={user}>
                <SetUser.Provider value={setUser}>
                  <Routes />
                </SetUser.Provider>
              </User.Provider>
            </Socket.Provider>
          </SetSocketContext.Provider>

        </BrowserRouter>
      </ConfigProvider>
    )
  }
}

export default function (props) {

  return <App {...props} />

}