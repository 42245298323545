import React, { Component, useContext } from 'react';
import { Layout, Row, Col, Tooltip, Button, List, Typography, Card, message, Spin, Popconfirm, Statistic, Checkbox, Modal, Space } from "antd";

import axios from 'axios';
import { Link, useNavigate, useParams } from "react-router-dom";
import StpLogin from 'stp-widget/dist/bxnk-banking-widget.es.js';

//Componentes
import User from "../../../../Hooks/Logged";
import { IconCheckGreen } from "../../../Widgets/Iconos";
import usePermissions from "../../../../Hooks/usePermissions";
import PageHeader from '../../../Widgets/PageHeader/PageHeader';
import FloatingButton from "../../../Widgets/Floating Button/FloatingButton";

//Modales
import ModalClasificacionBXNK from "./ModalClasificacionBXNK"

const { Content } = Layout;
const { Text } = Typography;

const moment = require('moment')

/**
 *
 *
 * @export
 * @class TransaccionesBXNK
 * @extends {Component}
 * @description Vista del listado de transacciones
 */
class TransaccionesBXNK extends Component {

    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            cuenta_id: this.props.params.cuenta_id,
            cuenta: {},
            transacciones: {
            	data: [],
            	limit: 20,
            	page: 1,
            	total: 0
            },
            seleccionadas: {},

        }
    }

    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        if(this.props?.params?.cuenta_id){
        	this.getCuenta();
        }
    }

    /**
    * @memberof TransaccionesBXNK
    * @description Obtiene informacion de la cuenta, para despues obtener la transacciones
    */
    getCuenta = () => {
        this.setState({ loading: true })
        axios.get('/cuentas/' + this.props.params.cuenta_id, {
        }).then(({ data }) => {
            this.setState({cuenta: data}, () => {
            	this.getTransacciones()
            })
        }).catch(error => {
            message.error('Error al cargar la cuenta')
            this.setState({ loading: false })
        })

    }

    /**
    * @memberof TransaccionesBXNK
    * @description Obtiene todas las transacciones de la cuenta que estan en registradas en Bxnk
    * @param values pagina
    */
    getTransacciones = ({
    	page = this.state.transacciones.page,
    	limit = this.state.transacciones.limit,
    } = this.state.transacciones) => {
        this.setState({ loading: true })
        axios.get('bxnk/transacciones', {
            params: {
                cuenta_id: this.props.params.cuenta_id,
                page: page,
                limit: limit,
            },
            headers:{
            	token_stp: sessionStorage.getItem('token_stp')
            }
        }).then(response => {
        	this.setState({transacciones: response.data})
        }).catch(error => {
        	console.log("error", error);
            message.error(error?.response?.data?.message ?? 'Error al obtener las transacciones (B)')
        }).finally(() => this.setState({ loading: false }))

    }

    /**
    *
    * @memberof TransaccionesBXNK
    * 
    * @method clasificarTransacciones
    * @description Abre el modal si se seleccionaron transacciones
    */
    clasificarTransacciones = () => {
        const selecionadasLenght = Object.keys(this.state.seleccionadas).length
        if (selecionadasLenght === 0)
            return Modal.error({
                title: "Debe seleccionar algunas transacciones",
                content: "Para poder agregar transacciones "
            })
        this.setState({ modalClasificarVisible: true })
    }


    /**
    * @memberof Cuentas
    * @method getCuentas
    * @description Trae las cuentas 
    * 
    */
    BXNKLogin = (values) => {
        this.setState({loading_bxnk: true})
        if(this.state.values) values = {...this.state.values, ...values}

        axios.post('/BXNK/login',{...values})
        .then(({data}) => {
            if(data.token){
                sessionStorage.setItem('token_stp', data.token);
                this.setState({
                    modal_bxnk: false,
                    qrcode: undefined,
                    values: undefined,
                    auth_2FA: false,
                })
                this.getTransacciones()
            
            }else if(data.type){
                this.setState({
                    qrcode: data.qrcode,
                    values,
                    auth_2FA: true
                })
            }

        }).catch(error => {
            console.log("error", error);
            message.error(error?.response?.data?.message ?? "Error al iniciar sesión")
        }).finally(()=>this.setState({loading_bxnk: false}))
    }

    render() {

    	const { cuenta } = this.state;

        return (
            <>
                <Spin spinning={false}>
                    <PageHeader
                        className="admin-page-header"
                        title={`Lista de Transacciones BXNK de ${cuenta.nombre}`}
                        extra={<StpLogin 
                            onLogin={(data)=>{
                                this.BXNKLogin(data)
                            }}
                            onCancel={()=>this.setState({modal_bxnk: false})}
                            onClick={()=>{
                                this.setState({modal_bxnk: true})}
                            }
                            open={this.state.modal_bxnk}
                            loading={this.state.loading_bxnk}
                            qrcode={this.state.qrcode}
                            auth_2FA={this.state.auth_2FA}
                        />}
                    />
                   
                    <Content className="admin-content content-bg pd-1">
                        <List
                            loading={this.state.loading}
                            className="component-list"
                            itemLayout="horizontal"
                            dataSource={this.state.transacciones.data}
                           
                            header={<Row className="header-list width-100 pl-1 pr-1">

                                <Col span={1} className=" center">
                                    <Text strong></Text>
                                </Col>
                                <Col span={3} className=" center">
                                    <Text strong>Referencia</Text>
                                </Col>
                                <Col xs={6} className="center">
                                    <Text strong>Concepto</Text>
                                </Col>
                                <Col xs={5} className="center">
                                    <Text strong>Ordenante</Text>
                                </Col>
                                <Col xs={4} className="center">
                                    <Text strong>Fecha</Text>
                                </Col>
                                <Col xs={4} className="center">
                                    <Text strong>Monto</Text>
                                </Col>
                                <Col xs={1} className="center">
                                    <Text strong></Text>
                                </Col>

                            </Row>
                            }

                            renderItem={item => {

                                const { monto, tipo, fecha_operacion, concepto, transaccion, id_transaction, ordenante, referencia_numerica, _id } = item;

                                return <List.Item className="component-list-item">
                                    <Tooltip  trigger={(item.transaccion !== null) ? ["hover"] : []} title=" Esta Transacción se clasificó previamente" placement="topLeft">
                                        <Card
                                            //onClick={(item.transaccion !== null) ? () => this.setState({ modalTransacDetalleVisible: true, transaccion_id: item.transaccion?._id }) : null}
                                            style={{ ...(item.transaccion !== null) ? { background: "rgba(0,0,0,0.1)" } : {} }}
                                            className="card-list">
                                            <Row className="width-100">
                                                <Col span={1} className="center">

                                                    <Checkbox className="radio-purple"
                                                        disabled={(item.transaccion !== null)}
                                                        checked={(item.transaccion == null) ? this.state.seleccionadas[_id] : true}
                                                        onChange={value => {
                                                            this.setState(state => {
                                                                if (value.target.checked === true)
                                                                    state.seleccionadas[_id] = item
                                                                else
                                                                    delete state.seleccionadas[_id]
                                                                return state;
                                                            })
                                                        }}
                                                    ></Checkbox>

                                                </Col>
                                                <Col span={3} className="center">
                                                    <Text>{referencia_numerica}</Text>
                                                </Col>
                                                <Col span={6} className="center">
                                                    <Text>{concepto}</Text>
                                                </Col>
                                                <Col span={5} className="center">
                                                    <Text>{ordenante.nombre}</Text>
                                                </Col>
                                                <Col span={4} className="center">
                                                    <Text strong>{moment(fecha_operacion).format('DD-MM-YYYY HH:mm')}</Text>
                                                </Col>
                                                <Col span={4} className="center">
                                                    <Statistic 
                                                    	value={monto} 
                                                    	valueStyle={{ fontSize: '16px', fontWeight: 'bold', color: (tipo === 2) ? '#FF0000' : '#2c9d37' }} 
                                                    	prefix={'$'} suffix={"MXN"} />
                                                </Col>
                                                <Col span={1} className="center">
                                                    {this.state.vista_guardadas ? <Popconfirm placement="topRight"
                                                        title="¿Eliminar de Guardados?" okText="Si, seguro" cancelText="No"
                                                        onConfirm={() => {
                                                            this.deleteGuardados(item._id)
                                                        }}>
                                                        <Button type="primary"
                                                            className="ant-btn button-survey btn-delete button-delete ant-btn-primary"
                                                            title="Eliminar">
                                                            
                                                        </Button>
                                                    </Popconfirm> : null
                                                    }
                                                </Col>
                                            </Row>
                                        </Card>
                                    </Tooltip>
                                </List.Item>
                            }}

                            pagination={{
                                onChange: (page, limit) => this.getTransacciones({page, limit}),

                                pageSizeOptions: [10, 20, 50, 100, 500],
                                showSizeChanger: true,

                                pageSize: this.state.transacciones.limit,
                                total: this.state.transacciones.total,
                                current: this.state.transacciones.page,

                                hideOnSinglePage: false,
                                showQuickJumper: false,
                                className: "pagination-list",
                            }}

                        />

                        <FloatingButton title="Clasificar" icon={<IconCheckGreen />} onClick={() => this.clasificarTransacciones()} />
                    </Content>
                </Spin>


                <ModalClasificacionBXNK
                    visible={this.state.modalClasificarVisible}
                    onClose={(flag) => {
                        this.setState({modalClasificarVisible: false})
                        if(flag === true){
                            this.setState({seleccionadas: {}})
                            this.getTransacciones()
                        }
                    }}
                    cuenta={this.state.cuenta}
                    seleccionadas={this.state.seleccionadas}
                />
            </>
        )
    }
}


export default function (params) {

    const user = useContext(User);

    const permisos = usePermissions(user?.rol_id?.permisos, {
        editarRubros: ["rubros", "edit"],
        deleteRubros: ["rubros", "delete"],
        createRubros: ["rubros", "create"],
    });

    return <TransaccionesBXNK {...params} params={useParams()} navigate={useNavigate()} {...permisos}/>
}