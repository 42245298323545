import React, { Component } from "react";
import { Row, Col, Modal, Form, InputNumber, message, Spin } from 'antd';

import axios from "axios"
import SelectProductos from '../../Widgets/Inputs/SelectProductos';

class ModalCuentas extends Component {


    constructor(props) {
        super(props);
        this.state = {
            loading: false,
        }
    }

    refModalCompras = React.createRef();

    componentDidMount() {


    }

    /**
    * @memberof Transacciones
    * @function onFinish
    * @description Añade un nuevo producto a la orden de compra
    */
    onFinish = (values) => {
        if (this.props.orden_compra_id) {
            this.addProducto(values)
        }
    }

    /**
   * @memberof Transacciones
   * @function addProducto
   * @description Añade un nuevo producto a la orden de compra
   */
    addProducto = (values) => {
        this.setState({ loading: true })
        axios.post('/orden-compra/add/producto', {
            ...values,
            orden_compra_id: this.props.orden_compra_id,
        }).then(response => {
            message.success("Producto agregado")
            this.props.onClose(true)
        }).catch(error => {
            message.error(error?.response?.data?.message ?? "Error al agregar el producto")
        }).finally(() => this.setState({ loading: false }))
    }




    render() {

        return (
            <Form
                layout="vertical"
                name="form-cuenta"
                id="form-cuenta"
                ref={this.refModalCompras}
                onFinish={this.onFinish}

            >
                <Spin spinning={this.state.loading}>
                    <Row justify="center" className="mt-2">
                        <Col xs={24} lg={20}>
                            <Form.Item
                                label="Producto"
                                name="producto_id"
                                rules={[{
                                    required: true,
                                    message: "Por favor, seleccione el proveedor"
                                }]}
                            >
                                <SelectProductos />
                            </Form.Item>
                        </Col>
                        <Col xs={24} lg={20}>
                            <Form.Item
                                label="Cantidad"
                                name="cantidad"
                            >
                                <InputNumber
                                    className="width-100"
                                    formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                    min={0}
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={24} lg={20}>
                            <Form.Item
                                label="Costo Unitario (MXN)"
                                name="costo_unitario"
                            >
                                <InputNumber
                                    className="width-100"
                                    formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                    min={0}
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={24} lg={20}>
                            <Form.Item
                                label="Utilidad Unitaria (MXN)"
                                name="utilidad_unitaria"
                            >
                                <InputNumber
                                    className="width-100"
                                    formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                    min={0}
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={24} lg={20}>
                            <Form.Item
                                label="Tiempo de Entrega (dias aprox)"
                                name="tipo_entrega"
                            >
                                <InputNumber
                                    className="width-100"
                                    formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                    min={0}
                                />
                            </Form.Item>
                        </Col>

                    </Row>
                </Spin>
            </Form>
        )
    }
}



export default function (props) {
    const { visible = false, onClose = () => { }, cuenta_id } = props

    return <Modal
        open={visible}
        onCancel={onClose}
        title={cuenta_id ? "Editar Compra" : "Crear Compra"}
        destroyOnClose={true}
        cancelText="Cancelar"
        okText="Guardar"
        okButtonProps={{ form: 'form-cuenta', key: 'submit', htmlType: 'submit' }}
    >
        <ModalCuentas {...props} />
    </Modal>

}