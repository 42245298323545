import React, { useState, useEffect } from 'react';
import { Row, Col, Input, Space, Button, message } from 'antd'

/**
 * @const VarianteInput
 * @description Input de Texto para añadir una variante de forma sencilla y automatica
 * 
 */
const VarianteInput = (props) => {

	let {
		className,
		placeholder = "Añadir otra variante",
		onClick = () => {},
	} = props;

	const [inputValue, setInputValue] = useState('');

	const handleInputChange = (e) => {
        setInputValue(e.target.value);
    };

    const handleButtonClick = () => {
        if (inputValue.trim() !== '') {
            onClick(inputValue.trim())
            setInputValue('');
        } else {
            message.error("Ingrese el nombre de la variante")
        }
    };

	return <Row className={className}>
		<Col span={24}>
			<Space className="width-100 pl-3">
				<Input
					placeholder={placeholder}
					value={inputValue}
                    onChange={handleInputChange}
				/>
				<Button onClick={handleButtonClick}>
					Listo
				</Button>
			</Space>
		</Col>
	</Row>
}

export default VarianteInput;