import React from 'react';
import { Button, Popconfirm } from 'antd';
import { DeleteOutlined, EyeOutlined, EditOutlined } from '@ant-design/icons';

const ButtonDelete = (props) => {

	let {
		type = "primary",
		className,
		icon = <DeleteOutlined style={{ color: 'currentcolor' }} />,
		onConfirm = () => { },
		onCancel = () => { },
		disabled = false,
		title = "¿Deseas eliminar este registro?"
	} = props

	return <Popconfirm
		placement="topRight"
		title={title}
		onConfirm={onConfirm}
		onCancel={onCancel}
		okText="Si"
		cancelText="No"
		onClick={(e)=>e.stopPropagation()}
	>
		<Button
			{...props}
			type={type}
			danger
			className={className}
			icon={icon}
			disabled={disabled}
		/>
	</Popconfirm>
}

const ButtonView = (props) => {

	let {
		className,
		icon = <EyeOutlined style={{ color: 'currentcolor' }} />,
		onClick = () => { },
	} = props

	return <Button
		type='primary'
		{...props}
		className={className}
		icon={icon}
		onClick={onClick}
	/>
}

const ButtonEdit = (props) => {

	let {
		type = "primary",
		className,
		icon = <EditOutlined style={{ color: 'currentcolor' }} />,
		onClick = () => { },
	} = props

	return <Button
		{...props}
		// type={type}
		className={className}
		icon={icon}
		onClick={onClick}
	/>
}


export {
	ButtonDelete,
	ButtonView,
	ButtonEdit,
}