import React, { Component } from "react";
import { Layout, Menu, Button } from "antd";
import SubMenu from "antd/lib/menu/SubMenu";
import { useNavigate } from "react-router-dom";
import { FaTruck, FaFileInvoiceDollar } from "react-icons/fa";
import {
	BsBoxSeam,
	BsShieldCheck,
	BsClipboardCheck,
	BsBag,
	BsCardChecklist,
} from "react-icons/bs";

import {
	MenuUnfoldOutlined,
	MenuFoldOutlined,
	AppstoreFilled,
	DollarOutlined,
	TagsOutlined,
	DashboardOutlined,
	UserOutlined,
	LineChartOutlined,
	SwapOutlined,
} from "@ant-design/icons";
//componentes
import User from "../../../Hooks/Logged";
import { LuWarehouse } from "react-icons/lu";
import { IconWallet } from "../../Widgets/Iconos";
import usePermissions from "../../../Hooks/usePermissions";

//css
import "../../../Styles/Global/sidebar.css";

const { Sider } = Layout;
class Sidebar extends Component {
	constructor(props) {
		super(props);
		this.state = {
			collapsed: false,
		};
	}

	/**
	 *
	 *
	 * @memberof AdminSidebar
	 */
	toogle = () => {
		this.setState({
			collapsed: !this.state.collapsed,
		});
	};

	render() {
		const { props } = this;
		return (
			<Sider
				theme="light"
				width={250}
				collapsedWidth={0}
				breakpoint="lg"
				className="sidebar"
			>
				<Menu
					mode="inline"
					theme="light"
					className="heigth-100 "
					style={{ borderRight: 0 }}
				>
					<Button ghost onClick={this.toogle} className="no-border">
						{this.state.collapsed ? (
							<MenuUnfoldOutlined />
						) : (
							<MenuFoldOutlined />
						)}
					</Button>

					<Menu.ItemGroup key="navegacion" title="NAVEGACIÓN">
						{props.accessDashboard && (
							<Menu.Item
								key="dashboard"
								icon={<DashboardOutlined />}
								onClick={() =>
									this.props.navigate("/admin/dashboard", { replace: true })
								}
							>
								Dashboard
							</Menu.Item>
						)}
						{props.accessFinanzas && (
							<SubMenu
								key="finanzas"
								title={
									<span
										onClick={() =>
											this.props.navigate("/admin/finanzas", { replace: true })
										}
									>
										Finanzas
									</span>
								}
								icon={<LineChartOutlined />}
							>
								{props.accessTransacciones && (
									<Menu.Item
										key="transacciones"
										icon={<DollarOutlined />}
										onClick={() =>
											this.props.navigate("/admin/finanzas/transacciones", {
												replace: true,
											})
										}
									>
										Transacciones
									</Menu.Item>
								)}
							</SubMenu>
						)}

						{/* {(props.accessShopify ) && <SubMenu key="shopify" title="Shopify" icon={<BsBag style={{ marginRight: "10px" }} />}>
														{
																props.accessShopify && <Menu.Item key="shopify-ordenes" icon={<DollarOutlined />} onClick={() => this.props.navigate("/admin/shopify/ordenes", { replace: true })}>
																		Pedidos
																</Menu.Item>
														}
														{
																props.accessShopify && <Menu.Item key="shopify-clientes" icon={<AppstoreFilled />} onClick={() => this.props.navigate("/admin/shopify/clientes", { replace: true })}>
																		Clientes
																</Menu.Item>
														}
												</SubMenu>} */}

						{props.accessClientes && (
							<SubMenu
								key="crm-dashboard"
								title={
									<span
										onClick={() =>
											this.props.navigate("/admin/crm/", { replace: true })
										}
									>
										CRM
									</span>
								}
								icon={<AppstoreFilled />}
							>
								<Menu.Item
									key="crm-clientes"
									icon={<AppstoreFilled />}
									onClick={() =>
										this.props.navigate("/admin/crm/clientes/listado", {
											replace: true,
										})
									}
								>
									Clientes
								</Menu.Item>
							</SubMenu>
						)}
						{props.accessCuentas && (
							<Menu.Item
								key="cuentas"
								icon={<IconWallet color="#1f1f1f" style={{ width: "18px" }} />}
								onClick={() =>
									this.props.navigate("/admin/cuentas", { replace: true })
								}
							>
								Cuentas
							</Menu.Item>
						)}
						{props.accessOrdenes && (
							<Menu.Item
								key="ordenes"
								icon={<BsClipboardCheck />}
								onClick={() =>
									this.props.navigate("/admin/ordenes", { replace: true })
								}
							>
								Pedidos
							</Menu.Item>
						)}
						{props.accessCompras && (
							<Menu.Item
								key="compras"
								icon={<FaFileInvoiceDollar />}
								onClick={() =>
									this.props.navigate("/admin/compras", { replace: true })
								}
							>
								Compras
							</Menu.Item>
						)}
						{props.accessLogistica && (
							<SubMenu
								key="logistica"
								title={
									<span
										onClick={() =>
											this.props.navigate("/admin/logistica/", {
												replace: true,
											})
										}
									>
										Logistica
									</span>
								}
								icon={<AppstoreFilled />}
							>
								{props.accessLogistica && props.accessPicking && (
									<Menu.Item
										key="pick-logistica"
										icon={<AppstoreFilled />}
										onClick={() =>
											this.props.navigate("/admin/logistica/picking-list", {
												replace: true,
											})
										}
									>
										Picking List
									</Menu.Item>
								)}
								{props.accessLogistica && props.accessPacking && (
									<Menu.Item
										key="pack-logistica"
										icon={<AppstoreFilled />}
										onClick={() =>
											this.props.navigate("/admin/logistica/packing-list", {
												replace: true,
											})
										}
									>
										Packing List
									</Menu.Item>
								)}
							</SubMenu>
						)}
						{props.accessFinanzas && (
							<SubMenu
								key="contabilidad"
								title={
									<span
										onClick={() =>
											this.props.navigate("/admin/contabilidad/", {
												replace: true,
											})
										}
									>
										Contabilidad
									</span>
								}
								icon={<AppstoreFilled />}
							>
								<Menu.Item
									key="vencida"
									icon={<AppstoreFilled />}
									onClick={() =>
										this.props.navigate("/admin/contabilidad/vencida", {
											replace: true,
										})
									}
								>
									Cartera Vencida
								</Menu.Item>
								<Menu.Item
									key="ventas"
									icon={<AppstoreFilled />}
									onClick={() =>
										this.props.navigate("/admin/contabilidad/ventas", {
											replace: true,
										})
									}
								>
									Ventas
								</Menu.Item>
							</SubMenu>
						)}
						{props.accessProductos && (
							<Menu.Item
								key="productos"
								icon={<BsBoxSeam />}
								onClick={() =>
									this.props.navigate("/admin/productos", { replace: true })
								}
							>
								Productos
							</Menu.Item>
						)}
						{props.accessInventario && (
							<Menu.Item
								key="inventarios"
								icon={<BsCardChecklist />}
								onClick={() =>
									this.props.navigate("/admin/inventarios", { replace: true })
								}
							>
								Inventarios
							</Menu.Item>
						)}
						{props.accessTransferencias && (
							<Menu.Item
								key="transferencias"
								icon={<SwapOutlined />}
								onClick={() =>
									this.props.navigate("/admin/transferencias", {
										replace: true,
									})
								}
							>
								Transferencias
							</Menu.Item>
						)}
						{props.accessAlmacenes && (
							<Menu.Item
								key="almacenes"
								icon={<LuWarehouse />}
								onClick={() =>
									this.props.navigate("/admin/almacenes", { replace: true })
								}
							>
								Locaciones
							</Menu.Item>
						)}
						{props.accessProveedores && (
							<Menu.Item
								key="proveedores"
								icon={<FaTruck />}
								onClick={() =>
									this.props.navigate("/admin/proveedores", { replace: true })
								}
							>
								Proveedores
							</Menu.Item>
						)}
						{props.accessAreas && (
							<Menu.Item
								key="areas"
								icon={<TagsOutlined />}
								onClick={() =>
									this.props.navigate("/admin/areas", { replace: true })
								}
							>
								Áreas
							</Menu.Item>
						)}
						{props.accessUsuarios && (
							<Menu.Item
								key="usuarios"
								icon={<UserOutlined />}
								onClick={() =>
									this.props.navigate("/admin/usuarios", { replace: true })
								}
							>
								Usuarios
							</Menu.Item>
						)}
						{props.accessRoles && (
							<Menu.Item
								key="roles"
								icon={<BsShieldCheck />}
								onClick={() =>
									this.props.navigate("/admin/roles", { replace: true })
								}
							>
								Roles
							</Menu.Item>
						)}
					</Menu.ItemGroup>
				</Menu>
			</Sider>
		);
	}
}

export default function View(props) {
	const user = React.useContext(User);

	const permisos = usePermissions(user?.rol_id?.permisos, {
		accessDashboard: ["dashboard"],
		accessUsuarios: ["usuarios", "access"],
		accessClientes: ["clientes", "access"],
		accessProductos: ["productos", "access"],
		accessRoles: ["roles", "access"],
		accessFinanzas: ["finanzas"],
		accessTransacciones: ["transacciones", "access"],
		accessCuentas: ["cuentas", "access"],
		accessAreas: ["areas", "access"],
		accessProveedores: ["proveedores", "access"],
		accessCRM: ["crm", "access"],
		accessAlmacenes: ["almacenes", "access"],
		accessInventario: ["inventarios", "access"],
		accessShopify: ["shopify", "access"],
		accessOrdenes: ["ordenes", "access"],
		accessCompras: ["compras", "access"],
		accessLogistica: ["logistica", "access"],
		accessPicking: ["logistica", "picking_list"],
		accessPacking: ["logistica", "packing_list"],

		accessTransferencias: ["transferencias", "access"],
	});

	let owner = user?.rol_id?.tipo === 0;

	return (
		<Sidebar {...props} navigate={useNavigate()} {...permisos} owner={owner} />
	);
}
