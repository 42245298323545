import React, { Component, useContext } from 'react';
import { Button, Col, Row, Spin, Layout, message, Typography, Statistic, Card, List } from 'antd'
import { FaArrowRight } from "react-icons/fa";
import Carousel from 'react-multi-carousel';
import axios from 'axios'

// Components
import User from "../../../Hooks/Logged";
import usePermissions from "../../../Hooks/usePermissions";
import { CardSaldo, CardCuenta } from "../../Widgets/Cards";
import PageHeader from '../../Widgets/PageHeader/PageHeader';
import ModalTransaccionDetalle from './Transacciones/ModalTransaccionDetalle';

//css
import 'react-multi-carousel/lib/styles.css';
import { useNavigate } from 'react-router-dom';
import '../../../Styles/Global/cards.css'
import { ButtonView } from '../../Widgets/Buttons';

const { Content, } = Layout;
const { Text, Title } = Typography;

const moment = require('moment');
moment.locale('es');


const responsive = {
	desktop_fhq: {
		breakpoint: { max: 4000, min: 1200 },
		items: 2
	},
	desktop_hq: {
		breakpoint: { max: 1500, min: 1200 },
		items: 1
	},
	desktop_xxl: {
		breakpoint: { max: 1200, min: 992 },
		items: 1
	},
	desktop_xl: {
		breakpoint: { max: 992, min: 768 },
		items: 1
	},
	desktop_lg: {
		breakpoint: { max: 768, min: 576 },
		items: 1
	},
	desktop_md: {
		breakpoint: { max: 576, min: 464 },
		items: 1
	},
	desktop_sm: {
		breakpoint: { max: 464, min: 0 },
		items: 1
	},

	mobile: {
		breakpoint: { max: 464, min: 0 },
		items: 1
	}
};


/**
 * 
 * @export
 * @class DashboardFinanzas
 * @extends {Component}
 * @description Componente del Dashboard de Finanzas
 */
 class DashboardFinanzas extends Component {

	constructor(props) {
		super(props)
		this.state = {
			loading: false,
			cuentas: [],
			transacciones: {
				data: [],
				limit: 10,
				page: 1,
				total: 0,
				pages: 0,
				loading: false,
			},
			modalTransacDetalleVisible: false,
			transaccion_id: undefined
		}
	}

	componentDidMount() {
		this.getTransacciones();
		this.getCuentas();
	}

	/**
	 *
	 *
	 * @memberof Transacciones
	 * 
	 * @method getTransacciones
	 * @description Obitiene las Transacciones
	 */
	getTransacciones = (
		{
			page = this.state.transacciones.page,
			limit = this.state.transacciones.limit,
			search = this.props.search
		} = this.state.transacciones
	) => {
		axios.get('/transacciones', {
			params: {
				page, limit, search
			}
		})
			.then(({ data }) => {
				
				this.setState({ 
					transacciones: {
						...this.state.transacciones,
						...data,
						loading: false
					}
				})
			})
			.catch(error => {
				this.setState({ 
					transacciones: { ...this.state.transacciones, loading: false }
				})
				console.log(error)
				message.error('Error al traer la información')
			})
	}

	/**
	 *
	 *
	 * @memberof Transacciones
	 * 
	 * @method getCuentas
	 * @description Obitiene las Transacciones
	 */
	getCuentas = () => {
		axios.get('/cuentas', {
			params: {
				pagination: false
			}
		}).then(({ data })=> {
			this.setState({
				cuentas: data.data,
				saldo_total: data.saldo_total
			})
		}).catch(error => {
			console.log(error)
			message.error('Error al traer la información de las cuentas')
		}).finally(() => this.setState({ loading: false }))
	}

	/**
	 *
	 * @memberof DashboardFinanzas
	 * @description Renderiza el monto con el color correspondiente segun el tipo de transaccion
	 */
	renderMonto = (tipo, monto) => {
		let colors = ['#000000', '#00FF19', '#FF0000'];
		return <Statistic value={monto} valueStyle={{ fontSize: '16px', fontWeight: 'bold', color: colors[tipo] }} prefix={'$'} />
	}

	render() {
		const { cuentas, saldo_total } = this.state
		return (
			<>
				<PageHeader
					className="admin-page-header"
					title="Dashboard Finanzas"
				/>
				<Content className='admin-content content-bg pd-1'>
					<Row gutter={[16, 16]}>
						<Col xs={24} lg={10} xl={8} xxl={5}>
							<CardSaldo saldo={saldo_total} cuentas={cuentas.length} />
						</Col>
						<Col xs={24} lg={14} xl={16} xxl={19}>
							<Carousel
								responsive={responsive}
								className='carousel-cards'
							>
								{cuentas.map(cuenta => {
									return <CardCuenta key={cuenta._id} cuenta={cuenta} ver_saldo={this.props.verSaldo} />
								})}
							</Carousel>
						</Col>
						<Col xs={24}>
							<Card className='card-shadow  height-100 border-16'>
								<Row>
									<Col span={22}>
										<Title level={3} className='text-gray'>Últimas Transacciones</Title>
									</Col>
									<Col span={2} className='center'>
										<Button onClick={() => this.props.navigate('/admin/finanzas/transacciones')} icon={<FaArrowRight style={{ marginTop: 4 }} />} />
									</Col>
								</Row>
								<Row>
									<List
										className='width-100'
										dataSource={this.state.transacciones.data}
										locale={{ emptyText: 'Sin Transacciones' }}
										key={(item => item._id)}
										renderItem={item => (
											<List.Item className='width-100'>
												<Row className='width-100'>
													<Col span={22} className="center " >
														<Row className="width-100 "> 
															<Col xs={10} lg={5}  xl={4} xxl={4} className="">
																<Text ellipsis strong>{moment(item.fecha).format('DD/MM/YYYY')}</Text>
															</Col>
															<Col xs={14} lg={5} xl={5} xxl={5} className="center ">
																<Text ellipsis>{item.concepto}</Text>
															</Col>
															<Col xs={10} lg={4} md={8} lg={6} xl={5} xxl={5} className="center ">
																<Text ellipsis>{item.cuenta_id?.nombre}</Text>
															</Col>
															<Col xs={14} lg={4} md={8} xl={5} xxl={5} className="center ">
																<Text ellipsis>{item.rubro_id?.nombre}</Text>
															</Col>
															<Col xs={24} lg={4} md={8} xl={5} xxl={5} className="center ">
																<Text ellipsis>{this.renderMonto(item.tipo, item.monto)}</Text>
															</Col>
														</Row>
													</Col>
													<Col span={2} className="center ">
														<ButtonView
															onClick={() => this.setState({ modalTransacDetalleVisible: true, transaccion_id: item._id })}
														/>
													</Col>
												</Row>
											</List.Item>
										)}
									/>
								</Row>
							</Card>
						</Col>
					</Row>
				</Content>

				<ModalTransaccionDetalle
					visible={this.state.modalTransacDetalleVisible}
					onClose={() => this.setState({
						modalTransacDetalleVisible: false,
						transaccion_id: undefined
					})}
					id={this.state.transaccion_id}
				/>
			</>
		)
	}
}

export default function (props) {

	const user = useContext(User);

    const permisos = usePermissions(user?.rol_id?.permisos, {
        verSaldo: ["cuentas", "ver_saldos"]

    });

	return <DashboardFinanzas {...props} navigate={useNavigate()} {...permisos}/>
	
}