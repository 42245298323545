import React, { Component, useContext } from 'react';
import { Row, Col, Popconfirm, Space, Spin, Layout, message, Typography, Tooltip, Card, List, Tag } from 'antd'
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { CheckCircleFilled } from '@ant-design/icons';

//Componentes
import User from "../../../Hooks/Logged";
import CustomAvatar from "../../Widgets/Avatar/Avatar";
import usePermissions from "../../../Hooks/usePermissions";
import PageHeader from '../../Widgets/PageHeader/PageHeader';
import { ButtonDelete, ButtonEdit } from '../../Widgets/Buttons';
import FloatingButton from "../../Widgets/Floating Button/FloatingButton";

//Modales
import ModalCuentas from './ModalCuentas.js';
import useSearch from '../../../Hooks/useSearch';
import ModalCuentaSTP from './ModalCuentasSTP.js';


import StpLogin from 'stp-widget/dist/bxnk-banking-widget.es.js';
import 'stp-widget/dist/style.css';

const { Content } = Layout;
const { Text } = Typography;
/**
 *
 *
 * @export
 * @class Cuentas
 * @extends {Component}
 * @description Vista de Project Manager
 */
class Cuentas extends Component {

    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            modalVisible: false,
            cuenta_id: undefined,
            cuentas_stp: [],

            cuentas: {
                data: [],
                limit: 10,
                page: 1,

                total: 0,
                pages: 0,

            }
        }
    }

    modalBXNKRef = React.createRef();

    componentDidMount() {
        this.props.setShowSearch(true)
        this.getCuentas();
    }

    componentDidUpdate(prevProps) {
        if (this.props.search !== prevProps.search) {
            this.getCuentas({ page: 1, search: this.props.search })
        }
    }

    componentWillUnmount() {
        this.props.setShowSearch(false)
    }

    /**
    * @memberof Cuentas
    * @method getCuentas
    * @description Trae las cuentas 
    * 
    */
    getCuentas = ({
        page = this.state.cuentas.page,
        limit = this.state.cuentas.limit,

        search = this.props.search

    } = this.state.cuentas) => {
        this.setState({ loading: true, cuentas: { ...this.state.cuentas, page, limit } })
        axios.get('/cuentas', {
            params: { page, limit, search }
        }).then(({ data }) => {
            this.setState({ cuentas: data })
        }).catch(error => {
            message.error(error.response?.data?.message)
        }).finally(() => this.setState({ loading: false }))
    }

    /**
    * @memberof Cuentas
    * @method getCuentas
    * @description Trae las cuentas 
    * 
    */
    BXNKLogin = (values) => {
        this.setState({ loading_bxnk: true })
        if (this.state.values) values = { ...this.state.values, ...values }

        axios.post('/BXNK/login', { ...values })
            .then(({ data }) => {
                if (data.token) {
                    sessionStorage.setItem('token_stp', data.token);
                    this.setState({
                        cuentas_stp: data.cuentas,
                        modal_cuentas_stp: true,
                        modal_bxnk: false,
                        values: undefined,
                        auth_2FA: false,
                        qrcode: undefined
                    })
                } else if (data.type === "TEMP_SECRET") {
                    this.setState({
                        qrcode: data.qrcode,
                        values,
                        auth_2FA: true
                    })
                }

            }).catch(error => {
                console.log("error", error);
                message.error(error?.response?.data?.message ?? "Error al iniciar sesión")
            }).finally(() => this.setState({ loading_bxnk: false }))
    }



    render() {

        const { cuentas } = this.state

        return (
            <>
                <Spin spinning={false}>
                    <PageHeader
                        className="admin-page-header"
                        title="Cuentas"
                    />

                    <Content className="admin-content content-bg pd-1">
                        <List
                            loading={this.state.loading}
                            className="component-list"
                            itemLayout="horizontal"
                            locale={{ emptyText: "Sin Cuentas" }}
                            dataSource={cuentas.data}
                            pagination={{
                                current: cuentas.page,
                                pageSize: cuentas.limit,
                                total: cuentas.total,
                                showSizeChanger: true,
                                position: 'bottom',
                                onChange: (page, limit) => {
                                    this.getCuentas({ page, limit })
                                }
                            }}
                            header={<Row className="width-100 pr-1 pl-1 component-list-header" >
                                <Col span={2} className="center">
                                    <Text strong>Logo</Text>
                                </Col>

                                <Col span={5} className="center">
                                    <Text strong>Nombre</Text>
                                </Col>
                                <Col span={4} className="center">
                                    <Text strong>Saldo</Text>
                                </Col>
                                <Col span={5} className="center">
                                    <Text strong>Titular</Text>
                                </Col>
                                <Col span={5} className="center">
                                    <Text strong>Número Cuenta</Text>
                                </Col>

                            </Row>
                            }

                            renderItem={item => (
                                <List.Item className="component-list-item">
                                    <Card className="card-list">
                                        <Row className="width-100" gutter={[6, 8]}>

                                            <Col xs={12} lg={2} className="center">
                                                <CustomAvatar url={{
                                                    url: `${axios.defaults.baseURL}/cuentas/${item._id}`,
                                                    logo: item.logo?.file
                                                }} color={item.color} />
                                            </Col>

                                            <Col xs={12} lg={5} className="center">
                                                {item.bxnk_cuenta_id ? <Tooltip placement="topRight" title={"Cuenta vinculada a BXNK"}>
                                                    <CheckCircleFilled style={{ color: "#2be1ff", fontSize: "20px", marginRight: "4px" }} />
                                                </Tooltip> : null}
                                                <Text strong>{item.nombre}</Text>
                                            </Col>
                                            <Col xs={12} lg={4} className="center">
                                                <Text className="text-gray">{this.props.verSaldo && item.bxnk_cuenta_id ? `$ ${item.saldo.toMoney(true)} MXN` : "Sin permisos" }</Text>
                                            </Col>
                                            <Col xs={12} lg={5} className="center">
                                                <Text className="text-gray">{item.titular}</Text>
                                            </Col>
                                            <Col xs={12} lg={5} className="center">
                                                <Text className="text-gray">{item.cuenta}</Text>
                                            </Col>
                                            <Col xs={12} lg={3} className="center">
                                                <Space>
                                                    <StpLogin
                                                        onLogin={(data) => {
                                                            this.BXNKLogin(data)
                                                        }}
                                                        onCancel={() => this.setState({ modal_bxnk: false, cuenta_id: undefined })}
                                                        onClick={() => {
                                                            this.setState({ modal_bxnk: true, cuenta_id: item._id })
                                                        }
                                                        }
                                                        open={this.state.modal_bxnk}
                                                        loading={this.state.loading_bxnk}
                                                        qrcode={this.state.qrcode}
                                                        auth_2FA={this.state.auth_2FA}
                                                    />
                                                    <ButtonEdit
                                                        title="Editar"
                                                        disabled={!this.props.canEdit}
                                                        onClick={() => { this.setState({ modalVisible: true, cuenta_id: item._id }) }}
                                                    />
                                                    <Popconfirm
                                                        placement="topRight"
                                                        title="¿Deseas eliminar esta Cuenta?"
                                                        onConfirm={() => axios.delete('/cuentas/' + item._id).then(() => {
                                                            this.getCuentas()
                                                            message.success('Cuenta eliminada')
                                                        }).catch(error => console.log(error))}
                                                        okText="Si"
                                                        cancelText="No"
                                                    >
                                                        <ButtonDelete
                                                            title="Eliminar"
                                                            disabled={!this.props.canDelete}

                                                        />
                                                    </Popconfirm>


                                                </Space>
                                            </Col>
                                        </Row>
                                    </Card>
                                </List.Item>
                            )}
                        />


                    </Content>

                    {this.props.canCreate &&     <FloatingButton title="Nuevo registro" onClick={() => this.setState({ modalVisible: true })} />}


                </Spin>
                <ModalCuentas
                    visible={this.state.modalVisible}
                    onClose={() => {
                        this.setState({ modalVisible: false, cuenta_id: undefined })
                        this.getCuentas()
                    }}
                    cuenta_id={this.state.cuenta_id}
                />
                <ModalCuentaSTP
                    visible={this.state.modal_cuentas_stp}
                    onClose={() => {
                        this.setState({ modal_cuentas_stp: false, cuenta_id: undefined })
                        this.getCuentas()
                    }}
                    cuenta_id={this.state.cuenta_id}
                    cuentas_stp={this.state.cuentas_stp}
                />
            </>
        )

    }
}

export default function Vista(props) {

    const user = useContext(User);
    const { search, setShow } = useSearch()

    const permisos = usePermissions(user?.rol_id?.permisos, {
        canEdit: ["cuentas", "edit"],
        canDelete: ["cuentas", "delete"],
        canCreate: ["cuentas", "create"],
        verSaldo: ["cuentas", "ver_saldos"]

    });

    return <Cuentas {...props} navigate={useNavigate()} {...permisos} search={search} setShowSearch={setShow} />

}