import React, { Component } from "react";
import { Link, useNavigate } from "react-router-dom";

import axios from "axios"
import { BsBoxSeam } from "react-icons/bs"
import { Layout, Row, Col, Button, List, Space, Typography, Card, message, Tooltip, Tag } from "antd";
import { FilterOutlined, MailOutlined, CheckCircleOutlined, FilePdfFilled } from "@ant-design/icons";
//componentes
import useSearch from "../../../Hooks/useSearch";
import { renderEstatusCompra } from "../../Utils";
import PageHeader from '../../Widgets/PageHeader/PageHeader';
import { ButtonDelete, ButtonEdit } from '../../Widgets/Buttons';
import FloatingButton from "../../Widgets/Floating Button/FloatingButton";

//Modales
import ModalCompras from './ModalCompras'
import DrawerProductosCompra from './DrawerProductosCompra'


const { Content } = Layout;
const { Text } = Typography;

const moment = require('moment')

/**
 *
 *
 * @export
 * @class Transacciones
 * @extends {Component}
 * @description Vista del listado de Transacciones
 */
class Transacciones extends Component {

	constructor(props) {
		super(props)
		this.state = {


			transacciones: {
				data: [],
				limit: 10,
				page: 1,
				total: 0,
				pages: 0,
				filters: [],
				sort: {},
				search: undefined
			}
		}
	}

	componentDidMount() {
		this.props.setShowSearch(true)
		this.get();
	}

	componentDidUpdate(prevProps) {
		if (this.props.search !== prevProps.search) {
			this.get({ page: 1, search: this.props.search })
		}
	}

	componentWillUnmount() {
		this.props.setShowSearch(false)
	}

	/**
	*
	*
	* @memberof Transacciones
	* @description Obtiene todas todas las transacciones
	* @param page pagina actual
	* @param limit limite de objetos de una pagina
	*/
	get = ({
		page = this.state.transacciones.page,
		limit = this.state.transacciones.limit,
		filters = this.state.transacciones.filters,
		sort = this.state.transacciones.sort,
		search = this.state.transacciones.search

	} = this.state.transacciones) => {
		this.setState({ loading: true })
		axios.get('/ordenes-compras', {
			params: {
				page,
				limit,
				filters,
				sort,
				search
			}
		})
			.then(({ data }) => {
				console.log("data", data);
				this.setState({
					transacciones: {
						...this.state.transacciones,
						...data,
						sort,
						search,
						filters,
						page
					}
				});
			})
			.catch(res => {
				message.error('No se pudieron cargar las transacciones')
			})
			.finally(() => this.setState({ loading: false }))
	}


	/**
	 *
	 *
	 * @memberof ListaCompras
	 * @method aceptarOrden
	 * @description Actualiza una orden de compra
	 */
	aceptarOrden = (id) => {
		this.setState({ loading: true })
		axios.put('/ordenes-compras', {
			compra_id: id,
			estatus: 1
		})
			.then(response => {
				message.success('Compra Actualizada')
				this.get()
			}).catch(error => {
				message.error('Error al actualizar la compra')
				console.log(error)
			}).finally(() => this.setState({ loading: false }))
	}


	/**
	 *
	 *
	 * @memberof ListaCompras
	 * @method sendEmail
	 * @description Envia una orden de compra por email
	 */
	sendEmail = (id) => {
		this.setState({ loading: true })
		axios.get('/ordenes-compras/pdf', {
			params: {
				orden_compra_id: id,
				enviar: true
			}
		}).then(response => {
			console.log("response", response);
			message.success('Orden Enviado')
			this.get();
		}).catch(error => {
			console.log(error)
			message.error('Error al enviar Orden')
		}).finally(() => this.setState({ loading: false }))

	}

	deleteFilter = (event, filtro) => {
		event.preventDefault()
		let { _id, objectName } = filtro

		let form = this?.drawerFiltros?.formFiltros?.current

		try {
			let values = form?.getFieldValue(objectName)
			let index = values.findIndex(e => e === _id)
			values.splice(index, 1)

			let newValues = {}
			newValues[objectName] = values
			form?.setFieldsValue(newValues)
			form?.submit()
		} catch (error) {
			console.log('error', error)
			this.setState({ visibleFilters: true })
		}
	}

	render() {

		const { filters } = this.state.transacciones

		return (
			<>
				<PageHeader
					className="admin-page-header"
					title="Compras"
					extra={
						<Button
							type="dashed"
							onClick={() => this.setState({ visibleFilters: true })}
							className="button-plus"
							icon={<FilterOutlined style={{ color: "currentcolor" }} />} />
					}
				>
					{filters?.length > 0 ? <Row align={"middle"}>
						{
							filters.map(filtro => {
								return <Tag
									closable
									closeIcon={<div className="point" onClick={event => this.deleteFilter(event, filtro)}></div>}
									style={{ lineHeight: '15px', paddingTop: '3px', paddingBottom: '3px' }}
								>
									<small>{filtro?.objectName?.toUpperCase().replace("_", " ")}<br></br></small>
									{filtro?.filter?.range?.join(' - ')}
									{filtro?.label || filtro?.nombre || filtro?.razon_social || filtro?.email || filtro?.folio}
								</Tag>
							})
						}
					</Row> : null}
				</PageHeader>
				<Content className="admin-content content-bg pd-1">
					<List
						loading={this.state.loading}
						className="component-list"
						itemLayout="horizontal"
						locale={{ emptyText: "Sin Transacciones" }}
						dataSource={this.state.transacciones?.data}
						pagination={{
							current: this.state.transacciones.page,
							pageSize: this.state.transacciones.limit,
							total: this.state.transacciones.total,
							position: 'bottom',
							className: "flex-left",
							onChange: (page, limit) => this.get({ page, limit })
						}}
						header={<Row className="header-list width-100 pl-1 pr-1">
							<Col span={2} className=" center">
								<Text strong >FECHA</Text>
							</Col>
							<Col xs={4} className="center">
								<Text strong >PROVEEDOR</Text>
							</Col>
							<Col xs={3} className="center">
								<Text strong >FOLIO</Text>
							</Col>
							<Col xs={3} className="center">
								<Text strong >ARTICULOS</Text>
							</Col>
							<Col xs={5} className="center text-center">
								<Text strong >COSTO TOTAL</Text>
							</Col>
							<Col xs={3} className=" center">
								<Text strong >ESTATUS</Text>
							</Col>
						</Row>
						}

						renderItem={item => (
							<List.Item className="component-list-item">
								<Card className="card-list">
									<Row className="width-100" gutter={[4, 4]}>
										<Col span={2} className="center">
											<Text>{moment(item.createdAt).format('DD/MM/YYYY')}</Text>
										</Col>
										<Col xs={4} className="center">
											<Text ellipsis className="text-gray-dark">{item.proveedor_id.nombre}</Text>
										</Col>
										<Col xs={3} className="center">
											<Text ellipsis className="text-gray-dark">{item.folio}</Text>
										</Col>
										<Col xs={3} className="center">
											<Text ellipsis className="text-gray-dark">Articulo(s) {item.orden_detalles.length ?? 0}</Text>
										</Col>
										<Col xs={5} className="center">
											<Text ellipsis className="text-gray-dark">$ {item.costo_total ? item.costo_total.toMoney(true) : 0} MXN</Text>
										</Col>
										<Col xs={3} className="center">
											{renderEstatusCompra(item.estatus)}
										</Col>
										<Col span={4} className="center">
											<Space>
												<ButtonEdit
													title="Editar"
													size="small"
													onClick={() => { this.setState({ modal_visible: true, compra_id: item._id }) }}
												/>
												<ButtonEdit
													size="small"
													title="Editar"
													icon={<BsBoxSeam />}
													onClick={() => { this.setState({ drawer_visible: true, compra_id: item._id }) }}
												/>
												<Button
													size="small"
													icon={<FilePdfFilled style={{ color: "currentColor" }} />}
													type="primary"
													title="PDF"
													onClick={() => {
														let url = new URL(axios.defaults.baseURL + '/ordenes-compras/pdf')
														url.searchParams.append('orden_compra_id', item?._id)
														url.searchParams.append('Authorization', sessionStorage.getItem('token'))
														window.open(url, '_blank')
													}}
													style={{
														backgroundColor: "rgb(68 210 178)",
														borderColor: "rgb(68 210 178)",
													}}
												/>
												<Tooltip title={"Enviar la orden de Compra al proveedor"} >
													<Button
														icon={<MailOutlined style={{ color: "currentColor" }} />}
														size="small"
														type="primary"
														title="Enviar"
														onClick={() => this.sendEmail(item._id)}
														style={{
															backgroundColor: "#B845FF",
															borderColor: "#B845FF"
														}}
													></Button>
												</Tooltip>
												<Tooltip title="Aceptar la orden" >
													<Button
														size="small"
														disabled={!(item.estatus === 0)}
														onClick={() => this.aceptarOrden(item._id)}
														icon={<CheckCircleOutlined />}
														type="primary"
														style={{
															backgroundColor: ((item.estatus === 0)) ? "rgb(108 230 52 / 40%)" : "#6CE634",
															borderColor: ((item.estatus === 0)) ? "rgb(108 230 52 / 40%)" : "#6CE634",//"#",
														}}
													></Button>
												</Tooltip>
												<ButtonDelete
													size="small"
													onConfirm={() => {
														this.setState({ loading: true })
														axios.delete('/ordenes-compras/' + item._id).then(() => {
															this.get()
															message.success('¡Orden de compra eliminado!')
														}).catch(error => {
															console.log(error)
															message.error("Error al eliminar la compra")
														}).finally(() => this.setState({ loading: false }))
													}}
													title="Eliminar"
												/>

											</Space>
										</Col>
									</Row>
								</Card>
							</List.Item>
						)}
					/>
				</Content>

				{/* <Tooltip title="Agregar una Nueva Transacción" > */}
				<FloatingButton title="Nuevo registro" onClick={() => this.setState({ modal_visible: true })} />

				<ModalCompras
					visible={this.state.modal_visible}
					onClose={(flag, compra_id) => {
						if (flag === true && compra_id) {
							this.setState({ drawer_visible: true, modal_visible: false, compra_id })
						} else {
							this.setState({ modal_visible: false, compra_id: undefined })
						}
					}}
					compra_id={this.state.compra_id}
				/>
				<DrawerProductosCompra
					visible={this.state.drawer_visible}
					onClose={() => {
						this.setState({ drawer_visible: false, compra_id: undefined })
						this.get()
					}}
					compra_id={this.state.compra_id}
					onSave={() => {
						this.setState({ drawer_visible: false, compra_id: undefined })
						this.get()
					}}
				/>

			</>
		)
	}
}



/**
 *
 *
 * @export
 * @param {*} props
 * @returns
 */
export default function (props) {

	const { search, setShow } = useSearch()

	return (<Transacciones {...props} navigate={useNavigate()} search={search} setShowSearch={setShow} />)
}