import React from 'react'
import { Route, Routes } from "react-router-dom";

import Ordenes  from '../../Components/Admin/Shopify/Ordenes';
import Clientes  from '../../Components/Admin/Shopify/Clientes';

/**
 * 
 * @export
 * @function RouterOrdenes
 * @description Router for  routes 
 */
function RouterOrdenes(props) {
  return (
    <Routes>
    	<Route path="ordenes" element={<Ordenes {...props} />} />
      <Route path="clientes" element={<Clientes {...props} />} />
    </Routes>
  )
}

export default RouterOrdenes