import React from 'react';

import {
  Routes,
  Route,
} from "react-router-dom";

import RouterPublic from './Routes/PublicRoutes'
import AdminRoutes from './Routes/AdminRoutes';

/**
 * 
 * @export
 * @function Routes
 * @description Main router for app
 */

 export default function() {
  return (
    <Routes>
      <Route path="/admin/*" element={<AdminRoutes />} />
      <Route path="/*" element={<RouterPublic />} />
    </Routes>
  )
}