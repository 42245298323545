import React, { Component } from 'react';
import { Row, Col, Typography, Button, Modal, Layout, Space, message, List, Card, Dropdown, Tag, Menu, Tooltip, Input, Switch } from 'antd'
import axios from 'axios';
import moment from 'moment';
import { FaShopify } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import { FilterOutlined, WarningFilled } from '@ant-design/icons';

//Componentes
import Sort from "../../../Widgets/Sort"
import User from '../../../../Hooks/Logged'
import { IconEye } from '../../../Widgets/Iconos'
import useSearch from '../../../../Hooks/useSearch';
import usePermissions from '../../../../Hooks/usePermissions';
import PageHeader from '../../../Widgets/PageHeader/PageHeader';
import { ButtonDelete, ButtonEdit } from '../../../Widgets/Buttons';
import { getResponseError, renderEstatusCliente } from '../../../Utils';
import FloatingButton from '../../../Widgets/Floating Button/FloatingButton'

//Modales
import ModalReporte from "./ModalReporte";
import ModalCliente from '../../Clientes/ModalClientes';
import DrawerFiltros from '../../../Widgets/DrawerFiltros/DrawerFiltros'


import '../../../../Styles/Modules/Admin/clientes.scss'


const { confirm } = Modal;
const { Content } = Layout;
const { Text, Title } = Typography;

/**
 *
 *
 * @export
 * @class Clientes
 * @extends {Component}
 * @description Vista donde se listan todos los Clientes
 */
class ClientesList extends Component {



    /**
     *Creates an instance of Clientes.
     * @param {*} props
     * @memberof Clientes
     */
    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            clientes: {
                data: [],
                page: 1,
                limit: 20,
                filters: [],
                total: 0,
                sort: {
                    createdAt: -1
                },
                reglas: false
            },
            vendedor_id: undefined,
            seleccionados: {},
            panels: [],
            modalCliente: false,
            search: this.props.search,

            clearCheckbox: false,
            visibleFilters: false,
            optionsVisible: false,
            fechas: []
        }
    }

    drawerFiltros = React.createRef()

    componentDidMount() {
        this.props.setShowSearch(false)
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token')

        this.getClientes({ filters: this.state.filters, reglas: this.props.asesor });

    }

    componentDidUpdate(prevProps) {
        if (this.props.search != prevProps.search) {
            console.log(this.props.search)
        }
    }


    /**
    * @memberOf Clientes
    * @method getClientes
    * @description Obtiene ls lista de Clientes
    */
    getClientes = ({

        page = this.state.clientes.page,
        limit = this.state.clientes.limit,
        search = this.state.clientes.search,
        filters = this.state.clientes.filters,
        reglas = this.state.clientes.reglas,
        sort = this.state.clientes.sort,

    } = this.state.clientes, { clientes } = this.state
    ) => {
        const params = { page, limit }
        if (sort && Object.values(sort).length > 0) params.sort = JSON.stringify(sort)
        if (search) params.search = search
        if (filters && filters?.length > 0) params.filters = JSON.stringify(filters)

        let clearCheckbox = (page === 1)

        this.setState({ clientes: { ...this.state.clientes, page, limit, search, filters, sort }, clearCheckbox, loading: true })
        axios.get('/clientes', {
            params: {
                page: clientes.data.length < 1 && page !== 1 ? 1 : page,
                limit: clientes.data.length < 1 && page !== 1 ? limit * page : limit,
                search,
                filters,
                reglas,
                sort
            }
        })
            .then(({ data }) => {
                this.setState({
                    clientes: {
                        ...this.state.clientes,
                        reglas,
                        data: data.data,
                        total: data.total,
                        search
                    }
                })

            })
            .catch(error => {
                message.error('Error al obtener la Información')
            })
            .finally(() => this.setState({ loading: false, clearCheckbox: false }))
    }


    /**
    * @param {number} cliente_id El identificador único del cliente.
    * @param {string} estatus El nuevo estado del cliente.
    * @description Función para actualizar el estado de un cliente.
    */
    updateEstatus = (cliente_id, estatus) => {
        this.setState({ loading: true })
        axios.put('/clientes', {
            id: cliente_id,
            estatus
        }).then(success => {
            message.success("Estatus de cliente actualizado")
            this.getClientes()
        }).catch(error => {
            message.error("Error al actualizar el estatus")
        }).finally(()=>this.setState({ loading: false }))
    }

    /**
     *
     *
     * @param {*} event
     * @param {*} filtro
     * @memberof Clientes
     * @description Renderizamos los filtros que estén en el arreglo.
     */
    renderFiltros = ({ filters = [] } = this.state.clientes) => {

        return filters.map(filtro => {
            return <Tag
                closable
                closeIcon={<div className="point" onClick={event => this.deleteFilter(event, filtro)}></div>}
                style={{ lineHeight: '15px', paddingTop: '3px', paddingBottom: '3px' }}
            >
                <small>{filtro?.objectName?.toUpperCase().replace("_", " ")}<br></br></small>
                {filtro?.filter?.range?.join(' - ')}
                {filtro?.label || filtro?.nombre || filtro?.razon_social || filtro?.email || filtro?.folio}
            </Tag>
        });
    }

    /**
     *
     * @param {*} event
     * @param {*} filtro
     * @memberof Clientes
     * @description Para eliminar los filtros
     */
    deleteFilter = (event, filtro) => {
        event.preventDefault()
        try {
            let { filters } = this.state.clientes
            let { _id, name } = filtro

            filters = filters.filter(f => {
                if (f.name === name) {
                    if (f.name === 'fecha') return false
                    return (f._id !== _id)
                }
                return true
            })

            this.setState({ clientes: { ...this.state.clientes, filters } }, () => this.getClientes())
        } catch (error) {
            console.log(error)
            this.setState({ visibleFilters: true })
        }
    }


    /**
     * @param {*} key La clave por la cual ordenar la lista de clientes.
     * @memberof ClientesList Objeto al que pertenece la función setSort.
     * @descrption Función para establecer el orden de clasificación de la lista de clientes.
    */
    setSort = (key) => {
        let value;
        switch (this.state.clientes.sort[key]) {
            case 1:
                value = -1
                break;
            case -1:
                value = undefined
                break;
            default:
                value = 1
                break;
        }

        let sort = {
            [key]: value
        }

        if(value === undefined && key !== "createdAt") sort = { createdAt: -1 }

        this.getClientes({
            sort
        })
    }



    render() {

        const { clientes, loading } = this.state;

        return (
            <>
                <PageHeader
                    className="admin-page-header"
                    title={<Row className="width-100" justify="space-between" align="middle">
                        <Col xxl={{ span: 24 }} xl={{ span: 24 }} lg={{ span: 24 }} md={{ span: 24 }} sm={{ span: 24 }} xs={{ span: 24 }}  >
                            <Title level={4} className="ant-page-header-heading-title m-0" >
                                Clientes
                            </Title>
                        </Col>
                        <Col span={24}>
                            {this.renderFiltros()}
                        </Col>
                    </Row>}
                    extra={<Space>
                        <Button
                            onClick={() => this.setState({ visibleFilters: true })}
                            className="button-plus"
                            icon={<FilterOutlined style={{ color: '#000', fontWeight: 600 }} />} 
                        />
                        {this.props.asesor ?
                            <Tooltip title="Visualiza pedidos de acuerdo a las reglas aplicadas al asesor.">
                                <Switch 
                                    title="Aplicar mis reglas" 
                                    checkedChildren="Filtrado" 
                                    unCheckedChildren="Filtrar Clientes" 
                                    onChange={(checked) => this.getClientes({ reglas: checked })} 
                                    defaultChecked={this.props.asesor}
                                ></Switch>
                            </Tooltip>
                            : null}
                        </Space>
                    }
                />
                <Content
                    className="admin-content content-bg clientes pd-1"
                    id="clientes-layout"
                >
                    <Row gutter={[24, 24]} align={"middle"} justify={"end"}>
                        <Col xs={18} className='flex flex-left'>
                            <Space>
                                <Button onClick={() => this.setState({ modal_reporte: true })}>
                                    Generar reporte
                                </Button>
                            </Space>
                        </Col>
                        <Col span={6} className='flex flex-right' >
                            <Space>
                                <Input
                                    placeholder='Buscar Clientes'
                                    style={{ maxWidth: 350 }}
                                    onChange={e => this.getClientes({ page: 1, search: e.target.value })}
                                />
                            </Space>
                        </Col>
                        <Col xs={24}>
                            <List
                                loading={this.state.loading}
                                className="component-list"
                                itemLayout="horizontal"
                                locale={{ emptyText: "Sin Clientes" }}
                                dataSource={clientes.data}
                                pagination={{
                                    current: clientes.page,
                                    pageSize: clientes.limit,
                                    total: clientes.total,
                                    pageSizeOptions: [10, 20, 50, 100, 500, 1000],
                                    position: 'bottom',
                                    className: "flex-left ml-1",
                                    onChange: (page, limit) => this.getClientes({ page, limit })
                                }}
                                header={
                                    <Row align="middle">
                                        <Col md={21}>
                                            <Row className="width-100 " align="middle" gutter={[0, 12]}>
                                                <Col xs={6} className="center" style={{ color: "gray" }} >
                                                    <Sort
                                                        sort={this.state.clientes?.sort && this.state.clientes?.sort['nombre']} 
                                                        onClick={() => this.setSort("nombre")} 
                                                    >
                                                        <Text ellipsis strong>Nombre</Text>
                                                    </Sort>
                                                </Col>
                                                <Col xs={7} className="center" >
                                                    <Sort
                                                        sort={this.state.clientes?.sort && this.state.clientes?.sort["email"]} onClick={() => this.setSort("email")} >
                                                        <Text ellipsis strong>Email</Text>
                                                    </Sort>
                                                </Col>
                                                <Col xs={4} className="center" >
                                                    <Sort
                                                        sort={this.state.clientes?.sort && this.state.clientes?.sort["createdAt"]} onClick={() => this.setSort("createdAt")} >
                                                        <Text ellipsis strong>Fecha Registro</Text>
                                                    </Sort>
                                                </Col>
                                                <Col xs={3} className="center" >
                                                    <Sort
                                                        sort={this.state.clientes?.sort && this.state.clientes?.sort["estatus"]} onClick={() => this.setSort("estatus")} >
                                                        <Text ellipsis strong>Estatus</Text>
                                                    </Sort>
                                                </Col>
                                                <Col xs={4} className="center" >
                                                    <Text ellipsis strong>Asesor</Text>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col xs={3} >
                                        </Col>
                                    </Row>
                                }

                                renderItem={item => (
                                    <List.Item className="component-list-item">
                                        <Card className="card-list">
                                            <Row align="middle">
                                                <Col xs={21} className="">
                                                    <Row className="width-100 " align="middle" gutter={[6, 4]}>
                                                        <Col xs={6} className="flex-left" >
                                                            {!item.shopify_cliente_id ? <Tooltip title="Cliente no registrado en Shopify">
                                                                <FaShopify style={{ marginRight: "4px", color: "red" }} />
                                                            </Tooltip> : null}
                                                            <Text ellipsis strong>{item.nombre ?? "-"} {item.apellido_paterno}</Text>
                                                        </Col>
                                                        <Col xs={7} className="center" >
                                                            <Text ellipsis >{item.email ?? "-"}</Text>
                                                        </Col>
                                                        <Col xs={4} className="center" >
                                                            <Text ellipsis >{moment(item.createdAt).format("DD/MM/YYYY")}</Text>
                                                        </Col>
                                                        <Col xs={3} className="center" >
                                                            <Dropdown
                                                                overlay={<Menu>
                                                                    <Menu.Item onClick={() => this.updateEstatus(item._id, 0)}>
                                                                        <Space direction="horizontal" size={8}>
                                                                            <div className={`clientes-circle small circle-1`} />
                                                                            <Text>Desistió / Cancelado</Text>
                                                                        </Space>
                                                                    </Menu.Item>
                                                                    <Menu.Item onClick={() => this.updateEstatus(item._id, 1)}>
                                                                        <Space direction="horizontal" size={8}>
                                                                            <div className={`clientes-circle small circle-2`} />
                                                                            <Text>Registrado</Text>
                                                                        </Space>
                                                                    </Menu.Item>
                                                                    <Menu.Item onClick={() => this.updateEstatus(item._id, 2)}>
                                                                        <Space direction="horizontal" size={8}>
                                                                            <div className={`clientes-circle small circle-3`} />
                                                                            <Text>Contactado</Text>
                                                                        </Space>
                                                                    </Menu.Item>
                                                                    <Menu.Item onClick={() => this.updateEstatus(item._id, 3)}>
                                                                        <Space direction="horizontal" size={8}>
                                                                            <div className={`clientes-circle small circle-4`} />
                                                                            <Text>Ya compró algo</Text>
                                                                        </Space>
                                                                    </Menu.Item>
                                                                    <Menu.Item onClick={() => this.updateEstatus(item._id, 4)}>
                                                                        <Space direction="horizontal" size={8}>
                                                                            <div className={`clientes-circle small circle-5`} />
                                                                            <Text>Pagado</Text>
                                                                        </Space>
                                                                    </Menu.Item>
                                                                </Menu>}
                                                                className="clientes-dropdown"
                                                                trigger={['click']}
                                                                disabled={!this.props.canEdit}
                                                            >
                                                                {renderEstatusCliente({estatus: item.estatus})}
                                                            </Dropdown>
                                                        </Col>
                                                        <Col xs={4} className="center" >
                                                            <Text ellipsis >{item.asesor?.nombre ?? "-"} {item.asesor?.apellidos ?? ""}</Text>
                                                        </Col>

                                                    </Row>
                                                </Col>
                                                <Col xs={3} className="center" >
                                                    <Space wrap>
                                                        <Link to={`/admin/crm/clientes/detalle/${item._id}`} >
                                                            <Button type='primary' className='btn-primary' icon={<IconEye />} />
                                                        </Link>
                                                        <ButtonEdit
                                                            disabled={!this.props.canEdit}
                                                            onClick={() => this.setState({ modalCliente: true, cliente_id: item._id })} />
                                                        <ButtonDelete
                                                            disabled={!this.props.canDelete}
                                                            onConfirm={() => {
                                                                confirm({
                                                                    title: "¿Quieres eliminar este cliente?",
                                                                    icon: <WarningFilled />,
                                                                    content: "Se eliminará todo lo relacionado al cliente (inversiones, transacciones, etc.)",
                                                                    okText: "Eliminar",
                                                                    cancelText: "Cancelar",
                                                                    onOk: () => axios.delete('/clientes', { params: { cliente_id: item._id } })
                                                                        .then((response) => {
                                                                            message.success("Se elimino correctamente")
                                                                            this.getClientes()
                                                                        })
                                                                        .catch((error) => {
                                                                            message.error(getResponseError(error.response, "No se pudo eliminar"));
                                                                            this.getClientes()
                                                                        }),
                                                                    onCancel: () => { }
                                                                })
                                                            }} />

                                                    </Space>
                                                </Col>
                                            </Row>
                                        </Card>
                                    </List.Item>
                                )}
                            />
                        </Col>
                    </Row>
                </Content>

                {this.props.canCreate && <FloatingButton title="Nuevo Registro" onClick={() => this.setState({ modalCliente: true, cliente_id: null })} />}

                <DrawerFiltros
                    ref={this.drawerFiltros}
                    title="Filtrar Clientes"
                    updateFilters={filters => this.getClientes({
                        page: 1,
                        filters
                    })}
                    onClose={() => this.setState({ visibleFilters: false })}
                    visible={this.state.visibleFilters}
                    fechas={true}
                    estatus={{
                        type: 'list',
                        data: [{
                            _id: 0,
                            label: "Desisitío / Perdido"
                        }, {
                            _id: 1,
                            label: "Registrado"
                        }, {
                            _id: 2,
                            label: "Contactado"
                        }, {
                            _id: 3,
                            label: "Ya compró algo"
                        }, {
                            _id: 4,
                            label: "Pagado"
                        }]
                    }}
                />

                <ModalCliente
                    visible={this.state.modalCliente}
                    cliente_id={this.state.cliente_id}
                    onClose={() => {
                        this.setState({ modalCliente: false, cliente_id: undefined })
                        this.getClientes()
                    }}

                />
                <ModalReporte
                    visible={this.state.modal_reporte}
                    onClose={() => {
                        this.setState({ modal_reporte: false })
                    }}

                />
            </>
        )
    }
}



export default function (props) {
    const { search, setShow } = useSearch()

    const user = React.useContext(User)

    const asesor = user.is_asesor;
    const permisos = usePermissions(user?.rol_id?.permisos, {
        canCreate: ["clientes", "create"],
        canEdit: ["clientes", "edit"],
        canDelete: ["clientes", "delete"],
        canViewDetails: ["clientes", "cliente-detalles", "access"],
        canAssignAsesor: ["clientes", "assign-asesor"]
    })

    const navigate = useNavigate();


    return <ClientesList {...props}
        search={search}
        {...permisos}
        asesor={asesor}
        setShowSearch={setShow}
        navigate={navigate}
    />
}