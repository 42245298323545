import React, { Component } from "react";
import {
	Row,
	Col,
	Modal,
	Form,
	Input,
	message,
	Spin,
	Button,
	Typography,
	Divider,
	Checkbox,
	Tooltip,
} from "antd";
import axios from "axios";
import {
	PlusOutlined,
	QuestionCircleOutlined,
} from "@ant-design/icons";

//componentes
import ProductoForm from "./ProductoForm";
import SelectCliente from "../../Widgets/Inputs/SelectClientes";
import SelectUsuarios from "../../Widgets/Inputs/SelectUsuarios";
import SelectDireccion from "../../Widgets/Inputs/SelectDirecciones";
import InputTags from "../../Widgets/Inputs/InputTags";

import User from "../../../Hooks/Logged";

//Modales
import ModalDescuento from "./ModalDescuento";
import ModalProductoOrdenes from "./ModalProductoOrdenes";
import ModalEnvioCosto from "./ModalEnvioCosto";

const { Text, Link } = Typography;

/**
 * @class ModalOrdenes
 * @description Modal para el CRUD de Productos
 */
class ModalOrdenes extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: false,
			total: 0,
			productos: {},
			productos_list: [],
			disabled: false,
			orden: {},
			change: 0,
		};
	}

	componentDidMount() {
		axios.defaults.headers.common["Authorization"] = sessionStorage.getItem("token");
		if (this.props.orden_id) {
			this.getOrden();
		}
	}

	formRef = React.createRef();

	/**
	 * @method onFinish
	 * @description Se ejecuta al hacer submit al formulario
	 */
	onFinish = (values) => {
		values.descuento = this.state.descuento;
		values.costo_envio = this.state.costo_envio;
		if (this.props.orden_id) {
			this.updateOrden(values);
		} else this.addOrden(values);
	};

	/**
	 * @method updateTotal
	 * @description Actualiza una varianble del state para volver a actualizar los totales
	 */
	updateTotal = (index, cantidad) => {
		let productos_list = [...this.state.productos_list];

		productos_list[index].cantidad = cantidad;
		if(cantidad == 0){
			productos_list.splice(index, 1)			
		}

		this.setState({ productos_list });
	};

	/**
	 * @method getOrden
	 * @description obtiene informacion de una orden
	 */
	getOrden = (values) => {
		this.setState({ loading: true });
		axios
			.get("/orden/" + this.props.orden_id)
			.then((response) => {
				let orden = response.data;

				this.formRef.current.setFieldsValue({
					...orden,
					email: orden.shopify_email,
					phone: orden.shopify_phone,
					asesor_id: orden?.asesor?._id
						? {
							value: orden?.asesor?._id,
							label: orden?.asesor?.nombre,
						}
						: null,
					cliente_id: orden?.cliente_id?._id
						? {
							value: orden?.cliente_id?._id,
							label: `${orden?.cliente_id?.nombre ?? "-"} ${orden?.cliente_id?.apellido_paterno}`,
						}
						: null,
					direccion_id: orden?.direccion_id?._id
						? {
							value: orden?.direccion_id?._id,
							label: `${orden?.direccion_id?.nombre ?? "-"}`,
						}
						: null,
					productos: orden.ordenes_productos_ids.map((e) => ({
						variante_id: e.variante_id?._id,
						cantidad: e.cantidad,
						precio: e.precio,
					})),
					tags: orden.shopify_tags,
				});

				let productos = {};

				orden.ordenes_productos_ids.forEach((elem, index) => {
					productos[index] = elem.total;
				});

				this.setState({
					productos_list: orden.ordenes_productos_ids,
					total: orden.total,
					productos,
					disabled: orden.shopify_id,
					orden: orden,
					descuento: orden.descuento,
					costo_envio: orden.costo_envio,
				});
			})
			.catch((error) => {
				message.error(
					error?.response?.data?.message ?? "Error al obtener el pedido",
				);
			})
			.finally(() => this.setState({ loading: false }));
	};

	getAsesor = (cliente_id) => {
		this.setState({ loading: true })
        axios.get('/clientes/' + cliente_id, {})
        .then(response => {
           	if(response?.data?.asesor_id?._id){
           		this.formRef.current.setFieldsValue({
           			asesor_id:{
						value: response?.data?.asesor_id?._id,
						label: `${response?.data?.asesor_id?.nombre ?? "-"} ${response?.data?.asesor_id?.apellidos ?? ""}`,
					}
           		})
           	}

        }).catch(error => {
            console.log("error", error);
            message.error("Error al obtener la información del asesor")
        }).finally(() => this.setState({ loading: false }))
	}

	/**
	 * @method addOrden
	 * @description refistra una nueva orden
	 */
	addOrden = (values) => {
		this.setState({ loading: true });
		console.log(values)

		if(values?.asesor_id?.value) values.asesor_id = values?.asesor_id?.value

		axios
			.post("/ordenes", values)
			.then((response) => {
				message.success("Orden creada");
				this.props.onClose(true);
			})
			.catch((error) => {
				console.log("error", error);
				message.error(
					error?.response?.data?.message ?? "Error al crear el pedido",
				);
			})
			.finally(() => this.setState({ loading: false }));
	};

	/**
	 * @method updateOrden
	 * @description refistra una nueva orden
	 */
	updateOrden = (values) => {
		values.direccion_id = values.direccion_id?.value ?? values.direccion_id;

		this.setState({ loading: true });
		axios
			.put("/ordenes", {
				orden_id: this.props.orden_id,
				...values,
			})
			.then((response) => {
				message.success("Pedido actualizado");
				this.props.onClose(true);
			})
			.catch((error) => {
				console.log("error", error);
				message.error(error?.response?.data?.message ?? "Error al actualizar el pedido");
			})
			.finally(() => this.setState({ loading: false }));
	};

	/**
	 * @method setVariantes
	 * @description añade las variantes a la orden
	 */
	setVariantes = (variantes) => {
		let variantes_orden = this.formRef.current.getFieldValue("productos");

		let variantes_old = [...this.state.productos_list];

		variantes_old.push(...variantes);

		// Utilizamos un Set para almacenar los _id únicos
		let uniqueIds = new Set();

		// Filtramos el array para incluir solo objetos con _id únicos
		variantes_old = variantes_old.filter((producto) => {
			if (!uniqueIds.has(producto.variante_id._id) && producto.cantidad > 0) {
				uniqueIds.add(producto.variante_id._id);
				return true;
			}
			return false;
		});

		this.formRef.current.setFieldsValue({
			productos: variantes_old.map((e) => ({
				variante_id: e.variante_id?._id,
				cantidad: e.cantidad,
				precio: e.precio,
			})),
		});

		this.setState({ productos_list: variantes_old });
	};

	/**
	 * @method setVariantes
	 * @description renderiza los totales de la orden
	 */
	renderTotal = () => {
		let sub_total = 0,
			impuestos = 0,
			total = 0,
			sub_total_show = 0,
			descuento_total = 0;

		let { orden, descuento, costo_envio } = this.state;

		if (this.formRef.current !== undefined && this.formRef.current !== null) {
			let variantes_orden = this.formRef.current.getFieldValue("productos");
			if (variantes_orden) {
				for (let variante of variantes_orden) {
					sub_total += variante.precio * variante.cantidad;
				}

				sub_total_show = sub_total;

				if (descuento?.tipo === "percentage") {
					descuento_total = sub_total * (descuento?.valor / 100);
				} else if (descuento?.tipo === "fixed_amount")
					descuento_total = descuento?.valor;

				sub_total = sub_total - descuento_total;
				impuestos = 0.16 * sub_total;
				total = sub_total + impuestos + (costo_envio?.monto ?? 0);
			}
		}
		return (
			<>
				<Col span={15} className="flex-right">
					<Text>Sub-Total</Text>
				</Col>
				<Col span={5} className="flex-right">
					<Text>
						$ {sub_total_show?.toMoney(true)} <small>MXN</small>
					</Text>
				</Col>
				<Col className="flex-right" span={15}>
					<Link
						onClick={() =>
							this.setState({
								modal_visible_descuento: this.state.disabled ? false : true,
							})
						}
						style={{ color: this.state.disabled ? "black" : null }}
					>
						Descuentos:
					</Link>
				</Col>
				<Col className=" flex-right" span={5}>
					<Text>
						$ {descuento_total ? `- ${descuento_total.toMoney(true)}` : 0}{" "}
						<small>MXN</small>
					</Text>
				</Col>
				<Col span={15} className="flex-right">
					<Text>IVA (16%)</Text>
				</Col>
				<Col span={5} className="flex-right">
					<Text>
						$ {impuestos?.toMoney(true)} <small>MXN</small>
					</Text>
				</Col>
				<Col className="flex-right" span={15}>
					<Link
						onClick={() =>
							this.setState({
								modal_visible_envio: this.state.disabled ? false : true,
							})
						}
						style={{ color: this.state.disabled ? "black" : null }}
					>
						Costo Envio:
					</Link>
				</Col>
				<Col className=" flex-right" span={5}>
					<Text>
						$ {(costo_envio?.monto ?? 0).toMoney(true)} <small>MXN</small>
					</Text>
				</Col>
				<Col span={15} className="flex-right">
					<Text strong>Total</Text>
				</Col>
				<Col span={5} className="flex-right">
					<Text strong>
						$ {total?.toMoney(true)} <small>MXN</small>
					</Text>
				</Col>
			</>
		);
	};

	render() {
		const { orden } = this.state;

		return (
			<Spin spinning={this.state.loading}>
				<Form
					id="form-orden"
					layout={"vertical"}
					className="mt-2"
					onFinish={this.onFinish}
					ref={this.formRef}
					initialValues={{
						crear_pedido: true,
						cliente_id: this.props.cliente
							? {
								value: this.props.cliente._id,
								label: `${this.props?.cliente.nombre ?? "-"} ${this.props?.cliente.apellido_paterno}`,
							}
							: null,
					}}
				>
					<Row justify="center" className="center" gutter={[8, 0]}>
						<Col span={10}>
							<Form.Item name="cliente_id" label="Cliente">
								<SelectCliente
									disabled={this.props.cliente || this.state.disabled}
									onSelect={(cliente_id) => {
										this.setState({ cliente_id });
										this.getAsesor(cliente_id);
										this.formRef.current.setFieldsValue({ direccion_id: null });
									}}
									onClear={() => {
										this.setState({ cliente_id: undefined });
										this.formRef.current.setFieldsValue({ direccion_id: null, asesor_id: null });
									}}
								/>
							</Form.Item>
						</Col>
						<Col span={10}>
							<Form.Item name="asesor_id" label="Asesor">
								<SelectUsuarios/>
							</Form.Item>
						</Col>
						<Col span={20}>
							<Form.Item name="credito" valuePropName="checked">
								<Checkbox>
									Linea de crédito &nbsp; 
									<Tooltip
										placement="topRight"
										title={
											"Utilizar la linea de crédito asignada para el cliente seleccionado"
										}
									>

										<QuestionCircleOutlined />
									</Tooltip>
								</Checkbox>
							</Form.Item>
						</Col>
						{/* NO BORRAR, POR SI EL CLIENTE LO PIDE */}
						{/* <Col span={20}>
							<Form.Item name="saldo" valuePropName="checked" extra>
								<Checkbox>
									Saldo a favor&nbsp; 
									<Tooltip
										placement="topRight"
										title={
											"Utilizar la linea de saldo a favor para el cliente seleccionado"
										}
									>

										<QuestionCircleOutlined />
									</Tooltip>
								</Checkbox>
							</Form.Item>
						</Col> */}
						<Col span={20}>
							<Form.Item name="direccion_id" label="Dirección">
								<SelectDireccion
									disabled={!this.state.cliente_id || this.state.disabled}
									params={{ cliente_id: this.state.cliente_id }}
								/>
							</Form.Item>
						</Col>
						{orden.shopify_id ? (
							<>
								<Col span={10}>
									<Form.Item name="email" label="Correo de contacto">
										<Input />
									</Form.Item>
								</Col>
								<Col span={10}>
									<Form.Item name="phone" label="Teléfono de contacto">
										<Input />
									</Form.Item>
								</Col>
								<Col span={20}>
									<Form.Item name="notas" label="Notas">
										<Input />
									</Form.Item>
								</Col>
								<Col span={20}>
									<Form.Item name="tags" label="Etiquetas">
										<InputTags />
									</Form.Item>
								</Col>{" "}
							</>
						) : null}
						<Col span={20}>
							<Form.List name="productos">
								{(fields, { add, remove }) => (
									<>
										{fields.map(({ key, name, ...restField }) => (
											<ProductoForm
												key={key}
												name={name}
												restField={restField}
												remove={() => {
													if (!this.state.disabled) {
														remove(name);
														this.updateTotal(name, 0, true);
													}
												}}
												updateTotal={this.updateTotal}
												data={this.state.productos_list[name]}
												disabled={this.state.disabled}
											/>
										))}
										<Form.Item>
											<Button
												type="dashed"
												onClick={() => this.setState({ modal_visible: true })}
												block
												icon={<PlusOutlined />}
												disabled={this.state.disabled}
											>
												Añadir producto
											</Button>
										</Form.Item>
									</>
								)}
							</Form.List>
						</Col>

						<Divider />
						{this.renderTotal()}
						{!this.props.orden_id ? (
							<Col span={20}>
								<Form.Item name="crear_pedido" valuePropName="checked">
									<Checkbox>
										Crear Pedido{" "}
										<Tooltip
											placement="topRight"
											title={
												"El pedido se creará directamente sin pasar a ser un borrador, pero no se podrá editar desde el sistema, excepto algunos campos."
											}
										>
											{" "}
											<QuestionCircleOutlined />{" "}
										</Tooltip>
									</Checkbox>
								</Form.Item>
							</Col>
						) : null}
					</Row>
				</Form>
				<ModalProductoOrdenes
					open={this.state.modal_visible}
					onClose={(flag) => {
						this.setState({ modal_visible: false });
					}}
					setVariantes={this.setVariantes}
				/>
				<ModalDescuento
					open={this.state.modal_visible_descuento}
					onClose={(flag) => {
						this.setState({ modal_visible_descuento: false });
						if (flag.tipo) {
							this.setState({ descuento: flag });
						}
					}}
					descuento={this.state.descuento}
				/>
				<ModalEnvioCosto
					open={this.state.modal_visible_envio}
					onClose={(flag) => {
						this.setState({ modal_visible_envio: false });
						if (flag.monto >= 0) {
							this.setState({ costo_envio: flag });
						}
					}}
					costo_envio={this.state.costo_envio}
				/>
			</Spin>
		);
	}
}

export default function Modals(props) {
	const { open = false, onClose = () => { }, orden_id } = props;

	const user = React.useContext(User);

	return (
		<Modal
			open={open}
			onCancel={onClose}
			title={orden_id ? "Editar Pedido" : "Crear Pedido"}
			closable={true}
			destroyOnClose={true}
			zIndex={1000}
			cancelText="Cancelar"
			okText="Guardar"
			okButtonProps={{ form: "form-orden", key: "submit", htmlType: "submit" }}
			width={700}
		>
			<ModalOrdenes {...props} />
		</Modal>
	);
}
