import React, { useState, useEffect } from 'react';
import { Input, Tag } from 'antd';

const TagInput = (props) => {

	const {
        value,
        onChange,
    } = props



	const [inputValue, setInputValue] = useState('');
	const [tags, setTags] = useState([]);

	const handleInputChange = (e) => {
		setInputValue(e.target.value);
	};

	const handleInputConfirm = () => {
		if (inputValue && !tags.includes(inputValue)) {
			setTags([...tags, inputValue.trim()]);
			setInputValue('');
			onChange([...tags, inputValue.trim()])	
		}
	};

	const handleTagClose = (removedTag) => {
		const updatedTags = tags.filter(tag => tag !== removedTag);
		setTags(updatedTags);
		onChange(updatedTags)	
	};

	useEffect(() => {
        if (value && typeof value === "string" ){
            let tags_value = value.split(", ")
            setTags([...tags_value])
        }
    }, [value])

	return (
		<div>
			<Input
				type="text"
				value={inputValue}
				onChange={handleInputChange}
				onBlur={handleInputConfirm}
				onPressEnter={handleInputConfirm}
				style={{ width: '200px' }}
			/>
			<div style={{ marginTop: '8px' }}>
				{tags.map(tag => (
					<Tag
						key={tag}
						closable
						onClose={() => handleTagClose(tag)}
					>
						{tag}
					</Tag>
				))}
			</div>
		</div>
	);
};

export default TagInput;
