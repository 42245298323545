import React from 'react'
import { Route, Routes } from "react-router-dom";

import Finanzas from '../../Components/Admin/Finanzas/Dashboard';
import Transacciones from '../../Components/Admin/Finanzas/Transacciones/Transacciones';
import TransaccionesBXNK from '../../Components/Admin/Finanzas/TransaccionesBXNK/TransaccionesBXNK';

/**
 * 
 * @export
 * @function RouterFinanzas
 * @description Router for Finanzas routes 
 */
function RouterFinanzas(props) {
  return (
    <Routes>
      <Route path="" element={<Finanzas {...props} />} />
      <Route path="transacciones" element={<Transacciones {...props} />} />
      <Route exact path="transacciones/BXNK/:cuenta_id" element={<TransaccionesBXNK {...props} />} />
    </Routes>
  )
}

export default RouterFinanzas