import React, { Component, useContext } from 'react';
import { Row, Col, Button, Popconfirm, Spin, Table, Layout, message, Breadcrumb, Typography, Space, List, Card } from 'antd'
import axios from 'axios';
import { Link, useNavigate, useParams } from "react-router-dom";

//Componentes
import User from "../../../Hooks/Logged";
import usePermissions from "../../../Hooks/usePermissions";
import FloatingButton from "../../Widgets/Floating Button/FloatingButton";
import PageHeader from '../../Widgets/PageHeader/PageHeader';

import ModalUbicaciones from './ModalUbicaciones'
import { ButtonDelete, ButtonEdit } from '../../Widgets/Buttons';
import useSearch from '../../../Hooks/useSearch';

const { Content } = Layout;
const { Text } = Typography

/**
 * @export
 * @class Ubicaciones
 * @extends {Component}
 * @description Vista de Ubicaciones
 */
class Ubicaciones extends Component {

    back = () => {
        this.props.navigate(-1);
    }

    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            modalVisible: false,

            almacen_id: this.props.params.almacen_id,
            ubicacion_id: undefined,
            area: null,


            ubicaciones: {
                data: [],

                page: 1,
                limit: 10,
                total: 0,
                pages: 0,
            },

            filtroSearch: '',
            searching: true,
        }
    }

    componentDidMount() {
		this.props.setShowSearch(true)
		this.getAlmacen();
        this.getUbicaciones();
	}

	componentDidUpdate(prevProps){
		if(this.props.search !== prevProps.search){
			this.getUbicaciones({page: 1, search: this.props.search})
		}
	}

	componentWillUnmount(){
		this.props.setShowSearch(false)
	}


    /**
     * @memberof ModalAreas
     * @method getAlmacen
     * @description Obtiene una area de la DB
     */
    getAlmacen = () => {
        let search = this.props.search;
        axios.get('/almacenes/' + this.props.params.almacen_id)
        .then(({data}) => {
            console.log("data", data);
            this.setState({almacen: data})
        }).catch(error => {
            message.error('Error al traer area')
            console.log(error)
        }).finally(() => this.setState({ loading: false }))
    }


    /**
     * @memberof ModalAreas
     * @method getArea
     * @description Obtiene los rubors de una area de la DB
     */
    getUbicaciones = ({
        page = this.state.ubicaciones.page,
        limit = this.state.ubicaciones.limit,
        search = this.props.search
    } = this.state.ubicaciones) => {

        axios.get('/ubicaciones', {
            params: { 
                limit, 
                page, 
                search, 
                almacen_id: this.props.params.almacen_id
            }
        })
            .then(({ data }) => {
                this.setState({ ubicaciones: data })
            })
            .catch(res => {
                message.error(res.response.data?.message ?? "Error al obtener las ubicaciones")
            })
            .finally(() => this.setState({ loading: false }))
    }


    render() {

        const { almacen, ubicaciones } = this.state;

        return (
            <>
                <Spin spinning={false}>
                    <PageHeader
                        className="admin-page-header"
                        title={`Ubicaciones de ${almacen?.nombre}`}
                        backIcon={true}
                    />

                    <Content className="admin-content content-bg pd-1">

                        <List
                            loading={this.state.loading}
                            className="component-list"
                            itemLayout="horizontal"
                            locale={{ emptyText: "Sin ubicaciones" }}
                            dataSource={ubicaciones.data}
                            pagination={{
                                current: ubicaciones.page,
                                pageSize: ubicaciones.limit,
                                total: ubicaciones.total,
                                hideOnSinglePage: false,
                                position: 'bottom',
                                onChange: (page, limit) => this.getUbicaciones({ page, limit })
                            }}
                            header={<Row className="width-100 pr-1 pl-1" >
                                <Col span={8} className="center">
                                    <Text strong>Nombre</Text>
                                </Col>
                                <Col span={12} className="center">
                                    <Text strong>Dirección</Text>
                                </Col>
                                

                            </Row>
                            }

                            renderItem={item => (
                                <List.Item className="component-list-item">
                                    <Card className="card-list">
                                        <Row className="width-100 ">

                                            <Col span={8} className="center">
                                                <Text ellipsis>{item.nombre}</Text>
                                            </Col>
                                            <Col span={12} className="center">
                                                <Text className="text-gray">{item.direccion}</Text>
                                            </Col>
                                            <Col span={4} className="center">
                                                <Space>
                                                    <ButtonEdit
                                                        title="Editar"
                                                        onClick={() => { this.setState({ modalVisible: true, ubicacion_id: item._id }) }}
                                                    />
                                                    
                                                    <ButtonDelete
                                                        title="Eliminar"
                                                        onConfirm={() => {
                                                            this.setState({loading: true})
                                                            axios.delete('/ubicaciones/' + item._id).then(() => {
                                                                this.getUbicaciones()
                                                                message.success('Ubicación eliminada')
                                                            }).catch(error => {
                                                                this.setState({loading: false})
                                                                console.log(error?.response?.data?.message ?? "Error al eliminar")
                                                            })
                                                        }}
                                                    />
                                                    
                                                </Space>
                                            </Col>
                                        </Row>
                                    </Card>
                                </List.Item>
                            )}
                        />


                    </Content>

                    <FloatingButton title="Nuevo registro" onClick={() => this.setState({ modalVisible: true })} />

                    <ModalUbicaciones
                        visible={this.state.modalVisible}
                        onClose={() => {
                            this.setState({ modalVisible: false, ubicacion_id: undefined })
                            this.getUbicaciones()
                        }}
                        ubicacion_id={this.state.ubicacion_id}
                        almacen_id={this.props.params.almacen_id}
                    />
                </Spin>
            </>
        )
    }
}
export default function (params) {

    const user = useContext(User);
    const { search, setShow } = useSearch()

    const permisos = usePermissions(user?.rol_id?.permisos, {
        editarRubros: ["rubros", "edit"],
        deleteRubros: ["rubros", "delete"],
        createRubros: ["rubros", "create"],
    });

    return <Ubicaciones {...params} params={useParams()} navigate={useNavigate()} {...permisos} search={search} setShowSearch={setShow}/>
}