import React, { Component, useContext } from 'react';
import { Layout, Row, Col, Tooltip, Typography, Dropdown, Modal, message, Statistic, Space, Collapse, Tag, Pagination, Button, Divider, Spin, Card, DatePicker } from "antd";
import axios from 'axios';
import dayjs from "dayjs";
import { GiCancel } from "react-icons/gi";
import { BsBoxSeam } from "react-icons/bs";
import ReactECharts from 'echarts-for-react';
import { MoreOutlined, FilterOutlined } from '@ant-design/icons';
import { AiFillDollarCircle } from "react-icons/ai";
import { useNavigate, useParams } from "react-router-dom";
import { FaShopify, FaTruck, FaSitemap } from "react-icons/fa";

//Componentes
import User from "../../../Hooks/Logged";
import usePermissions from "../../../Hooks/usePermissions";
import PageHeader from '../../Widgets/PageHeader/PageHeader';
import { renderEstatusOrdenEnvio, renderEstatusOrdenPago } from "../../Utils";

//Modales
import ModalOrdenes from '../Ordenes/ModalOrdenes'
import DrawerBitacora from '../Ordenes/DrawerBitacora'
import ModalTransacciones from '../Ordenes/ModalTransacciones'
import ModalOrdenesDetalles from '../Ordenes/ModalOrdenesDetalles'
import DrawerFiltros from '../../Widgets/DrawerFiltros/DrawerFiltros';
import ModalFulfillmentsDetalle from '../Ordenes/ModalFulfillmentsDetalle';



const { Content } = Layout;
const { confirm } = Modal;
const { Text } = Typography;
const { RangePicker } = DatePicker;
const es = require('dayjs/locale/es');

// Cargar el idioma español
dayjs.locale(es);

/**
 *
 *
 * @export
 * @class Ordenes
 * @extends {Component}
 * @description Vista del listado de Ordenes de Shopify
 */
class Ordenes extends Component {

    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            variante_id: null,
            cliente: {},
            ordenes: {
                data: [],
                limit: 50,
                pageInfo: undefined,
                total: 0
            },
            pedidos:{
                tipo: "normal",
                label: "Ver borradores",
            },
            seleccionadas: {},
            fecha_inicio: dayjs().startOf('month'),
            fecha_final: dayjs().endOf("month"),
            mes_actual: dayjs().format('MMMM'),
            mes_anterior: dayjs().subtract(1, 'month').format('MMMM'),
            data_grafica: [],
            totales: [],
            estadisticas: {
                enviados: 0,
                productos: 0,
                variantes: 0,
            },
        }
    }

    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        this.getOrdenes()
        this.getGrafica()
    }



    /**
    * @memberof Ordenes
    * @description Obtiene una lista de ordenes
    */
    getOrdenes = ({
        limit = this.state.ordenes.limit,
        page = this.state.ordenes.page,
        filters = this.state.ordenes.filters,
    } = this.state.ordenes) => {

        this.setState({ loading: true })
        axios.get('/ordenes', {
            params: {
                limit,
                page,
                orden_detalles: true,
                filters,
                tipo: "normal",
                fechas: [this.state.fecha_inicio, this.state.fecha_final]
            }
        }).then(({ data }) => {
            this.setState({
                ordenes: data
            })

        }).catch(error => {
            message.error(error.response?.data?.message ?? 'Error al obtener los pedidos')
        }).finally(() => this.setState({ loading: false }))

    }

    /**
   *
   * @memberof Ordenes
   * 
   * @method renderMenuItems
   * @description renderiza los elementos del menu de cada pedido
   */
    renderMenuItems = (orden) => {

        let menu_items = [
            {
                key: '1',
                label: <Text>Ver Detalle</Text>,
                onClick: () => this.setState({ modal_visible_detalle: true, orden_id: orden._id })
            },
            {
                key: '2',
                label: <Text>Ver Transacciones</Text>,
                onClick: () => this.setState({ modal_transacciones: true, orden_id: orden._id, orden: orden })
            },
            {
                key: '3',
                label: <Text>Ver PDF</Text>,
                onClick: () => {
                    let url = new URL(axios.defaults.baseURL + '/ordenes/pdf')
                    url.searchParams.append('orden_id', orden?._id)
                    url.searchParams.append('Authorization', sessionStorage.getItem('token'))
                    window.open(url, '_blank')
                }
            },
            {
                key: '6',
                label: <Text>Ver PDF de Empaquetado</Text>,
                onClick: () => {
                    let url = new URL(axios.defaults.baseURL + '/ordenes/packing')
                    url.searchParams.append('orden_id', orden?._id)
                    url.searchParams.append('timezone', Intl.DateTimeFormat().resolvedOptions().timeZone)
                    url.searchParams.append('Authorization', sessionStorage.getItem('token'))
                    window.open(url, '_blank')

                    this.getOrdenes()
                }
            },
            {
                key: '4',
                label: <Text>Editar</Text>,
                disabled: !this.props.canEdit,

                onClick: () => this.setState({ modal_visible: true, orden_id: orden._id })
            },
            {
                key: '5',
                label: <Text>Eliminar</Text>,
                disabled: !this.props.canDelete,
                onClick: () => {
                    confirm({
                        title: "¿Deseas eliminar este pedido?",
                        cancelText: "Cancelar",
                        okText: "SI",
                        onOk: () => {
                            this.setState({ loading: true })
                            axios.delete('/ordenes/' + orden._id).then((response) => {
                                message.success('Pedido Eliminada')
                                this.getOrdenes()
                            }).catch((error) => {
                                message.error('Pedido NO Eliminado')
                            }).finally(() => this.setState({ loading: false }))
                        },
                    });
                }
            }


        ]

        if (orden.shopify_draft_id && !orden.shopify_id) {
            menu_items.unshift({
                key: "0",
                label: <Text>Aprobar Draft</Text>,
                onClick: () => this.aprobarOrden(orden._id)
            })
        }

        if (orden.bitacora_log) {
            menu_items.push({
                key: "7",
                label: <Text>Ver Bitcora</Text>,
                onClick: () => this.setState({ drawer_bitacora: true, orden_id: orden._id })
            })
        }

        return menu_items
    }

    /**
    *
    * @memberof Ordenes
    * 
    * @method getGrafica
    * @description Obtiene la informacion de los pedidos de forma de Analytics 
    */
    getGrafica = () => {
        axios.get("/crm/comparativo",{
            params:{
                fecha_inicio: this.state.fecha_inicio,
                fecha_final: this.state.fecha_final
            }
        })
            .then(({ data }) => {
                this.setState({ ...data })
            }).catch(error => {
                console.log("error", error.response);
            })
    }

     /**
     * @memberof Dashboard
     * @method dates
     * @description Actualiza el rango de fechas para realizar la buequeda
     */
    onChangeDates = (dates) => {
        if (dates) {
            this.setState({
                fecha_inicio: dates[0],
                fecha_final: dates[1]
            }, () => {
                this.getGrafica()
                this.getOrdenes()
            })
        }else{
            this.setState({
                fecha_inicio: undefined,
                fecha_final: undefined
            }, () => {
                this.getGrafica()
                this.getOrdenes()
            })
        }
    }

    /**
    *
    * @memberof Ordenes
    * 
    * @method changeList
    * @description Filtra las ordenes consfimadas o los borradores
    */
    changeList = () => {
        let { pedidos } = this.state;

        let obj = {}
        if(pedidos.tipo === "normal"){
            obj = {
                pedidos:{
                    tipo: "borradores",
                    label: "Ver Pedidos"
                }
            }
        }else{
            obj = {
                pedidos:{
                    tipo: "normal",
                    label: "Ver Borradores"
                }
            }
        }

        this.setState({...obj},()=>this.getOrdenes())
    }

    render() {

        let { mes_actual, mes_anterior } = this.state;

        let option = {}
        if (this.state.data_grafica.length > 0)
            option = {
                dataset: [
                    {
                        id: 'dataset_raw',
                        source: this.state.data_grafica
                    },
                    {
                        id: 'dataset_month_2',
                        fromDatasetId: 'dataset_raw',
                        transform: {
                            type: 'filter',
                            config: {
                                and: [
                                    { dimension: 'Dia', gte: 1 },
                                    { dimension: 'Mes', '=': mes_anterior.charAt(0).toUpperCase() + mes_anterior.slice(1) }
                                ]
                            }
                        }
                    },
                    {
                        id: 'dataset_month_1',
                        fromDatasetId: 'dataset_raw',
                        transform: {
                            type: 'filter',
                            config: {
                                and: [
                                    { dimension: 'Dia', gte: 1 },
                                    { dimension: 'Mes', '=': mes_actual.charAt(0).toUpperCase() + mes_actual.slice(1) }
                                ]
                            }
                        }
                    }
                ],
                tooltip: {
                    trigger: 'axis',
                    formatter: function (params) {
                        let result = 'Día de Mes ' + params[0]?.axisValue + '<br/>';
                        result += params[0]?.marker + " " + params[0]?.value[1] + " " + params[0]?.value[0] + '<br/>';
                        if (params[1])
                            result += params[1]?.marker + " " + params[1]?.value[1] + " " + params[1]?.value[0] + '<br/>';
                        return result
                    }
                },
                xAxis: {
                    type: 'category',
                    nameLocation: 'middle'
                },
                yAxis: {
                    name: 'Pedidos'
                },
                series: [
                    {
                        type: 'line',
                        datasetId: 'dataset_month_2',
                        showSymbol: false,
                        encode: {
                            x: 'Dia',
                            y: 'Pedidos',
                            itemName: 'Dia',
                            tooltip: ['Pedidos']
                        }
                    },
                    {
                        type: 'line',
                        datasetId: 'dataset_month_1',
                        showSymbol: false,
                        encode: {
                            x: 'Dia',
                            y: 'Pedidos',
                            itemName: 'Dia',
                            tooltip: ['Pedidos']
                        }
                    }
                ]
            };


        let option_pie = {

            tooltip: {
                trigger: 'item'
            },
            legend: {
                orient: 'vertical',
                left: 'left'
            },
            series: [
                {
                    name: 'Access From',
                    type: 'pie',
                    radius: '50%',
                    data: this.state.totales,
                    emphasis: {
                        itemStyle: {
                            shadowBlur: 10,
                            shadowOffsetX: 0,
                            shadowColor: 'rgba(0, 0, 0, 0.5)'
                        }
                    }
                }
            ]
        };
        return (
            <Spin spinning={this.state.loading}>
                <PageHeader
                    className="admin-page-header"
                    backIcon={true}
                    breadcrumb={{
                        items: [
                            {
                                title: "CRM",
                                className: "hover",
                                onClick: () => this.props.navigate("/admin/crm/")
                            },
                            {
                                title: 'Pedidos Generales',
                            },
                        ]
                    }}
                    extra={
                        <Space>
                            <RangePicker
                                style={{ width: "280px" }}
                                onChange={this.onChangeDates}
                                defaultValue={[this.state.fecha_inicio, this.state.fecha_final]}
                            />
                            {/*<Button onClick={() => this.changeList()} loading={this.state.loading}>{this.state.pedidos.label}</Button>*/}
                            <Button
                                onClick={() => this.setState({ visibleFilters: true })}
                                className="button-plus"
                                icon={<FilterOutlined style={{ color: '#000', fontWeight: 600 }} />} 
                            />
                        </Space>
                    }
                >
                </PageHeader>
                <Content className="admin-content content-bg pd-1 mb-3">
                    <Row gutter={[8, 8]}>
                        <Col span={8}>
                            <Card
                                title={'Comparativos con el mes anterior'}
                                style={{ boxShadow: '0px 10px 15px -3px rgba(0,0,0,0.1)' }}>
                                <Row>
                                    <Col span={24} className="center">
                                        <ReactECharts
                                            option={option}
                                            style={{ width: '100%', maxWidth: 800, height: "300px" }}
                                        />
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                        <Col span={8} className="grid ">
                            <Row gutter={[8, 8]} className="">
                                <Col span={12} className="grid">
                                    <Card size="small" style={{ boxShadow: '0px 10px 15px -3px rgba(0,0,0,0.1)' }}>
                                        <Row className="mt-1">
                                            <Col span={24} className="flex-left-column">
                                                <div className="icon-box red">
                                                    <BsBoxSeam />
                                                </div>
                                                <Text style={{ fontSize: "16px" }}>Producto Vendidos</Text>
                                                <Text strong style={{ fontSize: "20px" }}>{(this.state.estadisticas.productos).toMoney(true, 0)}</Text>
                                            </Col>
                                        </Row>
                                    </Card>
                                </Col>
                                <Col span={12} className="grid">
                                    <Card size="small" style={{ boxShadow: '0px 10px 15px -3px rgba(0,0,0,0.1)' }}>
                                        <Row className="mt-1">
                                            <Col span={24} className="flex-left-column">
                                                <div className="icon-box orange">
                                                    <FaTruck />
                                                </div>
                                                <Text style={{ fontSize: "16px" }}>Envios Realizados</Text>
                                                <Text strong style={{ fontSize: "20px" }}>{(this.state.estadisticas.enviados).toMoney(true, 0)}</Text>
                                            </Col>
                                        </Row>
                                    </Card>
                                </Col>
                                <Col span={12} className="grid">
                                    <Card size="small" style={{ boxShadow: '0px 10px 15px -3px rgba(0,0,0,0.1)' }}>
                                        <Row className="mt-1">
                                            <Col span={24} className="flex-left-column">
                                                <div className="icon-box red">
                                                    <FaSitemap />
                                                </div>
                                                <Text style={{ fontSize: "16px" }}>Sabores vendidos</Text>
                                                <Text strong style={{ fontSize: "20px" }}>{(this.state.estadisticas.variantes).toMoney(true, 0)}</Text>
                                            </Col>
                                        </Row>
                                    </Card>
                                </Col>
                                <Col span={12} className="grid">
                                    <Card size="small" style={{ boxShadow: '0px 10px 15px -3px rgba(0,0,0,0.1)' }}>
                                        <Row className="mt-1">
                                            <Col span={24} className="flex-left-column">
                                                <div className="icon-box orange">
                                                    <GiCancel />
                                                </div>
                                                <Text style={{ fontSize: "16px" }}>Envios cancelados</Text>
                                                <Text strong style={{ fontSize: "20px" }}>{(0).toMoney(true, 0)}</Text>
                                            </Col>
                                        </Row>
                                    </Card>
                                </Col>
                            </Row>
                        </Col>
                        <Col span={8}>
                            <Card
                                title={'Total de ventas'}
                                style={{ boxShadow: '0px 10px 15px -3px rgba(0,0,0,0.1)' }}>
                                <Row>
                                    <Col span={24} className="center">
                                        <ReactECharts
                                            option={option_pie}
                                            style={{ width: '100%', maxWidth: 800, height: "300px" }}
                                        />
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                    </Row>
                    <Row className="header-list width-100 pl-2 pr-1 mb-1 mt-1">
                        <Col xs={12} md={3} className="center">
                            <Text strong>Pedido</Text>
                        </Col>
                        <Col xs={12} md={3} className="center">
                            <Text strong>Artículos</Text>
                        </Col>
                        <Col xs={12} md={3} className="center">
                            <Text strong>Costo Envío</Text>
                        </Col>
                        <Col xs={12} md={4} className="center">
                            <Text strong>Monto Total</Text>
                        </Col>
                        <Col xs={12} md={3} className="center">
                            <Text strong>Estatus Pago</Text>
                        </Col>
                        <Col xs={12} md={3} className="center">
                            <Text strong>Estatus Pedido</Text>
                        </Col>
                        <Col xs={12} md={2} className="center">
                            <Text strong>Fecha</Text>
                        </Col>
                    </Row>
                    <Collapse
                        className="collapse-vapemate"
                        items={this.state.ordenes?.data?.map((item, index) => ({
                            key: index,
                            label: <>
                                <Row className="width-100">
                                    <Col xs={24} className="center">
                                        <Row gutter={[8, 8]} className="width-100">
                                            <Col xs={12} md={3} className="center hover"
                                                onClick={(e) => {
                                                    e.stopPropagation()
                                                    this.setState({ modal_visible_detalle: true, orden_id: item._id })
                                                }}
                                                style={{ zIndex: 1000 }}
                                            >
                                                <Space >
                                                    {!item.shopify_id && !item.shopify_draft_id ? <FaShopify style={{ color: "red" }} /> : null}
                                                    <Text>{item.shopify_folio ?? item.folio}</Text>
                                                    {item.shopify_draft_id && !item.shopify_id ? <Tag color="magenta">Draft</Tag> : null}
                                                </Space>
                                            </Col>
                                            <Col xs={12} md={3} className="flex-column">
                                                <Text strong ellipsis>{item.total_productos ?? 0} Artículos</Text>
                                            </Col>
                                            <Col xs={12} md={3} className="center">
                                                <Statistic value={item.costo_envio?.monto ?? 0} prefix="$" valueStyle={{ fontSize: "14px" }} precision={2} />
                                            </Col>
                                            <Col xs={12} md={4} className="center">
                                                <Statistic
                                                    value={item.total}
                                                    prefix="$"
                                                    valueStyle={{
                                                        fontSize: '14px',
                                                        fontWeight: 'bold',
                                                        color: item.shopify_financial_status == "paid" ? '#2c9d37' : "#ecc602"
                                                    }}
                                                    precision={2}
                                                />
                                            </Col>
                                            <Col xs={12} md={3} className="flex-column">
                                                {renderEstatusOrdenPago(item.shopify_financial_status)}
                                            </Col>
                                            <Col xs={12} md={3} className="flex-column">
                                                {renderEstatusOrdenEnvio(item.shopify_fulfillment_status)}
                                            </Col>
                                            <Col xs={12} md={2} className="center">
                                                <Text>{dayjs(item.fecha).format("DD/MM/YY")}</Text>
                                            </Col>
                                            <Col xs={12} md={1} className="center">
                                                <FaTruck style={{ fontSize: "24px", color: item.shopify_fulfillments?.length > 0 ? "#0cc858" : "black" }} onClick={(e) => {
                                                    e.stopPropagation()
                                                    this.setState({ modal_visible_detalle_fulfillments: true, orden_id: item._id })
                                                }} />
                                            </Col>
                                            <Col xs={12} md={1} className="center">
                                                <Tooltip
                                                    placement="topRight"
                                                    title={item.credito ? "Se utilizó crédito" : "No se utilizó crédito"}
                                                >
                                                    <AiFillDollarCircle
                                                        style={{
                                                            color: item.credito ? "#ecc602" : "gray",
                                                            fontSize: "24px"
                                                        }}
                                                    />
                                                </Tooltip>
                                            </Col>
                                            <Col xs={12} md={1}>
                                                <Dropdown
                                                    placement="bottomRight"
                                                    menu={{
                                                        items: this.renderMenuItems(item)
                                                    }}
                                                >
                                                    <Button
                                                        type="ghost"
                                                        onClick={() => this.setState({ modalTransacDetalleVisible: true, transaccion_id: item._id })}
                                                        icon={<MoreOutlined style={{ color: "black" }} />}
                                                    />
                                                </Dropdown></Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </>,
                            children: [
                                <Row className="width-100 mb-1">
                                    <Col xs={24}>
                                        <Row gutter={[8, 8]}>
                                            <Col xs={12} md={5} className="flex-left-column">
                                                <Text ellipsis strong>PRODUCTO</Text>
                                            </Col>
                                            <Col xs={12} md={5} className="center">
                                                <Text ellipsis>PRECIO</Text>
                                            </Col>
                                            <Col xs={12} md={5} className="center">
                                                <Text ellipsis>DESCUENTO</Text>
                                            </Col>
                                            <Col xs={12} md={5} className="center">
                                                <Text ellipsis>CANTIDAD</Text>
                                            </Col>
                                            <Col xs={12} md={4} className="center">
                                                <Text ellipsis>TOTAL</Text>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>,
                                <Divider />,
                                ...item.ordenes_productos?.map(orden_producto => <Row className="width-100 mb-1">
                                    <Col xs={24}>
                                        <Row gutter={[8, 8]}>
                                            <Col xs={12} md={5} className="flex-left-column">
                                                <Text ellipsis strong>{orden_producto?.producto_id?.modelo ?? "--"}</Text>
                                                <Text ellipsis>{orden_producto?.variante_id?.sabor ?? "--"}</Text>
                                            </Col>
                                            <Col xs={12} md={5} className="center">
                                                <Text ellipsis>$ {orden_producto.precio?.toMoney(true) ?? '-'} MXN</Text>
                                            </Col>
                                            <Col xs={12} md={5} className="center">
                                                <Text ellipsis>$ {(orden_producto.descuento_total ?? orden_producto.descuento)?.toMoney(true) ?? '0'} MXN</Text>
                                            </Col>
                                            <Col xs={12} md={5} className="center">
                                                <Text ellipsis>{orden_producto.cantidad?.toMoney(true, 0) ?? 0}</Text>
                                            </Col>
                                            <Col xs={12} md={4} className="center">
                                                <Text ellipsis>$ {orden_producto.total?.toMoney(true, 2) ?? 0} MXN</Text>
                                            </Col>

                                        </Row>
                                    </Col>
                                </Row>)
                            ]
                        }))}
                    />
                    <Pagination
                        className="mt-1"
                        showSizeChanger
                        current={this.state.ordenes?.page}
                        total={this.state.ordenes?.total}
                        pageSize={this.state.ordenes?.limit}
                        onChange={(page, limit) => this.getOrdenes({ page, limit })}
                    />
                </Content>

                <ModalOrdenes
                    open={this.state.modal_visible}
                    onClose={(flag) => {
                        this.setState({ modal_visible: false, orden_id: undefined })
                        if (flag === true)
                            this.getOrdenes();
                    }}
                    orden_id={this.state.orden_id}
                />
                <ModalOrdenesDetalles
                    open={this.state.modal_visible_detalle}
                    onClose={(flag) => {
                        this.setState({ modal_visible_detalle: false, orden_id: undefined })
                    }}
                    orden_id={this.state.orden_id}
                />
                <ModalFulfillmentsDetalle
                    open={this.state.modal_visible_detalle_fulfillments}
                    onClose={(flag) => {
                        this.setState({ modal_visible_detalle_fulfillments: false, orden_id: undefined })
                    }}
                    orden_id={this.state.orden_id}
                />
                <ModalTransacciones
                    open={this.state.modal_transacciones}
                    onClose={(flag) => {
                        this.setState({ modal_transacciones: false, orden_id: undefined })
                    }}
                    orden_id={this.state.orden_id}
                    orden={this.state.orden}
                />
                <DrawerBitacora
                    open={this.state.drawer_bitacora}
                    onClose={() => {
                        this.setState({ drawer_bitacora: false, orden_id: undefined })
                    }}
                    orden_id={this.state.orden_id}
                />
                <DrawerFiltros
                    ref={this.drawerFiltros}
                    title="Filtrar Pedidos"
                    updateFilters={filters => this.getOrdenes({
                        page: 1,
                        filters
                    })}
                    onClose={() => this.setState({ visibleFilters: false })}
                    visible={this.state.visibleFilters}
                    clientes={true}
                    shopify_financial_status={[
                        {
                            _id: "pending",
                            label: "Pago Pendiente"
                        },{
                            _id: "partially_paid",
                            label: "Pago Parcial"
                        },{
                            _id: "paid",
                            label: "Pagado"
                        },{
                            _id: "voided",
                            label: "Pago Anulado"
                        }
                    ]}
                    shopify_fulfillment_status={
                        [
                            {
                                _id: "no_fulfilled",
                                label: "No Preparado"
                            },{
                                _id: "fulfilled",
                                label: "Envio Preparado"
                            }
                        ]
                    }
                />
            </Spin>
        )
    }
}


export default function (params) {

    const user = useContext(User);

    const permisos = usePermissions(user?.rol_id?.permisos, {
        canEdit: ["ordenes", "edit"],
        candDelete: ["ordenes", "delete"],
        canCreate: ["ordenes", "create"],
    });

    return <Ordenes {...params} params={useParams()} navigate={useNavigate()} {...permisos} />
}