import React, { Component } from "react";
import { Row, Col, Drawer, Form, Input, message, Spin, Select, List, Button, Typography, Space } from 'antd';
import axios from "axios"
import { LinkOutlined, MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";

const { Text, Link, Title } = Typography

const moment = require("moment");

/**
 * @class DrawerBitacora
 * @description Modal para el CRUD de Productos
 */
class DrawerBitacora extends Component {

    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            orden:{},
            bitacora: {
                data: [],
                limit: 50,
                page: 1,
                total: 0,
            }
        }
    }

    componentDidMount() {
        if(this.props.orden_id){
            this.getOrden()
            this.getBitacora()
        }
    }

    formRef = React.createRef();


    /**
    * @method onFinish
    * @description Se ejecuta al hacer submit al formulario
    */
    onFinish = (values) => {

        this.props.onClose(values)
    }


    /**
     * @method getOrden
     * @description obtiene informacion de una orden
     */
    getOrden = (values) => {
        axios.get("/orden/" + this.props.orden_id)
        .then((response) => {
            let orden = response.data;
            this.setState({
                orden
            });
        })
        .catch((error) => {
            message.error(
                error?.response?.data?.message ?? "Error al obtener el pedido",
            );
        })
    };

    /**
     * @method getBitacora
     * @description obtiene informacion de una orden
     */
    getBitacora = ({
        limit = this.state.bitacora.limit,
        page = this.state.bitacora.page,
    } = this.state.bitacora) => {
        this.setState({ loading: true });
        axios.get("/bitacora",{
            params:{
                limit,
                page,
                orden_id: this.props.orden_id
            }
        })
        .then((response) => {
            this.setState({
                bitacora: response.data
            }) 
        })
        .catch((error) => {
            message.error(
                error?.response?.data?.message ?? "Error al obtener la bitacora del pedido",
            );
        })
        .finally(() => this.setState({ loading: false }));
    };

    render() {

        const { bitacora, orden } = this.state
         
        return (
            <Spin spinning={this.state.loading}>
                <Row>
                    <Col span={24}>
                        <Title level={4} className="m-0 mb-2">{orden.shopify_folio}</Title>
                    </Col>
                    <List
                        loading={this.state.loading}
                        className="component-list"
                        itemLayout="horizontal"
                        locale={{ emptyText: "sin registras en la Bitacora" }}
                        dataSource={bitacora.data}
                        pagination={{
                            current: bitacora.page,
                            pageSize: bitacora.limit,
                            total: bitacora.total,
                            hideOnSinglePage: false,
                            position: 'bottom',
                            onChange: (page, limit) => this.getBitacora({ page, limit })
                        }}
                        renderItem={item => {

                            const { usuario_id, createdAt, descripcion } = item;

                            return <Col span={24} className="flex-left-column mb-2">
                                <Text className="text-gray">{moment(createdAt).format("DD/MM/YYYY hh:mm a")}</Text>
                                <Text>El usuario <strong>{usuario_id.nombre ?? "-"} {usuario_id.apellidos}</strong> {descripcion}</Text>
                            </Col>
                        }}
                    />
                </Row>
            </Spin>
        )
    }


}



export default function Modals(props) {

    const { open = false, onClose = () => { }, orden_id } = props

    return <Drawer
        open={open}
        onClose={onClose}
        title={"Bitacora"}
        width={600}
        destroyOnClose={true}
        extra={
            <Space>
                <Button type="primary" onClick={onClose}>OK</Button>
            </Space>
        }
    >
        <DrawerBitacora  {...props} />
    </Drawer>

}