import React from 'react'
import { Route, Routes } from "react-router-dom";

import Ordenes from '../../Components/Admin/Ordenes/Ordenes';

/**
 * 
 * @export
 * @function RouterOrdenes
 * @description Router for Ordenes routes 
 */
function RouterOrdenes(props) {
  return (
    <Routes>
      <Route path="" element={<Ordenes {...props} />} />
    </Routes>
  )
}

export default RouterOrdenes