import React from 'react'
import { Route, Routes } from "react-router-dom";

import Transferencias from '../../Components/Admin/Transferencias/Transferencias';

/**
 * 
 * @export
 * @function RouterTransferencias
 * @description Router for Inventarios routes 
 */
function RouterTransferencias(props) {
  return (
    <Routes>
      <Route path="" element={<Transferencias {...props} />} />
    </Routes>
  )
}

export default RouterTransferencias