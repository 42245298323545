import React, { Component } from "react";
import PageHeader from "../../Widgets/PageHeader/PageHeader";
import { Layout, DatePicker, Row, Col, Radio, Card } from "antd";

import { CardContabilidad } from "../../Widgets/Cards";
import { FaWallet } from "react-icons/fa";
import { AiFillDollarCircle } from "react-icons/ai";
import { IoReceipt } from "react-icons/io5";
import { FaMoneyBills } from "react-icons/fa6";
import axios from "axios";
import dayjs from "dayjs";
import OrdenesCollapse from "./OrdenesCollapse";
import ReactECharts from "echarts-for-react"
const { Content } = Layout;
const { RangePicker } = DatePicker;

class Vencidas extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,

      cantidades: {
        pagado: 0,
        por_recibir: 0,
        por_vencer: 0,
        vencidos: 0,
      },
      historico: [
        {
          name: "Efectivo",
          periodo: "0",
          value: 0,
        },
        {
          name: "Crédito",
          periodo: "0",
          value: 0,
        },
        {
          name: "Pago en especie",
          periodo: "0",
          value: 0,
        },
        {
          name: "Transferencia SPEI",
          periodo: "0",
          value: 0,
        },
      ],
      metodos_pago: [],

      //filtros
      intervalo: 1,
      rango_fechas: [dayjs().startOf('month'), dayjs().endOf("month")],
      agrupar: null
    };
  }


  componentDidMount() {
    this.getInfoCards();
    this.getPagos();
  }

  ordenesRef = React.createRef(null);

  getInfoCards = ({ rango_fechas } = this.state) => {
    this.setState({ loading: true });
    axios
      .get("/cartera-vencida", {
        params: {
          rango_fechas
        },
      })
      .then((response) => {
        console.log("res cartera vencida", response.data);

        this.setState({
          cantidades: response.data.cantidades,
          rango_fechas,
        });
      })
      .catch((error) => {
        console.log("err", error);
      })
      .finally(() => this.setState({ loading: false }));
  };


  getPagos = ({ rango_fechas, intervalo } = this.state) => {

    console.log(rango_fechas, intervalo)
    axios.get("/cartera-vencida/pagos", {
      params: {
        rango_fechas,
        intervalo
      },
    })
      .then(({ data }) => {
        let historico_format = [
          {
            name: "Efectivo",
            periodo: "0",
            value: 0,
          },
          {
            name: "Crédito",
            periodo: "0",
            value: 0,
          },
          {
            name: "Pago en especie",
            periodo: "0",
            value: 0,
          },
          {
            name: "Transferencia SPEI",
            periodo: "0",
            value: 0,
          },
        ]
        if (data.historico.length > 0) {
          historico_format = data.historico
        }
        this.setState({
          intervalo: intervalo,
          metodos_pago: data.metodos_pago,
          historico: historico_format,
        });

      })
      .catch((error) => {
        console.log("err", error);
      })
      .finally(() => this.setState({ loading: false }));
  };

  filter = (element, value) => {
    this.setState({ [element]: value }, this.ordenesRef?.current?.getOrdenes({ agrupar: value }));
  }

  render() {
    let option = {
      tooltip: {
        trigger: "item",
      },
      legend: {
        orient: "vertical",
        left: "left",
      },
      series: {
        name: "Metodos de Pago",
        type: "pie",
        radius: "50%",
        data: this.state.metodos_pago,
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: "rgba(0, 0, 0, 0.5)",
          },
        },
      },
    };

    let option_2 = {
      dataset: (this.state.historico.length > 0) ? [
        {
          id: "historico",
          source: this.state.historico,
        },
        {
          id: "efectivo",
          fromDatasetId: "historico",
          transform: {
            type: "filter",
            config: {
              and: [{ dimension: "name", "=": "Efectivo" }],
            },
          },
        },
        {
          id: "credito",
          fromDatasetId: "historico",
          transform: {
            type: "filter",
            config: {
              and: [{ dimension: "name", "=": "Crédito" }],
            },
          },
        },
        {
          id: "especie",
          fromDatasetId: "historico",
          transform: {
            type: "filter",
            config: {
              and: [{ dimension: "name", "=": "Pago en especie" }],
            },
          },
        },
        {
          id: "spei",
          fromDatasetId: "historico",
          transform: {
            type: "filter",
            config: {
              and: [{ dimension: "name", "=": "Transferencia SPEI" }],
            },
          },
        },
      ] : [],
      tooltip: {
        trigger: "axis",
        formatter: (params) => {
          let output = "Periodo: " + params[0].data.periodo + "</br>";

          for (let serie of params) {
            output += serie.seriesName + ": " + serie.data.value + "</br>";
          }

          return output;
        },
      },
      xAxis: {
        type: "time",
        nameLocation: "middle",
      },
      yAxis: {
        name: "Cantidad",
      },
      series: [
        {
          type: "line",
          name: "Efectivo",
          datasetId: "efectivo",
          showSymbol: false,
          encode: {
            x: "max_date",
            y: "value",
            itemName: "name",
            tooltip: ["value"],
          },
        },
        {
          type: "line",
          name: "Crédito",
          datasetId: "credito",
          showSymbol: false,
          encode: {
            x: "max_date",
            y: "value",
            itemName: "name",
            tooltip: ["value"],
          },
        },
        {
          type: "line",
          name: "Transferencia SPEI",
          datasetId: "spei",
          showSymbol: false,
          encode: {
            x: "max_date",
            y: "value",
            itemName: "name",
            tooltip: ["value"],
          },
        },
        {
          type: "line",
          name: "Pago en especie",
          datasetId: "especie",
          showSymbol: false,
          encode: {
            x: "max_date",
            y: "value",
            itemName: "name",
            tooltip: ["value"],
          },
        },
      ],
    };

    let { rango_fechas, intervalo } = this.state;
    return (
      <>
        <PageHeader
          className="admin-page-header"
          title="Cartera Vencida"
          extra={
            <>
              <RangePicker
                defaultValue={this.state.rango_fechas}
                onChange={(values) => {
                  this.setState({ rango_fechas: values })
                  this.ordenesRef?.current?.getOrdenes({ rango_fechas: values })
                  this.getInfoCards({ rango_fechas: values })
                  this.getPagos({ rango_fechas: values, intervalo })
                }}
              />
            </>
          }
        />

        <Content className="admin-content content-bg pd-1">
          <Row gutter={[12, 12]}>
            <Col xs={24} md={12} xl={6}>
              <CardContabilidad
                onClick={() => this.filter('agrupar', 'vencidos')}
                color="#FF547D"
                icon={<FaWallet />}
                titulo="Pagos Vencidos"
                value={`$ ${this.state.cantidades.vencidos.toMoney(true)}`}
              />
            </Col>
            <Col xs={24} md={12} xl={6}>
              <CardContabilidad
                onClick={() => this.filter('agrupar', 'vencer')}
                color="#FFB359"
                icon={< AiFillDollarCircle />}
                titulo="Pagos por vencer"
                value={`$ ${this.state.cantidades.por_vencer.toMoney(true)}`}
              />
            </Col>
            <Col xs={24} md={12} xl={6}>
              <CardContabilidad
                onClick={() => this.filter('agrupar', 'recibidos')}
                color="#ADFF45"
                icon={<IoReceipt />}
                titulo="Pagos Recibidos"
                value={`$ ${this.state.cantidades.pagado.toMoney(true)}`}
              />
            </Col>
            <Col xs={24} md={12} xl={6}>
              <CardContabilidad
                onClick={() => this.filter('agrupar', 'recibir')}
                color="#AC46CF"
                icon={<FaMoneyBills />}
                titulo="Pagos por recibir"
                value={`$ ${this.state.cantidades.por_recibir.toMoney(true)}`}
              />
            </Col>
          </Row>
          <Row className="w-100 mb-1 mt-1" gutter={12}>
            <Col xs={24} md={24} lg={12}>
              <Card
                title={"Metodos de pago"}
                style={{
                  boxShadow: "0px 10px 15px -3px rgba(0,0,0,0.1)",
                  height: "50lvh",
                }}
              >
                <Row>
                  <Col span={24} className="center">
                    <ReactECharts
                      option={option}
                      style={{ width: "100%", maxWidth: 400 }}
                    />
                  </Col>
                </Row>
              </Card>
            </Col>
            <Col xs={24} md={24} lg={12}>
              <Card
                title={"Comparativo de pagos"}
                style={{
                  boxShadow: "0px 10px 15px -3px rgba(0,0,0,0.1)",
                  height: "50lvh",
                }}
              >
                <Row className="pd-0">
                  <Radio.Group
                    defaultValue={1}
                    size={"small"}
                    onChange={({ target }) => {
                      this.setState({ intervalo: target.value }, this.getPagos({ intervalo: target.value },))

                    }}
                  >
                    <Radio.Button value={1}>Diario</Radio.Button>
                    <Radio.Button value={2}>Semanal</Radio.Button>
                    <Radio.Button value={3}>Mensual</Radio.Button>
                    <Radio.Button value={4}>Anual</Radio.Button>
                  </Radio.Group>
                </Row>
                <Row>
                  <Col span={24} className="center">
                    <ReactECharts
                      option={option_2}
                      style={{ width: "100%", maxWidth: 700 }}
                    />
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
          <OrdenesCollapse
            cliente_id={this.state.cliente_id}
            ref={this.ordenesRef}
            agrupar={this.state.agrupar}
            fechas={this.state.rango_fechas}
          />
        </Content>
      </>

    );
  }
}

export default (props) => {
  return <Vencidas />;
};
