import React from 'react'
import { Route, Routes } from "react-router-dom";

import Logistica from '../../Components/Admin/Logistica/Dashboard';
import PickingList from '../../Components/Admin/Logistica/PickingList';
import PackingList from '../../Components/Admin/Logistica/PackingList';

/**
 * 
 * @export
 * @function RouterLogistica
 * @description Router for Logistica routes 
 */
function RouterLogistica(props) {
  return (
    <Routes>
      <Route path="" element={<Logistica {...props} />} />
      <Route path="/picking-list" element={<PickingList {...props} />} />
      <Route path="/packing-list" element={<PackingList {...props} />} />
    </Routes>
  )
}

export default RouterLogistica