import React, { Component, } from 'react';
import { Row, Col, Layout, Card, Typography, Statistic, Progress, DatePicker, Form, message, List, Spin } from 'antd'
import axios from 'axios';
import dayjs from "dayjs";
import ReactECharts from 'echarts-for-react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { InfoCircleOutlined, ArrowUpOutlined, ArrowDownOutlined, RightOutlined } from '@ant-design/icons';


//Componentes
import useSearch from '../../../Hooks/useSearch';
import PageHeader from '../../Widgets/PageHeader/PageHeader';

const { Content } = Layout;
const { Text, Title } = Typography;
const { RangePicker } = DatePicker;

/**
 *
 *
 * @export
 * @class Dashboard
 * @extends {Component}
 * @description Vista donde se muestra un resumen de las estadisticas del sistema
 */
class Dashboard extends Component {

    back = () => {
        this.props.navigate(-1)
    }


    /**
     *Creates an instance of Usuarios.
     * @param {*} props
     * @memberof Usuarios
     */
    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            loading_clientes: false,
            clientes: 0,
            preferenciales: 0,
            preferenciales_nuevos: 0,
            normales: 0,
            normales_nuevos: 0,
            porcentajeCambio: 0,
            montos: {},
            ventas: [],
            ventas_anuales: {},
            fecha_inicio: dayjs().startOf('month'),
            fecha_final: dayjs().endOf("month"),
            clientes_cartera: [],
            clientes_data: {
                data: [],
                limit: 20,
                page: 1,
                total: 0,
                pages: 0,
                filters: []
            },
            variantes: [],
            clientes_arr: [],
            valores: []
        }
    }


    componentDidMount() {
        this.props.setShowSearch(true)
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token')

        this.getInfo()
        this.getClientes()
        this.getGrafica()
    }

    componentDidUpdate(prevProps) {
        if (this.props.search != prevProps.search) {
            this.getClientes()
        }
    }

    /**
     * @memberof Dashboard
     * @method getInfo
     * @description Obtiene la informacion para el dashboard del CRM 
     */
    getInfo = () => {
        this.setState({ loading: true })
        axios.get('/crm', {
            params: {
                fecha_inicio: this.state.fecha_inicio,
                fecha_final: this.state.fecha_final,
            }
        })
            .then(response => {
                this.setState({
                    ...response.data
                })
            }).catch(error => {
                console.log(error);
            }).finally(() => this.setState({ loading: false }))
    }

    /**
     * @memberof Dashboard
     * @method getClientes
     * @description Obtiene la lista de clientes, nuemro de pedidos realizados y productos
     */
    getClientes = ({
        page = this.state.clientes_data.page,
        limit = this.state.clientes_data.limit,
        search = this.props.search,
    } = this.state.clientes_data) => {
        this.setState({ loading_clientes: true })
        axios.get('/crm/clientes', {
            params: {
                limit,
                page,
                search,
                fecha_inicio: this.state.fecha_inicio,
                fecha_final: this.state.fecha_final,
            }
        })
        .then(({ data }) => {
            this.setState({ clientes_data: data })
        })
        .catch(res => {
            message.error(res.response?.data?.message)
        })
        .finally(() => this.setState({ loading_clientes: false }))
}

    /**
     * @memberof Dashboard
     * @method getGrafica
     * @description Obtiene la informacion para el dashboard del CRM 
     */
    getGrafica = () => {
        this.setState({ loading: true })
        axios.get('/crm/productos', {
            params: {
                fecha_inicio: this.state.fecha_inicio,
                fecha_final: this.state.fecha_final,
            }
        })
            .then(response => {
                this.setState({
                    clientes_arr: [],
                    valores: [],
                    variantes: [],
                },()=>{
                    this.setState({
                        ...response.data
                    })
                })
            }).catch(error => {
                console.log(error);
            }).finally(() => this.setState({ loading: false }))
    }

    /**
     * @memberof Dashboard
     * @method dates
     * @description Actualiza el rango de fechas para realizar la buequeda
     */
    onChangeDates = (dates) => {
        if (dates) {
            this.setState({
                fecha_inicio: dates[0],
                fecha_final: dates[1]
            }, () => {
                this.getInfo()
                this.getGrafica()
                this.getClientes()
            })
        }else{
            this.setState({
                fecha_inicio: undefined,
                fecha_final: undefined
            }, () => {
                this.getInfo()
                this.getGrafica()
                this.getClientes()
            })
        }
    }

    render() {

        // There should not be negative values in rawData
        const rawData = this.state.valores;
        const totalData = [];

        const grid = {
            left: 100,
            right: 100,
            top: 50,
            bottom: 50
        };
        const series = this.state.variantes.map((name, sid) => {
            return {
                name,
                type: 'bar',
                stack: 'total',
                barWidth: '60%',
                label: {
                    show: true,
                    //formatter: (params) => Math.round(params.value * 1000) / 10 + '%'
                },
                data: rawData.map(row => row[sid] ?? null)
            };
        });

        const option = {
            legend: {
                selectedMode: false
            },
            grid,
            yAxis: {
                type: 'value'
            },
            xAxis: {
                type: 'category',
                data: this.state.clientes_arr,
                axisLabel: {
                    rotate: 12  // Aquí puedes ajustar el ángulo de rotación
                }
            },
            series
        };


        return (
            <>
                <PageHeader
                    className="admin-page-header"
                    title="Dashboard"
                    extra={<Form>
                        <Form.Item
                            name="fechas"
                            className="m-0"
                        >
                            <RangePicker
                                onChange={this.onChangeDates}
                                defaultValue={[this.state.fecha_inicio, this.state.fecha_final]}
                            />
                        </Form.Item>
                    </Form>}
                />
                <Spin spinning={this.state.loading}>
                    <Content className="admin-content content-bg pd-1">
                        <Row gutter={[16, 16]}>
                            <Col xs={24} sm={12} md={12} lg={7} className="grid">
                                <Card>
                                    <Row gutter={[8, 8]}>
                                        <Col span={23}>
                                            <Row gutter={[8, 8]}>
                                                <Col span={14} className="flex-left-column">
                                                    <Text style={{ fontSize: "14px" }} className="m-0 text-gray">Total Clientes</Text>
                                                    <Text strong style={{ fontSize: "32px" }}>{(this.state.clientes).toMoney(true, 0)}</Text>
                                                </Col>
                                                <Col span={10} className="flex-left-column">
                                                    <Text style={{ fontSize: "14px" }} className="m-0 text-gray">Este periodo</Text>
                                                    <Statistic
                                                        value={this.state.porcentajeCambio}
                                                        precision={2}
                                                        valueStyle={{ color: this.state.porcentajeCambio < 0 ? "red" : '#3f8600', fontSize: "18px" }}
                                                        prefix={this.state.porcentajeCambio < 0 ? <ArrowDownOutlined /> : <ArrowUpOutlined />}
                                                        suffix="%"
                                                    />
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col span={1}>
                                            <InfoCircleOutlined />
                                        </Col>
                                    </Row>
                                </Card>
                            </Col>
                            <Col xs={24} sm={12} md={12} lg={7} className="grid">
                                <Card>
                                    <Row gutter={[8, 8]}>
                                        <Col span={23}>
                                            <Row gutter={[8, 8]}>
                                                <Col span={14} className="flex-left-column">
                                                    <Text style={{ fontSize: "14px" }} className="m-0 text-gray">Clientes Mayoristas</Text>
                                                    <Text strong style={{ fontSize: "32px" }}>{(this.state.preferenciales).toMoney(true, 0)}</Text>
                                                </Col>
                                                <Col span={10} className="flex-left-column">
                                                    <Text style={{ fontSize: "14px" }} className="m-0 text-gray">Nuevos</Text>
                                                    <Statistic
                                                        value={this.state.preferenciales_nuevos}
                                                        precision={0}
                                                        valueStyle={{ fontSize: "18px" }}
                                                    />
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col span={1}>
                                            <InfoCircleOutlined />
                                        </Col>
                                    </Row>
                                </Card>
                            </Col>
                            <Col xs={24} sm={12} md={12} lg={7} className="grid">
                                <Card>
                                    <Row gutter={[8, 8]}>
                                        <Col span={23}>
                                            <Row gutter={[8, 8]}>
                                                <Col span={14} className="flex-left-column">
                                                    <Text style={{ fontSize: "14px" }} className="m-0 text-gray">Clientes Minoristas</Text>
                                                    <Text strong style={{ fontSize: "32px" }}>{(this.state.normales).toMoney(true, 0)}</Text>
                                                </Col>
                                                <Col span={10} className="flex-left-column">
                                                    <Text style={{ fontSize: "14px" }} className="m-0 text-gray">Nuevos</Text>
                                                    <Statistic
                                                        value={this.state.normales_nuevos}
                                                        precision={0}
                                                        valueStyle={{ fontSize: "18px" }}
                                                    />
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col span={1}>
                                            <InfoCircleOutlined />
                                        </Col>
                                    </Row>
                                </Card>
                            </Col>
                            <Col xs={24} sm={12} md={12} lg={3} className="grid">
                                <Card onClick={() => this.props.navigate("/admin/crm/pedidos-generales")} className="hover">
                                    <Row gutter={[8, 8]} className=" grid" style={{ minHeight: "75px" }}>
                                        <Col span={24} className="center text-gray">
                                            <Text style={{ fontSize: "14px" }} className="m-0 text-gray">Pedidos Generales </Text> <RightOutlined />
                                        </Col>
                                    </Row>
                                </Card>
                            </Col>

                            <Col xs={24} md={24} lg={16} className="grid">
                                <Card
                                    title={'Productos más comprados por cliente'}
                                    extra={<Text className="hover text-gray" onClick={() => this.props.navigate("/admin/productos")}>Ver Todos <RightOutlined /></Text>}
                                    style={{ boxShadow: '0px 10px 15px -3px rgba(0,0,0,0.1)' }}>
                                    <Row>
                                        <Col span={24} className="center">
                                            <ReactECharts
                                                notMerge={true}
                                                option={option}
                                                style={{ width: '100%', maxWidth: 800, height: "350px" }}
                                            />
                                        </Col>
                                    </Row>
                                </Card>
                            </Col>
                            <Col xs={24} md={24} lg={8} className="grid">
                                <Card
                                    title={'Cartera Vencida'}
                                    style={{ boxShadow: '0px 10px 15px -3px rgba(0,0,0,0.1)' }}
                                    extra={<Text className="hover text-gray" onClick={()=>this.props.navigate("/admin/contabilidad/vencida")}>Ver Todos <RightOutlined /></Text>}
                                >
                                    {this.state.clientes_cartera.map(item => <Row>
                                        <Col span={24} className="mb-2">
                                            <Row gutter={[8, 8]}>
                                                <Col span={24}>
                                                    <Text>{item.nombre} {item.apellido_paterno ?? "-"}</Text>
                                                </Col>
                                                <Col span={8} className="center">
                                                    <Statistic
                                                        value={item.credito_disponible}
                                                        precision={2}
                                                        prefix={"$"}
                                                        valueStyle={{ fontSize: "12px", color: "#52c41a" }}
                                                    />
                                                </Col>
                                                <Col span={8} className="center">
                                                    <Statistic
                                                        value={item.credito_por_pagar}
                                                        precision={2}
                                                        prefix={"$"}
                                                        valueStyle={{ fontSize: "12px", color: "red" }}
                                                    />
                                                </Col>
                                                <Col span={8} className="center">
                                                    <Statistic
                                                        value={item.credito_limite}
                                                        precision={2}
                                                        prefix={"$"}
                                                        valueStyle={{ fontSize: "12px" }}
                                                    />
                                                </Col>
                                                <Col span={24}>
                                                    <Progress
                                                        percent={100}
                                                        color="red"
                                                        size={["100%", 20]}
                                                        success={{ percent: ((item.credito_por_pagar * 100) / item.credito_limite ?? 1), strokeColor: "red" }}
                                                        strokeColor="#52c41a"
                                                        showInfo={false}
                                                    />
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>)}
                                </Card>
                            </Col>
                            <Col xs={18}>
                                <Title level={4}>Clientes: Pedidos Realizados y Productos Comprados</Title>
                            </Col>
                            <Col xs={6}>
                               
                            </Col>
                            <Col span={24}>
                                <List
                                    loading={this.state.loading_clientes}
                                    className="component-list"
                                    itemLayout="horizontal"
                                    locale={{ emptyText: "Sin Clientes" }}
                                    dataSource={this.state.clientes_data.data}
                                    pagination={{
                                        current: this.state.clientes_data.page,
                                        pageSize: this.state.clientes_data.limit,
                                        total: this.state.clientes_data.total,
                                        pageSizeOptions: [10, 20, 50, 100, 500, 1000],
                                        position: 'bottom',
                                        className: "flex-left ml-1",
                                        onChange: (page, limit) => this.getClientes({ page, limit })
                                    }}

                                    renderItem={item => (
                                        <List.Item className="component-list-item">
                                            <Card className="card-list">
                                                <Row align="middle">
                                                    <Col xs={24} className="">
                                                        <Row className="width-100 " align="middle" gutter={[6, 4]}>
                                                            <Col xs={6} className="flex-left" >
                                                                <Text ellipsis strong>{item.nombre} {item.apellido_paterno} {item.apellido_materno}</Text>
                                                            </Col>
                                                            <Col xs={6} className="center " >
                                                                <Text ellipsis >{item.pedidos} Pedidos</Text>
                                                            </Col>
                                                            <Col xs={6} className="center " >
                                                                <Text ellipsis >{item.productos} Productos</Text>
                                                            </Col>
                                                            <Col xs={6} className="center " >
                                                                <Text
                                                                    ellipsis
                                                                    className="text-gray hover"
                                                                    onClick={() => this.props.navigate(`/admin/crm/clientes/ordenes/${item._id}`)}
                                                                >Ver más <RightOutlined /></Text>
                                                            </Col>

                                                        </Row>
                                                    </Col>
                                                </Row>
                                            </Card>
                                        </List.Item>
                                    )}
                                />
                            </Col>
                        </Row>
                    </Content>
                </Spin>

            </>
        )
    }
}

export default props => {
    const { setShow } = useSearch()
    const navigate = useNavigate()
    const [params, setParams] = useSearchParams();



    return <Dashboard {...props} setShowSearch={setShow} navigate={navigate} params={params} />
}
