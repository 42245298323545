import React from 'react'
import { Route, Routes } from "react-router-dom";

import Almacenes from '../../Components/Admin/Almacenes/Almacenes';
import Ubicaciones from '../../Components/Admin/Ubicaciones/Ubicaciones';

/**
 * 
 * @export
 * @function RouterAlmacenes
 * @description Router for Almacenes routes 
 */
function RouterAlmacenesRubros(props) {
  return (
    <Routes>
    	<Route path="" element={<Almacenes {...props} />} />
    	<Route path="/ubicaciones/:almacen_id" element={<Ubicaciones {...props} />} />
    </Routes>
  )
}

export default RouterAlmacenesRubros