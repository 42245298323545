import { Avatar, Row, Col, Card, Typography, Space } from "antd";
import '../../Styles/Modules/Comment.scss'
import dayjs from "dayjs";
import { useEffect } from "react";
var localizedFormat = require('dayjs/plugin/localizedFormat')

dayjs.extend(localizedFormat)



/**
 *
 *
 * @export
 * @param {*} props
 * @returns
 */
export default function Comment(props) {


    useEffect(() => {
        console.log(props)
    },[])

    return (<Row className={` mb-1 ${props.system ? 'system' : 'message'}`}>
        <Col span={2}>
            {props?.avatar}
        </Col>
        <Col span={21}>

            <Card className="contenido">
                {props.system ? <>
                    <Row justify={'space-between'}>
                        <Col span={17}>
                            <Typography.Text>{props?.content}</Typography.Text>
                        </Col>
                        <Col span={7}>
                            <Typography.Text className="fecha">{dayjs(props.datetime).format('LLL')}</Typography.Text>
                        </Col>
                    </Row>
                </> : <>
                    <Row justify={'space-between'}>
                        <Col>
                            <Typography.Text className="nombre">{props.author}</Typography.Text> <Typography.Text className="action">escribió</Typography.Text>
                        </Col>
                        <Col>
                            <Typography.Text className="fecha">{dayjs(props.datetime).format('LLL')}</Typography.Text>
                        </Col>
                    </Row>
                    <Row>
                        {props?.content}
                    </Row>
                    <Row justify={"end"}>
                        <Space>
                            {props?.actions.map(e => {return e})}
                        </Space>
                        
                    </Row>
                </>}

            </Card>
        </Col>
    </Row>)

}