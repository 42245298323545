import React, { Component, useContext } from 'react'
import { Row, Col, Form, Input, Button, Typography, Modal } from 'antd';
import { Navigate, Link, useNavigate } from 'react-router-dom';
import { User, SetUser } from '../../Hooks/Logged';
import axios from 'axios'

//css
import '../../Styles/Global/auth.scss';
import PhoneInput from '../Widgets/Inputs/PhoneInput';

const { Title, Text } = Typography

/**
 * 
 * @class Register
 * @extends {Component}
 * @description Componente para registrarse
 */
class Register extends Component {

	constructor(props) {
		super(props);
		this.state = {

		}
	}

	/**
	 * @memberof Register
	 *
	 * @method handleSubmit
	 * @description  Envia los datos del formulario al Servidor
	 **/
	handleSubmit = (values) => {
		this.setState({ loading: true })
		axios.post('/register', values)
			.then(({ data, headers }) => {

				const { setUser } = this.props;
				axios.defaults.headers.post["Authorization"] = headers.authorization;
				sessionStorage.setItem('token', headers.authorization);
				axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');

				setUser(data.user);

				this.props.navigate('/customer/compras')

			})
			.catch((error) => {
				console.log("error", error);
				Modal.error({
					title: "Ocurrio un error en el registro",
					content: error?.response?.data?.message ?? "Intente mas tarde"
				})
				this.setState({ loading: false });
			})
	};


	render() {
		return (
			<Row className='layout-login'>
				<Col xs={0} md={10} className="col-welcome">

					<Row>
						<div>
							<img width="400" src="/img/logo.svg" alt="Fuga pa´ Durango" />
							<p className='subtitle-isyt-erp'>Raffle Manager</p>
						</div>
					</Row>
				</Col>
				<Col xs={24} md={14}>
					<div className='login-form-container'>
						<Form
							onFinish={this.handleSubmit}
							layout={"vertical"}
							requiredMark={false}
							className='login-form'
						>
							<Title level={3} className="h3-title mb-3">Regístrate</Title>
							<Row gutter={[16, 0]}>
								<Col span={24}>
									<Form.Item
										name="nombre"
										label="Nombre(s)"
										rules={[
											{ required: true, message: 'Ingrese su Nombre' },
											{
												max: 50,
												message: "Máximo 50 caracteres"
											}
										]}>
										<Input size="large" className="input-login" />
									</Form.Item>
								</Col>
								<Col span={12}>
									<Form.Item
										name="apellido_paterno"
										label="Apellido Paterno"
										rules={[
											{ required: true, message: 'Ingrese su apellido' },
											{
												max: 50,
												message: "Máximo 50 caracteres"
											}
										]}>
										<Input size="large" className="input-login" />
									</Form.Item>
								</Col>
								<Col span={12}>
									<Form.Item
										name="apellido_materno"
										label="Apellido Materno"
										rules={[
											{
												max: 50,
												message: "Máximo 50 caracteres"
											}
										]}
									>
										<Input size="large" className="input-login" />
									</Form.Item>
								</Col>
								<Col span={24}>
									<Form.Item
										name="email"
										label="Correo electrónico"
										rules={[
											{
												type: 'email',
												message: 'Email no valido',
											},
											{
												required: true,
												message: 'Ingrese el Email',
											}, {
												max: 50,
												message: "Máximo 50 caracteres"
											}
										]}
									>
										<Input placeholder="ejemplo@mail.com" size="large" className="input-login" />
									</Form.Item>
								</Col>
								<Col span={24}>
									<Form.Item
										name="telefono"
										label="Teléfono"
										rules={[
											{
												required: true,
												message: 'Ingrese el Teléfono',
											}
										]}
									>
										<PhoneInput />
									</Form.Item>
								</Col>
								<Col span={24}>
									<Form.Item
										label="Contraseña"
										name="password"
										hasFeedback
										rules={[
											{ required: true, message: 'Ingrese su contraseña' },
											{
												max: 50,
												message: "Máximo 50 caracteres"
											}
										]}
									>
										<Input.Password placeholder="********" size="large" />
									</Form.Item>
								</Col>
								<Col span={24}>
									<Form.Item
										name="confirm"
										label="Confirmar Contraseña"
										dependencies={['password']}
										hasFeedback
										rules={[
											{
												required: true,
												message: 'Por favor confirme su contraseña',
											},
											{
												max: 50,
												message: "Máximo 50 caracteres"
											},
											({ getFieldValue }) => ({
												validator(_, value) {
													if (!value || getFieldValue('password') === value) {
														return Promise.resolve();
													}
													return Promise.reject(new Error('Las contraseñas no coinciden'));
												},
											}),
										]}
									>
										<Input.Password placeholder="********" size="large" />
									</Form.Item>
								</Col>
							</Row>
							<Form.Item>
								<Button type="primary" htmlType="submit" block size="large" >Crear Cuenta</Button>
							</Form.Item>
							<Text>¿Ya tienes cuenta? <Link to="/login">Iniciar sesión</Link></Text>
						</Form>
					</div>
				</Col>

			</Row>
		)
	}
}

export default function View(props) {

	let user = useContext(User)
	const setUser = useContext(SetUser)

	return <Register {...props} user={user} setUser={setUser} navigate={useNavigate()} />
}