import React from 'react'
import { Route, Routes } from "react-router-dom";
import { useEffect } from 'react';

import Ordenes from '../../Components/Admin/CRM/Ordenes';
import Productos from '../../Components/Admin/CRM/Productos';
import Dashboard from '../../Components/Admin/CRM/Dashboard';
import Clientes from '../../Components/Admin/CRM/Clientes/Clientes';
import OrdenesGenerales from '../../Components/Admin/CRM/OrdenesGenerales';
import ClientesList from '../../Components/Admin/CRM/Clientes/ClientesList';
import DetalleCliente from '../../Components/Admin/CRM/Clientes/DetalleCliente';
import SaboresVendidos from '../../Components/Admin/CRM/Clientes/SaboresVendidos';


/**
 * @export
 * @function PublicRoutes
 * @extends {Component}
 */
function RouterCRM(props) {

    return (
        <Routes>
            <Route path="" element={<Dashboard  {...props}  />} />
            <Route path="clientes" element={<Clientes  {...props}  />} />
            <Route path="clientes/listado" element={<ClientesList  {...props}  />} />
            <Route path="clientes/detalle/:cliente_id" element={<DetalleCliente  {...props}/>} />
            <Route path="clientes/ordenes/:cliente_id" element={<Ordenes  {...props}/>} />
            <Route path="productos/:producto_id" element={<Productos  {...props}/>} />
            <Route path="pedidos-generales" element={<OrdenesGenerales  {...props}/>} />
            <Route path="cliente/sabores-vendidos/:cliente_id" element={<SaboresVendidos  {...props}  />} />
        </Routes>
    )
}

export default RouterCRM;