import React, { Component, useContext } from 'react';
import { Layout, Row, Col, Tooltip, Typography, message, Statistic, Space, Collapse, Tag, Pagination, DatePicker, Button, Spin, Empty } from "antd";
import axios from 'axios';
import dayjs from "dayjs";
import { FaShopify, FaTruck } from "react-icons/fa";
import { AiFillDollarCircle } from "react-icons/ai";
import { useNavigate, useParams } from "react-router-dom";
import { FilePdfOutlined, FileExcelOutlined } from '@ant-design/icons';

//Componentes
import User from "../../../Hooks/Logged";
import usePermissions from "../../../Hooks/usePermissions";
import PageHeader from '../../Widgets/PageHeader/PageHeader';
import SelectVariante from '../../Widgets/Inputs/SelectVariantes';
import { renderEstatusOrdenEnvio, renderEstatusOrdenPago } from "../../Utils";

//Modales
import ModalOrdenesDetalles from '../Ordenes/ModalOrdenesDetalles'
import ModalFulfillmentsDetalle from '../Ordenes/ModalFulfillmentsDetalle'

const { Content } = Layout;
const { Text } = Typography;
const { RangePicker } = DatePicker;

const moment = require('moment')

/**
 *
 *
 * @export
 * @class Ordenes
 * @extends {Component}
 * @description Vista del listado de Ordenes de Shopify
 */
class Ordenes extends Component {

    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            producto_id: this.props.params.producto_id,
            producto: {},
            ordenes: {
                data: [],
                limit: 50,
                pageInfo: undefined,
                total: 0
            },
            variante_id: undefined,
            seleccionadas: {},
            fechas: [dayjs().startOf('month'), dayjs().endOf("month")]
        }
    }

    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        this.getOrdenes()
        if (this.props.params.producto_id) {
            this.getProducto()
        }
    }

    /**
     *
     * @memberof DetalleInversion
     * 
     * @method getProducto
     * @description Obtiene informacion del Cliente
     */
    getProducto = () => {
        //this.setState({ loading: true })
        axios.get('/productos/' + this.state.producto_id, {
            params: {
            }
        }).then(response => {
            console.log("response", response)
            this.setState({
                producto: response.data,
            })

        }).catch(error => {
            console.log("error", error);
            message.error("Error al obtener la información del producto")
        })
        //.finally(() => this.setState({ loading: false }))
    }

    /**
    * @memberof Ordenes
    * @description Obtiene una lista de ordenes
    */
    getOrdenes = ({
        limit = this.state.ordenes.limit,
        page = this.state.ordenes.page,
        variante_id = this.state.variante_id,
    } = this.state.ordenes) => {

        this.setState({ loading: true })
        axios.get('/ordenes', {
            params: {
                limit,
                page,
                variante_id,
                producto_id: this.state.producto_id,
                orden_detalles: true,
                fechas: this.state.fechas,
                tipo: "normal"
            }
        }).then(({ data }) => {
            this.setState({
                ordenes: data,
                variante_id
            })

        }).catch(error => {
            message.error(error.response?.data?.message ?? 'Error al obtener los pedidos')
        }).finally(() => this.setState({ loading: false }))

    }

    /**
    * @memberof onChangeDates
    * @description Actualiza las fechas de busqueda
    */
    onChangeDates = (dates) => {
        if (dates) {
            this.setState({
                fechas: dates
            }, () => {
                this.getOrdenes()
            })

        } else {
            this.setState({ fechas: undefined }, () => this.getOrdenes())
        }
    }

    /**
     *
     * @memberof ClientesDetalles
     * 
     * @method getPDFPedidos
     * @description Generamos el PDF del estado de cuenta del cliente
     */
    getPDFPedidos = () => {
        let url = new URL(axios.defaults.baseURL + '/reportes/variantes')
        url.searchParams.append('Authorization', sessionStorage.getItem('token'))
        url.searchParams.append('producto_id', this.state.producto?._id)

        if (this.state.fechas?.length > 0) {
            url.searchParams.append('fecha_inicial', this.state.fechas[0])
            url.searchParams.append('fecha_final', this.state.fechas[1])
        }
        if (this.state.variante_id)
            url.searchParams.append('variante_id', this.state.variante_id)

        window.open(url, '_blank')
    }

    /**
    *
    * @memberof ClientesDetalles
    * 
    * @method getCSVPedidos
    * @description Generamos el csv de los pedidos
    */
    getCSVPedidos = () => {
        let url = new URL(axios.defaults.baseURL + '/reportes/variantes')
        url.searchParams.append('Authorization', sessionStorage.getItem('token'))
        url.searchParams.append('producto_id', this.state.producto?._id)
        if (this.state.fechas?.length > 0) {
            url.searchParams.append('fecha_inicial', this.state.fechas[0])
            url.searchParams.append('fecha_final', this.state.fechas[1])
        }
        if (this.state.variante_id)
            url.searchParams.append('variante_id', this.state.variante_id)
        url.searchParams.append('csv', true)
        window.open(url, '_blank')
    }


    render() {

        const { cuenta, producto } = this.state;

        return (
            <>
                <PageHeader
                    className="admin-page-header"
                    breadcrumb={{
                        items: [
                            {
                                title: "Productos",
                                className: "hover",
                                onClick: () => this.props.navigate("/admin/productos/")
                            },
                            {
                                title: `${producto.modelo ?? "-"}`,
                            },
                            {
                                title: <SelectVariante
                                    style={{ minWidth: "150px" }}
                                    placeholder="Variante"
                                    className="select-transparent mt-0"
                                    disabled={this.state.loading}
                                    onChange={(e) => { this.getOrdenes({ variante_id: e }) }}
                                    onClear={() => this.getOrdenes({ variante_id: null })}
                                    params={{ producto_id: this.state.producto_id }}
                                />,
                            },
                        ]
                    }}

                    extra={<>
                        <Button
                            title="Reporte CSV"
                            onClick={() => this.getCSVPedidos()}
                            icon={<FileExcelOutlined />} />
                        <Button
                            title="Reporte PDF"
                            onClick={() => this.getPDFPedidos()}
                            icon={<FilePdfOutlined />}
                        />
                        <RangePicker
                            style={{ width: "280px" }}
                            onChange={this.onChangeDates}
                            defaultValue={this.state.fechas}
                        />

                    </>}
                >

                </PageHeader>

                <Content className="admin-content content-bg pd-1 mb-3">
                    <Row className="header-list width-100 pl-2 pr-1 mb-1">
                        <Col xs={12} md={3} className=" center">
                            <Text strong>Pedido</Text>
                        </Col>
                        <Col xs={12} md={3} className="center">
                            <Text strong>Artículos</Text>
                        </Col>
                        <Col xs={12} md={3} className="center">
                            <Text strong>Costo Envío</Text>
                        </Col>
                        <Col xs={12} md={4} className="center">
                            <Text strong>Monto Total</Text>
                        </Col>
                        <Col xs={12} md={3} className="center">
                            <Text strong>Estatus Pago</Text>
                        </Col>
                        <Col xs={12} md={3} className="center">
                            <Text strong>Estatus Pedido</Text>
                        </Col>
                        <Col xs={12} md={3} className="center">
                            <Text strong>Fecha</Text>
                        </Col>
                    </Row>
                    <Spin spinning={this.state.loading}>
                        {this.state.ordenes?.data.length === 0 ? <Empty /> : null}
                        <Collapse
                            className="collapse-vapemate"
                            items={this.state.ordenes?.data?.map((item, index) => ({
                                key: index,
                                label: <>
                                    <Row className="width-100">
                                        <Col xs={24} className="center">
                                            <Row gutter={[8, 8]} className="width-100">
                                                <Col xs={12} md={3} className="center hover" onClick={() => this.setState({ modal_visible_detalle: true, orden_id: item._id })} style={{ zIndex: 1000 }}>
                                                    <Space >
                                                        {!item.shopify_id && !item.shopify_draft_id ? <FaShopify style={{ color: "red" }} /> : null}
                                                        <Text>{item.shopify_folio ?? item.folio}</Text>
                                                        {item.shopify_draft_id && !item.shopify_id ? <Tag color="magenta">Draft</Tag> : null}
                                                    </Space>
                                                </Col>
                                                <Col xs={12} md={3} className="flex-column">
                                                    <Text strong ellipsis>{item.total_productos ?? 0} Artículos</Text>
                                                </Col>
                                                <Col xs={12} md={3} className="center">
                                                    <Statistic value={item.costo_envio?.monto ?? 0} prefix="$" valueStyle={{ fontSize: "14px" }} precision={2} />
                                                </Col>
                                                <Col xs={12} md={4} className="center">
                                                    <Statistic
                                                        value={item.total}
                                                        prefix="$"
                                                        valueStyle={{
                                                            fontSize: '14px',
                                                            fontWeight: 'bold',
                                                            color: item.shopify_financial_status == "paid" ? '#2c9d37' : "#ecc602"
                                                        }}
                                                        precision={2}
                                                    />
                                                </Col>
                                                <Col xs={12} md={3} className="flex-column">
                                                    {renderEstatusOrdenPago(item.shopify_financial_status)}
                                                </Col>
                                                <Col xs={12} md={3} className="flex-column">
                                                    {renderEstatusOrdenEnvio(item.shopify_fulfillment_status)}
                                                </Col>
                                                <Col xs={12} md={3} className="center">
                                                    <Text>{moment(item.fecha).format("DD/MM/YY HH:mm")}</Text>
                                                </Col>
                                                <Col xs={12} md={1} className="center">
                                                    <FaTruck style={{ fontSize: "24px", color: item.shopify_fulfillments?.length > 0 ? "#0cc858" : "black" }} onClick={(e) => {
                                                        e.stopPropagation()
                                                        this.setState({ modal_visible_detalle_fulfillments: true, orden_id: item._id })
                                                    }} />
                                                </Col>
                                                <Col xs={12} md={1} className="center">
                                                    <Tooltip
                                                        placement="topRight"
                                                        title={item.credito ? "Se utilizó crédito" : "No se utilizó crédito"}
                                                    >
                                                        <AiFillDollarCircle
                                                            style={{
                                                                color: item.credito ? "#ecc602" : "gray",
                                                                fontSize: "24px"
                                                            }}
                                                        />
                                                    </Tooltip>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </>,
                                children: [
                                    <Row className="width-100 mb-1">
                                        <Col xs={24}>
                                            <Row gutter={[8, 8]}>
                                                <Col xs={12} md={5} className="flex-left-column">
                                                    <Text ellipsis strong>PRODUCTO</Text>
                                                </Col>
                                                <Col xs={12} md={5} className="center">
                                                    <Text ellipsis>PRECIO</Text>
                                                </Col>
                                                <Col xs={12} md={5} className="center">
                                                    <Text ellipsis>DESCUENTO</Text>
                                                </Col>
                                                <Col xs={12} md={5} className="center">
                                                    <Text ellipsis>CANTIDAD</Text>
                                                </Col>
                                                <Col xs={12} md={4} className="center">
                                                    <Text ellipsis>TOTAL</Text>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>,
                                    ...item.ordenes_productos?.map(orden_producto => <Row className="width-100 mb-1">
                                        <Col xs={24}>
                                            <Row gutter={[8, 8]}>
                                                <Col xs={12} md={5} className="flex-left-column">
                                                    <Text ellipsis strong>{orden_producto.producto_id.modelo ?? "--"}</Text>
                                                    <Text ellipsis>{orden_producto.variante_id.sabor ?? "--"}</Text>
                                                </Col>
                                                <Col xs={12} md={5} className="center">
                                                    <Text ellipsis>$ {orden_producto.precio?.toMoney(true) ?? '-'} MXN</Text>
                                                </Col>
                                                <Col xs={12} md={5} className="center">
                                                    <Text ellipsis>$ {(orden_producto.descuento_total ?? orden_producto.descuento)?.toMoney(true) ?? '0'} MXN</Text>
                                                </Col>
                                                <Col xs={12} md={5} className="center">
                                                    <Text ellipsis>{orden_producto.cantidad?.toMoney(true, 0) ?? 0}</Text>
                                                </Col>
                                                <Col xs={12} md={4} className="center">
                                                    <Text ellipsis>$ {orden_producto.total?.toMoney(true, 2) ?? 0} MXN</Text>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>)
                                ]
                            }))}
                        />
                    </Spin>
                    <Pagination
                        className="mt-1"
                        showSizeChanger
                        current={this.state.ordenes?.page}
                        total={this.state.ordenes?.total}
                        pageSize={this.state.ordenes?.limit}
                        onChange={(page, limit) => this.getOrdenes({ page, limit })}
                    />
                </Content>


                <ModalOrdenesDetalles
                    open={this.state.modal_visible_detalle}
                    onClose={(flag) => {
                        this.setState({ modal_visible_detalle: false, orden_id: undefined })
                    }}
                    orden_id={this.state.orden_id}
                />

                <ModalFulfillmentsDetalle
                    open={this.state.modal_visible_detalle_fulfillments}
                    onClose={(flag) => {
                        this.setState({ modal_visible_detalle_fulfillments: false, orden_id: undefined })
                    }}
                    orden_id={this.state.orden_id}
                />

            </>
        )
    }
}


export default function (params) {

    const user = useContext(User);

    const permisos = usePermissions(user?.rol_id?.permisos, {
        editarRubros: ["rubros", "edit"],
        deleteRubros: ["rubros", "delete"],
        createRubros: ["rubros", "create"],
    });

    return <Ordenes {...params} params={useParams()} navigate={useNavigate()} {...permisos} />
}