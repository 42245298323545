import React, { Component, useContext } from 'react';
import { Row, Col, Spin, Layout, message, Space, List, Card, Typography, Button, Tooltip } from 'antd'

import axios from 'axios';

import User from "../../../Hooks/Logged";
import useSearch from '../../../Hooks/useSearch';
import { renderEstatusTransferencias } from "../../Utils";
import usePermissions from "../../../Hooks/usePermissions";
import PageHeader from '../../Widgets/PageHeader/PageHeader';
import FloatingButton from "../../Widgets/Floating Button/FloatingButton";
import { ButtonEdit, ButtonView, } from '../../Widgets/Buttons';
import { FileAddOutlined } from '@ant-design/icons';
//Modales
import ModalTransferencias from './ModalTransferencias'
import ModalRecibirTransferencia from './ModalRecibirTransferencias'


const { Content } = Layout;
const { Text } = Typography
/**
 * @export
 * @class Transferencias
 * @extends {Component}
 * @description Vista de Transferencias
 */
class Transferencias extends Component {

    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            modal_visible: false,
            transferencia_id: null,
            view_only: false,
            transferencias: {
                data: [],
                page: 1,
                limit: 10,
                total: 0,
                pages: 0,
            }
        }
    }

    componentDidMount() {
        this.props.setShowSearch(true)
        this.getTransferencias();
    }


    componentWillUnmount() {
        this.props.setShowSearch(false)
    }

    /**
     *
     *
     * @memberof Transferencias
     * 
     * @method getTransferencias
     * @description Obitiene las Transferencias
     */
    getTransferencias = ({
        page = this.state.transferencias.page,
        limit = this.state.transferencias.limit,
        search = this.props.search
    } = this.state.transferencias) => {
        this.setState({ loading: true })
        axios.get('/transferencias', {
            params: { limit, page, search }
        })
            .then(({ data }) => {
                console.log('data trans', data)
                this.setState({ transferencias: data })
            })
            .catch(res => {
                message.error(res.response.data ?? "No fue posible obtener las transferencias")
            })
            .finally(() => this.setState({ loading: false }))
    }


    render() {

        const { transferencias } = this.state;

        return (
            <>
                <Spin spinning={false}>
                    <PageHeader
                        className="admin-page-header"
                        title="Transferencias"
                    />
                    <Content className="admin-content content-bg pd-1">
                        <List
                            loading={this.state.loading}
                            className="component-list"
                            itemLayout="horizontal"
                            locale={{ emptyText: "Sin Transferencias" }}
                            dataSource={transferencias.data}
                            pagination={{
                                current: transferencias.page,
                                pageSize: transferencias.limit,
                                total: transferencias.total,
                                hideOnSinglePage: false,
                                position: 'bottom',
                                onChange: (page, limit) => this.getTransferencias({ page, limit })
                            }}
                            header={<Row className="width-100 pr-1 pl-1" >
                                <Col span={5} className="center">
                                    <Text strong>Folio</Text>
                                </Col>
                                <Col span={5} className="center">
                                    <Text strong>Origen</Text>
                                </Col>
                                <Col span={5} className="center">
                                    <Text strong>Destino</Text>
                                </Col>
                                <Col span={5} className="center">
                                    <Text strong>Estatus</Text>
                                </Col>
                            </Row>
                            }

                            renderItem={item => (
                                <List.Item className="component-list-item">
                                    <Card className="card-list">
                                        <Row className="width-100 ">

                                            <Col span={5} className="center">
                                                <Text strong>{item?.folio ?? "-"}</Text>
                                            </Col>
                                            <Col span={5} className="center">
                                                <Text ellipsis>{item.almacen_origen_id?.nombre ?? "-"}</Text>
                                            </Col>
                                            <Col span={5} className="center">
                                                <Text ellipsis>{item.almacen_destino_id?.nombre ?? "-"}</Text>
                                            </Col>
                                            <Col span={5} className="center">
                                                <Text ellipsis>{renderEstatusTransferencias(item.estatus)}</Text>
                                            </Col>

                                            <Col span={4} className="center">
                                                <Space>
                                                    <ButtonView
                                                        title="Editar"
                                                        onClick={() => { this.setState({ modal_visible: true, transferencia_id: item._id, view_only: true }) }}

                                                    />
                                                    <ButtonEdit
                                                        title="Editar"
                                                        disabled={(item.estatus >= 2)}
                                                        onClick={() => { this.setState({ modal_visible: true, transferencia_id: item._id, view_only: false }) }}

                                                    />
                                                    <Tooltip title="Recibir Inventario" >
                                                        <Button
                                                            disabled={(item.estatus === 0 || item.estatus == 3)}
                                                            onClick={() => this.setState({ modal_recibir_visible: true, transferencia_id: item._id })}
                                                            icon={<FileAddOutlined />}
                                                            type="primary"
                                                        ></Button>
                                                    </Tooltip>
                                                </Space>
                                            </Col>
                                        </Row>
                                    </Card>
                                </List.Item>
                            )}
                        />

                    </Content>
                    <FloatingButton title="Nuevo registro" onClick={() => this.setState({ modal_visible: true })} />
                    <ModalTransferencias
                        visible={this.state.modal_visible}
                        onClose={() => {
                            this.setState({ modal_visible: false, transferencia_id: undefined })
                            this.getTransferencias()
                        }}
                        transferencia_id={this.state.transferencia_id}
                        view_only={this.state.view_only}

                    />
                    <ModalRecibirTransferencia
                        visible={this.state.modal_recibir_visible}
                        onClose={() => {
                            this.setState({ modal_recibir_visible: false, transferencia_id: undefined })
                            this.getTransferencias()
                        }}
                        transferencia_id={this.state.transferencia_id}
                    />
                </Spin>
            </>
        )
    }
}

export default function Vista(props) {

    const user = useContext(User);
    const { search, setShow } = useSearch()

    const permisos = usePermissions(user?.rol_id?.permisos, {
        editarTransferencias: ["transferencias", "edit"],
        deleteTransferencias: ["transferencias", "delete"],
        createTransferencias: ["transferencias", "create"],
        accessTransferencias: ["transferencias", "access"],
    });

    return <Transferencias {...props} {...permisos} search={search} setShowSearch={setShow} />

}