import React, { Component } from "react";
import { Row, Col, Button, Modal, Typography, Form, Input, message, Spin, InputNumber, Select } from 'antd';
import axios from "axios"


import SelectProductos from "../../Widgets/Inputs/SelectProductos"
import SelectAlmacenes from "../../Widgets/Inputs/SelectAlmacenes"
import SelectUbicaciones from "../../Widgets/Inputs/SelectUbicaciones"

const { Title, Text } = Typography;
const { Option } = Select;

class ModalInventarios extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            variantes: {
                data: [],
                page: 1,
                limit: 20,
                total: 0,
                search: ''
            }
        }
    }

    ModalInventarios = React.createRef();

    componentDidMount() {
        if (this.props.inventario_id !== undefined) {
            this.getInventario()
        }
        if (this.props.almacen_id) {
            this.ModalInventarios.current?.setFieldsValue({ almacen_id: this.props.almacen_id })
        }
    }


    /**
     * @memberof ModalInventarios
     * @method onFinish
     * @description Se ejecuta al dar enter al formulario
     */
    onFinish = (values) => {

        if (values.variante_id && typeof values.variante_id === 'object') values.variante_id = values.variante_id.value

        if (this.props.inventario_id) {
            this.updateInventario(values)
        } else {
            this.addInventario(values)
        }
    }


    /**
     * @memberof ModalInventarios
     * @method addInventario
     * @description Añade una area a la BD
     */
    addInventario = (values) => {
        axios.post('/inventarios', {
            ...values
        }).then(response => {
            message.success('¡Inventario registrado!')
            this.props.onClose()
        }).catch(error => {
            console.log(error)
            message.error(error?.response?.data?.message ?? 'Error al registrar el inventario.')
        })
    }
    getVariantes = ({
        page = this.state.variantes.page,
        limit = this.state.variantes.limit,
        search = this.state.variantes.search,
    } = this.state.variantes) => {

        console.log(search)
        axios.get('/variantes', {
            params: {
                page,
                limit,
                search,
                producto_id: this.ModalInventarios.current?.getFieldValue('producto_id'),
            },
            headers: { Authorization: sessionStorage.getItem('token') }
        }).then(({ data }) => {
            this.setState({ variantes: data })
        }).catch(error => {
            console.log("ERR, error", error)
            message.error(error?.response?.data?.message ?? 'Error al obtener las variantes')
        })
    }

    /**
     * @memberof ModalInventarios
     * @method getInventario
     * @description Obtiene una inventario de la DB
     */
    getInventario = () => {

        this.setState({ loading: true })

        axios.get('/inventarios/' + this.props.inventario_id)
            .then(({ data }) => {
                console.log("data", data);
                this.setState({ almacen_id: data?.almacen_id?._id })
                this.ModalInventarios.current.setFieldsValue({
                    ...data,
                    producto_id: data.producto_id?._id ? {
                        value: data.producto_id?._id,
                        label: data.producto_id?.modelo,
                    } : null,
                    variante_id: data.variante_id?._id ? {
                        value: data.variante_id?._id,
                        label: data.variante_id?.modelo,
                    } : null,
                    almacen_id: data.almacen_id?._id ? {
                        value: data.almacen_id?._id,
                        label: data.almacen_id?.nombre,
                    } : null,
                    ubicacion_id: data.ubicacion_id?._id ? {
                        value: data.ubicacion_id?._id,
                        label: data.ubicacion_id?.nombre,
                    } : null,
                })

            }).catch(error => {
                message.error('Error al traer el registro')
            }).finally(() => this.setState({ loading: false }))
    }



    /**
     * @memberof ModalInventarios
     * @method updateInventario
     * @description Actualiza la información de una area
     */
    updateInventario = (values) => {
        this.setState({ loading: true })
        axios.put('/inventarios/' + this.props.inventario_id, {
            ...values
        }).then(response => {
            this.props.onClose()
            message.success('¡Inventario actualizado!')
        }).catch(error => {
            message.error(error?.response?.data?.message ?? 'Error al actualizar el inventario.')
            console.log(error.response)
        }).finally(() => this.setState({ loading: false }))
    }

    onValuesChange = (changedValues, allValues) => {
        if (changedValues.producto_id) {
            this.getVariantes()
        }

    }
    render() {
        return (
            <Form
                id="form-inventario"
                name="form-inventario"
                layout="vertical"
                ref={this.ModalInventarios}
                onFinish={this.onFinish}
                initialValues={{
                    disponible:0,
                    consignado:0,
                    futuro:0
                }}
                onValuesChange={this.onValuesChange}

            >
                <Spin spinning={this.state.loading}>

                    <Row justify="center" className=" mt-3" gutter={[16, 16]}>
                        <Col xs={24} lg={12} >
                            <Form.Item
                                label="Producto"
                                name="producto_id"
                                rules={[{
                                    required: true,
                                    message: "Por favor, seleccione el producto"
                                }]}
                            >
                                <SelectProductos
                                    disabled={this.props.inventario_id}
                                />
                            </Form.Item>
                        </Col>

                        <Col xs={24} lg={12} >
                            <Form.Item
                                label="Variante"
                                name="variante_id"
                                rules={[{
                                    required: true,
                                    message: "Por favor, seleccione la variante"
                                }]}
                            >
                                <Select
                                    disabled={this.props.inventario_id}
                                    placeholder="Seleccione la variante"
                                    showSearch
                                    filterOption={false}
                                    labelInValue
                                    onSearch={(search) => this.getVariantes({ search })}
                                >
                                    {
                                        this.state.variantes?.data?.map(({ _id, sku, sabor }) => <Option value={_id} key={_id} className="">
                                            <Text>{sku ?? "-"}</Text>
                                            <small style={{ float: "right" }}>{sabor ?? "-"}</small>
                                        </Option>)
                                    }
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col xs={24} lg={24} >
                            <Form.Item
                                label="Almacén"
                                name="almacen_id"
                                rules={[{
                                    required: true,
                                    message: "Por favor, seleccione el almacén"
                                }]}
                            >
                                <SelectAlmacenes

                                    onSelect={(almacen_id) => {
                                        this.setState({ almacen_id })
                                        this.ModalInventarios.current.setFieldsValue({ ubicacion_id: undefined })
                                    }}
                                    onClear={() => {
                                        this.setState({ almacen_id: undefined })
                                        this.ModalInventarios.current.setFieldsValue({ ubicacion_id: undefined })
                                    }}
                                />
                            </Form.Item>
                        </Col>

                        <Col xs={24} lg={24} >
                            <Form.Item
                                label="Ubicación"
                                name="ubicacion_id"
                            >
                                <SelectUbicaciones
                                    almacen_id={this.state.almacen_id}
                                />
                            </Form.Item>
                        </Col>


                        <Col xs={24} lg={8} >
                            <Form.Item
                                label="Disponible"
                                name="disponible"
                            >
                                <InputNumber
                                    className="width-100"
                                    formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                    min={0}
                                />

                            </Form.Item>
                        </Col>
                        <Col xs={24} lg={8} >
                            <Form.Item
                                label="Consignado"
                                name="consignado"
                            >
                                <InputNumber
                                    className="width-100"
                                    formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                    min={0}
                                />

                            </Form.Item>
                        </Col>
                        <Col xs={24} lg={8} >
                            <Form.Item
                                label="Futuro"
                                name="futuro"
                            >
                                <InputNumber
                                    className="width-100"
                                    formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                    min={0}
                                />

                            </Form.Item>
                        </Col>
                    </Row>
                </Spin>
            </Form>
        )
    }
}



export default function (props) {
    const { visible = false, onClose = () => { } } = props

    return <Modal
        open={visible}
        onCancel={onClose}
        title={props.inventario_id ? "Editar Inventario" : "Crear Inventario"}
        destroyOnClose={true}
        zIndex={1000}
        width={600}
        cancelText="Cancelar"
        okText="Guardar"
        okButtonProps={{ form: 'form-inventario', key: 'submit', htmlType: 'submit' }}

    >
        <ModalInventarios {...props} />
    </Modal>

}