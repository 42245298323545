import React, { Component } from "react";
import { Row, Col, Modal, Form, Select, message, Spin } from 'antd';

import axios from "axios"

const { Option } = Select;


class ModalCuentas extends Component {


    constructor(props) {
        super(props);
        this.state = {
            loading: false,
        }
    }

    refModalCuentas = React.createRef();

    componentDidMount() {
        if (this.props.cuenta_id) {

        }
    }




    /**
     * @memberof ModalCuentas
     * @method onFinish
     * @description Se ejecuta al dar enter al formulario
     * 
     */
    onFinish = (values) => {

        this.updateCuenta(values)
    }

    /**
     * @memberof ModalCuentas
     * @method updateCuenta
     * @description Actualiza la información de una Cuenta
     * 
     */
    updateCuenta = (values) => {
        this.setState({ loading: true })

        let index = this.props.cuentas_stp.findIndex(e => e._id.toString() === values.bxnk_cuenta_id.toString())

        if (index !== -1) {
            values.bxnk_cuenta_numero = this.props.cuentas_stp[index].cuenta;
            values.bxnk_cuenta_nombre = this.props.cuentas_stp[index].nombre;
        }

        axios.put('/cuentas/' + this.props.cuenta_id, values, {
            headers: {
                "Content-Type": 'multipart/form-data'
            }
        }).then(response => {
            message.success('Cuenta Actualizada')
            this.props.onClose()
        }).catch(error => {
            message.error('Error al actualizar la cuenta')
            console.log(error)
        }).finally(() => this.setState({ loading: false }))
    }


    render() {

        return (
            <Form
                layout="vertical"
                name="form-cuenta"
                id="form-cuenta"
                ref={this.refModalCuentas}
                onFinish={this.onFinish}

            >
                <Spin spinning={this.state.loading}>
                    <Row gutter={[16, 16]} justify="center">
                        <Col xs={24} lg={20} >
                            <Form.Item
                                label="Cuenta"
                                name="bxnk_cuenta_id"
                                rules={[{
                                    required: true,
                                    message: "Por favor, seleccione la cuenta"
                                }]}
                            >
                                <Select

                                >
                                    {
                                        this.props.cuentas_stp?.map(e => <Option value={e._id}>{e.nombre} <small>{e.cuenta}</small></Option>)
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                </Spin>
            </Form>
        )
    }
}



export default function (props) {
    const { visible = false, onClose = () => { }, cuenta_id } = props

    return <Modal
        open={visible}
        onCancel={onClose}
        title={cuenta_id ? "Relacionar cuenta con BXNK" : "Crear Cuenta"}
        destroyOnClose={true}
        cancelText="Cancelar"
        okText="Guardar"
        okButtonProps={{ form: 'form-cuenta', key: 'submit', htmlType: 'submit' }}
    >
        <ModalCuentas {...props} />
    </Modal>

}