import { Drawer, Spin, Row, Col, Form, Input, Select, DatePicker, Empty, Divider, InputNumber, message, Button, Checkbox, Typography, Space, Dropdown } from "antd";
import axios from "axios";
import moment from 'moment';

import React, { useContext } from "react";
import { AvatarLoader } from "../../Widgets/Uploaders";
import dayjs from "dayjs";
import { getResponseError } from "../../Utils";
import Logged from '../../../Hooks/Logged'
import Comment from "../../Widgets/Comment";
import '../../../Styles/Modules/Prospectos/chat.scss'
import SelectUsuarios from "../../Widgets/Inputs/SelectUsuarios";
import SelectEstado from "../../Widgets/Inputs/SelectEstado";

const { Option } = Select
const { Title, Text } = Typography;

class DrawerCliente extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            asesores: [],
            estados: [],
            paises: [],
            cliente_id: this.props.cliente_id,
            cliente: {
                _id: undefined,

            },
            orden: {
                ordenes_productos_ids : []
            }
        }
    }

    formRef = React.createRef()

    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token')
        if (this.props.orden_id)
            this.getOrden()
    }

    /**
    * @method addUser
    * @description Obtiene la información de la orden
    */
    getOrden = () => {
        this.setState({loading:true})
        axios.get('/orden/'+this.props.orden_id)
        .then(({data})=>{
            console.log("data", data);
            this.setState({orden: data})
            this.formRef.current.setFieldsValue({
                asesor_id: data?.asesor_id?._id ? {
                    value: data?.asesor_id?._id,
                    label: `${data?.asesor_id?.nombre} ${data?.asesor_id?.apellidos}`
                } : null
            })

        }).catch((error)=>{
            message.error(error?.response?.data?.message ?? "Error al obtener la información del pedido")
        }).finally(()=>this.setState({loading: false}))
    }

     /**
    * @method updateOrden
    * @description refistra una nueva orden
    */
    updateOrden = (values) => {
        this.setState({loading: true})
        axios.put('/ordenes',{
            orden_id: this.props.orden_id,
            ...values
        })
        .then(response => {
            message.success("Pedido actualizado")
            this.props.getOrdenes()
        }).catch(error => { 
            message.error(error?.response?.data?.message ?? "Error al actualizar el pedido")
        }).finally(()=>this.setState({loading: false}))
    }


    render() {
        
        let { orden } = this.state;

        let items = [
            {
                key: '0',
                label: <Button 
                    className="text-white" 
                    block style={{width: "200px", backgroundColor: "#ed0000"}}
                    onClick={()=>this.updateOrden({logistica_estatus: 0})}
                >Nuevas</Button>,
            },
            {
                key: '1',
                label: <Button 
                    className="text-white" 
                    block 
                    style={{width: "200px", backgroundColor: "#dbc500"}}
                    onClick={()=>this.updateOrden({logistica_estatus: 1})}
                >En Proceso</Button>,
            },
            {
                key: '2',
                label: <Button 
                    className="text-white" 
                    block 
                    style={{width: "200px", backgroundColor: "#5c54e0"}}
                    onClick={()=>this.updateOrden({logistica_estatus: 2})}
                >Enviadas</Button>,
            },
            {
                key: '3',
                label: <Button 
                    className="text-white" 
                    block 
                    style={{width: "200px", backgroundColor: "#1dd200"}}
                    onClick={()=>this.updateOrden({logistica_estatus: 3})}
                >Completadas</Button>,
            }
        ]

        items = items.filter(item => item.key !== orden.logistica_estatus?.toString());

        return (
            <Spin spinning={this.state.loading}>
                <Form
                    layout="vertical"
                    ref={this.formRef}
                >
                    <Row>
                        <Col span={24}>
                            <Title level={4}>Detalles del pedido {orden.folio ?? '-'}</Title>
                        </Col>
                        <Divider> Información General </Divider>
                        <Col span={8}>
                            <Form.Item
                                label="Cliente"
                            >  
                                <Text strong>{orden.cliente_id?.nombre ?? "Sin Cliente"} {orden.cliente_id?.apellido_paterno ?? ""}</Text>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                label="Fecha"
                            >  
                                <Text strong>{moment(orden.fecha).format("DD/MM/YYYY HH:mm")}</Text>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                label="Asiganado a"
                                name="asesor_id"
                            >  
                                <SelectUsuarios 
                                    placeholder="Sin Asesor"
                                    bordered={false}
                                    onSelect={(usuario_id)=>this.updateOrden({asesor_id: usuario_id})}
                                />

                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item
                                label="Notas"
                            >  
                                <Text strong>{orden.notas ?? '-'}</Text>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Divider> Información del pedido </Divider>
                    <Row  style={{ height: "350px", overflow: "hidden", overflowY: "auto" }} className="">
                        {
                            orden.ordenes_productos_ids?.map( producto => <>
                                <Col span={7} className="">
                                    <Form.Item
                                        label="Articulo"   
                                    >
                                        <div
                                            className="flex-left-column"
                                        >
                                            <Text strong>{producto.producto_id?.modelo}</Text>
                                            <Text className="text-gray">Sabor: {producto.producto_id?.sabor ?? "-"}</Text>
                                            <Text className="text-gray">SKU: {producto.producto_id?.sku ?? "-"}</Text>
                                            <Text className="text-gray">Precio: $ {producto.producto_id?.precio?.toMoney(true) ?? 0} MXN</Text>
                                        </div>
                                    </Form.Item>
                                </Col>
                                 <Col span={7} className="">
                                    <Form.Item
                                        label="Ubicación"   
                                    >
                                        <div
                                            className="flex-left-column"
                                        >
                                            <Text strong>-</Text>
                                            <Text className="text-gray">Ubi: -</Text>
                                        </div>
                                    </Form.Item>
                                </Col>
                                <Col span={4} className="">
                                    <Form.Item
                                        label="Cantidad"   
                                    >   
                                        <Text strong>{producto.cantidad}</Text>
                                    </Form.Item>
                                </Col>
                                <Col span={6} className="text-right">
                                    <Form.Item
                                        labelAlign="right" 
                                        label="Subtotal"
                                    >   
                                        <Text strong className="mr-1">$ {producto.total?.toMoney(true) ?? 0} MXN</Text>
                                    </Form.Item>
                                </Col>
                            </> )
                        }
                    </Row>
                    <Row className="width-100 mt-1 pl-1 pr-1">
                        <Col className="flex-right" span={18}>
                            <Text strong>Sub-Total:</Text>
                        </Col>
                        <Col className=" flex-right" span={6}>
                            <Text>$ {orden.sub_total?.toMoney(true)} <small>MXN</small></Text>
                        </Col>
                        {orden.impuestos > 0 ? <> <Col className="flex-right" span={18}>
                            <Text strong>IVA:</Text>
                        </Col>
                        <Col className=" flex-right" span={6}>
                            <Text>$ {orden.impuestos?.toMoney(true)} <small>MXN</small></Text>
                        </Col> </> : null}
                        {orden.costo_envio > 0 ? <> <Col className="flex-right" span={18}>
                            <Text strong>Shipping:</Text>
                        </Col>
                        <Col className=" flex-right" span={6}>
                            <Text>$ {orden.costo_envio?.toMoney(true)} <small>MXN</small></Text>
                        </Col> </> : null}
                        {orden.descuento > 0 ? <> <Col className="flex-right" span={18}>
                            <Text strong>Descuento:</Text>
                        </Col>
                        <Col className=" flex-right" span={6}>
                            <Text>- $ {orden.descuento?.toMoney(true)} <small>MXN</small></Text>
                        </Col> </> : null}
                        <Col className="flex-right" span={18}>
                            <Text strong>Total:</Text>
                        </Col>
                        <Col className=" flex-right" span={6}>
                            <Text strong>$ {orden.total?.toMoney(true)} <small>MXN</small></Text>
                        </Col>
                    </Row>
                    <Row className="mt-3" justify="center" style={{marginBottom: "8rem"}}>
                        <Col span={6}>
                            <Dropdown
                                menu={{
                                    items: items
                                }}
                                placement="topRight"
                            >
                                <Button type="primary" block>Cambiar Estatus</Button>
                            </Dropdown>
                        </Col>
                    </Row>
                </Form>
            </Spin>
                   
        )

    }


}

export default function Vista(props) {

    const user = useContext(Logged)

    const { visible, onClose } = props

    return (
        <Drawer
            placement="right"
            closable={true}
            maskClosable={true}
            onClose={onClose}
            headerStyle={{ display: "none" }}
            open={visible}
            width={props.cliente_id ? 1200 : 700}
            className="drawer-prospecto"
            destroyOnClose={true}
        >
            <DrawerCliente
                {...props}
                user={user}
            />
        </Drawer>
    )

}