import { Button, Affix, Space } from "antd";

import './style.css'

/**
 * @const FloatingButtonActions
 * @description Boton flotante inferior central, para realizar multiples acciones
 */
const FloatingButtonActions = (props) => {

    let { items, visible } = props;

    return (
        <Affix offsetBottom={50} style={{ position: 'absolute', left: "50%" }}>
            <div className={`affix-multiple ${visible ? "show" : ""}`}>
                <Space>
                    {items?.map(({label, onClick = () => {}})=> {
                        return <Button type="ghost" onClick={()=>onClick()} style={{color: "white"}}>
                            {label}
                        </Button>
                    })}
                   
                </Space>
            </div>
        </Affix>
    )

}

export default FloatingButtonActions;