import React, { Component } from "react";
import { Row, Col, Modal, Form, Input, message, Spin, Select, InputNumber, Button, Typography, Switch, Space } from 'antd';
import axios from "axios"
import { LinkOutlined, MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";


import User from "../../../Hooks/Logged";
import { getResponseError } from "../../Utils";
import usePermissions from "../../../Hooks/usePermissions";
import { AvatarLoader, ImagesUploader } from "../../Widgets/Uploaders";
import SelectMetacampos from "../../Widgets/Inputs/SelectMetacampos"

const { Option } = Select;
const {Text} = Typography


/**
 * @class ModalProductos
 * @description Modal para el CRUD de Productos
 */
class ModalProductos extends Component {

    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            variante: {},

        }
    }

    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token')
        if (this.props.variante_id)
            this.getVariante()

    }

    formRef = React.createRef();

    /**
    * @method getVariante
    * @description Obtener la variante
    */
    getVariante = () => {
        this.setState({ loading: true })
        axios.get('/variantes/' + this.props.variante_id)
        .then(({ data }) => {
            this.formRef.current.setFieldsValue({
                ...data,
                crear_shopify: data.shopify_variante_id ? true : false,
                imagenes: data.imagenes ? data.imagenes.map(f => ({
                    uid: f.file,
                    name: f.name,
                    status: 'done',
                    fromDB: true,
                    url: axios.defaults.baseURL + "/variantes/" + data._id + "?imagen=" + f.file + "&Authorization=" + sessionStorage.getItem("token")
                })) : null,
            })
            this.setState({variante: data})
        }).catch(error => {
            message.error(error?.response?.data?.message ?? 'Error al obtener la variante')
        }).finally(() => this.setState({ loading: false }))
    }


    /**
    * @method addVariante
    * @description Añade un nuevo registro de variante
    */
    addVariante = (values) => {
        this.setState({ loading: true })
        axios.post('/variantes', values, {
            headers: {
                "Content-Type": 'multipart/form-data'
            }
        })
        .then(response => {
            message.success('¡Variante Añadido!')
            this.props.onClose(true)
        }).catch(error => {
            console.log("error", error);
            message.error(getResponseError(error.response, 'Error al crear la variante'))
        }).finally(() => this.setState({ loading: false }))
    }

    /**
    * @method updateVariante
    * @description Actualiza la variante
    */
    updateVariante = (values) => {

        this.setState({ loading: true })
        axios.put('/variantes', values, {
            headers: {
                "Content-Type": 'multipart/form-data'
            }
        })
        .then(response => {
            message.success('¡Variante actualizada!')
            this.props.onClose(true)
        }).catch(error => {
            console.log("error", error);
            message.error(getResponseError(error.response, 'Error al actualizar la variante'))
        }).finally(() => this.setState({ loading: false }))
    }

    /**
   * @method onFinish
   * @description Se ejecuta al hacer submit al formulario
   */
    onFinish = (values) => {

        const formData = new FormData()

        formData.appendMultiple({
            ...values,
            variante_id: this.props.variante_id,
            producto_id: this.props.producto_id,
            imagenes: undefined,
            uris: undefined,
            metacampos: JSON.stringify(values.metacampos)
        })

        if(values.imagenes){
            values.imagenes.forEach(img => {
                if(!img.fromDB) formData.append('imagenes', img, img.name)
            })
        }

        if(values.uris){
            values.uris.forEach(uri => {
                formData.append('uris', uri)
            })
        }

        if (this.props.variante_id) {
            this.updateVariante(formData)
        } else {
            this.addVariante(formData)
        }
    }

    render() {

        const { variante } = this.state;

        return (
            <Spin spinning={this.state.loading}>
                <Form
                    id="form-producto"
                    layout={"vertical"}
                    className="mt-2"
                    onFinish={this.onFinish}
                    initialValues={{
                        active: true,
                        crear_shopify: true
                    }}
                    ref={this.formRef}
                >
                    <Row justify="center" className="center">

                        <Col span={20}>
                            
                
                            <Form.Item
                                name="sabor"
                                label="Sabor"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Ingrese el sabor',
                                    }
                                ]}
                            >
                                <Input />
                            </Form.Item>

                            <Form.Item
                                name="sku"
                                label="SKU"
                                // rules={[
                                //     {
                                //         required: true,
                                //         message: 'Ingrese el sku',
                                //     }
                                // ]}
                            >
                                <Input />
                            </Form.Item>


                            <Row gutter={[16, 16]}>
                                <Col xs={24} md={12}>
                                    {this.props.viewCosto ? <Form.Item
                                        name="costo"
                                        label="Costo"
                                        // rules={[
                                        //     {
                                        //         required: true,
                                        //         message: 'Ingrese el costo',
                                        //     }
                                        // ]}
                                    >
                                        <InputNumber style={{width: '100%'}} min={0.01} precision={2}/>
                                    </Form.Item> : null }
                                </Col>
                                <Col xs={24} md={12}>
                                    <Form.Item
                                        name="precio"
                                        label="Precio"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Ingrese el precio',
                                            }
                                        ]}
                                    >
                                        <InputNumber style={{width: '100%'}} min={0.01} precision={2}/>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={[16, 16]}>
                                <Col xs={24} md={12}>
                                    <Form.Item
                                        name="active"
                                        label="Activo"
                                        valuePropName="checked"
                                    >
                                        <Switch/>
                                    </Form.Item>
                                </Col>
                                <Col xs={24} md={12}>
                                    <Form.Item
                                        name="crear_shopify"
                                        label="¿Registrar en Shopify?"
                                        valuePropName="checked"
                                    >
                                        <Switch
                                            disabled={variante.shopify_variante_id ? true : false}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={24}>
                                    <Form.List name="metacampos">
                                        {(fields, { add, remove }) => (
                                            <>
                                                {fields.map(({ key, name, ...restField }) => (
                                                    <Row
                                                        key={key}
                                                        gutter={[12,12]}
                                                        style={{marginBottom: "12px"}}
                                                    >
                                                        <Col span={11}>
                                                            <Form.Item
                                                                className="m-0"
                                                                {...restField}
                                                                name={[name, 'metacampo_id']}
                                                                rules={[
                                                                    {
                                                                        required: true,
                                                                        message: 'Selecciona un metacampo',
                                                                    },
                                                                ]}
                                                            >
                                                                <SelectMetacampos
                                                                    dropdownRender={true}
                                                                />
                                                            </Form.Item>
                                                        </Col>
                                                        <Col span={11}>
                                                            <Form.Item
                                                                className="m-0"
                                                                {...restField}
                                                                name={[name, 'descripcion']}
                                                                rules={[
                                                                    {
                                                                        required: true,
                                                                        message: 'Añade una descripción',
                                                                    },
                                                                ]}
                                                            >
                                                                <Input placeholder="Descripción" />
                                                            </Form.Item>
                                                        </Col>
                                                        <Col span={2} className="center">
                                                            <MinusCircleOutlined onClick={() => remove(name)} />
                                                        </Col>
                                                    </Row>
                                                ))}
                                                <Form.Item>
                                                    <Button 
                                                        type="dashed" 
                                                        onClick={() => add()} 
                                                        block 
                                                        icon={<PlusOutlined />}
                                                        disabled={this.state.disabled}
                                                    >
                                                        Añadir Metacampo
                                                    </Button>
                                                </Form.Item>
                                            </>
                                        )}
                                    </Form.List>
                                </Col>
                                <Col span={24}>
                                    <Form.Item
                                        name="imagenes"
                                        label="Imagenes"
                                    >
                                        <ImagesUploader
                                            onRemove={({name, fromDB}) => {
                                                if(fromDB){
                                                    this.setState({loading: true})
                                                    axios.put(`/variantes`, {
                                                        variante_id: this.props.variante_id,
                                                        delete_image: name
                                                    }).catch(error => {
                                                        message.error("Error al eliminar")
                                                    }).finally(()=> this.setState({loading: false}))
                                                }
                                            }}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Form>
            </Spin>
        )
    }


}



export default function Modals(props) {

    const { visible = false, onClose = () => { }, variante_id } = props

    const user = React.useContext(User)
    const permisos = usePermissions(user?.rol_id?.permisos, {
        viewCosto: ['productos', 'costo'],
    })

    return <Modal
       open={visible}
        onCancel={onClose}
        title={variante_id ? "Editar Variante" : "Crear Variante"}
        closable={true}
        destroyOnClose={true}
        zIndex={1000}
        cancelText="Cancelar"
        okText="Guardar"
        okButtonProps={{ form: 'form-producto', key: 'submit', htmlType: 'submit' }}
        width={800}
    >
        <ModalProductos  {...props} {...permisos}/>
    </Modal>

}