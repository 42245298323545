import React from 'react'
import { Route, Routes } from "react-router-dom";

import Areas from '../../Components/Admin/Areas/Areas';
import Rubros from '../../Components/Admin/Areas/Rubros/Rubros';

/**
 * 
 * @export
 * @function RouterAreasRubros
 * @description Router for Areas routes 
 */
function RouterAreasRubros(props) {
  return (
    <Routes>
      <Route path="" element={<Areas {...props} />} />
      <Route path=":area_id/rubros" element={<Rubros {...props} />} />
    </Routes>
  )
}

export default RouterAreasRubros