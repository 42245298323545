import React, { useContext } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import { Layout } from "antd";

import Navbar from "../Components/Admin/Navigation/Navbar";
import Sidebar from "../Components/Admin/Navigation/Sidebar";
import Dashboard from "../Components/Admin/Dashboard/Dashboard";
import axios from "axios";

import "../Styles/Global/admin.scss";
import "../Styles/Global/global.scss";
import RouterUsuarios from "./Admin/RouterUsuarios";
import RouterRoles from "./Admin/RouterRoles";
import User from "../Hooks/Logged";

import { Search } from "../Hooks/useSearch";
import RouterClientes from "./Admin/RouterClientes";
import RouterProductos from "./Admin/RouterProductos";
import RouterCuentas from "./Admin/RouterCuentas";
import RouterAreasRubros from "./Admin/RouterAreasRubros";
import RouterFinanzas from "./Admin/RouterFinanzas";
import RouterCRM from "./Admin/RouterCRM";
import RouterProveedores from "./Admin/RouterProveedores";
import RouterAlmacenes from "./Admin/RouterAlmacenes";
import RouterInventarios from "./Admin/RouterInventarios";
import RouterTransferencias from "./Admin/RouterTransferencias";

import RouterCompras from "./Admin/RouterCompras";
import RouterLogistica from "./Admin/RouterLogistica";
import RouterShopify from "./Admin/RouterShopify";
import RouterOrdenes from "./Admin/RouterOrdenes";
import RouterContabilidad from "./Admin/RouterContabilidad";

const { Content } = Layout;

/**
 *
 * @export
 * @function AdminRoutes
 * @description Router for handling all admin routes
 */
class AdminRoutes extends React.Component {
  static contextType = User;

  constructor(props) {
    super(props);
    this.state = {
      search: null,
      showSearch: true,
      clear: true,
    };
  }

  componentDidMount() {
    this.axiosInterceptor = axios.interceptors.response.use(
      (response) => {
        //console.log('response interceptor', response)
        return response;
      },
      (error) => {
        console.log("error interceptor", error?.response);
        if (error?.response?.status === 401) this.props.navigate("/login");
        return Promise.reject(error);
      },
    );
  }
  componentWillUnmount() {
    axios.interceptors.request.eject(this.axiosInterceptor);
  }

  setSearch = (search) => this.setState({ search });

  setShowSearch = (showSearch) => this.setState({ showSearch });

  componentWillUnmount() {
    axios.interceptors.request.eject(this.updateToken);
  }

  render() {
    return (
      <Search.Provider
        value={{
          search: this.state.search,
          setSearch: this.setSearch,
          show: this.state.showSearch,
          setShow: this.setShowSearch,
        }}
      >
        <Layout className="layout-main">
          <Navbar />
          <Layout>
            <Sidebar />
            <Content style={{ minHeight: "calc(100vh - 100px)" }}>
              <Routes>
                <Route
                  path="/dashboard"
                  element={<Dashboard search={this.state.search} />}
                />
                <Route
                  path="/usuarios/*"
                  element={<RouterUsuarios search={this.state.search} />}
                />
                <Route
                  path="/clientes/*"
                  element={<RouterClientes search={this.state.search} />}
                />
                <Route
                  path="/productos/*"
                  element={<RouterProductos search={this.state.search} />}
                />
                <Route
                  path="/proveedores/*"
                  element={<RouterProveedores search={this.state.search} />}
                />
                <Route
                  path="/roles/*"
                  element={<RouterRoles search={this.state.search} />}
                />
                <Route
                  path="/cuentas/*"
                  element={<RouterCuentas search={this.state.search} />}
                />
                <Route
                  path="/areas/*"
                  element={<RouterAreasRubros search={this.state.search} />}
                />
                <Route
                  path="/finanzas/*"
                  element={<RouterFinanzas search={this.state.search} />}
                />
                <Route
                  path="/crm/*"
                  element={<RouterCRM search={this.state.search} />}
                />
                <Route
                  path="/almacenes/*"
                  element={<RouterAlmacenes search={this.state.search} />}
                />
                <Route
                  path="/inventarios/*"
                  element={<RouterInventarios search={this.state.search} />}
                />
                <Route
                  path="/transferencias/*"
                  element={<RouterTransferencias search={this.state.search} />}
                />
                <Route
                  path="/compras/*"
                  element={<RouterCompras search={this.state.search} />}
                />
                <Route
                  path="/logistica/*"
                  element={<RouterLogistica search={this.state.search} />}
                />
                <Route
                  path="/shopify/*"
                  element={<RouterShopify search={this.state.search} />}
                />
                <Route
                  path="/ordenes/*"
                  element={<RouterOrdenes search={this.state.search} />}
                />
                <Route
                  path="/contabilidad/*"
                  element={<RouterContabilidad search={this.state.search} setShowSearch={this.setShowSearch} />}
                />
              </Routes>
            </Content>
          </Layout>
        </Layout>
      </Search.Provider>
    );
  }
}
export default function (props) {
  const user = useContext(User);
  const navigate = useNavigate();
  return <AdminRoutes {...props} user={user} navigate={navigate} />;
}
