import React, { Component } from "react";
import { Row, Col, Modal, Form, Input, message, Spin, Select, InputNumber, Button, Typography, Switch } from 'antd';
import axios from "axios"
import { LinkOutlined, MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";


import User from "../../../Hooks/Logged";
import { getResponseError } from "../../Utils";
import usePermissions from "../../../Hooks/usePermissions";
import { AvatarLoader, ImagesUploader } from "../../Widgets/Uploaders";

const { Option } = Select;
const {Text} = Typography


/**
 * @class ModalProductos
 * @description Modal para el CRUD de Productos
 */
class ModalProductos extends Component {

    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            producto: {}
        }
    }

    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token')
        if (this.props.producto_id)
            this.get()

    }

    formRef = React.createRef();

    /**
    * @method addUser
    * @description Obtener al producto
    */
    get = () => {
        this.setState({ loading: true })
        axios.get('/productos/' + this.props.producto_id)
            .then(({ data }) => {
                this.formRef.current.setFieldsValue({
                    ...data,
                    imagenes: data.imagenes ? data.imagenes.map(f => ({
                        uid: f.file,
                        name: f.name,
                        status: 'done',
                        fromDB: true,
                        url: axios.defaults.baseURL + "/productos/" + data._id + "?imagen=" + f.file + "&Authorization=" + sessionStorage.getItem("token")
                    })) : null,
                    crear_shopify: data.shopify_id,
                })
                this.setState({ producto: data })
            }).catch(error => {
                message.error('Error al obtener el producto')
            }).finally(() => this.setState({ loading: false }))
    }


    /**
    * @method add
    * @description Añade un nuevo registro de producto
    */
    add = (values) => {
        this.setState({ loading: true })
        axios.post('/productos', values, {
            headers: {
                "Content-Type": 'multipart/form-data'
            }
        })
            .then(response => {
                message.success('¡Producto Creado!')
                this.props.onClose()
            }).catch(error => {
                console.log("error", error);
                message.error(getResponseError(error.response, 'Error al crear el Producto'))
            }).finally(() => this.setState({ loading: false }))
    }

    /**
    * @method updateUser
    * @description Añade un nuevo registro de producto
    */
    update = (values) => {

        this.setState({ loading: true })
        axios.put('/productos', values, {
            headers: {
                "Content-Type": 'multipart/form-data'
            }
        })
            .then(response => {
                message.success('¡Producto Actualizado!')
                this.props.onClose()
            }).catch(error => {
                console.log("error", error);
                message.error(getResponseError(error.response, 'Error al actualizar el Producto'))
            }).finally(() => this.setState({ loading: false }))
    }

    /**
   * @method onFinish
   * @description Se ejecuta al hacer submit al formulario
   */
    onFinish = (values) => {

        const formData = new FormData()

        formData.appendMultiple({
            ...values,
            id: this.props.producto_id,
            imagenes: undefined,
            uris: undefined
        })

        if(values.imagenes){
            values.imagenes.forEach(img => {
                if(!img.fromDB) formData.append('imagenes', img, img.name)
            })
        }

        if(values.uris){
            values.uris.forEach(uri => {
                formData.append('uris', uri)
            })
        }

        if (this.props.producto_id) {
            this.update(formData)
        } else {
            this.add(formData)
        }
    }

    render() {

        let { producto } = this.state;

        return (
            <Spin spinning={this.state.loading}>
                <Form
                    id="form-producto"
                    layout={"vertical"}
                    className="mt-2"
                    onFinish={this.onFinish}
                    initialValues={{
                        active: true,
                        crear_shopify: true
                    }}
                    ref={this.formRef}
                >
                    <Row justify="center" className="center">

                        <Col span={20}>
                            
                            <Form.Item
                                name="modelo"
                                label="Modelo"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Ingrese el modelo',
                                    }
                                ]}
                            >
                                <Input />
                            </Form.Item>

                            <Form.Item
                                name="marca"
                                label="Marca"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Ingrese la marca',
                                    }
                                ]}
                            >
                                <Input />
                            </Form.Item>

                            { this.props.producto_id ? null : <> 
                                <Form.Item
                                    name="sabor"
                                    label="Sabor"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Ingrese el sabor',
                                        }
                                    ]}
                                >
                                    <Input />
                                </Form.Item>

                                <Form.Item
                                    name="sku"
                                    label="SKU"
                                    
                                >
                                    <Input />
                                </Form.Item>
                            </>}

                            <Form.Item
                                name="descripcion"
                                label="Descripción"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Ingrese la descripción',
                                    }
                                ]}
                            >
                                <Input.TextArea rows={4} />
                            </Form.Item>


                            { this.props.producto_id ? null : <Row gutter={[16, 16]}>
                                <Col xs={24} md={12}>
                                    {this.props.viewCosto ? <Form.Item
                                        name="costo"
                                        label="Costo"
                                        
                                    >
                                        <InputNumber style={{width: '100%'}} min={0.01} precision={2}/>
                                    </Form.Item> : null }
                                </Col>
                                <Col xs={24} md={12}>
                                    <Form.Item
                                        name="precio"
                                        label="Precio"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Ingrese el precio',
                                            }
                                        ]}
                                    >
                                        <InputNumber style={{width: '100%'}} min={0.01} precision={2}/>
                                    </Form.Item>
                                </Col>
                            </Row>}

                            <Form.Item
                                name="imagenes"
                                label="Imagenes"
                            >
                                <ImagesUploader
                                    onRemove={({name, fromDB}) => {
                                        if(fromDB) axios.put(`/productos`, {
                                            id: this.props.producto_id,
                                            delete_image: name
                                        })
                                    }}
                                />
                            </Form.Item>

                            <Row gutter={[16, 16]}>
                                <Col xs={24} md={12}>
                                    <Form.Item
                                        name="active"
                                        label="Activo"
                                        valuePropName="checked"
                                    >
                                        <Switch/>
                                    </Form.Item>
                                </Col>
                                <Col xs={24} md={12}>
                                    <Form.Item
                                        name="crear_shopify"
                                        label="¿Registrar en Shopify?"
                                        valuePropName="checked"
                                    >
                                        <Switch
                                            disabled={producto?.shopify_id ? true : false}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Form>
            </Spin>
        )
    }


}



export default function Modals(props) {

    const { visible = false, onClose = () => { }, producto_id } = props

    const user = React.useContext(User)
    const permisos = usePermissions(user?.rol_id?.permisos, {
        viewCosto: ['productos', 'costo'],
    })

    return <Modal
       open={visible}
        onCancel={onClose}
        title={producto_id ? "Editar Producto" : "Crear Producto"}
        closable={true}
        destroyOnClose={true}
        zIndex={1000}
        cancelText="Cancelar"
        okText="Guardar"
        okButtonProps={{ form: 'form-producto', key: 'submit', htmlType: 'submit' }}
    >
        <ModalProductos  {...props} {...permisos}/>
    </Modal>

}