import React, { Component } from "react";
import { Row, Col, Button, Modal, Typography, Form, Statistic, Tag, message, Spin, Divider } from 'antd';
//componentes
import { PaperClipOutlined } from "@ant-design/icons"
import CustomAvatar from "../../../Widgets/Avatar/Avatar";
import axios from "axios"

const { Title, Text } = Typography;
const moment = require('moment');



/**
 *
 *
 * @class ModalTransaccionesDetalle
 * @extends {Component}
 */
class ModalTransaccionesDetalle extends Component {


    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            transaccion: {
                _id: 0,
                concepto: '',
                cuenta_id: {
                    nombre: ''
                },
                cuenta_destino_id: {
                    nombre: ''
                },
                monto: 0,
                fecha: new Date(),

                tipo: 0,

                usuario_id: {
                    nombre: ''
                },
                area_id: {
                    nombre: ''
                },
                rubro_id: {
                    nombre: ''
                },
                descripcion: '',
                comprobantes: [],
                actualiza_saldo: false,
                tipo_cliente: 'transaccion-especie',
                descripcion: ''
            },
        }

    }

    componentDidMount() {
        this.getTransaccion(this.props.id)
    }


    /**
    * @memberof ModalTran
    * @method getTransaccion
    * @description Obtiene la informacion de la transacciones para el modal de detalle. Actualiza el state
    * con informacion obtenida.
    */
    getTransaccion = (id) => {
        this.setState({ loading: true })
        axios.get(`/transacciones/${id}`, {
           
        }).then(({ data }) => {
            this.setState({ transaccion: data });
        })
            .catch(error => {
                console.log('error', error)
            })
            .finally(() => {
                this.setState({ loading: false })
            })

    }

    getTipoColor = (tipo = 0) => {
		const colors = [
			'#36F905',
			'#EB5757',
			'#1890FF',
			'#000000'
		]

		return colors[tipo - 1]
	}

    render() {
        const { transaccion } = this.state;
        return (
            <Form layout="vertical"   >
                <Spin spinning={false}>
                    <Row justify="center">
                        {
                            transaccion.bxnk_transaccion_id ? <Col xs={24} className="flex-column mt-1 mb-1">
                                <Title level={5} className="m-0"> Transacción proveniente de </Title>
                                <img src={"/BXNKLogo.png"} width={150}/>
                            </Col> : null
                        }
                        <Col xs={24} lg={11}>
                            <Form.Item label="Concepto" >
                                <Text type="secondary" >{transaccion.concepto}</Text>
                            </Form.Item>
                        </Col>
                        <Col xs={24} lg={{ span: 11, }}>
                            <Form.Item label="Fecha" >
                                <Text type="secondary" >{moment(transaccion.fecha).format('LL')}</Text>
                            </Form.Item>
                        </Col>
                        <Col xs={24} lg={{ span: 11 }}>
                            <Form.Item label="Area" >
                                <Text type="secondary" >{transaccion.area_id?.nombre ?? "-"}</Text>
                            </Form.Item>
                        </Col>
                        <Col xs={24} lg={11}>
                            <Form.Item label="Rubro" >
                                <Text type="secondary" >{transaccion.rubro_id?.nombre ?? "-"}</Text>
                            </Form.Item>
                        </Col>

                        <Col xs={24} lg={{ span: 11, }}>
                            <Form.Item label="Cuenta" >
                                <div className="flex-left-column">
                                    {(transaccion.actualiza_saldo === true) ? <Tag color="#87d068" className="tag-actualiza-saldo" >Actualiza Saldo</Tag> : null}
                                    <Text type="secondary" style={{marginTop: "6px"}}> <CustomAvatar name={transaccion.cuenta_id.nombre} /> {transaccion.cuenta_id.nombre}</Text>
                                </div>
                            </Form.Item>
                        </Col>

                        <Col xs={24} lg={11}>
                            <Form.Item label="Tipo" >
                                <Text type="secondary" >{
                                    (transaccion.tipo != 3) ? ((transaccion.tipo == 1) ? 'Ingreso' : 'Egreso') : 'Traspaso'}
                                </Text>
                            </Form.Item>
                        </Col>

                        <Col xs={24} lg={{ span: 11 }}>
                            <Form.Item label="Monto" >
                                <Statistic
                                    value={transaccion.monto}
                                    valueStyle={{ fontSize: '16px', fontWeight: 'bold', color: this.getTipoColor(transaccion.tipo) }}
                                    prefix={'$'}
                                    suffix={'MXN'}
                                /> 
                            </Form.Item>
                        </Col>

                        <Col xs={24} lg={11}>
                            <Form.Item label="Cliente" >
                                {(transaccion.cliente_id?.nombre != undefined) ? 
                                    <div>
                                        <CustomAvatar name={transaccion.cliente_id?.nombre} size="small" style={{ marginRight: '5px' }} />
                                        {transaccion.cliente_id?.nombre}
                                    </div>
                                :null}
                            </Form.Item>
                        </Col>
                        <Col xs={24} lg={22}>
                            <Form.Item label="Comprobantes" >
                                {transaccion.comprobantes?.map((file, index) => {
                                    return <Button type="link" icon={<PaperClipOutlined />} to={axios.defaults.baseURL + "/transacciones/" + transaccion._id + "?comprobante=" + file.file + "&Authorization=" + sessionStorage.getItem("token")}>{file.name}</Button>
                                })}
                            </Form.Item>
                        </Col>


                        <Divider />
                        <Col xs={24} lg={11}>
                            <Form.Item label="Autor" name="usuario_id">
                                <div>
                                    <CustomAvatar name={`${transaccion.usuario_id?.nombre} ${transaccion.usuario_id?.apellidos }`} size="small" style={{ marginRight: '5px' }} />
                                    {`${transaccion.usuario_id?.nombre} ${transaccion.usuario_id?.apellidos || ""}`}
                                </div>
                            </Form.Item>
                        </Col>
                        <Col xs={24} lg={{ span: 11 }}>
                            <Form.Item label="Editores" name="editado_por">
                                {transaccion.editado_por?.map(function ({ _id, nombre, apellidos }, index) {
                                    return <div>
                                        <CustomAvatar name={`${nombre} ${apellidos}`} size="small" style={{ marginRight: '5px' }} />
                                        {`${nombre} ${apellidos}`}
                                    </div>
                                })}
                            </Form.Item>
                        </Col>
                    </Row>
                </Spin>
            </Form>
        )
    }
}



export default function (props) {
    const { visible = false, onClose = () => { } } = props

    return <Modal
       open={visible}
        onCancel={onClose}
        footer={null}
        closable={true}
        destroyOnClose={true}
        title={"Detalle Transacción"}
        zIndex={1000}
    >
        <ModalTransaccionesDetalle {...props} />
    </Modal>

}