import { useNavigate } from "react-router-dom";
import { Typography, Tooltip, Dropdown, Button, Space, message} from "antd"
import { ExclamationCircleFilled, CopyOutlined } from "@ant-design/icons";


const { Text } = Typography;

export default function ({ cliente = {}, shopify_cliente, delete_order = false, asesor }) {

    let { nombre, apellido_paterno } = cliente

    const navigate = useNavigate()

    let items = []

    if(cliente.nombre)
        items = [
            {
                label: <div className="flex-between"><Text>{cliente.email ?? "Sin Email"}</Text><CopyOutlined className="ml-1"/></div>,
                key: '0',
                onClick: () => {
                    navigator.clipboard.writeText(cliente.email ?? "")
                    .then(() => {
                        message.info('Texto copiado al portapapeles');
                    })
                        
                }
            },
            {
                label: <Button block style={{minWidth: "230px"}} onClick={()=>navigate("/admin/crm/clientes/detalle/"+cliente?._id)}>Ver Detalle</Button>,
                key: "1",
            },
        ];

    return <>
        <Dropdown menu={{ items }} trigger={['click']} placement="bottom" >
            <div className="hover-cursor text-center" delete={delete_order} style={{lineHeight: "18px"}}>
                {nombre ?? '-'} {apellido_paterno ?? ""} {!cliente && shopify_cliente ? <>{shopify_cliente?.first_name ?? '-'} {shopify_cliente?.last_name ?? ""}
                <Tooltip placement="topRight" title={"Cliente no registrado"}>
                    <ExclamationCircleFilled style={{ color: "#ff4d4f", marginLeft: "0.5rem" }} />
                </Tooltip>
            </> : null} 
            { asesor ?<> <br/><small className="text-gray">Asesor: {asesor.nombre}</small> </> : null}
            </div>
        </Dropdown>
    </>
}