import React from 'react'
import { Route, Routes } from "react-router-dom";

import Inventarios from '../../Components/Admin/Inventarios/Inventarios';

/**
 * 
 * @export
 * @function RouterInventarios
 * @description Router for Inventarios routes 
 */
function RouterInventarios(props) {
  return (
    <Routes>
      <Route path="" element={<Inventarios {...props} />} />
      <Route path="/:almacen_id" element={<Inventarios {...props} />} />
    </Routes>
  )
}

export default RouterInventarios