import React, { Component, useContext } from 'react';
import { Layout, Row, Col, Tooltip, Typography, Dropdown, Modal, message, Statistic, Space, Collapse, Tag, Pagination, DatePicker, Form, Button, Divider, Spin, Empty, Card, Select, Switch } from "antd";
import axios from 'axios';
import dayjs from "dayjs";
import ReactECharts from 'echarts-for-react';
import { FaShopify, FaTruck } from "react-icons/fa";
import { AiFillDollarCircle } from "react-icons/ai";
import { useNavigate, useParams } from "react-router-dom";
import { FilePdfOutlined, FileExcelOutlined, MoreOutlined, FilterOutlined, PlusOutlined, MinusOutlined, ArrowUpOutlined, ArrowDownOutlined, InfoCircleOutlined } from '@ant-design/icons';

//Componentes
import User from "../../../../Hooks/Logged";
import usePermissions from "../../../../Hooks/usePermissions";
import PageHeader from '../../../Widgets/PageHeader/PageHeader';
import SelectClientes from '../../../Widgets/Inputs/SelectClientes';
import SelectVariantes from '../../../Widgets/Inputs/SelectVariantes';
import { renderEstatusOrdenEnvio, renderEstatusOrdenPago } from "../../../Utils";

//Modales
import ModalOrdenes from '../../Ordenes/ModalOrdenes';
import DrawerBitacora from '../../Ordenes/DrawerBitacora';
import ModalTransacciones from '../../Ordenes/ModalTransacciones';
import ModalOrdenesDetalles from '../../Ordenes/ModalOrdenesDetalles';
import DrawerFiltros from '../../../Widgets/DrawerFiltros/DrawerFiltros';
import ModalFulfillmentsDetalle from '../../Ordenes/ModalFulfillmentsDetalle';



const { Content } = Layout;
const { confirm } = Modal;
const { Text, Title } = Typography;
const { RangePicker } = DatePicker;


/**
 *
 *
 * @export
 * @class Ordenes
 * @extends {Component}
 * @description Vista del listado de Ordenes de Shopify
 */
class Ordenes extends Component {

    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            loading_ordenes: false,
            cliente_id: this.props.params.cliente_id,
            variante_id: null,
            cliente: {},
            ordenes: {
                data: [],
                page: 1,
                limit: 50,
                pageInfo: undefined,
                total: 0,
                filters: [],
                search: this.props.search
            },
            utilidad_por_anio: [],
            utilidad_porcentaje: 0,
            utilidad_cambio: 0,
            teorico: false,
            fechas: [dayjs().startOf('month'), dayjs().endOf("month")]
        }
    }

    componentDidMount() {
        this.props.setShowSearch(true)
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        this.getOrdenes()
        this.getGrafica()
    }

    componentDidUpdate(prevProps){
        if(this.props.search !== prevProps.search){
            this.getOrdenes({page: 1, search: this.props.search})
        }

    }

    getGrafica = ({
        year = this.state.year,
    } = this.state) => {
        this.setState({loading: true})
        axios.get("/ventas-anuales",{
            params:{
                year,
                teorico: this.state.teorico,
            }
        })
        .then(({data}) => {
            console.log("data", data);
            this.setState({...data, year})
        }).catch(error => {
            console.log("error", error.response);
        }).finally(()=>this.setState({loading: false}))
    }


    /**
    * @memberof Ordenes
    * @description Obtiene una lista de ordenes
    */
    getOrdenes = ({
        limit = this.state.ordenes.limit,
        page = this.state.ordenes.page,
        filters = this.state.ordenes.filters,
        search = this.props.search,
    } = this.state.ordenes) => {
        this.setState({ loading_ordenes: true })
        
        let productos_ids = (filters?.filter(element => element.name === "producto_id"))?.map(e => e._id)
        let variantes_ids = (filters?.filter(element => element.name === "variante_id"))?.map(e => e._id)

        axios.get('/ordenes', {
            params: {
                limit,
                page,
                filters: filters.filter(item => item.name !== "producto_id" && item.name !== "variante_id"),
                productos_ids,
                variantes_ids,
                tipo: "normal", //Pedidos confirmados
                orden_detalles: true,
                fechas: this.state.fechas,
                contabilidad: true,
                search
            }
        }).then(({ data }) => {
            console.log("data", data);
            this.setState({
                ordenes: {
                    ...data,
                    filters,
                    search
                }
            })

        }).catch(error => {
            console.log("error", error);
            message.error(error?.response?.data?.message ?? 'Error al obtener los pedidos')
        }).finally(() => this.setState({ loading_ordenes: false }))

    }

    /**
    * @memberof onChangeDates
    * @description Actualiza las fechas de busqueda
    */
    onChangeDates = (dates) => {
        if (dates) {
            this.setState({ fechas: dates }, () => { this.getOrdenes() })
        }else{
            this.setState({ fechas: undefined }, () => this.getOrdenes())
        }
    }

    /**
     *
     * @memberof ClientesDetalles
     * 
     * @method getPDFPedidos
     * @description Generamos el PDF del estado de cuenta del cliente
     */
    getPDFPedidos = () => {
        let url = new URL(axios.defaults.baseURL + '/reportes/utilidades')
        url.searchParams.append('Authorization', sessionStorage.getItem('token'))
        if(this.state?.fechas?.length > 0){
            url.searchParams.append('fecha_inicial', this.state.fechas[0])
            url.searchParams.append('fecha_final', this.state.fechas[1])
        }
        if(this.state?.ordenes?.filters?.length > 0){
            let filters = JSON.parse(JSON.stringify(this.state?.ordenes?.filters));
            url.searchParams.append('filters', JSON.stringify(filters?.filter(item => item.name !== "producto_id" && item.name !== "variante_id")))
            let productos_ids = (filters?.filter(element => element.name === "producto_id"))?.map(e => e._id)
            if(productos_ids) url.searchParams.append('productos_ids', JSON.stringify(productos_ids))
            let variantes_ids = (filters?.filter(element => element.name === "variante_id"))?.map(e => e._id)
            if(variantes_ids) url.searchParams.append('variantes_ids', JSON.stringify(variantes_ids))
        }
        window.open(url, '_blank')
    }

    /**
    *
    * @memberof ClientesDetalles
    * 
    * @method getCSVPedidos
    * @description Generamos el csv de los pedidos
    */
    getCSVPedidos = () => {
        let url = new URL(axios.defaults.baseURL + '/reportes/utilidades')
        url.searchParams.append('Authorization', sessionStorage.getItem('token'))
        if(this.state?.fechas?.length > 0){
            url.searchParams.append('fecha_inicial', this.state.fechas[0])
            url.searchParams.append('fecha_final', this.state.fechas[1])
        }
        if(this.state?.ordenes?.filters?.length > 0){
            let filters = JSON.parse(JSON.stringify(this.state?.ordenes?.filters));
            url.searchParams.append('filters', JSON.stringify(filters?.filter(item => item.name !== "producto_id" && item.name !== "variante_id")))
            let productos_ids = (filters?.filter(element => element.name === "producto_id"))?.map(e => e._id)
            if(productos_ids) url.searchParams.append('productos_ids', JSON.stringify(productos_ids))
            let variantes_ids = (filters?.filter(element => element.name === "variante_id"))?.map(e => e._id)
            if(variantes_ids) url.searchParams.append('variantes_ids', JSON.stringify(variantes_ids))
        }
        url.searchParams.append('csv', true)
        window.open(url, '_blank')
    }

    
    /**
    *
    * @memberof Ordenes
    * 
    * @method aprobarOrden
    * @description Aprueba la orden y pasa de draft a pedido normal
    */
    aprobarOrden = (orden_id) => {
        this.setState({ loading: true })
        axios.post('/ordenes/aprobar', {
            orden_id
        })
            .then(response => {
                message.success("Orden Aprobada")
                this.getOrdenes()
            })
            .catch(error => {
                console.log("error", error);
                message.error("Error al aprobar la orden")
            }).finally(() => this.setState({ loading: false }))
    }

    /**
       *
       * @memberof Ordenes
       * 
       * @method renderMenuItems
       * @description renderiza los elementos del menu de cada pedido
       */
    renderMenuItems = (orden) => {

        let menu_items = [
            {
                key: '1',
                label: <Text>Ver Detalle</Text>,
                onClick: () => this.setState({ modal_visible_detalle: true, orden_id: orden._id })
            },
            {
                key: '3',
                label: <Text>Ver PDF</Text>,
                onClick: () => {
                    let url = new URL(axios.defaults.baseURL + '/ordenes/pdf')
                    url.searchParams.append('orden_id', orden?._id)
                    url.searchParams.append('Authorization', sessionStorage.getItem('token'))
                    window.open(url, '_blank')
                }
            },
            {
                key: '6',
                label: <Text>Ver PDF de Empaquetado</Text>,
                onClick: () => {
                    let url = new URL(axios.defaults.baseURL + '/ordenes/packing')
                    url.searchParams.append('orden_id', orden?._id)
                    url.searchParams.append('timezone', Intl.DateTimeFormat().resolvedOptions().timeZone)
                    url.searchParams.append('Authorization', sessionStorage.getItem('token'))
                    window.open(url, '_blank')

                    this.getOrdenes()
                }
            },
            
        ]

        return menu_items
    }

     /**
     * @method renderOptions
     * @descripcion Renderiza las opciones del select 
     * */
    renderOptions = () => {
        const currentYear = dayjs().year();

        const yearsArray = [];
        for (let year = 2023; year <= currentYear; year++) {
            yearsArray.push({ value:year, label: year});
        }

        return yearsArray
    }

    render() {

        let { cliente } = this.state;

        let option = {
            xAxis: {
                type: 'category',
                data: [ "Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre" ],
                axisLabel: {
                    rotate: 45  // Aquí puedes ajustar el ángulo de rotación
                }
            },
            yAxis: {
                type: 'value'
            },
            series: [
                {
                    data: this.state.utilidad_por_anio?.map(e => e.utilidades ),
                    type: 'bar'
                }
            ],
            tooltip: {
                trigger: 'axis',
                axisPointer: {
                  type: 'shadow'
                }
            },
        };

        return (
            <Spin spinning={this.state.loading}>
                <PageHeader
                    className="admin-page-header"
                    backIcon ={true}
                    breadcrumb={{
                        items: [
                            {
                                title: "Contabilidad",
                                className: "hover",
                                onClick: () => this.props.navigate("/admin/contabilidad/")
                            },
                        ]
                    }}
                    extra={<Space direction='horizontal'>
                        <Button
                            title="Reporte CSV"
                            onClick={() => this.getCSVPedidos()}
                            icon={<FileExcelOutlined />} />
                        <Button
                            title="Reporte PDF"
                            onClick={() => this.getPDFPedidos()}
                            icon={<FilePdfOutlined />}
                        />
                        <Form layout='horizontal' className=''>
                            <Form.Item
                                name="fechas"
                                className="m-0 "
                            >
                                <RangePicker
                                    style={{ width: "80%" }}
                                    onChange={this.onChangeDates}
                                    defaultValue={this.state.fechas}
                                />
                            </Form.Item>
                        </Form>
                        <Button
                            onClick={() => this.setState({ visibleFilters: true })}
                            className="button-plus"
                            icon={<FilterOutlined style={{ color: '#000', fontWeight: 600 }} />} 
                        />
                    </Space>}
                >

                </PageHeader>

                <Content className="admin-content content-bg pd-1 mb-3">
                    <Row className="mb-2" gutter={[16,16]}>
                        <Col xs={24} lg={16}>
                            <Card 
                                title={<Title level={5}>Utilidad por mes {this.state.teorico ? "Teorico" : ""} &nbsp;
                                <Tooltip placement="bottomLeft" title={ this.state.teorico ? "Se toman en cuenta todos los productos sin importar si no tiene costo establecido, solo de los pedidos marcados como pagado" : 
                                "Solo se toman en cuenta los productos con un costo establecido, solo de los pedidos marcados como pagado"}><InfoCircleOutlined /></Tooltip></Title>}
                                
                                extra={<Space>
                                    <Select 
                                        options={this.renderOptions()} 
                                        size="small"
                                        defaultValue={dayjs().year()}
                                        onSelect={(year)=>this.getGrafica({year})}
                                    />
                                    <Switch onChange={(checked)=>this.setState({teorico: checked},()=>this.getGrafica())}/>
                                </Space>}
                            >
                                <Row>
                                    <Col span={24} className="center">
                                        <ReactECharts
                                            option={option}
                                            style={{ width: '100%', maxWidth: 800, height: "300px" }}
                                        />
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                        <Col xs={24} lg={8}>
                            <Row gutter={[0,16]}>
                                <Col span={24}>
                                    <Card
                                        title={<Text>Variación de utilidad mensual <Tooltip placement="bottomLeft" 
                                            title={"Solo se toman en cuenta los productos con costo establecido"}><InfoCircleOutlined />
                                            </Tooltip></Text>}
                                    >
                                        <Row>
                                            <Col span={24} className="center">
                                                <Space>
                                                    <Statistic
                                                        value={this.state.utilidad_porcentaje}
                                                        precision={2}
                                                        valueStyle={{ color: this.state.utilidad_porcentaje <= 0 ? "#cf1322" : '#3f8600' }}
                                                        prefix={this.state.utilidad_porcentaje <= 0 ? <ArrowDownOutlined/> : <ArrowUpOutlined /> }
                                                        suffix="%"
                                                    />
                                                    (<Statistic
                                                        value={this.state.utilidad_cambio}
                                                        precision={2}
                                                        valueStyle={{ color: this.state.utilidad_cambio <= 0 ? "#cf1322" : '#3f8600', fontSize: "12px" }}
                                                        prefix={this.state.utilidad_cambio <= 0 ? "" : "+" }
                                                        suffix="MXN"
                                                    />)
                                                </Space>
                                            </Col>
                                        </Row>
                                    </Card>
                                </Col>
                                <Col span={24}>
                                    <Card 
                                        title={<Text>Variación de utilidad mensual Teorica <Tooltip placement="bottomLeft" 
                                            title={"Se toman en cuenta todos los productos, sin importar si no tiene costo establecido"}><InfoCircleOutlined />
                                            </Tooltip></Text>}
                                    >
                                        <Row>
                                            <Col span={24} className="center">
                                                <Space>
                                                    <Statistic
                                                        value={this.state.utilidad_porcentaje_teorica}
                                                        precision={2}
                                                        valueStyle={{ color: this.state.utilidad_porcentaje_teorica <= 0 ? "#cf1322" : '#3f8600' }}
                                                        prefix={this.state.utilidad_porcentaje_teorica <= 0 ? <ArrowDownOutlined/> : <ArrowUpOutlined /> }
                                                        suffix="%"
                                                    />
                                                    (<Statistic
                                                        value={this.state.utilidad_cambio_teorica ?? 0}
                                                        precision={2}
                                                        valueStyle={{ color: this.state.utilidad_cambio_teorica <= 0 ? "#cf1322" : '#3f8600', fontSize: "12px" }}
                                                        prefix={this.state.utilidad_cambio_teorica <= 0 ? "" : "+" }
                                                        suffix="MXN"
                                                    />)
                                                </Space>
                                            </Col>
                                        </Row>
                                    </Card>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Title level={4} className="mb-1">Lista de Ordenes con utilidades <Tooltip placement="bottomLeft" title={"Todos los pedidos realizados, sin importar si no tiene costo establecido"}><InfoCircleOutlined /></Tooltip></Title>
                    <Row className="header-list width-100 pl-2 pr-1 mb-1">
                        <Col xs={12} md={3} className="center">
                            <Text strong>Pedido</Text>
                        </Col>
                        <Col xs={12} md={3} className="center">
                            <Text strong>Artículos</Text>
                        </Col>
                        <Col xs={12} md={4} className="center">
                            <Text strong>Monto Total</Text>
                        </Col>
                        <Col  xs={12} md={4}className="center">
                            <Text strong>Utilidad</Text>
                        </Col>
                        <Col  xs={12} md={3} className="center">
                            <Text strong>Estatus Pago</Text>
                        </Col>
                        <Col  xs={12} md={3} className="center">
                            <Text strong>Estatus Pedido</Text>
                        </Col>
                        <Col  xs={12} md={2} className="center">
                            <Text strong>Fecha</Text>
                        </Col>
                    </Row>
                    <Spin spinning={this.state.loading_ordenes}>
                        {this.state.ordenes?.data?.length === 0 ? <Empty/> : null}
                        <Collapse
                            className="collapse-vapemate"
                            loading={this.state.loading_ordenes}
                            items={this.state.ordenes?.data?.map((item, index) => ({
                                key: index,
                                label: <>
                                    <Row className="width-100"
                                        style={{ ...(item.shopify_cancelled_at) ? { background: "rgba(0,0,0,0.1)" } : {} }}
                                    >
                                        <Col xs={24} className="center">
                                            <Row gutter={[8, 8]} className="width-100">
                                                <Col xs={12} md={3} className="center hover"
                                                    onClick={(e) => {
                                                        e.stopPropagation()
                                                        this.setState({ modal_visible_detalle: true, orden_id: item._id })
                                                    }}
                                                    style={{ zIndex: 1000 }}
                                                >
                                                    <Space >
                                                        { !item.shopify_id && !item.shopify_draft_id ? <FaShopify style={{color: "red"}}/> : null}
                                                        <Text>{item.shopify_folio ?? item.folio}</Text>
                                                        {item.shopify_draft_id && !item.shopify_id ? <Tag color="magenta">Draft</Tag> : null}
                                                    </Space>
                                                </Col>
                                                <Col xs={12} md={3} className="flex-column">
                                                    <Text strong ellipsis>{item.total_productos ?? 0} Artículos</Text>
                                                </Col>
                                                <Col xs={12} md={4} className="center">
                                                    <Statistic
                                                        value={item.total}
                                                        prefix="$"
                                                        valueStyle={{
                                                            fontSize: '14px',
                                                            fontWeight: 'bold',
                                                            color: item.shopify_financial_status == "paid" ? '#2c9d37' : "#ecc602"
                                                        }}
                                                        precision={2}
                                                    />
                                                </Col>
                                                <Col xs={12} md={4} className="center">
                                                    <Statistic
                                                        value={item?.total_utilidad ?? 0}
                                                        precision={2}
                                                        valueStyle={{ color: item?.total_utilidad > 0 ? '#3f8600' : '#cf1322', fontSize: "14px", fontWeight: "bold"}}
                                                        prefix={ item?.total_utilidad > 0 ? <PlusOutlined /> : ""}
                                                        suffix="MXN"
                                                    />
                                                </Col>
                                                <Col xs={12} md={3} className="flex-column">
                                                    {renderEstatusOrdenPago(item.shopify_financial_status)}
                                                </Col>
                                                <Col xs={12} md={3} className="flex-column">
                                                    {renderEstatusOrdenEnvio(item.shopify_fulfillment_status)}
                                                </Col>
                                                <Col xs={12} md={2} className="center">
                                                    <Text>{dayjs(item.fecha).format("DD/MM/YY")}</Text>
                                                </Col>
                                                
                                                <Col xs={12} md={1} className="center">
                                                    <Tooltip
                                                        placement="topRight"
                                                        title={item.credito ? "Se utilizó crédito" : "No se utilizó crédito"}
                                                    >
                                                        <AiFillDollarCircle
                                                            style={{
                                                                color: item.credito ? "#ecc602" : "gray",
                                                                fontSize: "24px"
                                                            }}
                                                        />
                                                    </Tooltip>
                                                </Col>
                                                <Col xs={12} md={1}>
                                                    <Dropdown
                                                        placement="bottomRight"
                                                        menu={{
                                                            items: this.renderMenuItems(item)
                                                        }}
                                                    >
                                                        <Button
                                                            type="ghost"
                                                            onClick={() => this.setState({ modalTransacDetalleVisible: true, transaccion_id: item._id })}
                                                            icon={<MoreOutlined style={{ color: "black" }} />}
                                                        />
                                                    </Dropdown></Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </>,
                                children: [
                                    <Row className="width-100 mb-1">
                                        <Col xs={24}>
                                            <Row gutter={[8, 8]}>
                                                <Col xs={12} md={5} className="flex-left-column">
                                                    <Text ellipsis strong>PRODUCTO</Text>
                                                </Col>
                                                <Col xs={12} md={3} className="center">
                                                    <Text ellipsis>PRECIO</Text>
                                                </Col>
                                                <Col xs={12} md={3} className="center">
                                                    <Text ellipsis>CANTIDAD</Text>
                                                </Col>
                                                <Col xs={12} md={3} className="center">
                                                    <Text ellipsis>DESCUENTO</Text>
                                                </Col>
                                                <Col xs={12} md={3} className="center">
                                                    <Text ellipsis>TOTAL</Text>
                                                </Col>
                                                <Col xs={12} md={3} className="center">
                                                    <Text ellipsis>COSTO TOTAL</Text>
                                                </Col>
                                                <Col xs={12} md={4} className="center">
                                                    <Text ellipsis>UTILIDAD</Text>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>,
                                    <Divider />,
                                    ...item.ordenes_productos?.map(orden_producto => <Row className="width-100 mb-1">
                                        <Col xs={24}>
                                            <Row gutter={[8, 8]}>
                                                <Col xs={12} md={5} className="flex-left-column">
                                                    <Text ellipsis strong>{orden_producto?.producto_id?.modelo ?? "--"}</Text>
                                                    <Text ellipsis>{orden_producto?.variante_id?.sabor ?? "--"}</Text>
                                                </Col>
                                                <Col xs={12} md={3} className="center">
                                                    <Text ellipsis>$ {orden_producto?.precio?.toMoney(true) ?? '-'} MXN</Text>
                                                </Col>
                                                <Col xs={12} md={3} className="center">
                                                    <Text ellipsis>{orden_producto?.cantidad?.toMoney(true, 0) ?? 0}</Text>
                                                </Col>
                                                <Col xs={12} md={3} className="center">
                                                    <Text ellipsis className={`${orden_producto?.descuento_total > 0 ? "text-red" : null}`}>$ {(orden_producto?.descuento_total ?? orden_producto?.descuento)?.toMoney(true) ?? '0'} MXN</Text>
                                                </Col>
                                                <Col xs={12} md={3} className="center">
                                                    <Text ellipsis>$ {orden_producto?.total?.toMoney(true, 2) ?? 0} MXN</Text>
                                                </Col>
                                                <Col xs={12} md={3} className="center">
                                                    <Text ellipsis>$ {orden_producto?.costo_total?.toMoney(true) ?? '-'} MXN</Text>
                                                </Col>
                                                <Col xs={12} md={4} className="center">
                                                    <Statistic
                                                        value={orden_producto?.utilidad ?? 0}
                                                        precision={2}
                                                        valueStyle={{ color: orden_producto?.utilidad > 0 ? '#3f8600' : '#cf1322', fontSize: "14px", fontWeight: "bold"}}
                                                        prefix={ orden_producto?.utilidad > 0 ? <PlusOutlined /> : ""}
                                                        suffix="MXN"
                                                    />
                                                    
                                                </Col>

                                            </Row>
                                        </Col>
                                    </Row>)
                                ]
                            }))}
                        />
                    </Spin>
                </Content>

                <Pagination
                    className="mt-1"
                    showSizeChanger
                    current={this.state.ordenes?.page}
                    total={this.state.ordenes?.total}
                    pageSize={this.state.ordenes?.limit}
                    onChange={(page, limit) => this.getOrdenes({ page, limit })}
                />
                <ModalOrdenes
                    open={this.state.modal_visible}
                    onClose={(flag) => {
                        this.setState({ modal_visible: false, orden_id: undefined })
                        if (flag === true)
                            this.getOrdenes();
                    }}
                    orden_id={this.state.orden_id}
                />
                <ModalOrdenesDetalles
                    open={this.state.modal_visible_detalle}
                    onClose={(flag) => {
                        this.setState({ modal_visible_detalle: false, orden_id: undefined })
                    }}
                    orden_id={this.state.orden_id}
                />
                <ModalTransacciones
                    open={this.state.modal_transacciones}
                    onClose={(flag) => {
                        this.setState({ modal_transacciones: false, orden_id: undefined })
                    }}
                    orden_id={this.state.orden_id}
                    orden={this.state.orden}
                />
                <DrawerBitacora
                    open={this.state.drawer_bitacora}
                    onClose={() => {
                        this.setState({ drawer_bitacora: false, orden_id: undefined })
                    }}
                    orden_id={this.state.orden_id}
                />

                <ModalFulfillmentsDetalle
                    open={this.state.modal_visible_detalle_fulfillments}
                    onClose={(flag) => {
                        this.setState({ modal_visible_detalle_fulfillments: false, orden_id: undefined })
                    }}
                    orden_id={this.state.orden_id}
                />
                <DrawerFiltros
                    ref={this.drawerFiltros}
                    title="Filtrar Clientes"
                    updateFilters={filters => this.getOrdenes({
                        page: 1,
                        filters
                    })}
                    onClose={() => this.setState({ visibleFilters: false })}
                    visible={this.state.visibleFilters}
                    productos={true}
                    shopify_financial_status={[
                        {
                            _id: "pending",
                            label: "Pago Pendiente"
                        },{
                            _id: "partially_paid",
                            label: "Pago Parcial"
                        },{
                            _id: "paid",
                            label: "Pagado"
                        },{
                            _id: "voided",
                            label: "Pago Anulado"
                        }
                    ]}
                    shopify_fulfillment_status={
                        [
                            {
                                _id: "no_fulfilled",
                                label: "No Preparado"
                            },{
                                _id: "fulfilled",
                                label: "Envio Preparado"
                            }
                        ]
                    }
                />

            </Spin>
        )
    }
}


export default function (params) {

    const user = useContext(User);

    const permisos = usePermissions(user?.rol_id?.permisos, {
        canEdit: ["ordenes", "edit"],
        candDelete: ["ordenes", "delete"],
        canCreate: ["ordenes", "create"],
    });

    return <Ordenes {...params} params={useParams()} navigate={useNavigate()} {...permisos} />
}