import React, { Component } from "react";
import { Row, Col, Modal, Form, Input, message, Spin, } from 'antd';
import axios from "axios"

import SelectPais from "../../../Widgets/Inputs/SelectPais";
import SelectEstado from "../../../Widgets/Inputs/SelectEstado";
import PhoneInput from "../../../Widgets/Inputs/PhoneInput";



/**
 * @class ModalClientes
 * @description Modal para el CRUD de Clientes
 */
class ModalClientes extends Component {

	constructor(props) {
		super(props)
		this.state = {
			loading: false,
			asesores: [],
			estados: [],
			paises: []

		}
	}

	componentDidMount() {
		axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token')
		if (this.props.direccion) {
			this.formRef.current.setFieldsValue({
				...this.props.direccion,
				pais_id: {
					label: this.props.direccion?.pais_id?.nombre_en,
					value: this.props.direccion?.pais_id?._id,
				},
				estado_id: {
					label: this.props.direccion?.estado_id?.nombre,
					value: this.props.direccion?.estado_id?._id,
				}
			})
			this.setState({ pais_id: this.props.direccion?.pais_id?._id, })
		} else {
			this.getPais()
		}
	}

	formRef = React.createRef();

	/**
   * @method addUser
   * @description Obtener al cliente
   */
	getPais = () => {
		this.setState({ loading: true })
		axios.get('/pais', {
			params: {
				codigo: "MX"
			}
		})
			.then(({ data }) => {
				this.formRef.current.setFieldsValue({ pais_id: data._id })
				this.setState({ pais_id: data._id })
			}).catch(error => {
				message.error('Error al obtener el pais')
			}).finally(() => this.setState({ loading: false }))
	}


	/**
	* @method addDireccion
	* @description Añade una nueva direccion al cliente
	*/
	addDireccion = (values) => {
		this.setState({ loading: true })
		axios.post('/clientes/direccion', {
			...values,
			cliente_id: this.props.cliente_id,
		})
			.then(response => {
				message.success('Dirección creada')
				this.props.onClose(true)
			}).catch(error => {
				console.log("error", error);
				message.error(error.response?.message?.data ?? 'Error al crear la dirección')
			}).finally(() => this.setState({ loading: false }))
	}

	/**
	* @method updateDireccion
	* @description Actualiza una direccion del cliente
	*/
	updateDireccion = (values) => {

		this.setState({ loading: true })
		axios.put('/clientes/direccion', {
			...values,
			direccion_id: this.props.direccion_id
		})
			.then(response => {
				message.success('Dirección actualizada')
				this.props.onClose(true)
			}).catch(error => {
				console.log("error", error);
				message.error(error.response?.data?.message ?? 'Error al actualizar la dirección')
			}).finally(() => this.setState({ loading: false }))
	}

	/**
   * @method onFinish
   * @description Se ejecuta al hacer submit al formulario
   */
	onFinish = (values) => {

		values.cliente_id = this.props.cliente_id

		if (this.props.direccion_id) {
			this.updateDireccion(values)
		} else {
			this.addDireccion(values)
		}
	}


	render() {
		return (
			<Spin spinning={this.state.loading}>
				<Form
					className="mt-3"
					id="form-cliente"
					layout={"vertical"}
					onFinish={this.onFinish}
					ref={this.formRef}
				>
					<Row gutter={[8, 8]}>
						<Col span={12}>
							<Form.Item
								label="Nombre"
								name={'nombre'}
								rules={[{ required: true, message: 'Ingrese el nombre' }]}
							>
								<Input />
							</Form.Item>
						</Col>
						<Col span={12}>
							<Form.Item
								label="Apellido"
								name={'apellidos'}
								rules={[{ required: true, message: 'Ingrese el apellido' }]}
							>
								<Input />
							</Form.Item>
						</Col>
						<Col span={24}>
							<Form.Item
								label="Calle y número de casa"
								name={'calles'}
								rules={[{ required: true, message: 'Ingrese la calle' }]}
							>
								<Input />
							</Form.Item>
						</Col>
						<Col span={24}>
							<Form.Item
								label="Apartamento, local, etc."
								name={'numero'}
							//rules={[{ required: true, message: 'Ingrese la colonia' }]}
							>
								<Input />
							</Form.Item>
						</Col>
						<Col span={8}>
							<Form.Item
								label="Telefono"
								name={'telefono'}
							>
								<PhoneInput />
							</Form.Item>
						</Col>
						<Col span={8}>
							<Form.Item
								label="País"
								name={'pais_id'}
								rules={[{ required: true, message: 'País es requerido' }]}
							>
								<SelectPais
									defaultValue={this.state.initial_pais_id}
									onSelect={(pais_id) => this.setState({ pais_id })}
									allowClear={false}
								/>
							</Form.Item>
						</Col>
						<Col span={8}>
							<Form.Item
								label="Estado"
								name={'estado_id'}
								rules={[{ required: true, message: 'Estado' }]}
							>
								<SelectEstado pais_id={this.state.pais_id} />
							</Form.Item>
						</Col>
						<Col span={12}>
							<Form.Item
								label={"Ciudad"}
								name={'ciudad'}
								rules={[{ required: true, message: 'Ciudad es requerida' }]}
							>
								<Input />
							</Form.Item>
						</Col>
						<Col span={12}>
							<Form.Item
								label="Código Postal"
								name={'codigo_postal'}
								rules={[{ required: true, message: 'Código Postal es requerido' }]}
							>
								<Input />
							</Form.Item>
						</Col>
						<Col span={24}>
							<Form.Item
								label="Especificaciones"
								name={'especificaciones'}

							>
								<Input.TextArea />
							</Form.Item>
						</Col>
						<Col span={24}>
							<Form.Item
								label="Notas"
								name={'notas'}
							>
								<Input.TextArea />
							</Form.Item>
						</Col>
					</Row>
				</Form>
			</Spin>
		)
	}


}



export default function Modals(props) {

	const { visible = false, onClose = () => { }, direccion_id } = props

	return <Modal
		visible={visible}
		onCancel={onClose}
		title={direccion_id ? "Editar Dirección" : "Crear Dirección"}
		closable={true}
		destroyOnClose={true}
		zIndex={1000}
		cancelText="Cancelar"
		okText="Aceptar"
		okButtonProps={{ form: 'form-cliente', key: 'submit', htmlType: 'submit' }}
		width={700}
	>
		<ModalClientes  {...props} />
	</Modal>

}