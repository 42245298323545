import React, { Component } from "react";
import { Row, Col, Form, InputNumber, Input, Button, DatePicker } from 'antd';
import { MinusOutlined, } from "@ant-design/icons"


/**
 *
 *
 * @class ItemRegla
 * @extends {Component}
 */
export default class ItemRegla extends Component {

    constructor(props) {
        super(props);
        this.state = {

        }
    }

    componentDidMount() {

    }

    renderMessages = (error) => {
        return <span>{error ?? ""}</span>;
    }

    render() {
        const { name,
            key,
            tipo,
            antiguedad_meses,
            antiguedad_fecha,
            index,
            errores,
            remove,
            regla } = this.props;

        return (
            <React.Fragment key={index}>
                <Row align="center" className="width-100 mt-05" gutter={[8, 8]} >
                    <Form.Item
                        name={[name, '_id']}
                        noStyle
                    >
                        <Input type="hidden" />
                    </Form.Item>
                    <Form.Item
                        name={[name, 'antiguedad_fecha']}
                        shouldUpdate

                        noStyle
                        initialValue={antiguedad_fecha}
                    >
                        <Input type="date" style={{ display: 'none' }} />
                    </Form.Item>


                    <Col xs={10} className="">
                        <Form.Item
                            name={[name, 'limite_inferior']}
                            initialValue={0}
                            validateStatus={(errores[index] != null && Object.entries(errores[index]).length > 0) ? "error" : "sucess"}
                            help={this.renderMessages(errores[index]?.limite_inferior)}
                        >
                            <InputNumber
                                prefix={"$"}
                                min={0}
                                placeholder="0"
                                className="width-100"
                                formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                parser={value => value.replace(/\$\s?|(,*)/g, '')}
                            />

                        </Form.Item>
                    </Col>
                    <Col xs={10} className="">
                        <Form.Item
                            name={[name, 'limite_superior']}
                            initialValue={1}
                            validateStatus={(errores[index] != null && Object.entries(errores[index]).length > 0) ? "error" : "sucess"}
                            help={this.renderMessages(errores[index]?.limite_superior)}
                        >
                            <InputNumber
                                prefix={"$"}
                                min={1}
                                placeholder="0"
                                className="width-100"
                                formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                parser={value => value.replace(/\$\s?|(,*)/g, '')}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={4} className="text-center ">
                        <Button
                            icon={<MinusOutlined />}
                            onClick={() => { this.props.remove(name) }}
                            type="primary"
                            shape="circle"
                            danger
                            size="small"
                            title="Eliminar Regla" />

                    </Col>
                </Row>

            </React.Fragment >
        )
    }
}