import React, { Component } from "react";
import { Row, Col, Modal, Form, Input, message, Spin, Select, InputNumber, Button, Typography } from 'antd';
import axios from "axios"
import { LinkOutlined, MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";

const { Text, Link } = Typography


/**
 * @class ModalOrdenes
 * @description Modal para el CRUD de Productos
 */
class ModalOrdenes extends Component {

    constructor(props) {
        super(props)
        this.state = {
            loading: false
        }
    }

    componentDidMount() {
    
    }

    formRef = React.createRef();


    /**
    * @method onFinish
    * @description Se ejecuta al hacer submit al formulario
    */
    onFinish = (values) => {

        this.setState({ loading: true })
        axios.post('/shopify/fulfillment',{
        	orden_id: this.props.orden_id,
        	...values
        }).then(response => {
        	message.success("Pedido preparado")
        	this.props.onClose(true)
        }).catch(error => {
        	console.log("error", error);
        	message.error("Error al preparar el pedido")
        }).finally(()=>this.setState({loading: false}))
    }


    render() {

         
        return (
            <Spin spinning={this.state.loading}>
                <Form
                    id="form-envio"
                    layout={"vertical"}
                    className="mt-2"
                    onFinish={this.onFinish}
                    ref={this.formRef}
                    initialValues={{
                    }}
                >
                    <Row justify="center" className="center" gutter={[8,0]}>
                        <Col span={22}>
                            <Form.Item
                                name="tracking_number"
                                label="Clave de rastreo"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Ingrese la clave de rastreo',
                                    },
                                ]}
                            >
                                <Input/>
                            </Form.Item> 
                        </Col> 
                        <Col span={22}>
                            <Form.Item
                                name="company"
                                label="Compañia"
                            >
                                <Input/>
                            </Form.Item> 
                        </Col> 
                        <Col span={22}>
                            <Form.Item
                                name="url"
                                label="URL"
                            >
                                <Input/>
                            </Form.Item> 
                        </Col> 
                    </Row>
                </Form>
            </Spin>
        )
    }


}



export default function Modals(props) {

    const { open = false, onClose = () => { }, orden_id } = props

    return <Modal
        open={open}
        onCancel={onClose}
        title={"Preparar de Envío"}
        closable={true}
        destroyOnClose={true}
        zIndex={1000}
        cancelText="Cancelar"
        okText="Guardar"
        okButtonProps={{ form: 'form-envio', key: 'submit', htmlType: 'submit' }}
    >
        <ModalOrdenes  {...props} />
    </Modal>

}   