import React, { Component } from 'react';
import { Row, Col, Typography, Button, Popconfirm, Modal, Space, message, Card, List, Tag } from 'antd'
import { MailOutlined } from '@ant-design/icons';
import axios from 'axios';

import Logged, { User } from "../../../../Hooks/Logged";
import FloatingButton from "../../../Widgets/Floating Button/FloatingButton";

import ModalUsuarios from '../../Usuarios/ModalUsuarios'
import { ButtonDelete, ButtonEdit } from '../../../Widgets/Buttons';
import usePermissions from '../../../../Hooks/usePermissions';
import CustomAvatar from '../../../Widgets/Avatar/Avatar';


const { Text } = Typography

/**
 *
 *
 * @export
 * @class Usuarios
 * @extends {Component}
 * @description Vista donde se listan todos los usuarios
 */
class Usuarios extends Component {
    static contextType = Logged;

    back = () => {
        this.props.history.goBack();
    }


    /**
     *Creates an instance of Usuarios.
     * @param {*} props
     * @memberof Usuarios
     */
    constructor(props) {
        super(props)
        this.state = {

            loading: false,
            usuarios: {
                data: [],
                limit: 10,
                page: 1,
                total: 0,
                pages: 0,
                filters: []
            },
            usuario_id: null
        }
    }

    componentDidMount() {

        this.getUsuarios()
    }

    componentDidUpdate(prevProps) {
        if (this.props.search != prevProps.search) {
            this.getUsuarios()
        }
    }

    /**
     * @methodOf  Usuarios
     * @method sendEmail
     *
     * @description Envia un correo para recuperar la contraseña
     *
     * */
    sendEmail = (email) => {
        this.setState({ loading: true })
        axios.put("/password/recovery", { email: email })
            .then(res => {
                Modal.success({
                    title: 'Restablecer contraseña.',
                    content: '¡El correo ha sido enviado!'
                })
            })
            .catch(res => {
                console.log(res)
                Modal.warning({
                    title: 'Error',
                    content: 'El correo no se ha podido enviar. Verifica que el correo sea correcto'
                });
            })
            .finally(() => {
                this.setState({
                    loading: false
                })
            })

    }

    /**
    *
    * @memberof RazonesSociales
    * @method getRS
    * @description Obtiene la informacion de los Empresas y actualiza los valores locales de la lista.
    */
    getUsuarios = async ({
        page = this.state.usuarios.page,
        limit = this.state.usuarios.limit,
        search = this.props.search,
    } = this.state.usuarios) => {

        this.setState({ loading: true })
        axios.get('/usuarios', {
            params: {
                limit,
                page,
                search,
                cliente_id: this.props.cliente_id
            }
        })
            .then(({ data }) => {
                this.setState({ usuarios: data })
            })
            .catch(res => {
                message.error(res.response.data.message)
            })
            .finally(() => this.setState({ loading: false }))
    }




    render() {

        return (
            <>
                <List
                    loading={this.state.loading}
                    className="component-list"
                    itemLayout="horizontal"
                    locale={{ emptyText: "Sin Usuarios" }}
                    dataSource={this.state.usuarios.data}
                    pagination={{
                        current: this.state.usuarios.page,
                        pageSize: this.state.usuarios.limit,
                        total: this.state.usuarios.total,
                        hideOnSinglePage: false,
                        position: 'bottom',
                        onChange: (page, limit) => this.getUsuarios({ page, limit })

                    }}
                    header={<Row className="component-list-header width-100 pr-1 pl-1" >
                        <Col span={1} className="center">
                        </Col>
                        <Col span={8} className="center">
                            <Text strong>NOMBRE</Text>
                        </Col>
                        <Col span={6} className="center">
                            <Text strong>Email</Text>
                        </Col>
                        <Col span={5} className="center">
                            <Text strong>ROL</Text>
                        </Col>

                    </Row>
                    }

                    renderItem={item => (
                        <List.Item className="component-list-item">
                            <Card className="card-list">
                                <Row className="width-100" gutter={[8, 8]}>
                                    <Col xs={12} md={1} className="center">
                                        <CustomAvatar
                                            name={item.nombre}
                                            url={item.avatar ? {
                                                url: axios.defaults.baseURL + '/usuarios/' + item._id,
                                                avatar: item.avatar?.name,
                                                logo: item.avatar.file
                                            } : null}
                                        />
                                    </Col>
                                    <Col xs={12} md={8} className="center">
                                        <Text strong ellipsis>{item.nombre} {item.apellidos}</Text>
                                    </Col>
                                    <Col xs={12} md={6} className="center">
                                        <Text className="text-gray">{item.email}</Text>
                                    </Col>
                                    <Col xs={12} md={5} className="center">
                                        <Tag color="#FF5500">{item.rol_id?.nombre}</Tag>
                                    </Col>
                                    <Col xs={12} md={3} className="flex-right">
                                        <Space>
                                            <Popconfirm
                                                placement="topRight"
                                                title="¿Deseas enviar un correo de recuperación de contraseña?" okText="Si" cancelText="No"
                                                onConfirm={() => this.sendEmail(item.email)}
                                            >
                                                <Button
                                                    disabled={!this.props.canRecovery}
                                                    type="primary"
                                                    icon={<MailOutlined />}
                                                    title="Recupración de contraseña"
                                                />
                                            </Popconfirm>
                                            <ButtonEdit disabled={!this.props.canEdit} onClick={() => this.setState({ modalUsuarios: true, usuario_id: item._id })} />
                                            <ButtonDelete disabled={!this.props.canDelete} onConfirm={() => axios.delete('/usuarios/', { params: { usuario_id: item._id } }).then((response) => {
                                                message.success(response?.data?.message)
                                                this.getUsuarios()
                                            })
                                                .catch((error) => {
                                                    message.error(error?.response?.data?.message);
                                                    this.getUsuarios();
                                                })
                                            } />

                                        </Space>
                                    </Col>
                                </Row>
                            </Card>
                        </List.Item>
                    )}
                />


                {this.props.canCreate && <FloatingButton title="Nuevo registro" onClick={() => this.setState({ modalUsuarios: true })} />}

                <ModalUsuarios
                    visible={this.state.modalUsuarios}
                    usuario_id={this.state.usuario_id}
                    cliente_id={this.props.cliente_id}
                    cliente={true}
                    onClose={() => {
                        this.setState({ modalUsuarios: false, usuario_id: undefined })
                        this.getUsuarios()
                    }}
                />

            </>
        )
    }
}

export default function (props) {

    const user = React.useContext(User)
    const permisos = usePermissions(user?.rol_id?.permisos, {
        canEdit: ['usuarios', 'edit'],
        canDelete: ['usuarios', 'delete'],
        canCreate: ['usuarios', 'create'],
        canRecovery: ['usuarios', 'recovery'],
    })

    return <Usuarios {...props} {...permisos} />
}