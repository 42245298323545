import React from 'react'
import { Route, Routes } from "react-router-dom";

import Compras from '../../Components/Admin/Compras/Compras';

/**
 * 
 * @export
 * @function RouterCompras
 * @description Router for Compras routes 
 */
function RouterCompras(props) {
  return (
    <Routes>
      <Route path="" element={<Compras {...props} />} />
    </Routes>
  )
}

export default RouterCompras