import React from 'react'
import { Route, Routes } from "react-router-dom";

import Proveedores from '../../Components/Admin/Proveedores/Proveedores';

/**
 * 
 * @export
 * @function RouterProveedores
 * @description Router for Proveedores routes 
 */
function RouterProveedores(props) {
  return (
    <Routes>
      <Route path="" element={<Proveedores {...props} />} />
    </Routes>
  )
}

export default RouterProveedores