import React, { Component, useContext } from "react";
import { Row, Col, Spin, Layout, message, Space, List, Typography,Card, } from "antd";
import axios from "axios";
import { CopyOutlined } from '@ant-design/icons';
//Componentes
import User from "../../../Hooks/Logged";
import usePermissions from "../../../Hooks/usePermissions";
import PageHeader from "../../Widgets/PageHeader/PageHeader";
import { ButtonDelete, ButtonEdit } from "../../Widgets/Buttons";
import FloatingButton from "../../Widgets/Floating Button/FloatingButton";

//modales
import ModalCSV from "./ModalCSV";
import ModalProveedores from "./ModalProveedores";

const { Content } = Layout;
const { Text } = Typography;

/**
 * @export
 * @class Proveedores
 * @extends {Component}
 * @description Vista de Proveedores
 */
class Proveedores extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            modalVisible: false,

            proveedor_id: undefined,

            proveedores: {
                data: [],
                limit: 10,
                page: 1,
                total: 0,
                pages: 0,
            },
        };
    }

    componentDidMount() {
        this.get();
    }

    /**
     *
     *
     * @memberof Proveedores
     *
     * @method get
     * @description Obtiene los Proveedores
     */
    get = (
        {
            page = this.state.proveedores.page,
            limit = this.state.proveedores.limit,
            search = this.props.search,
        } = this.state.proveedores
    ) => {
        this.setState({ loading: true });
        axios
            .get("/proveedores", {
                params: {
                    page,
                    limit,
                    search,
                },
            })
            .then(({ data }) => {
                this.setState({ proveedores: data });
            })
            .catch((error) => {
                console.log(error);
                message.error("Error al traer la información");
            })
            .finally(() => this.setState({ loading: false }));
    };

    delete = (id) => {
        axios.delete(`/proveedores/${id}`)
            .then(() => {
                message.success("Proveedor eliminado");
                this.get();
            }).catch((error) => console.log(error))
    }

    render() {
        return (
            <>
                <Spin spinning={false}>
                    <PageHeader
                        className="admin-page-header"
                        title="Proveedores"
                    />

                    <Content className="admin-content content-bg pd-1">
                        <List
                            loading={this.state.loading}
                            className="component-list"
                            itemLayout="horizontal"
                            locale={{ emptyText: "Sin Proveedores" }}
                            dataSource={this.state.proveedores.data}
                            pagination={{
                                current: this.state.proveedores.page,
                                pageSize: this.state.proveedores.limit,
                                total: this.state.proveedores.total,
                                hideOnSinglePage: false,
                                showSizeChanger: true,
                                position: "bottom",
                                className: "flex-left",
                                onChange: (page, limit) =>
                                    this.get({ page, limit }),
                            }}
                            header={
                                <Row className="width-100 pr-1 pl-1 component-list-header">
                                    <Col span={10} className="center">
                                        <Text strong>Nombre</Text>
                                    </Col>
                                    <Col span={10} className="center">
                                        <Text strong>Razon Social</Text>
                                    </Col>
                                    <Col span={4} className="center">
                                        <Text strong>Acciones</Text>
                                    </Col>
                                </Row>
                            }
                            renderItem={(item) => {
                                return (
                                    <List.Item className="component-list-item">
                                        <Card className="card-list">
                                            <Row className="width-100" gutter={[6, 6]}>
                                                <Col xs={24} md={10} className="center" >
                                                    <Text ellipsis strong> {item.nombre}</Text>
                                                </Col>
                                                <Col xs={24} md={10} className="center" >
                                                    <Text>{item.razon_social}</Text>
                                                </Col>
                                                <Col xs={24} md={4} className="center" >
                                                    <Space>
                                                        <ButtonEdit
                                                            title="Editar"
                                                            disabled={!this.props.cantEdit}
                                                            onClick={() => {
                                                                this.setState(
                                                                    {
                                                                        modalVisible: true,
                                                                        proveedor_id: item._id,
                                                                    }
                                                                );
                                                            }}
                                                        />
                                                        <ButtonDelete
                                                            disabled={!this.props.canDelete}
                                                            title="Eliminar"
                                                            onConfirm={() => this.delete(item._id)}
                                                        />
                                                    </Space>
                                                </Col>
                                            </Row>
                                        </Card>
                                    </List.Item>
                                );
                            }}
                        />
                    </Content>

                    {this.props.canCreate ? <> <FloatingButton
                        title="Nuevo registro"
                        onClick={() => this.setState({ modalVisible: true })}
                    />
                        <FloatingButton
                            buttonStyle={{
                                width: "40px",
                                height: "40px",
                                bottom: "42px",
                                right: "125px",
                            }}
                            icon={<CopyOutlined style={{ color: "white", fontSize: "20px", left: -6, top: 2, position: "relative" }} />}
                            title="Crear productos por CSV"
                            onClick={() => this.setState({ modalCSV: true })}

                        />
                    </> : null}
                    <ModalProveedores
                        visible={this.state.modalVisible}
                        onClose={() => {
                            this.setState({
                                modalVisible: false,
                                proveedor_id: undefined,
                            });
                            this.get();
                        }}
                        proveedor_id={this.state.proveedor_id}
                    />
                </Spin>
                <ModalCSV
                    visible={this.state.modalCSV}
                    onClose={() => this.setState({ modalCSV: false })}
                    onSuccess={(closeModal) => {
                        this.get();

                        if (closeModal)
                            this.setState({ modalCSV: false, })
                    }}
                />
            </>
        );
    }
}

export default function Vista(props) {

    const user = useContext(User);

    const permisos = usePermissions(user?.rol_id?.permisos, {
        cantEdit: ["proveedores", "edit"],
        canDelete: ["proveedores", "delete"],
        canCreate: ["proveedores", "create"],
    });

    return <Proveedores {...props} {...permisos} />

}
