import React, { Component, useContext } from 'react';
import { Row, Col, Collapse, Form, Spin, Layout, message, Select, List, Card, Typography, InputNumber, Image, Tag, Pagination } from 'antd'

import axios from 'axios';
import { FaShopify } from 'react-icons/fa';
import { CopyOutlined } from "@ant-design/icons"
import { useParams, useNavigate } from "react-router-dom";

//Componentes
import User from "../../../Hooks/Logged";
import useSearch from '../../../Hooks/useSearch';
import ImageProducto from '../../Widgets/ImageProducto';
import TableInventarioVariantes from './TableInventario';
import usePermissions from "../../../Hooks/usePermissions";
import PageHeader from '../../Widgets/PageHeader/PageHeader';
import FloatingButton from "../../Widgets/Floating Button/FloatingButton";

//Modales
import ModalCSV from './ModalCSV';
import ModalInventarios from './ModalInventarios'


const { Content } = Layout;
const { Text } = Typography
const { Option } = Select
/**
 * @export
 * @class Inventarios
 * @extends {Component}
 * @description Vista de Inventarios
 */
class Inventarios extends Component {

    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            modalVisible: false,
            modalCSV: false,

            filtroSearch: '',
            searching: true,
            almacen_id: this.props.params?.almacen_id,
            inventarios: {
                data: [],
                page: 1,
                limit: 10,
                total: 0,
                pages: 0,
            },

            almacenes: {
                data: [],
                page: 1,
                limit: 100,
                total: 0,
                pages: 0,
            }
        }
    }

    refForm = React.createRef();
    refTable = React.createRef();
    componentDidMount() {
        this.props.setShowSearch(true)
        this.getAlmacenes()
    }

    componentDidUpdate(prevProps) {
        if (this.props.search !== prevProps.search) {
            this.getInventarios({ page: 1, search: this.props.search })
        }
    }

    componentWillUnmount() {
        this.props.setShowSearch(false)
    }

    /**
     *
     *
     * @memberof Inventarios
     * 
     * @method getInventarios
     * @description Obitiene las Inventarios, solo los productos, sin variantes para agilizar la consulta
     */
    getInventarios = ({
        page = this.state.inventarios.page,
        limit = this.state.inventarios.limit,
        search = this.props.search,
        almacen_id = this.state.almacen_id
    } = this.state.inventarios) => {
        this.setState({ loading: true })

        axios.get('/inventarios', {
            params: { 
                limit, 
                page, 
                search, 
                almacen_id, 
                only_products: true, 
            }
        })
        .then(({ data }) => {
            this.setState({ inventarios: data })
            const nuevaUrl = `?almacen_id=${almacen_id}`;
            this.props.navigate(nuevaUrl);
        })
        .catch(res => {
            message.error(res.response.data ?? "No fue posible obtener el inventario")
        })
        .finally(() => this.setState({ loading: false }))
    }

    /**
       *
       *
       * @memberof Inventarios
       * 
       * @method getAlmacenes
       * @description Obitiene los almacenes para el filtro
       */
    getAlmacenes = ({
        page,
        limit,
        search,
    } = this.state.almacenes) => {
        axios.get('/almacenes', {
            params: {
                page,
                limit,
                search,
            },
            headers: { Authorization: sessionStorage.getItem('token') }
        }).then(({ data }) => {

            let default_selected = data.data[0]?._id;

            this.setState({
                almacenes: data,
                almacen_id: default_selected
            })
            this.refForm.current.setFieldsValue({ almacen_id: default_selected })
            this.getInventarios({ almacen_id: default_selected })

            const nuevaUrl = `?almacen_id=${default_selected}`;
            this.props.navigate(nuevaUrl);

        }).catch(error => {
            console.log("ERR, error", error)
            message.error(error?.response?.data?.message ?? 'Error al obtener los almacenes')
        })
    }


    render() {

        return (
            <>
                <Spin spinning={this.state.loading}>
                    <PageHeader
                        className="admin-page-header"
                        title="Inventario"
                        extra={<>
                            <Form className='width-100  ' ref={this.refForm}>
                                <Form.Item name="almacen_id" >
                                    <Select placeholder="Almacen"
                                        allowClear={false}
                                        style={{ width: 200 }}
                                        onSearch={(e) => this.getAlmacenes({ search: e })}
                                        onChange={(e) => {

                                            this.getInventarios({ almacen_id: e, page: 1 })
                                            this.setState({ almacen_id: e })

                                        }}>
                                        {this.state.almacenes.data.map(almacen => <Option key={almacen._id} value={almacen._id}>{almacen.nombre}</Option>)}
                                    </Select>
                                </Form.Item>
                            </Form>

                        </>}
                    />
                    <Content className="admin-content content-bg pd-1">
                        <Collapse
                            destroyInactivePanel={true}
                            items={this.state.inventarios?.data?.map((item, index) => ({
                                key: index,
                                label: <>
                                    <Row className="width-100">
                                        <Col xs={21} className="center">
                                            <Row gutter={[8, 8]} className="width-100">
                                                <Col xs={12} md={3} className="center">
                                                    <ImageProducto producto={item.producto_id}/>
                                                </Col>
                                                <Col xs={12} md={6} className="flex-left">
                                                    <Text strong ellipsis> {item.producto_id?.shopify_id ? <FaShopify /> : null} {item.producto_id?.modelo ?? '-'}</Text>
                                                </Col>
                                                <Col xs={12} md={6} className="center">
                                                    <Text strong ellipsis>{item.producto_id?.marca ?? '-'}</Text>
                                                </Col>

                                                <Col xs={12} md={4} className="center">
                                                    <Text className="text-gray">{item.producto_id?.active ? <Tag color="green">Activo</Tag> : <Tag color="volcano">Desactivado</Tag>}</Text>
                                                </Col>
                                                <Col xs={12} md={4} className="center">
                                                    <Text className="text-gray">{item.almacen_id?.nombre}</Text>
                                                </Col>
                                            </Row>
                                        </Col>

                                    </Row>
                                </>,
                                children: <TableInventarioVariantes
                                    ref={this.refTable}
                                    id={item.producto_id._id}
                                    key={index}
                                    canEdit={this.props.candEdit}
                                    canDelete={this.props.canDelete}
                                    producto_id={item.producto_id._id}
                                    almacen_id={this.state.almacen_id}
                                />,
                            }))}
                        />


                        <Pagination
                            className="mt-1"
                            showSizeChanger
                            current={this.state.inventarios?.page}
                            total={this.state.inventarios?.total}
                            pageSize={this.state.inventarios?.limit}
                            onChange={(page, limit) => this.getInventarios({ page, limit })}
                        />


                    </Content >

                    {this.props.canCreate &&
                        <FloatingButton title="Nuevo registro" onClick={() => this.setState({ modalVisible: true })} />}
                    {this.props.canCreate && <FloatingButton
                        buttonStyle={{
                            width: "40px",
                            height: "40px",
                            bottom: "42px",
                            right: "125px",
                        }}
                        icon={<CopyOutlined style={{ color: "white", fontSize: "20px", left: -6, top: 2, position: "relative" }} />}
                        title="Crear inventarios por CSV"
                        onClick={() => this.setState({ modalCSV: true })}

                    />}

                    <ModalInventarios
                        visible={this.state.modalVisible}
                        onClose={() => {
                            this.getInventarios()
                            this.setState({ modalVisible: false, inventario_id: null })

                        }}
                        almacen_id={this.state.almacen_id}
                        inventario_id={this.state.inventario_id}
                    />

                    <ModalCSV
                        visible={this.state.modalCSV}
                        onClose={() => {
                            this.setState({
                                modalCSV: false,
                            })
                        }}

                        onSuccess={(closeModal) => {
                            this.getInventarios();
                            if (closeModal)
                                this.setState({ modalCSV: false, })
                        }}
                    />

                </Spin >
            </>
        )
    }
}

export default function Vista(props) {

    const user = useContext(User);
    const { search, setShow } = useSearch()
    const params = useParams()
    const navigate = useNavigate()
    const permisos = usePermissions(user?.rol_id?.permisos, {
        candEdit: ["inventarios", "edit"],
        canDelete: ["inventarios", "delete"],
        canCreate: ["inventarios", "create"],
    })

    return <Inventarios
        {...props}
        {...permisos}
        params={params}
        search={search}
        setShowSearch={setShow}
        navigate={navigate} />

}