import React, { Component } from "react";
import {
  Row,
  Col,
  Space,
  Modal,
  List,
  Typography,
  Form,
  DatePicker,
  Input,
  InputNumber,
  Checkbox,
  Select,
  message,
  Spin,
  Cascader,
  Tag,
  AutoComplete,
  Divider,
} from "antd";
import axios from "axios";

import CustomAvatar from "../../../Widgets/Avatar/Avatar";
import { ImagesUploader } from "../../../Widgets/Uploaders";
import SelectAreas from "../../../Widgets/Inputs/SelectAreas";
import SelectRubros from "../../../Widgets/Inputs/SelectRubros";
import SelectCuentas from "../../../Widgets/Inputs/SelectCuentas";
import SelectClientes from "../../../Widgets/Inputs/SelectClientes";
import SelectOrdenes from "../../../Widgets/Inputs/SelectOrdenes";

const { Title, Text, Paragraph } = Typography;
const { Option } = Select;
const moment = require("moment");

const Decimal = require("decimal.js");

class ModalTransacciones extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      seleccionadas: Object.values(this.props.seleccionadas || {}),

      filters: {},
      tipo_cambio: 0,
      cuentaSeleccionada: "",
      disabled: false,
      transaccion: {},
      cliente_id: undefined,
      concepto_unico: false,
    };
  }

  modalRef = React.createRef();

  componentDidMount() {
    console.clear();
    console.log(Object.values(this.props.seleccionadas));
  }

  /**
   * @memberof ModalTransacciones
   * @method onFinish
   * @description Se ejecuta al dar enter al formulario
   *
   */
  onFinish = (values) => {
    if (values.area_id?.value) values.area_id = values.area_id.value;
    if (values.rubro_id?.value) values.rubro_id = values.rubro_id.value;
    if (values.cliente_id?.value) values.cliente_id = values.cliente_id.value;
    if (values.cuenta_id?.value) values.cuenta_id = values.cuenta_id.value;
    values.tipo_cambio = values.tipo_cambio?.toMoney() || 0;

    values.transacciones = this.state.seleccionadas;

    this.addTransacciones(values);
  };

  /**
   * @memberof ModalTransacciones
   * @method getTipoCambio
   * @description Trae el ulitmo tipo de cambio utilizado
   *
   */
  getTipoCambio = () => {
    this.modalRef.current?.setFieldsValue({
      tipo_cambio: 19.5,
    });
  };

  /**
   * @memberof ModalTransacciones
   * @method addTransacciones
   * @description Crea nuevas transacciones con las seleccionadas obtenidas de BXNK
   *
   */
  addTransacciones = (values) => {
    this.setState({ loading: true });
    axios
      .post("/bxnk/transacciones", values)
      .then((response) => {
        message.success("Transacciones clasificadas");
        this.props.onClose(true);
      })
      .catch((error) => {
        console.log(error);
        message.error("Error al traer la información");
      })
      .finally(() => this.setState({ loading: false }));
  };

  render() {
    const { disabled } = this.state;
    const { clasificacion } = this.props;
    let { seleccionadas } = this.state;

    return (
      <Form
        layout="vertical"
        name="form-transaccion"
        id="form-transaccion"
        ref={this.modalRef}
        onFinish={this.onFinish}
        initialValues={{
          cuenta_id: this.props.cuenta
            ? {
                value: this.props.cuenta?._id,
                label: this.props.cuenta?.label,
              }
            : null,
        }}
      >
        <Spin spinning={this.state.loading}>
          <Divider className="modal-divider">
            Transacciones a clasificar
          </Divider>
          <Row>
            <List
              className="width-100"
              itemLayout="horizontal"
              dataSource={seleccionadas || []}
              renderItem={(item, index) => (
                <List.Item className="width-100" style={{ display: "block" }}>
                  <Row style={{ width: "100%", gap: 20 }}>
                    <Form.Item
                      label="Concepto"
                      style={{ flex: 1, marginBottom: "10px" }}
                    >
                      <Input
                        value={item.concepto}
                        style={{ width: "100%" }}
                        onChange={(e) => {
                          console.log(this.state.seleccionadas);
                          this.setState((state) => {
                            state.seleccionadas[index].concepto =
                              e.target.value;
                            return state;
                          });
                        }}
                      />
                    </Form.Item>
                    <div style={{ textAlign: "right", marginTop: "20px" }}>
                      <strong>
                        {moment(item.fecha_operacion).format("YYYY-MM-DD")}
                      </strong>
                      <p>
                        {item.tipo == 1 ? "" : "-"}{" "}
                        {Decimal(item.monto)
                          .toNumber()
                          .toLocaleString("en-US", {
                            style: "currency",
                            currency: "USD",
                          })}
                        <small>
                          <strong> MXN</strong>
                        </small>
                      </p>
                    </div>
                  </Row>
                </List.Item>
              )}
            />
          </Row>
          <Divider className="modal-divider">
            Información de las Transacciones
          </Divider>

          <Row gutter={[16, 0]}>
            <Col xs={24} lg={12}>
              <Form.Item label="Area" name="area_id" required>
                <SelectAreas
                  onSelect={(area_id) => this.setState({ area_id })}
                />
              </Form.Item>
            </Col>
            <Col xs={24} lg={12}>
              <Form.Item label="Rubro" name="rubro_id" required>
                <SelectRubros
                  params={{
                    area_id: this.state.area_id,
                  }}
                />
              </Form.Item>
            </Col>

            <Col xs={24} lg={12}>
              <Form.Item label="Cliente" name="cliente_id">
                <SelectClientes
                  onSelect={(cliente_id) => this.setState({ cliente_id })}
                />
              </Form.Item>
            </Col>
            <Col xs={24} lg={12}>
              <Form.Item label="Cuenta" name="cuenta_id" required>
                <SelectCuentas disabled={this.props.cuenta} />
              </Form.Item>
            </Col>

            <Col xs={24} lg={12}>
              <Form.Item
                label="Pedidos"
                name="ordenes_id"
              >
                <SelectOrdenes
                  mode="multiple"
                  params={{
                    cliente_id: this.state.cliente_id,
                    pendientes: true
                  }}
                />
              </Form.Item>
            </Col>

            <Col xs={24} lg={12}>
              <Form.Item
                label="Tipo Cambio"
                name="tipo_cambio"
                rules={[
                  {
                    required: true,
                    message: "Por favor, ingrese monto",
                  },
                ]}
              >
                <InputNumber
                  min={1}
                  defaultValue={0}
                  className="width-100"
                  disabled={disabled}
                  // disabled={this.props.transaccion_id}
                  formatter={(value) =>
                    `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                  }
                  parser={(value) =>
                    value ? value.replace(/\$\s?|(,*)/g, "") : 0
                  }
                />
              </Form.Item>
            </Col>
          </Row>
        </Spin>
      </Form>
    );
  }
}

export default function (props) {
  const {
    visible = false,
    onClose = () => {},
    transaccion_id,
    clasificacion,
  } = props;

  return (
    <Modal
      open={visible}
      onCancel={onClose}
      title={"Clasificar Transacciones"}
      closable={true}
      maskClosable={true}
      destroyOnClose={true}
      zIndex={1000}
      okButtonProps={{
        form: "form-transaccion",
        key: "submit",
        htmlType: "submit",
      }}
    >
      <ModalTransacciones {...props} />
    </Modal>
  );
}
