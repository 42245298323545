import React, { Component } from "react";
import { Row, Col, Modal, Form, Input, message, Spin, Switch, Space } from 'antd';
import axios from "axios"
import PhoneInput from '../../Widgets/Inputs/PhoneInput';
import SelectPais from "../../Widgets/Inputs/SelectPais";
import SelectEstado from "../../Widgets/Inputs/SelectEstado";


class ModalAlmacen extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            tipo: 1,
            pais_id: null,
        }
    }

    ModalAlmacen = React.createRef();

    componentDidMount() {
        if (this.props.almacen_id !== undefined) {
            this.getAlmacen()
        }
    }


    /**
     * @memberof ModalAlmacen
     * @method onFinish
     * @description Se ejecuta al dar enter al formulario
     */
    onFinish = (values) => {
        if (this.props.almacen_id) {
            this.updateAlmacen(values)
        } else {
            this.addAlmacen(values)
        }
    }


    /**
     * @memberof ModalAlmacen
     * @method addAlmacen
     * @description Añade una area a la BD
     */
    addAlmacen = (values) => {
        axios.post('/almacenes', values)
            .then(response => {
                console.log("response", response);
                message.success('¡Almancen creado!')
                this.props.onClose(true)
            }).catch(error => {
                console.log(error)
                message.error(error.response?.data?.message ?? 'Error al crear Almancen.')
            })
    }


    /**
     * @memberof ModalAlmacen
     * @method getAlmacen
     * @description Obtiene un almacen de la DB
     */
    getAlmacen = () => {
        this.setState({ loading: true })
        axios.get('/almacenes/' + this.props.almacen_id)
            .then(({ data }) => {
                console.log('data get almacen', data)
                this.ModalAlmacen.current.setFieldsValue({
                    ...data,
                    nombre: data.nombre,
                    ...data.direccion_id,
                    estado_id: data.direccion_id?.estado_id?._id ? {
                        value: data.direccion_id?.estado_id?._id,
                        label: data.direccion_id?.estado_id?.nombre
                    } : null,
                    pais_id: data.direccion_id?.pais_id?._id ? {
                        value: data.direccion_id?.pais_id?._id,
                        label: data.direccion_id?.pais_id?.nombre
                    } : null,
                })
                this.setState({pais_id: data.direccion_id?.pais_id?._id})
            }).catch(error => {
                message.error('Error al traer el Almancén')
            }).finally(() => this.setState({ loading: false }))
    }



    /**
     * @memberof ModalAlmacen
     * @method updateAlmacen
     * @description Actualiza la información de un  almacen
     */
    updateAlmacen = (values) => {
        this.setState({ loading: true })
        axios.put('/almacenes/' + this.props.almacen_id, {
            ...values
        }).then(response => {
            message.success('¡Almacén Actualizado!')
            this.props.onClose()
        }).catch(error => {
            message.error(error.response?.data?.message ?? 'Error al actualizar Almacén')
            console.log(error.response)
        }).finally(() => this.setState({ loading: false }))
    }


    /*onValuesChange = (changedValues, allValues) => {
        if (changedValues.pais_id) {
            this.setState({ pais_id: changedValues.pais_id })
        }

    }*/
    render() {
        return (
            <Form
                id="form-almacen"
                name="form-almacen"
                layout="vertical"
                ref={this.ModalAlmacen}
                onValuesChange={this.onValuesChange}
                onFinish={this.onFinish}

            >
                <Spin spinning={this.state.loading}>

                    <Row className="ant-row-center mt-3" gutter={[16, 16]}>
                        <Col xs={24} lg={24} >
                            <Form.Item
                                label="Nombre"
                                name="nombre"
                                rules={[{
                                    required: true,
                                    message: "Por favor, ingrese el nombre"
                                }]}
                            >
                                <Input placeholder="Nombre" ></Input>
                            </Form.Item>
                        </Col>
                        {/* <Col xs={24} lg={24} >
                            <Form.Item
                                label="Dirección"
                                name="direccion"
                            >
                                <Input.TextArea placeholder="Dirección" />

                            </Form.Item>
                        </Col> */}

                        <Col xs={24} lg={24} >
                            <Form.Item
                                label='País'
                                name='pais_id'
                                rules={[{ required: true, message: 'Pais es obligatorio' }]}
                            >
                                <SelectPais onSelect={(pais_id)=>this.setState({pais_id})}/>
                            </Form.Item>
                        </Col>
                        <Col xs={24} lg={24} >

                            <Form.Item
                                label="Calle y Número"
                                name='calles'
                                //rules={[{ required: true, message: 'Calle y Número es obligatorio' }]}
                            >
                                <Input disabled={this.state.disabled} placeholder="Calle #12323" />
                            </Form.Item>
                        </Col>


                        <Col xs={24} lg={12} >
                            <Form.Item
                                label='Apartament, local, etc'
                                name='colonia'
                            >
                                <Input disabled={this.state.disabled} placeholder="Apartament, local, etc" />
                            </Form.Item>
                        </Col>
                        <Col xs={24} lg={12} >

                            <Form.Item
                                label='Código Postal'
                                name='codigo_postal'
                                //rules={[{ required: true, message: 'Código Postal es obligatorio ' }]}
                            >
                                <Input type="number" disabled={this.state.disabled} placeholder="Código Postal" />
                            </Form.Item>
                        </Col>

                        <Col xs={24} lg={12} >
                            <Form.Item
                                label='Ciudad'
                                name='ciudad'
                                //rules={[{ required: true, message: 'Ciudad es obligatorio' }]}

                            >
                                <Input disabled={this.state.disabled} placeholder="Ciudad" />
                            </Form.Item>
                        </Col>
                        <Col xs={24} lg={12} >

                            <Form.Item
                                label='Estado'
                                name='estado_id'
                                //rules={[{ required: true, message: 'Estado es obligatorio' }]}

                            >
                                <SelectEstado
                                    size="large"
                                    placeholder="Estado a seleccionar"
                                    disabled={!this.state.pais_id}
                                    pais_id={this.state.pais_id}
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={24} lg={12} >

                            <Form.Item
                                label='Teléfono'
                                name='telefono'

                            >
                                <PhoneInput />
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={12}>
                            <Form.Item
                                name="tienda_rappi"
                                label={<Space direction="horizontal">
                                    ¿Registrar como tienda en RAPPI ?
                                    <img src="/img/Rappi.svg" style={{ height: 40 }}></img></Space>}
                                valuePropName="checked"
                            >
                                <Switch />
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={24}>
                            <Form.Item
                                name="default_retorno"
                                label="Almacen como default para devolucion de productos"
                                valuePropName="checked"
                            >
                                <Switch />
                            </Form.Item>
                        </Col>
                    </Row>
                </Spin>
            </Form>
        )
    }
}



export default function (props) {
    const { visible = false, onClose = () => { } } = props

    return <Modal
        open={visible}
        onCancel={onClose}
        title={props.almacen_id ? "Editar Almacén" : "Crear Almacén"}
        destroyOnClose={true}
        zIndex={1000}
        cancelText="Cancelar"
        okText="Guardar"
        okButtonProps={{ form: 'form-almacen', key: 'submit', htmlType: 'submit' }}

    >
        <ModalAlmacen {...props} />
    </Modal>

}