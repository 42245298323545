import React, { Component } from "react";
import { Row, Col, Space, Modal, List, Typography, Form, DatePicker, Input, InputNumber, Collapse, Select, message, Spin, Cascader, Tag, Tooltip, Divider, Checkbox } from 'antd';
import { ExclamationCircleFilled } from '@ant-design/icons';
import axios from "axios"

//Componentes
import ImageProducto from "../../Widgets/ImageProducto";
import { renderEstatusOrdenPago, renderEstatusOrdenEnvio } from "../../Utils";


const { Title, Text, Paragraph } = Typography;
const { Option } = Select;
const moment = require('moment');
const Decimal = require('decimal.js');

class ModalCancelOrden extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            orden: {
                ordenes_productos_ids: []
            },
            orden_producto_restaurar: [],
            almacen: {},
            ordenes: [],
        }
    }

    modalRef = React.createRef();

    componentDidMount() {
        if(this.props.orden_id){
            this.getOrden()
        }
        if(this.props.ordenes_ids){
            this.getOrdenes()
        }

        this.getAlmacenes()
    }

    /**
    * @method addUser
    * @description Obtiene la información de la orden
    */
    getOrden = () => {
        this.setState({loading:true})
        axios.get('/orden/'+this.props.orden_id)
        .then(({data})=>{
            console.log("data", data);
            this.setState({ordenes: [{...data, ordenes_productos: data.ordenes_productos_ids}]})

        }).catch((error)=>{
            message.error(error?.response?.data?.message ?? "Error al obtener la información del pedido")
        }).finally(()=>this.setState({loading: false}))
    }

    /**
    * @memberof Ordenes
    * @description Obtiene una lista de ordenes
    */
    getOrdenes = () => {

        console.log('props',this.props)
        this.setState({ loading: true })
        axios.get('/ordenes', {
            params: {
                limit: 200,
                orden_detalles: true,
                ordenes_ids: this.props.ordenes_ids
            }
        }).then(({ data }) => {
                console.log("data.data", data.data);
            this.setState({
                ordenes: data.data
            })

        }).catch(error => {
            message.error(error.response?.data?.message ?? 'Error al obtener los pedidos')
        }).finally(() => this.setState({ loading: false }))

    }

    /**
    * @method onChange
    * @description Función para agregar o eliminar un orden_producto_id del array orden_producto_restaurar.
    */
    onChange = (orden_producto_id) => {
	    let arr = [...this.state.orden_producto_restaurar];

	    const index = arr.findIndex(item => item === orden_producto_id);

	    if (index === -1) {
	        arr.push(orden_producto_id);
	    } else {
	        arr = arr.filter(item => item.toString() !== orden_producto_id.toString());
	        console.log("arr", arr);
	    }

	    this.setState({ orden_producto_restaurar: arr });
	}

	/**
    * @method onChange
    * @description Función para agregar o eliminar un orden_producto_id del array orden_producto_restaurar.
    */
	onFinish = () => {
		let { orden_producto_restaurar } = this.state;
		console.log("orden_producto_restaurar", orden_producto_restaurar);

		this.setState({ loading: true })
		axios.post("/ordenes/retornar-productos",{
			orden_producto_restaurar,
            orden_id: this.props.orden_id, //or
            ordenes_ids: this.props.ordenes_ids,
		}).then(response => {
			message.success("Productos restaurados")
            this.props.onClose()
		}).catch(error => {
			message.error(error?.response?.data?.message ?? "Error al retornar productos")
		}).finally(()=>this.setState({loading: false}))
	}

    /**
     *
     *
     * @memberof Almacenes
     * 
     * @method getAlmacenes
     * @description Obitiene las Almacenes
     */
    getAlmacenes = () => {
        this.setState({ loading: true })
        axios.get('/almacenes', {
            params: { 
                default_retorno: true,
            }
        })
            .then(({ data }) => {
                this.setState({ almacen: data?.data[0] ?? {} })
            })
            .catch(res => {
                message.error(res.response.data ?? "No fue posible obtener el almacén")
            })
            .finally(() => this.setState({ loading: false }))
    }

    render() {

        const { ordenes, almacen } = this.state;

        return (
            <Spin spinning={this.state.loading}>
                <Form
                    layout="vertical"

                    name="form-cancelar"
                    id="form-cancelar"
                    ref={this.modalRef}
                    onFinish={this.onFinish}
                >
                    <Row gutter={[4,8]}>
                        <Row>
                            <Col span={24}>
                                <Title level={3}>Retornar productos a {almacen.nombre ?? "No hay un almacén disponible"}</Title>
                            </Col>
                        </Row>

                        { ordenes.map(orden => <>
               				{ orden._id ? <> <Col span={24}>
               					<Space>
               						<Title level={4} className="m-0">{orden.shopify_folio} <small className="text-gray">{orden.folio}</small></Title>
               						{renderEstatusOrdenPago(orden.shopify_financial_status)}
               						{renderEstatusOrdenEnvio(orden.shopify_fulfillment_status)}
               					</Space>
               				</Col> 
               				<Col span={24}>
               					<Space>
               						<small className="text-gray">{moment(orden.fecha).format("DD/MM/YYYY HH:mm")}</small>
               					</Space>
               				</Col> </> : null }
                            { !orden.cliente_id?._id && orden.shopify_cliente ? <Col span={24} className="flex-left-column">
                                <Text>Cliente: {orden.shopify_cliente?.first_name ?? "-"} {orden.shopify_cliente?.last_name ?? ""}
                                    <Tooltip placement="topRight" title={"Cliente no registrado"}>
                                        <ExclamationCircleFilled style={{color: "#ff4d4f", marginLeft: "0.5rem"}}/>
                                    </Tooltip>
                                </Text>
                                <Text>{orden.shopify_cliente?.email ?? "No email"} {orden.shopify_cliente?.phone ?? ""}</Text>
                            </Col> : null }
                            { orden.cliente_id?._id && !orden.shopify_cliente ? <Col span={24} className="flex-left-column">
                                <Text>Cliente: {orden.cliente_id?.nombre ?? "-"} {orden.cliente_id?.apellido_paterno ?? ""}</Text>
                                <Text>{orden.cliente_id?.email ?? "No email"} {orden.cliente_id?.telefono ?? ""}</Text>
                            </Col> : null }

                            { orden.cliente_id?._id && orden.shopify_cliente ? <Col span={24} className="flex-left-column">
                                <Text>Cliente: {orden.cliente_id?.nombre ?? "-"} {orden.cliente_id?.apellido_paterno ?? ""}</Text>
                                <Text>{orden.cliente_id?.email ?? "No email"} {orden.cliente_id?.telefono ?? ""}</Text>
                            </Col> : null }

                            { orden.notas ? <Col span={24}>
                                <Paragraph strong style={{fontSize: "15px"}}>Notas: {orden.notas}</Paragraph>
                            </Col> : null }
                            {
                                !orden.direccion_id && orden.shopify_shipping_address ? <Col span={24}>
                                    <Collapse 
                                        size="small"
                                        items={[
                                            {
                                                key: '1',
                                                label: 'Dirección de envio',
                                                children: <Paragraph className="flex-left-column">
                                                    <Text>{orden.shopify_shipping_address?.first_name ?? ""} {orden.shopify_shipping_address?.last_name ?? ""}</Text>
                                                    <Text>{orden.shopify_shipping_address?.company ?? ""}</Text>
                                                    <Text>{orden.shopify_shipping_address?.address1 ?? ""}</Text>
                                                    <Text>{orden.shopify_shipping_address?.address2 ?? ""}</Text>
                                                    <Text>{orden.shopify_shipping_address?.zip ?? ""} {orden.shopify_shipping_address?.city ?? ""} {orden.shopify_shipping_address?.province ?? ""}</Text>
                                                    <Text>{orden.shopify_shipping_address?.country ?? ""}</Text>
                                                </Paragraph>,
                                            },
                                        ]}
                                    />
                                </Col> : null
                            }
                            {
                                orden.direccion_id ? <Col span={24}>
                                    <Collapse 
                                        size="small"
                                        items={[
                                            {
                                                key: '1',
                                                label: 'Dirección de envio',
                                                children: <Paragraph className="flex-left-column">
                                                    <Text>{orden.direccion_id?.nombre ?? ""}</Text>
                                                    <Text>{orden.direccion_id?.calles ?? ""}</Text>
                                                    <Text>{orden.direccion_id?.colonia ?? ""}</Text>
                                                    <Text>{orden.direccion_id?.codigo_postal ?? ""} {orden.direccion_id?.ciudad ?? ""} {orden.direccion_id?.estado_id?.nombre ?? ""}</Text>
                                                    <Text>{orden.direccion_id?.pais_id?.nombre ?? ""}</Text>
                                                    <Text>{orden.direccion_id?.especificaciones ?? ""}</Text>
                                                </Paragraph>,
                                            },
                                        ]}
                                    />
                                </Col> : null
                            }
               				
                            <Row
                                className="width-100 mt-1" 
                                gutter={[12,12]}
                            >
    							<Col className="center" span={11}>
    								<Text strong>PRODUCTO</Text>
    							</Col>
    							<Col className="flex-right" span={10}>
    								<Text>CANTIDAD</Text>
    							</Col>
    						</Row>

               					{
               						orden.ordenes_productos?.map((item, index) => {
               							return <Row 
                                            key={index} 
                                            className="width-100 mt-1" 
                                            style={{borderBottom: "solid 1px #c6c6c6",}}
                                            gutter={[12,12]}
                                        >
                                        	<Col className="center" span={2}>
                                                <Checkbox onChange={() => this.onChange( item._id)} disabled={item.retornado}/>
                                            </Col>
                                            <Col className="center" span={2}>
                                                <ImageProducto
                                                    producto={item.producto_id}
                                                    variante={item.variante_id}
                                                />
                                            </Col>
               								<Col className="flex-left-column" span={8}>
               									<Text strong>{item.producto_id?.modelo ?? "-"}</Text>
                                                <Text>{item.variante_id?.sabor ?? "-"} {item.retornado ? <Text className="text-red">PRODUCTO DEVUELTO</Text> : null}</Text>
               								</Col>
               								<Col className="flex-right" span={10}>
               									<Text>{item.cantidad}</Text>
               								</Col>
               							</Row>
               						})
               					}
                            <Row className="mb-2 width-100">
                                <Col span={24}></Col>
                            </Row>
                        </>)}
           				
           			</Row>
                    
                </Form>
            </Spin>
        )
    }
}



export default function (props) {
    const { open = false, onClose = () => { }, transaccion_id } = props

    return <Modal
        open={open}
        onCancel={onClose}
        title={"Retornar productos"}
        closable={true}
        maskClosable={true}
        destroyOnClose={true}
        zIndex={1000}
        okButtonProps={{ form: 'form-cancelar', key: 'submit', htmlType: 'submit' }}
        width={800}
    >
        <ModalCancelOrden {...props} />
    </Modal>

}