import React, { Component, useContext } from 'react';
import { Layout, Row, Col, Tooltip, Typography, Dropdown, Modal, message, Statistic, Space, Collapse, Tag, Pagination, DatePicker, Form, Button, Divider, Spin, Empty } from "antd";
import axios from 'axios';
import dayjs from "dayjs";
import { FaShopify, FaTruck } from "react-icons/fa";
import { AiFillDollarCircle } from "react-icons/ai";
import { useNavigate, useParams } from "react-router-dom";
import { FilePdfOutlined, FileExcelOutlined, MoreOutlined, FilterOutlined } from '@ant-design/icons';

//Componentes
import User from "../../../Hooks/Logged";
import usePermissions from "../../../Hooks/usePermissions";
import PageHeader from '../../Widgets/PageHeader/PageHeader';
import SelectClientes from '../../Widgets/Inputs/SelectClientes';
import SelectVariantes from '../../Widgets/Inputs/SelectVariantes';
import { renderEstatusOrdenEnvio, renderEstatusOrdenPago } from "../../Utils";

//Modales
import ModalOrdenes from '../Ordenes/ModalOrdenes';
import DrawerBitacora from '../Ordenes/DrawerBitacora';
import ModalTransacciones from '../Ordenes/ModalTransacciones';
import ModalOrdenesDetalles from '../Ordenes/ModalOrdenesDetalles';
import DrawerFiltros from '../../Widgets/DrawerFiltros/DrawerFiltros';
import ModalFulfillmentsDetalle from '../Ordenes/ModalFulfillmentsDetalle';



const { Content } = Layout;
const { confirm } = Modal;
const { Text } = Typography;
const { RangePicker } = DatePicker;


/**
 *
 *
 * @export
 * @class Ordenes
 * @extends {Component}
 * @description Vista del listado de Ordenes de Shopify
 */
class Ordenes extends Component {

    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            cliente_id: this.props.params.cliente_id,
            variante_id: null,
            cliente: {},
            ordenes: {
                data: [],
                page: 1,
                limit: 50,
                pageInfo: undefined,
                total: 0,
                filters: [],
            },
            fechas: [dayjs().startOf('month'), dayjs().endOf("month")]
        }
    }

    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        this.getOrdenes()
        if (this.props.params.cliente_id) {
            this.getCliente()
        }
    }

    /**
     *
     * @memberof DetalleInversion
     * 
     * @method getCliente
     * @description Obtiene informacion del Cliente
     */
    getCliente = (id = this.state.cliente_id) => {
        this.setState({ loading: true })
        axios.get('/clientes/' + id, {
            params: {
            }
        }).then(response => {
            this.setState({
                cliente: response.data,
                cliente_id: response.data?._id,
            })

        }).catch(error => {
            console.log("error", error);
            message.error("Error al obtener la información del cliente")
        }).finally(() => this.setState({ loading: false }))
    }

    /**
    * @memberof Ordenes
    * @description Obtiene una lista de ordenes
    */
    getOrdenes = ({
        limit = this.state.ordenes.limit,
        page = this.state.ordenes.page,
        filters = this.state.ordenes.filters,
        cliente_id = this.state.cliente_id,
    } = this.state.ordenes) => {
        this.setState({ loading: true })
        
        let productos_ids = (filters?.filter(element => element.name === "producto_id"))?.map(e => e._id)
        let variantes_ids = (filters?.filter(element => element.name === "variante_id"))?.map(e => e._id)

        axios.get('/ordenes', {
            params: {
                limit,
                page,
                cliente_id,
                filters: filters.filter(item => item.name !== "producto_id" && item.name !== "variante_id"),
                productos_ids,
                variantes_ids,
                tipo: "normal",
                orden_detalles: true,
                fechas: this.state.fechas,
            }
        }).then(({ data }) => {
            console.log("data", data);
            this.setState({
                ordenes: {
                    ...data,
                    filters
                }
            })

        }).catch(error => {
            console.log("error", error);
            message.error(error?.response?.data?.message ?? 'Error al obtener los pedidos')
        }).finally(() => this.setState({ loading: false }))

    }

    /**
    * @memberof onChangeDates
    * @description Actualiza las fechas de busqueda
    */
    onChangeDates = (dates) => {
        console.log("dates", dates);
        if (dates) {
            this.setState({ fechas: dates }, () => { this.getOrdenes() })
        }else{
            this.setState({ fechas: undefined }, () => this.getOrdenes())
        }
    }

    /**
     *
     * @memberof ClientesDetalles
     * 
     * @method getPDFPedidos
     * @description Generamos el PDF del estado de cuenta del cliente
     */
    getPDFPedidos = () => {
        let url = new URL(axios.defaults.baseURL + '/reportes/pedidos')
        url.searchParams.append('Authorization', sessionStorage.getItem('token'))
        url.searchParams.append('cliente_id', this.state.cliente?._id)
        if(this.state?.fechas?.length > 0){
            url.searchParams.append('fecha_inicial', this.state.fechas[0])
            url.searchParams.append('fecha_final', this.state.fechas[1])
        }
        if(this.state?.ordenes?.filters?.length > 0){
            let filters = JSON.parse(JSON.stringify(this.state?.ordenes?.filters));
            url.searchParams.append('filters', JSON.stringify(filters?.filter(item => item.name !== "producto_id" && item.name !== "variante_id")))
            let productos_ids = (filters?.filter(element => element.name === "producto_id"))?.map(e => e._id)
            if(productos_ids) url.searchParams.append('productos_ids', JSON.stringify(productos_ids))
            let variantes_ids = (filters?.filter(element => element.name === "variante_id"))?.map(e => e._id)
            if(variantes_ids) url.searchParams.append('variantes_ids', JSON.stringify(variantes_ids))
        }
        window.open(url, '_blank')
    }

    /**
    *
    * @memberof ClientesDetalles
    * 
    * @method getCSVPedidos
    * @description Generamos el csv de los pedidos
    */
    getCSVPedidos = () => {
        let url = new URL(axios.defaults.baseURL + '/reportes/pedidos')
        url.searchParams.append('Authorization', sessionStorage.getItem('token'))
        url.searchParams.append('cliente_id', this.state.cliente?._id)
        if(this.state?.fechas?.length > 0){
            url.searchParams.append('fecha_inicial', this.state.fechas[0])
            url.searchParams.append('fecha_final', this.state.fechas[1])
        }
        if(this.state?.ordenes?.filters?.length > 0){
            let filters = JSON.parse(JSON.stringify(this.state?.ordenes?.filters));
            url.searchParams.append('filters', JSON.stringify(filters?.filter(item => item.name !== "producto_id" && item.name !== "variante_id")))
            let productos_ids = (filters?.filter(element => element.name === "producto_id"))?.map(e => e._id)
            if(productos_ids) url.searchParams.append('productos_ids', JSON.stringify(productos_ids))
            let variantes_ids = (filters?.filter(element => element.name === "variante_id"))?.map(e => e._id)
            if(variantes_ids) url.searchParams.append('variantes_ids', JSON.stringify(variantes_ids))
        }
        url.searchParams.append('csv', true)
        window.open(url, '_blank')
    }

    
    /**
    *
    * @memberof Ordenes
    * 
    * @method aprobarOrden
    * @description Aprueba la orden y pasa de draft a pedido normal
    */
    aprobarOrden = (orden_id) => {
        this.setState({ loading: true })
        axios.post('/ordenes/aprobar', {
            orden_id
        })
            .then(response => {
                message.success("Orden Aprobada")
                this.getOrdenes()
            })
            .catch(error => {
                console.log("error", error);
                message.error("Error al aprobar la orden")
            }).finally(() => this.setState({ loading: false }))
    }

    /**
       *
       * @memberof Ordenes
       * 
       * @method renderMenuItems
       * @description renderiza los elementos del menu de cada pedido
       */
    renderMenuItems = (orden) => {

        let menu_items = [
            {
                key: '1',
                label: <Text>Ver Detalle</Text>,
                onClick: () => this.setState({ modal_visible_detalle: true, orden_id: orden._id })
            },
            {
                key: '2',
                label: <Text>Ver Transacciones</Text>,
                onClick: () => this.setState({ modal_transacciones: true, orden_id: orden._id, orden: orden })
            },
            {
                key: '3',
                label: <Text>Ver PDF</Text>,
                onClick: () => {
                    let url = new URL(axios.defaults.baseURL + '/ordenes/pdf')
                    url.searchParams.append('orden_id', orden?._id)
                    url.searchParams.append('Authorization', sessionStorage.getItem('token'))
                    window.open(url, '_blank')
                }
            },
            {
                key: '6',
                label: <Text>Ver PDF de Empaquetado</Text>,
                onClick: () => {
                    let url = new URL(axios.defaults.baseURL + '/ordenes/packing')
                    url.searchParams.append('orden_id', orden?._id)
                    url.searchParams.append('timezone', Intl.DateTimeFormat().resolvedOptions().timeZone)
                    url.searchParams.append('Authorization', sessionStorage.getItem('token'))
                    window.open(url, '_blank')

                    this.getOrdenes()
                }
            },
            {
                key: '4',
                label: <Text>Editar</Text>,
                disabled: !this.props.canEdit,

                onClick: () => this.setState({ modal_visible: true, orden_id: orden._id })
            },
            {
                key: '5',
                label: <Text>Eliminar</Text>,
                disabled: !this.props.canDelete,
                onClick: () => {
                    confirm({
                        title: "¿Deseas eliminar este pedido?",
                        cancelText: "Cancelar",
                        okText: "SI",
                        onOk: () => {
                            this.setState({ loading: true })
                            axios.delete('/ordenes/' + orden._id).then((response) => {
                                message.success('Pedido Eliminada')
                                this.getOrdenes()
                            }).catch((error) => {
                                message.error('Pedido NO Eliminado')
                            }).finally(() => this.setState({ loading: false }))
                        },
                    });
                }
            }


        ]

        if (orden.shopify_draft_id && !orden.shopify_id) {
            menu_items.unshift({
                key: "0",
                label: <Text>Aprobar Draft</Text>,
                onClick: () => this.aprobarOrden(orden._id)
            })
        }

        if (orden.bitacora_log) {
            menu_items.push({
                key: "7",
                label: <Text>Ver Bitcora</Text>,
                onClick: () => this.setState({ drawer_bitacora: true, orden_id: orden._id })
            })
        }

        return menu_items
    }

    render() {

        let { cliente } = this.state;

        return (
            <Spin spinning={this.state.loading}>
                <PageHeader
                    className="admin-page-header"
                    breadcrumb={{
                        items: [
                            {
                                title: "CRM",
                                className: "hover",
                                onClick: () => this.props.navigate("/admin/crm/")
                            },
                            {
                                title: <SelectClientes
                                    style={{minWidth: "240px"}}
                                    className="select-transparent mt-0" 
                                    disabled={this.state.loading} 
                                    onSelect={(e) => { this.getCliente(e); this.getOrdenes({ cliente_id: e }) }} 
                                    value={cliente?._id ? { value:cliente?._id, label: `${cliente.nombre} ${cliente.apellido_paterno}`  } : null } />,
                            },
                            {
                                title: 'Pedidos',
                            },
                        ]
                    }}
                    extra={<Space direction='horizontal'>
                        <Button
                            title="Reporte CSV"
                            onClick={() => this.getCSVPedidos()}
                            icon={<FileExcelOutlined />} />
                        <Button
                            title="Reporte PDF"
                            onClick={() => this.getPDFPedidos()}
                            icon={<FilePdfOutlined />}
                        />
                        <Form layout='horizontal' className=''>
                            <Form.Item
                                name="fechas"
                                className="m-0 "
                            >
                                <RangePicker
                                    style={{ width: "80%" }}
                                    onChange={this.onChangeDates}
                                    defaultValue={this.state.fechas}
                                />
                            </Form.Item>
                        </Form>
                        <Button
                            onClick={() => this.setState({ visibleFilters: true })}
                            className="button-plus"
                            icon={<FilterOutlined style={{ color: '#000', fontWeight: 600 }} />} 
                        />
                    </Space>}
                >

                </PageHeader>

                <Content className="admin-content content-bg pd-1 mb-3">
                    <Row className="header-list width-100 pl-2 pr-1 mb-1">
                        <Col xs={12} md={3} className="center">
                            <Text strong>Pedido</Text>
                        </Col>
                        <Col xs={12} md={3} className="center">
                            <Text strong>Artículos</Text>
                        </Col>
                        <Col xs={12} md={3} className="center">
                            <Text strong>Costo Envío</Text>
                        </Col>
                        <Col  xs={12} md={4}className="center">
                            <Text strong>Monto Total</Text>
                        </Col>
                        <Col  xs={12} md={3} className="center">
                            <Text strong>Estatus Pago</Text>
                        </Col>
                        <Col  xs={12} md={3} className="center">
                            <Text strong>Estatus Pedido</Text>
                        </Col>
                        <Col  xs={12} md={2} className="center">
                            <Text strong>Fecha</Text>
                        </Col>
                    </Row>
                    {this.state.ordenes?.data?.length === 0 ? <Empty/> : null}
                    <Collapse
                        className="collapse-vapemate"
                        items={this.state.ordenes?.data?.map((item, index) => ({
                            key: index,
                            label: <>
                                <Row className="width-100">
                                    <Col xs={24} className="center">
                                        <Row gutter={[8, 8]} className="width-100">
                                            <Col xs={12} md={3} className="center hover"
                                                onClick={(e) => {
                                                    e.stopPropagation()
                                                    this.setState({ modal_visible_detalle: true, orden_id: item._id })
                                                }}
                                                style={{ zIndex: 1000 }}
                                            >
                                                <Space >
                                                    { !item.shopify_id && !item.shopify_draft_id ? <FaShopify style={{color: "red"}}/> : null}
                                                    <Text>{item.shopify_folio ?? item.folio}</Text>
                                                    {item.shopify_draft_id && !item.shopify_id ? <Tag color="magenta">Draft</Tag> : null}
                                                </Space>
                                            </Col>
                                            <Col xs={12} md={3} className="flex-column">
                                                <Text strong ellipsis>{item.total_productos ?? 0} Artículos</Text>
                                            </Col>
                                            <Col xs={12} md={3} className="center">
                                                <Statistic value={item.costo_envio?.monto ?? 0} prefix="$" valueStyle={{ fontSize: "14px" }} precision={2} />
                                            </Col>
                                            <Col xs={12} md={4} className="center">
                                                <Statistic
                                                    value={item.total}
                                                    prefix="$"
                                                    valueStyle={{
                                                        fontSize: '14px',
                                                        fontWeight: 'bold',
                                                        color: item.shopify_financial_status == "paid" ? '#2c9d37' : "#ecc602"
                                                    }}
                                                    precision={2}
                                                />
                                            </Col>
                                            <Col xs={12} md={3} className="flex-column">
                                                {renderEstatusOrdenPago(item.shopify_financial_status)}
                                            </Col>
                                            <Col xs={12} md={3} className="flex-column">
                                                {renderEstatusOrdenEnvio(item.shopify_fulfillment_status)}
                                            </Col>
                                            <Col xs={12} md={2} className="center">
                                                <Text>{dayjs(item.fecha).format("DD/MM/YY")}</Text>
                                            </Col>
                                            <Col xs={12} md={1} className="center">
                                                <FaTruck style={{ fontSize: "24px", color: item.shopify_fulfillments?.length > 0 ? "#0cc858" : "black" }} onClick={(e) => {
                                                    e.stopPropagation()
                                                    this.setState({ modal_visible_detalle_fulfillments: true, orden_id: item._id })
                                                }} />
                                            </Col>
                                            <Col xs={12} md={1} className="center">
                                                <Tooltip
                                                    placement="topRight"
                                                    title={item.credito ? "Se utilizó crédito" : "No se utilizó crédito"}
                                                >
                                                    <AiFillDollarCircle
                                                        style={{
                                                            color: item.credito ? "#ecc602" : "gray",
                                                            fontSize: "24px"
                                                        }}
                                                    />
                                                </Tooltip>
                                            </Col>
                                            <Col xs={12} md={1}>
                                                <Dropdown
                                                    placement="bottomRight"
                                                    menu={{
                                                        items: this.renderMenuItems(item)
                                                    }}
                                                >
                                                    <Button
                                                        type="ghost"
                                                        onClick={() => this.setState({ modalTransacDetalleVisible: true, transaccion_id: item._id })}
                                                        icon={<MoreOutlined style={{ color: "black" }} />}
                                                    />
                                                </Dropdown></Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </>,
                            children: [
                                <Row className="width-100 mb-1">
                                    <Col xs={24}>
                                        <Row gutter={[8, 8]}>
                                            <Col xs={12} md={5} className="flex-left-column">
                                                <Text ellipsis strong>PRODUCTO</Text>
                                            </Col>
                                            <Col xs={12} md={5} className="center">
                                                <Text ellipsis>PRECIO</Text>
                                            </Col>
                                            <Col xs={12} md={5} className="center">
                                                <Text ellipsis>DESCUENTO</Text>
                                            </Col>
                                            <Col xs={12} md={5} className="center">
                                                <Text ellipsis>CANTIDAD</Text>
                                            </Col>
                                            <Col xs={12} md={4} className="center">
                                                <Text ellipsis>TOTAL</Text>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>,
                                <Divider />,
                                ...item.ordenes_productos?.map(orden_producto => <Row className="width-100 mb-1">
                                    <Col xs={24}>
                                        <Row gutter={[8, 8]}>
                                            <Col xs={12} md={5} className="flex-left-column">
                                                <Text ellipsis strong>{orden_producto?.producto_id?.modelo ?? "--"}</Text>
                                                <Text ellipsis>{orden_producto?.variante_id?.sabor ?? "--"}</Text>
                                            </Col>
                                            <Col xs={12} md={5} className="center">
                                                <Text ellipsis>$ {orden_producto?.precio?.toMoney(true) ?? '-'} MXN</Text>
                                            </Col>
                                            <Col xs={12} md={5} className="center">
                                                <Text ellipsis>$ {(orden_producto?.descuento_total ?? orden_producto?.descuento)?.toMoney(true) ?? '0'} MXN</Text>
                                            </Col>
                                            <Col xs={12} md={5} className="center">
                                                <Text ellipsis>{orden_producto?.cantidad?.toMoney(true, 0) ?? 0}</Text>
                                            </Col>
                                            <Col xs={12} md={4} className="center">
                                                <Text ellipsis>$ {orden_producto?.total?.toMoney(true, 2) ?? 0} MXN</Text>
                                            </Col>

                                        </Row>
                                    </Col>
                                </Row>)
                            ]
                        }))}
                    />
                </Content>

                <Pagination
                    className="mt-1"
                    showSizeChanger
                    current={this.state.ordenes?.page}
                    total={this.state.ordenes?.total}
                    pageSize={this.state.ordenes?.limit}
                    onChange={(page, limit) => this.getOrdenes({ page, limit })}
                />
                <ModalOrdenes
                    open={this.state.modal_visible}
                    onClose={(flag) => {
                        this.setState({ modal_visible: false, orden_id: undefined })
                        if (flag === true)
                            this.getOrdenes();
                    }}
                    orden_id={this.state.orden_id}
                />
                <ModalOrdenesDetalles
                    open={this.state.modal_visible_detalle}
                    onClose={(flag) => {
                        this.setState({ modal_visible_detalle: false, orden_id: undefined })
                    }}
                    orden_id={this.state.orden_id}
                />
                <ModalTransacciones
                    open={this.state.modal_transacciones}
                    onClose={(flag) => {
                        this.setState({ modal_transacciones: false, orden_id: undefined })
                    }}
                    orden_id={this.state.orden_id}
                    orden={this.state.orden}
                />
                <DrawerBitacora
                    open={this.state.drawer_bitacora}
                    onClose={() => {
                        this.setState({ drawer_bitacora: false, orden_id: undefined })
                    }}
                    orden_id={this.state.orden_id}
                />

                <ModalFulfillmentsDetalle
                    open={this.state.modal_visible_detalle_fulfillments}
                    onClose={(flag) => {
                        this.setState({ modal_visible_detalle_fulfillments: false, orden_id: undefined })
                    }}
                    orden_id={this.state.orden_id}
                />
                <DrawerFiltros
                    ref={this.drawerFiltros}
                    title="Filtrar Clientes"
                    updateFilters={filters => this.getOrdenes({
                        page: 1,
                        filters
                    })}
                    onClose={() => this.setState({ visibleFilters: false })}
                    visible={this.state.visibleFilters}
                    productos={true}
                    shopify_financial_status={[
                        {
                            _id: "pending",
                            label: "Pago Pendiente"
                        },{
                            _id: "partially_paid",
                            label: "Pago Parcial"
                        },{
                            _id: "paid",
                            label: "Pagado"
                        },{
                            _id: "voided",
                            label: "Pago Anulado"
                        }
                    ]}
                    shopify_fulfillment_status={
                        [
                            {
                                _id: "no_fulfilled",
                                label: "No Preparado"
                            },{
                                _id: "fulfilled",
                                label: "Envio Preparado"
                            }
                        ]
                    }
                />

            </Spin>
        )
    }
}


export default function (params) {

    const user = useContext(User);

    const permisos = usePermissions(user?.rol_id?.permisos, {
        canEdit: ["ordenes", "edit"],
        candDelete: ["ordenes", "delete"],
        canCreate: ["ordenes", "create"],
    });

    return <Ordenes {...params} params={useParams()} navigate={useNavigate()} {...permisos} />
}