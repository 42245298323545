import { CloseOutlined, WarningOutlined, PlusOutlined, MinusOutlined, SwapOutlined, StopOutlined, } from "@ant-design/icons"
import { Typography, Tag, Modal, Row, Col, Badge } from 'antd'

import axios from "axios"
import '../Styles/Utils.css'


/**
 * @param {*} estatus
 * @description Renderiza el Tag con el estatus de la inversion
 */
const renderEstatusCliente = ({ estatus = 0 }) => {

    let steps = {
        0: <Tag className="tag-estatus cancelado">Cancelado</Tag>,
        1: <Tag className="tag-estatus registrado">Registrado</Tag>,
        2: <Tag className="tag-estatus contactado">Contactado</Tag>,
        3: <Tag className="tag-estatus compro">Ya compró algo</Tag>,
        4: <Tag className="tag-estatus pagado">Pagado</Tag>,

    }

    return estatus != undefined ? steps[estatus] : <Tag className="tag-estatus registrado">Registrado</Tag>;

}


/**
 * 
 * @param {*} response 
 * @param {String} si_falla_text 
 * @returns {String}
 */
const getResponseError = (response, si_falla_text) => {

    if (typeof (response?.data) === "string")
        return response.data

    if (typeof (response?.data.message) === "string")
        return response.data.message

    if (typeof (response?.data.message.data) === "string")
        return response.data.message.data

    return si_falla_text;
}

/**
 * 
 * @param {*} response 
 * @param {String} si_falla_text 
 * @returns {String}
 */
const renderTipoInventario = (tipo) => {

    let tipos = {
        1: "Disponible",
        2: "Consignado",
        3: "Futuro",
    }

    return tipos[tipo] ?? "-"
}
const renderTransferenciasFolio = (transferencias) => {
    let tags = []
    if ((Array.isArray(transferencias) && transferencias?.length > 0)) {
        transferencias.map((t, i) => {
            if (i < 4)
                tags.push(<Tag color="#1a91ff">{t.folio}</Tag>)


        })
        if (transferencias.length >= 4) {
            tags.push(<Badge count={transferencias.length - 4} />)
        }
    }
    return tags;
}

/**
 * @param num Fuente
 * @description Renderiza el genero del cliente
 */
const renderGenero = (genero) => {

    let tipo = {
        0: "Masculino",
        1: "Femenino",
        2: "Otro",
    }

    return genero !== undefined ? tipo[genero] : 'N/A'
}

/**
 * @param num Fuente
 * @description Renderiza el tipo del cliente
 */
const renderTipoCliente = (tipo) => {

    let tipos = {
        1: "Minorista",
        2: "Mayorista ⭐",
    }

    return tipo !== undefined ? tipos[tipo] : 'N/A'
}

const getTipoColor = tipo => {
    const colors = [
        '#36F905',
        '#EB5757',
        '#1890FF',
        '#000000'
    ]

    return colors[tipo - 1]
}

/**
 *
 * @description Retorna el estado en el que se encuentra una orden
 */
const renderEstatusCompra = (estatus) => {
    let estatus_label = {
        0: <Tag color="#facc15">Creada</Tag>,
        1: <Tag color="#1a91ff">Aceptada</Tag>,
        2: <Tag color="#1d4ed8">Confirmada</Tag>,
        3: <Tag color="#facc15">Incompleta</Tag>,
        4: <Tag color="#bef264">Completa</Tag>,
        5: <Tag color="#eab308">En entrega</Tag>,
        6: <Tag color="#eab308">Entregada</Tag>,
        7: <Tag color="#22c55e">Completa</Tag>,
        8: <Tag color="#15803d">Enviada</Tag>
    }
    return (estatus !== null || estatus !== undefined) ? estatus_label[estatus] : 'N/A'
}

/**
 *
 * @description Retorna el estado en el que se encuentra una orden
 */
const renderEstatusOrdenPago = (estatus, color) => {

    let estatus_label = {
        "pending": <Tag color={color ?? "#ecc602"}>Pago Pendiente</Tag>,
        "partially_paid": <Tag color={color ?? "#f48900"}>Pago Parcial</Tag>,
        "paid": <Tag color={"#0cc858"}>Pagado</Tag>,
        "voided": <Tag color={"#ff4d4f"}>Pago Anulado</Tag>,
        "refunded": <Tag color={"#b74dff"}>Reembolsado</Tag>,
    }

    return (estatus !== null && estatus !== undefined) ? estatus_label[estatus] : <Tag color={color ?? "#ecc602"}>Pago Pendiente</Tag>
}

/**
 *
 * @description Retorna el estado en el que se encuentra una orden
 */
const renderEstatusOrdenEnvio = (estatus) => {

    let estatus_label = {
        "fulfilled": <Tag color="#0cc858">Envio Preparado</Tag>,
        "partial": <Tag color="#ecc602">Parcial</Tag>,
        "no_fulfilled": <Tag color="#ecc602">No Preparado</Tag>,
    }

    return (estatus == null || estatus == undefined) ? <Tag color="#ecc602">No preparado</Tag> : estatus_label[estatus]
}

const getErrorRAPPI = (response, si_falla_text) => {

    if ((Array.isArray(response?.data) && response.data?.length > 0))
        return Modal.info({
            title: 'No se pudo actualizar el inventario de RAPPI',
            content: (
                response.data.map(error => <div>
                    <p>{error.message}</p>
                </div>)

            ),
            onOk() { },
        })

    if (Array.isArray(response?.data && response.data?.field != undefined))
        return Modal.info({
            title: 'No se pudo actualizar el inventario de RAPPI',
            content: (
                <Row>
                    <Row>
                        <Col span={12}>
                            <p>{response.data.field}</p>
                        </Col>
                        <Col span={12}> <p>{response.data.field}</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={12}>
                            <p>{response.data.field}</p>
                        </Col >
                        <Col span={12}> <p>{response.data.description}</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={12}>
                            <p>{response.data.field}</p>
                        </Col>
                        <Col span={12}> <p>{response.data.internal}</p>
                        </Col>
                    </Row>
                </Row>
            ),
            onOk() { },
        })

    return si_falla_text;
}
/**
  *
  *
  * @param {*} tipo
  * @description Renderiza el icono según el estatus
  */
const renderIconTransaccion = (tipo) => {
    switch (tipo) {
        case 1:
            return <PlusOutlined style={{ color: "currentColor" }} />;
        case 2:
            return <MinusOutlined style={{ color: "currentColor" }} />;
        case 3:
            return <SwapOutlined style={{ color: "currentColor" }} />;
        case 4:
            return <StopOutlined style={{ color: "currentColor" }} />;
    }
};

const renderEstatusTransferencias = (tipo) => {

    let tipos = {
        0: <Tag color="magenta">Borrador</Tag>,
        1: <Tag color="blue">Pendiente</Tag>,
        2: <Tag color="yellow">Parcial</Tag>,
        3: <Tag color="green">Entregado</Tag>,
    }

    return tipos[tipo] ?? "-"
}
export {
    renderEstatusCliente,
    getResponseError,
    renderTipoInventario,
    renderGenero,
    renderTipoCliente,
    getTipoColor,
    renderEstatusCompra,
    renderEstatusOrdenPago,
    renderEstatusOrdenEnvio,
    renderEstatusTransferencias,
    renderTransferenciasFolio,
    renderIconTransaccion
}