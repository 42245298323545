import React, { Component } from "react";
import { Row, Col, Modal, Form, Input, message, Spin, Select, InputNumber, Button, Typography } from 'antd';
import axios from "axios"
import { LinkOutlined, MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";

const { Text, Link } = Typography


/**
 * @class ModalOrdenes
 * @description Modal para el CRUD de Productos
 */
class ModalOrdenes extends Component {

    constructor(props) {
        super(props)
        this.state = {
            loading: false
        }
    }

    componentDidMount() {
    
    }

    formRef = React.createRef();


    /**
    * @method onFinish
    * @description Se ejecuta al hacer submit al formulario
    */
    onFinish = (values) => {

        this.props.onClose(values)
    }


    render() {

        const { orden } = this.state
         
        return (
            <Spin spinning={this.state.loading}>
                <Form
                    id="form-descuento"
                    layout={"vertical"}
                    className="mt-2"
                    onFinish={this.onFinish}
                    ref={this.formRef}
                    initialValues={{
                        tipo: "fixed_amount",
                        ...this.props.descuento,
                    }}
                >
                    <Row justify="center" className="center" gutter={[8,0]}>
                        <Col span={10}>
                            <Form.Item
                                name="tipo"
                                label="Tipo Descuento"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Seleccione el tipo de descuento',
                                    }
                                ]}
                            >
                                <Select 
                                    options={[
                                        { value: 'fixed_amount', label: 'Monto Fijo' },
                                        { value: 'percentage', label: 'Porcentaje' },
                                    ]}
                                    onSelect={(tipo_descuento)=>this.setState({tipo_descuento})}
                                />
                            </Form.Item> 
                        </Col>
                        <Col span={10}>
                            <Form.Item
                                name="valor"
                                label="Monto"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Ingrese un monto',
                                    },
                                    ({ getFieldValue }) => ({
                                        validator(_, value) {
                                            if (getFieldValue('tipo') === 'percentage' && value > 99) {
                                                return Promise.reject('El máximo para descuentos porcentuales es 99');
                                            }
                                            return Promise.resolve();
                                        },
                                    }),
                                ]}
                            >
                                <InputNumber 
                                    min={0}
                                    className="width-100"
                                    controls={false}
                                    formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    parser={value => value ? value.replace(/\$\s?|(,*)/g, '') : 0}
                                    max={this.state.tipo_descuento === "percentage" ? 99 : null}
                                    addonAfter={this.state.tipo_descuento === "percentage" ? "%" : null}
                                    addonBefore={this.state.tipo_descuento === "percentage" ? null : "$"}
                                />
                            </Form.Item> 
                        </Col>
                        <Col span={20}>
                            <Form.Item
                                name="descripcion"
                                label="Motivo"
                            >
                                <Input/>
                            </Form.Item> 
                        </Col>

                        
                    </Row>
                </Form>
            </Spin>
        )
    }


}



export default function Modals(props) {

    const { open = false, onClose = () => { }, orden_id } = props

    return <Modal
        open={open}
        onCancel={onClose}
        title={"Descuento"}
        closable={true}
        destroyOnClose={true}
        zIndex={1000}
        cancelText="Cancelar"
        okText="Guardar"
        okButtonProps={{ form: 'form-descuento', key: 'submit', htmlType: 'submit' }}
        width={700}
    >
        <ModalOrdenes  {...props} />
    </Modal>

}