import React, { Component, useContext } from "react";
import { Row, Col, Modal, Typography, Form, Input, InputNumber, Select, message, Checkbox, Spin } from 'antd';
import axios from "axios"

//componentes
import User from "../../../Hooks/Logged";
import ColorPicker from '../../Widgets/ColorPicker';
import { AvatarLoader } from "../../Widgets/Uploaders";
import usePermissions from "../../../Hooks/usePermissions";


const { Title } = Typography;
const { Option } = Select;


class ModalCuentas extends Component {


    constructor(props) {
        super(props);
        this.state = {
            loading: false,
        }
    }

    refModalCuentas = React.createRef();

    componentDidMount() {
        ;

        if (this.props.cuenta_id !== undefined) {
            this.getCuenta()
        }
    }

    /**
     * @memberof ModalCuentas
     * @method getCuenta
     * @description Obtiene una Cuenta de la DB
     *
     */
    getCuenta = () => {
        this.setState({ loading: true })
        axios.get('/cuentas/' + this.props.cuenta_id).then(response => {
            let cuenta = response.data;
            this.refModalCuentas.current.setFieldsValue({
                nombre: cuenta.nombre,
                titular: cuenta.titular,
                banco: cuenta.banco,
                cuenta: cuenta.cuenta,
                privado: cuenta.privado,
                tipo: cuenta.tipo,
                saldo: cuenta.saldo,
                color: cuenta.color,
                marca: cuenta.marca,
                logo: cuenta.logo ? {
                    uid: cuenta.logo.file,
                    name: cuenta.logo.name,
                    status: 'done',
                    fromDB: true,
                    url: axios.defaults.baseURL + "/cuentas/" + cuenta._id + "?logo=true&Authorization=" + sessionStorage.getItem("token")
                } : null
            })

        }).catch(error => {
            message.error('Error al traer la cuenta')
            console.log(error)
        }).finally(() => this.setState({ loading: false }))
    }


    /**
     * @memberof ModalCuentas
     * @method onFinish
     * @description Se ejecuta al dar enter al formulario
     * 
     */
    onFinish = (values) => {

        const formData = new FormData()

        formData.appendMultiple({
            ...values,
            historia: !!this.props.cuenta_id,
        })


        if (this.props.cuenta_id) {
            this.updateCuenta(formData)
        } else {
            this.addCuenta(formData)
        }

    }

    /**
     * @memberof ModalCuentas
     * @method addCuenta
     * @description Añade una cuenta a la BD
     *
     */
    addCuenta = (values) => {
        axios.post('/cuentas', values, {
            headers: {
                "Content-Type": 'multipart/form-data'
            }
        }).then(response => {
            message.success('Cuenta creada')
            this.props.onClose()
        }).catch(error => {
            message.error('Error al crear la cuenta')
            console.log(error)
        })
    }

    /**
     * @memberof ModalCuentas
     * @method updateCuenta
     * @description Actualiza la información de una Cuenta
     * 
     */
    updateCuenta = (values) => {
        this.setState({ loading: true })
        axios.put('/cuentas/' + this.props.cuenta_id, values, {
            headers: {
                "Content-Type": 'multipart/form-data'
            }
        }).then(response => {
            message.success('Cuenta Actualizada')
            this.props.onClose()
        }).catch(error => {
            message.error('Error al actualizar la cuenta')
            console.log(error)
        }).finally(() => this.setState({ loading: false }))
    }


    render() {

        return (
            <Form
                layout="vertical"
                name="form-cuenta"
                id="form-cuenta"
                ref={this.refModalCuentas}
                onFinish={this.onFinish}

            >
                <Spin spinning={this.state.loading}>
                    <Row align="center">
                        <Form.Item
                            name="logo"
                        >
                            <AvatarLoader
                                imageCrop={true}
                                onRemove={() => {
                                    axios.put('/cuentas/' + this.props.cuenta_id, {
                                        delete_logo: true
                                    })
                                }}

                            />
                        </Form.Item>
                    </Row>
                    <Row gutter={[16, 16]}>
                        <Col xs={24} lg={12}>
                            <Form.Item
                                label="Nombre"
                                name="nombre"
                                rules={[{
                                    required: true,
                                    message: "Por favor, ingrese el nombre"
                                }]}
                            >
                                <Input placeholder="Nombre" ></Input>
                            </Form.Item>
                        </Col>

                        <Col xs={24} lg={12}>
                            <Form.Item
                                label="Titular"
                                name="titular"
                                rules={[{
                                    required: true,
                                    message: "Por favor, ingrese el titular"
                                }]}
                            >
                                <Input placeholder="Titular" ></Input>

                            </Form.Item>
                        </Col>
                        <Col xs={24} lg={12}>
                            <Form.Item
                                label="Banco"
                                name="banco"
                                rules={[{
                                    required: true,
                                    message: "Por favor, ingrese el banco"
                                }]}
                            >
                                <Input placeholder="Banco" ></Input>
                            </Form.Item>
                        </Col>

                        <Col xs={24} lg={12} >
                            <Form.Item
                                label="Número de Cuenta"
                                name="cuenta"
                                rules={[{
                                    required: true,
                                    message: "Por favor, ingrese el número de cuenta"
                                }]}
                            >

                                <Input placeholder="Número de cuenta" maxLength={24} ></Input>
                            </Form.Item>
                        </Col>
                        <Col xs={24} lg={12}>
                            <Form.Item
                                label="Cuenta privada"
                                name="privado"
                                valuePropName="checked"
                            >
                                <Checkbox> Privada</Checkbox>
                            </Form.Item>
                        </Col>

                        <Col xs={24} lg={12} >
                            <Form.Item
                                label="Marca"
                                name="marca"
                                rules={[{
                                    required: true,
                                    message: "Por favor, seleccione la marca"
                                }]}
                            >
                                <Select  >
                                    <Option value={1}>MasterCard</Option>
                                    <Option value={2}>Visa</Option>
                                    <Option value={3}>American Express</Option>
                                    <Option value={4}>SPEI</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={24} lg={12}>
                            <Form.Item
                                label="Color"
                                name="color"
                                rules={[{
                                    required: true,
                                    message: "Indique el color"
                                }]}
                            >
                                <ColorPicker />
                            </Form.Item>
                        </Col>

                        <Col xs={24} lg={12} >
                            <Form.Item
                                label="Tipo Cuenta"
                                name="tipo"
                                rules={[{
                                    required: true,
                                    message: "Por favor, seleccione el tipo de Cuenta"
                                }]}
                            >
                                <Select
                                    onChange={(value) => this.setState({ tipo: value })}
                                >
                                    <Option value={1}>Bancaria</Option>
                                    <Option value={2}>Efectivo / Caja Menor</Option>
                                </Select>
                            </Form.Item>
                        </Col>



                        {this.props.verSaldo ? <Col xs={24} lg={12}>
                            <Form.Item
                                label="Saldo"
                                name="saldo"
                                rules={[{
                                    required: true,
                                    message: "Por favor, ingrese el saldo de la cuenta"
                                }]}
                            >

                                <InputNumber
                                    className="width-100"
                                    formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                    placeholder="Saldo"
                                    min={0}
                                />

                            </Form.Item>
                        </Col> : null }
                    </Row>
                </Spin>
            </Form>
        )
    }
}



export default function (props) {
    const { visible = false, onClose = () => { }, cuenta_id } = props

    const user = useContext(User);
    const permisos = usePermissions(user?.rol_id?.permisos, {
        verSaldo: ["cuentas", "ver_saldos"]

    });

    return <Modal
        open={visible}
        onCancel={onClose}
        title={cuenta_id ? "Editar Cuenta" : "Crear Cuenta"}
        destroyOnClose={true}
        cancelText="Cancelar"
        okText="Guardar"
        okButtonProps={{ form: 'form-cuenta', key: 'submit', htmlType: 'submit' }}
    >
        <ModalCuentas {...props} {...permisos}/>
    </Modal>

}