import React from 'react'
import { Route, Routes } from "react-router-dom";
import { Layout, Button } from 'antd';
import { useNavigate } from 'react-router-dom';

// Components
import ThemeEdit from '../Components/ThemeEdit/index';
import Login from '../Components/Auth/Login';
import Recovery from '../Components/Auth/Recovery';
import UpdatePassword from '../Components/Auth/UpdatePassword';
import Register from '../Components/Auth/Register';

import LandingPage from '../Components/Public/LandingPage';

/**
 * 
 * @export
 * @function PublicRoutes
 * @description Router for handling all public routes
 */
function PublicRoutes() {

  const navigate = useNavigate();

  return (
    <Layout>
      <Routes>
        {/* <Route path='/' element={<Landing />} />
        <Route path='post/:url' element={<Publicacion />} /> */}
        <Route path='/' element={<LandingPage />} />
        <Route path='theme' element={<ThemeEdit />} />
        <Route path='login' element={<Login />} />
        <Route path='password/recovery' element={<Recovery />} />

        <Route path='recovery/:token' element={<UpdatePassword />} />
        <Route path='register/:token' element={<UpdatePassword />} />
								<Route path='register' element={<Register />} />
      </Routes>
    </Layout>
  )
}

export default PublicRoutes;