import React, { Component, useContext, useEffect } from 'react';
import { Row, Col, Button, Popconfirm, Spin, Layout, message, Space, List, Card, Typography } from 'antd'

import axios from 'axios';
import { Link, useNavigate } from "react-router-dom";


export default function LandingPage(props) {

    const navigate = useNavigate()

    useEffect(() => {
        navigate('/login')

    },[])

    return 

}