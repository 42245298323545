import React, { Component, useContext } from 'react';
import { Row, Col, Popconfirm, Spin, Layout, message, Typography, Space, List, Card } from 'antd'
import axios from 'axios';
import { useNavigate, useParams } from "react-router-dom";

//Componentes
import User from "../../../../Hooks/Logged";
import usePermissions from "../../../../Hooks/usePermissions";
import CustomAvatar from "../../../Widgets/Avatar/Avatar";
import FloatingButton from "../../../Widgets/Floating Button/FloatingButton";
import PageHeader from '../../../Widgets/PageHeader/PageHeader';

import ModalRubro from './ModalRubro'
import { ButtonDelete, ButtonEdit } from '../../../Widgets/Buttons';
import useSearch from '../../../../Hooks/useSearch';

const { Content } = Layout;
const { Text } = Typography

/**
 * @export
 * @class Rubros
 * @extends {Component}
 * @description Vista de Rubros
 */
class Rubros extends Component {

    back = () => {
        this.props.navigate(-1);
    }

    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            modalVisible: false,

            area_id: this.props.params.area_id,
            rubro_id: undefined,
            area: null,


            rubros: {
                data: [],

                page: 1,
                limit: 10,
                total: 0,
                pages: 0,
            },

            filtroSearch: '',
            searching: true,
        }
    }

    componentDidMount() {
        this.props.setShowSearch(true)
        this.getArea();
        this.getRubros();
    }

    componentDidUpdate(prevProps) {
        if (this.props.search !== prevProps.search) {
            this.getRubros({ page: 1, search: this.props.search })
        }
    }

    componentWillUnmount() {
        this.props.setShowSearch(false)
    }


    /**
     * @memberof ModalAreas
     * @method getArea
     * @description Obtiene una area de la DB
     */
    getArea = () => {
        let search = this.props.search;
        axios.get('/areas/' + this.state.area_id).then(response => {
            let area = response.data
            this.setState({
                area,
                searching: true,
                filtroSearch: search
            })


        }).catch(error => {
            message.error('Error al traer area')
            console.log(error)
        }).finally(() => this.setState({ loading: false }))
    }


    /**
     * @memberof ModalAreas
     * @method getArea
     * @description Obtiene los rubors de una area de la DB
     */
    getRubros = ({
        page = this.state.rubros.page,
        limit = this.state.rubros.limit,
        search = this.props.search
    } = this.state.rubros) => {
        this.setState({ loading: true, areas: { ...this.state.rubros, page, limit } })
        axios.get('/rubros', {
            params: { limit, page, search, area_id: this.state.area_id }
        })
            .then(({ data }) => {
                this.setState({ rubros: data })
            })
            .catch(res => {
                message.error(res.response.data ?? "No fue")
            })
            .finally(() => this.setState({ loading: false }))
    }


    render() {

        const { area, rubros } = this.state;

        return (
            <>
                <Spin spinning={false}>
                    <PageHeader
                        className="admin-page-header"
                        title="Rubros"
                        backIcon={true}
                    />

                    <Content className="admin-content content-bg pd-1">

                        <List
                            loading={this.state.loading}
                            className="component-list"
                            itemLayout="horizontal"
                            locale={{ emptyText: "Sin Rubros" }}
                            dataSource={rubros.data}
                            pagination={{
                                current: rubros.page,
                                pageSize: rubros.limit,
                                total: rubros.total,
                                hideOnSinglePage: false,
                                position: 'bottom',
                                onChange: (page, limit) => this.getRubros({ page, limit })
                            }}
                            header={<Row className="width-100 pr-1 pl-1" >
                                <Col span={3} className="center">
                                    <Text strong>Color</Text>
                                </Col>
                                <Col span={7} className="center">
                                    <Text strong>Nombre</Text>
                                </Col>
                                <Col span={9} className="center">
                                    <Text strong>Descripcion</Text>
                                </Col>
                                <Col span={5} className="center">
                                    <Text strong>Acciones</Text>
                                </Col>

                            </Row>
                            }

                            renderItem={item => (
                                <List.Item className="component-list-item">
                                    <Card className="card-list">
                                        <Row className="width-100 ">

                                            <Col span={3} className="center">
                                                <CustomAvatar color={item.color} />
                                            </Col>

                                            <Col span={7} className="center">
                                                <Text className="text-gray">{item.nombre}</Text>
                                            </Col>
                                            <Col span={9} className="center">
                                                <Text className="text-gray">{item.descripcion}</Text>
                                            </Col>
                                            <Col span={5} className="center">
                                                <Space>
                                                    <ButtonEdit
                                                        title="Editar"
                                                        onClick={() => { this.setState({ modalVisible: true, rubro_id: item._id }) }}
                                                        disabled={!this.props.canEdit}

                                                    />
                                                    <Popconfirm
                                                        placement="topRight"
                                                        title="¿Deseas eliminar este rubro?"
                                                        onConfirm={() => axios.delete('/rubros/' + item._id).then(() => {
                                                            this.getRubros()
                                                            message.success('Rubro eliminado')
                                                        }).catch(error => console.log(error))}
                                                        okText="Si"
                                                        cancelText="No"
                                                    >
                                                        <ButtonDelete
                                                            title="Eliminar"
                                                            disabled={!this.props.canDelete}

                                                        />
                                                    </Popconfirm>
                                                </Space>
                                            </Col>
                                        </Row>
                                    </Card>
                                </List.Item>
                            )}
                        />


                    </Content>


                    {this.props.canCreate && <FloatingButton title="Nuevo registro" disabled={!this.props.canCreate} onClick={() => this.setState({ modalVisible: true })} />}

                    <ModalRubro
                        visible={this.state.modalVisible}
                        onClose={() => {
                            this.setState({ modalVisible: false, rubro_id: undefined })
                            this.getRubros()
                        }}
                        rubro_id={this.state.rubro_id}
                        area_id={this.state.area_id}
                    />
                </Spin>
            </>
        )
    }
}
export default function (params) {

    const user = useContext(User);
    const { search, setShow } = useSearch()

    const permisos = usePermissions(user?.rol_id?.permisos, {
        canEdit: ["areas", "rubros", "edit"],
        canDelete: ["areas", "rubros", "delete"],
        canCreate: ["areas", "rubros", "create"],

    });

    return <Rubros
        {...params}
        params={useParams()}
        navigate={useNavigate()}
        {...permisos}
        search={search}
        setShowSearch={setShow}
    />
}
