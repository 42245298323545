import React, { Component } from "react";
import { Row, Col, Modal, Typography, List, Statistic, Drawer } from 'antd';
import axios from "axios"
import dayjs from "dayjs";

const { Text } = Typography

class ModalTransacciones extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            transacciones: []
        }
    }

    modalRef = React.createRef();

    componentDidMount() {

        if (this.props.orden_id) {
            this.getTransacciones()
        }
    }

    getTransacciones = () => {
        this.setState({ loading: true })
        axios.get('/ordenes/transacciones/' + this.props.orden_id).then(({ data }) => {
            this.setState({ loading: false, transacciones: data })
        }).catch(error => {
            console.log("error", error);
            this.setState({ loading: false })
        })
    }




    render() {

        const { loading, transacciones } = this.state;

        console.log('transacciones', transacciones)
        return (

            <List
                loading={loading}
                itemLayout="horizontal"
                dataSource={transacciones}
                pagination={false}
                footer={
                    <>
                        <Text strong >Monto Pendiente:</Text>
                        <Statistic
                            value={this.props.orden?.monto_pendiente}
                            valueStyle={{ fontSize: '16px', fontWeight: 'bold', color: '#F75' }}
                            prefix={'$'} suffix={"MXN"} /></>
                }
                renderItem={item => {

                    return <List.Item className="w-100">
                        <Row className="w-100">
                            <Col span={9}>
                                {item?._id?.concepto ?? item?.concepto ?? ''}
                            </Col>
                            <Col span={9}>
                                {dayjs(item?._id?.createdAt).format('LL')}
                            </Col>
                            <Col span={6}>
                                <Statistic
                                    value={item.monto}
                                    valueStyle={{ fontSize: '16px', fontWeight: 'bold', color: '#2c9d37' }}
                                    prefix={'$'} suffix={"MXN"} />
                            </Col>
                        </Row>

                    </List.Item>

                }}
            />

        )
    }
}



export default function (props) {
    const { open = false, onClose = () => { }, orden_id } = props

    return <Drawer
        open={open}
        onClose={onClose}
        title={"Transacciones"}
        // footer={null}
        closable={true}
        maskClosable={true}
        destroyOnClose={true}
        zIndex={1000}
        footer={null}
        width={600}
    >
        <ModalTransacciones {...props} />
    </Drawer>

}