import React, { Component } from "react";
import { Row, Col, Modal, Form, Input, message, Spin, Select, DatePicker,  Empty } from 'antd';
import { AvatarLoader } from "../../Widgets/Uploaders";
import PhoneInput from "../../Widgets/Inputs/PhoneInput";
import axios from "axios"
import { getResponseError } from "../../Utils";
import dayjs from "dayjs";
const { Option } = Select;



/**
 * @class ModalClientes
 * @description Modal para el CRUD de Clientes
 */
class ModalClientes extends Component {

    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            asesores: [],
            estados: [],
            paises: [],
            pais_id:null,
        }
    }

    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token')
        this.getAsesores()
        this.getPaises()
        if (this.props.cliente_id)
            this.get()

    }

    formRef = React.createRef();

    /**
    * @method addUser
    * @description Obtener al cliente
    */
    get = () => {
        this.setState({ loading: true })
        axios.get('/clientes/' + this.props.cliente_id)
            .then(({ data }) => {
                console.log("data", data);
                data.fecha_nacimiento = dayjs(data.fecha_nacimiento)
                this.formRef.current.setFieldsValue({
                    ...data,
                    avatar: data.avatar ? {
                        uid: data.avatar.file,
                        name: data.avatar.name,
                        status: 'done',
                        fromDB: true,
                        url: axios.defaults.baseURL + "/clientes/" + data._id + "?avatar=" + data.avatar.file + "&Authorization=" + sessionStorage.getItem("token")
                    } : null,
                    pais_id: data.pais_id ? {
                        label: data.pais_id.nombre,
                        value: data.pais_id._id,
                    } : null,
                    estado_id: data.estado_id ? {
                        label: data.estado_id.nombre,
                        value: data.estado_id._id,
                    } : null,
                    asesor_id: data.asesor_id ? {
                        label: data.asesor_id.nombre,
                        value: data.asesor_id._id,
                    } : null,
                })
                this.setState({ cliente: data.cliente_id })
            }).catch(error => {
                message.error('Error al obtener el cliente')
            }).finally(() => this.setState({ loading: false }))
    }


    /**
    * @method add
    * @description Añade un nuevo registro de cliente
    */
    add = (values) => {
        this.setState({ loading: true })
        axios.post('/clientes', values, {
            headers: {
                "Content-Type": 'multipart/form-data'
            }
        })
            .then(response => {
                message.success('¡Cliente Creado!')
                this.props.onClose()
            }).catch(error => {
                console.log("error", error);
                message.error(getResponseError(error.response, 'Error al crear el Cliente'))
            }).finally(() => this.setState({ loading: false }))
    }

    /**
    * @method updateUser
    * @description Añade un nuevo registro de cliente
    */
    update = (values) => {

        console.log('update', values)
        this.setState({ loading: true })
        axios.put('/clientes', values, {
            headers: {
                "Content-Type": 'multipart/form-data'
            }
        })
            .then(response => {
                message.success('¡Cliente Actualizado!')
                this.props.onClose()
            }).catch(error => {
                console.log("error", error);
                message.error(getResponseError(error.response, 'Error al actualizar el Cliente'))
            }).finally(() => this.setState({ loading: false }))
    }

    /**
   * @method onFinish
   * @description Se ejecuta al hacer submit al formulario
   */
    onFinish = (values) => {

        const formData = new FormData()

        if(values.asesor_id?.value) values.asesor_id = values.asesor_id.value
        console.log("values", values);

        formData.appendMultiple({
            ...values,
            id: this.props.cliente_id,
        })

        if (this.props.cliente_id) {
            this.update(formData)
        } else {
            this.add(formData)
        }
    }

    getEstados = (pais_id = this.state.pais_id, clean = false) => {

        if (clean)
            this.formRef.current.setFieldsValue({ estado_id: null })

        axios.get('/estados', {
            params: { pais_id }
        })
            .then(({ data }) => {
                this.setState({ estados: data.data })
            })
            .catch(error => {
                message.error('Ha ocurrido un error al obtener los estados.')
            })
            .finally(() => this.setState({ loading: false }))
    }

    getPaises = () => {
        axios.get('/paises')
            .then(({ data }) => {
                this.setState({ paises: data.data })
            })
            .catch(error => {
                message.error('Ha ocurrido un error al obtener los paises.')
            })
            .finally(() => this.setState({ loading: false }))
    }

    getAsesores = (search) => {
        axios.get('/usuarios', {
            params: {
                search,
                asesores: true
            }
        })
            .then(({ data }) => {
                //console.log(data)
                this.setState({ asesores: data.data })
            })
            .catch(error => {
                message.error('Ha ocurrido un error al obtener los vendedores.')
            })
            .finally(() => this.setState({ loading: false }))
    }

    render() {
        return (
            <Spin spinning={this.state.loading}>
                <Form
                    id="form-cliente"
                    layout={"vertical"}
                    onFinish={this.onFinish}
                    ref={this.formRef}
                    initialValues={{
                        tipo: 1,
                        estatus: 1
                    }}
                >
                    <Row justify="center" className="center">

                        <Col span={20}>
                            <Row justify={"center"}>
                                <Form.Item
                                    name="avatar"
                                >
                                    <AvatarLoader
                                        imageCrop={true}
                                        onRemove={() => {
                                            axios.put('/clientes/', {
                                                id: this.props.cliente_id,
                                                delete_avatar: true
                                            })
                                        }}

                                    />
                                </Form.Item>
                            </Row>

                            <Form.Item
                                name="nombre"
                                label="Nombre"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Ingrese el nombre',
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>

                            <Form.Item
                                name="apellido_paterno"
                                label="Apellidos"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Ingrese el apellido',
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>

                            {/*<Form.Item
                                name="apellido_materno"
                                label="Apellido Materno"
                            >
                                <Input />
                            </Form.Item>*/}

                            <Form.Item
                                name="fecha_nacimiento"
                                label="Fecha de Nacimiento"
                            >
                                <DatePicker className="w-100" />
                            </Form.Item>

                            <Form.Item
                                name="genero"
                                label="Género"

                            >
                                <Select
                                    options={[{
                                        label: 'Masculino',
                                        value: 0
                                    }, {
                                        label: 'Femenino',
                                        value: 1
                                    }, {
                                        label: 'Otro',
                                        value: 2
                                    }]}
                                />
                            </Form.Item>

                            <Form.Item
                                name="email"
                                label="Correo electronico"
                                rules={[
                                    {
                                        type: "email",
                                        message: "Ingrese un correo electronico válido"
                                    },{
                                        required: true,
                                        message: 'Ingrese un correo electronico',
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>



                            <Form.Item
                                name="telefono"
                                label="Telefono"
                            >
                                <PhoneInput />
                            </Form.Item>

                            <Form.Item
                                name="tipo"
                                label="Tipo"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Seleccione el tipo',
                                    },
                                ]}
                            >
                                <Select
                                    options={[{
                                        label: 'Minorista',
                                        value: 1
                                    }, {
                                        label: 'Mayorista',
                                        value: 2
                                    }]}
                                />
                            </Form.Item>
                            <Form.Item
                                name="estatus"
                                label="Estatus"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Seleccione el estatus',
                                    },
                                ]}
                            >
                                <Select
                                    options={[{
                                        label: 'Desistio',
                                        value: 0
                                    }, {
                                        label: 'Registrado',
                                        value: 1
                                    }, {
                                        label: 'Contactado',
                                        value: 2
                                    }, {
                                        label: 'Ha comprado',
                                        value: 3
                                    }, {
                                        label: 'Pagado',
                                        value: 4
                                    }]}
                                />
                            </Form.Item>

                            <Form.Item
                                label="Asesor"
                                name="asesor_id"
                            >
                                <Select
                                    showSearch
                                    defaultActiveFirstOption={false}
                                    showArrow={true}
                                    filterOption={false}
                                    allowClear={true}
                                    onSearch={(value) => this.getAsesores(value)}
                                    notFoundContent={<Empty
                                        description="No se encontró Cliente"
                                    />}
                                >
                                    {
                                        this.state.asesores.map(({ _id, nombre, apellidos }) => {
                                            return <Option key={_id} value={_id}>{`${nombre} ${apellidos || ""}`}</Option>
                                        })
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Spin>
        )
    }


}



export default function Modals(props) {

    const { visible = false, onClose = () => { }, cliente_id } = props

    return <Modal
        open={visible}
        onCancel={onClose}
        title={cliente_id ? "Editar Cliente" : "Crear Cliente"}
        closable={true}
        destroyOnClose={true}
        zIndex={1000}
        cancelText="Cancelar"
        okText="Guardar"
        okButtonProps={{ form: 'form-cliente', key: 'submit', htmlType: 'submit' }}
    >
        <ModalClientes  {...props} />
    </Modal>

}