import { Component } from "react";
import { List, Statistic, Row, Col, Typography, Empty, Tooltip, Space, message, Card, Tag, Button, Modal, Dropdown } from 'antd';
import useSearch from "../../../Hooks/useSearch";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { BiTask } from "react-icons/bi";
import { FaShopify } from "react-icons/fa";
import { FilterOutlined, MoreOutlined } from "@ant-design/icons";

import ReactECharts from 'echarts-for-react';
import DrawerFiltros from "../../Widgets/DrawerFiltros/DrawerFiltros";

const { Text } = Typography
const moment = require('moment')
const { confirm } = Modal;

class Productos extends Component {

    constructor(props) {
        super(props)
        this.state = {
            productos: {
                
                filters: [],
                
            },
            variantes_historicos: []
        }
    }

    /**
  * @memberof Cuentas
  * @method getInfo
  * @description Trae infofmacion general
  * 
  */
    getProductos = ({

        filters = this.state.productos.filters,

    } = this.state.productos) => {
        this.setState({ loading: true })
        console.log('filters', filters)
        axios.get('/dashboard/productos', {
            params: {

                filters: filters,

            }
        })
            .then(response => {
                console.log("response productos", response.data.variantes);

                this.setState({

                    variantes_historicos: response.data.variantes


                })
            }).catch(error => {

            }).finally(() => this.setState({ loading: false }))
    }

    componentDidMount() {

        this.getProductos()
    }

    render() {

        var option_3 = {
            grid: {
                containLabel: true
            },
            tooltip: {
                trigger: 'axis',

                axisPointer: {
                    type: 'shadow' // 'shadow' as default; can also be 'line' or 'shadow'
                },
                formatter: (params) => {
                    
                    let string_sabores = ""
                    for (let sabor of params[0].data.variantes) {

                        string_sabores += sabor.variante.sabor + "&emsp;" + sabor.cantidad + "<br/>"
                    }

                    return (
                        params[0].name + " - " + params[0].value.cantidad +
                        '<br/>' +

                        '<br/>' +
                        string_sabores


                    );
                },
            },
            dataset: {
                source: this.state.variantes_historicos
            },
            xAxis: {},
            yAxis: [{
                type: 'category', axisLine: {
                    lineStyle: {
                        width: 1,
                        color: 'black',
                        shadowColor: 'black',
                    }
                },
            }],
            series: [
                {
                    type: 'bar',
                    encode: {
                        // Map "amount" column to x-axis.
                        x: 'cantidad',
                        // Map "product" row to y-axis.
                        y: 'nombre'
                    }
                }
            ]
        }

        return (
            <>

                <Card title={<Row className="width-100 pl-1 pr-1">
                    <Col xs={23} className="">
                            <Text strong>Ventas Historicas</Text>
                        </Col>
                        <Col xs={1}>
                            <Button
                                onClick={() => this.setState({ visibleFilters: true })}
                                className="button-plus"
                                icon={<FilterOutlined style={{ color: '#000', fontWeight: 600 }} />}
                            />
                        </Col>
                </Row>} style={{ boxShadow: '0px 10px 15px -3px rgba(0,0,0,0.1)' }}>
                    <Row>
                        <Col span={24} className="center">
                            <ReactECharts
                                option={option_3}
                                style={{ width: '100%', maxWidth: 800 }}
                            />
                        </Col>
                    </Row>
                </Card>
                <DrawerFiltros
                    ref={this.drawerFiltros}
                    title="Filtrar Pedidos"
                    updateFilters={filters => this.getProductos({
                        filters
                    })}
                    onClose={() => this.setState({ visibleFilters: false })}
                    visible={this.state.visibleFilters}
                    //fechas={true}
                    clientes={true}
                    productos={true}
                    // shopify_financial_status={[
                    //     {
                    //         _id: "pending",
                    //         label: "Pago Pendiente"
                    //     }, {
                    //         _id: "partially_paid",
                    //         label: "Pago Parcial"
                    //     }, {
                    //         _id: "paid",
                    //         label: "Pagado"
                    //     }, {
                    //         _id: "voided",
                    //         label: "Pago Anulado"
                    //     }
                    // ]}
                    // shopify_fulfillment_status={
                    //     [
                    //         {
                    //             _id: "no_fulfilled",
                    //             label: "No Preparado"
                    //         }, {
                    //             _id: "fulfilled",
                    //             label: "Envio Preparado"
                    //         }
                    //     ]
                    // }
                />


            </>
        )
    }
}

const Vista = (props) => {
    const { setShow } = useSearch()
    const navigate = useNavigate()



    return <Productos {...props} setShowSearch={setShow} navigate={navigate} />
}

export default Vista;