import { Component } from "react";
import { List, Statistic, Row, Col, Typography, Empty, Tooltip, Space, message, Card, Tag, Button, Modal, Dropdown } from 'antd';
import useSearch from "../../../Hooks/useSearch";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { BiTask } from "react-icons/bi";
import { FaShopify } from "react-icons/fa";
import { FilterOutlined, MoreOutlined } from "@ant-design/icons";

import { renderEstatusOrdenPago, renderEstatusOrdenEnvio } from '../../Utils';
import ClienteSubMenu from "../../Widgets/Var/ClienteSubMenu";
import DrawerFiltros from "../../Widgets/DrawerFiltros/DrawerFiltros";
import ModalCancelarOrden from "../Ordenes/ModalCancelarOrden";
import ModalFulfilment from "../Ordenes/ModalFulfilment";
import ModalOrdenesDetalles from "../Ordenes/ModalOrdenesDetalles";
import ModalTransacciones from "../Ordenes/ModalTransacciones";
import ModalOrdenes from "../Ordenes/ModalOrdenes";
import DrawerBitacora from "../Ordenes/DrawerBitacora";


const { Text } = Typography
const moment = require('moment')
const { confirm } = Modal;

class Ordenes extends Component {

    constructor(props) {
        super(props)
        this.state = {
            ordenes: {
                data: [],
                limit: 50,
                page: 1,
                total: 0,
                filters: [],
                reglas: false
            },
        }
    }

    /**
    *
    * @memberof Ordenes
    * 
    * @method renderMenuItems
    * @description renderiza los elementos del menu de cada pedido
    */
    renderMenuItems = (orden) => {

        let menu_items = [
            {
                key: '1',
                label: <Text>Ver Detalle</Text>,
                onClick: () => this.setState({ modal_visible_detalle: true, orden_id: orden._id })
            },
            {
                key: '2',
                label: <Text>Ver Transacciones</Text>,
                onClick: () => this.setState({ modal_transacciones: true, orden_id: orden._id })
            },
            {
                key: '3',
                label: <Text>Ver PDF</Text>,
                onClick: () => {
                    let url = new URL(axios.defaults.baseURL + '/ordenes/pdf')
                    url.searchParams.append('orden_id', orden?._id)
                    url.searchParams.append('Authorization', sessionStorage.getItem('token'))
                    window.open(url, '_blank')
                }
            },
            {
                key: '6',
                label: <Text>Ver PDF de Empaquetado</Text>,
                onClick: () => {
                    let url = new URL(axios.defaults.baseURL + '/ordenes/packing')
                    url.searchParams.append('orden_id', orden?._id)
                    url.searchParams.append('timezone', Intl.DateTimeFormat().resolvedOptions().timeZone)
                    url.searchParams.append('Authorization', sessionStorage.getItem('token'))
                    window.open(url, '_blank')

                    this.getOrdenes();
                }
            },
        ]
        if (!orden.shopify_cancelled_at) {
            menu_items.push({
                key: '4',
                label: <Text>Editar</Text>,
                disabled: !this.props.canEdit,

                onClick: () => this.setState({ modal_visible: true, orden_id: orden._id })
            })
        }


        if (orden.shopify_draft_id && !orden.shopify_id) {
            menu_items.unshift({
                key: "0",
                label: <Text>Aprobar Draft</Text>,
                onClick: () => this.aprobarOrden(orden._id)
            })
        }

        if (orden.bitacora_log) {
            menu_items.push({
                key: "7",
                label: <Text>Ver Bitcora</Text>,
                onClick: () => this.setState({ drawer_bitacora: true, orden_id: orden._id })
            })
        }

        if (orden.shopify_fulfillment_status !== "fulfilled") {
            menu_items.push({
                key: "9",
                label: <Text>Preparar envio</Text>,
                onClick: () => this.setState({ modal_fulfillment: true, orden_id: orden._id })
            })
        }

        if (!orden.shopify_cancelled_at) {
            menu_items.push(
                {
                    key: '8',
                    label: <Text>Cancelar Pedido</Text>,
                    disabled: !this.props.canDelete,
                    onClick: () => {
                        confirm({
                            title: "¿Deseas CANCELAR este pedido?",
                            cancelText: "Cerrar",
                            okText: "SI",
                            onOk: () => {
                                this.setState({ loading: true })
                                axios.post('/ordenes/cancelar', {
                                    orden_id: orden._id
                                }).then((response) => {
                                    message.success('Pedido Cancelado')
                                    this.getOrdenes()
                                }).catch((error) => {
                                    message.error('Pedido NO Cancelado')
                                }).finally(() => this.setState({ loading: false }))
                            },
                        });
                    }
                }
            )
        }

        if (orden.shopify_cancelled_at) {
            menu_items.push({
                key: "7",
                label: <Text>Regresar Productos</Text>,
                onClick: () => this.setState({ modal_cancelar: true, orden_id: orden._id })
            })
        }

        menu_items.push({
            key: '5',
            label: <Text>Eliminar</Text>,
            disabled: !this.props.canDelete,
            onClick: () => {
                confirm({
                    title: "¿Deseas eliminar este pedido?",
                    cancelText: "Cancelar",
                    okText: "SI",
                    onOk: () => {
                        this.setState({ loading: true })
                        axios.delete('/ordenes/' + orden._id).then((response) => {
                            message.success('Pedido Eliminada')
                            this.getOrdenes()
                        }).catch((error) => {
                            message.error('Pedido NO Eliminado')
                        }).finally(() => this.setState({ loading: false }))
                    },
                });
            }
        }
        )

        return menu_items
    }

    /**
    * @memberof Ordenes
    * @description Obtiene una lista de ordenes provenientes directamente del api de shopify
    */
    getCancelados = ({
        limit = this.state.ordenes.limit,
        page = this.state.ordenes.page,
        reglas = this.state.ordenes?.reglas,
        filters = this.state.ordenes.filters,
        search = this.props.search,
    } = this.state.ordenes) => {

        this.setState({ loading: true })

        axios.get('/ordenes', {
            params: {
                limit,
                page,
                filters: filters.filter(item => item.name !== "producto_id" && item.name !== "variante_id"),
                canceladas: true,
                orden_detalles: true,
            }
        }).then(({ data }) => {
            this.setState({
                ordenes: {
                    ...data,

                }
            })

        }).catch(error => {
            message.error(error.response?.data?.message ?? 'Error al obtener los pedidos cancelados')
        }).finally(() => this.setState({ loading: false }))

    }

    componentDidMount() {

        this.getCancelados()
    }

    render() {

        return (
            <>

                <List
                    loading={this.state.loading}
                    className="component-list"
                    itemLayout="horizontal"
                    dataSource={this.state.ordenes.data}
                    pagination={{
                        current: this.state.ordenes.page,
                        pageSize: this.state.ordenes.limit,
                        total: this.state.ordenes.total,
                        position: 'bottom',
                        className: "flex-left",
                        pageSizeOptions: [10, 20, 50, 100],
                        showSizeChanger: true,
                        onChange: (page, limit) => this.getOrdenes({ page, limit })
                    }}
                    locale={{ emptyText: <Empty /> }}
                    header={<Row className="header-list width-100 pl-1 pr-1">
                        <Col xs={23} className=" center">
                            <Text strong>Pedidos cancelados</Text>
                        </Col>
                        <Col xs={1}>
                            <Button
                                onClick={() => this.setState({ visibleFilters: true })}
                                className="button-plus"
                                icon={<FilterOutlined style={{ color: '#000', fontWeight: 600 }} />}
                            />
                        </Col>
                        <Col xs={12} md={6} lg={6} xl={3} className=" center">
                            <Text strong>Folio</Text>
                        </Col>
                        <Col xs={12} md={6} lg={6} xl={3} className="center">
                            <Text strong>Artículos</Text>
                        </Col>
                        <Col xs={12} md={6} lg={6} xl={5} className="center">
                            <Text strong>Cliente</Text>
                        </Col>
                        <Col xs={12} md={6} lg={6} xl={3} className="center">
                            <Text strong>Fecha</Text>
                        </Col>
                        <Col xs={12} md={6} lg={6} xl={3} className="center">
                            <Text strong>Estatus Pago</Text>
                        </Col>
                        <Col xs={12} md={6} lg={6} xl={3} className="center">
                            <Text strong>Estatus Pedido</Text>
                        </Col>
                        <Col xs={23} md={11} lg={6} xl={3} className="center">
                            <Text strong>Monto</Text>
                        </Col>
                        <Col xs={1} md={1} lg={1} xl={1} className="center">
                            <Text strong></Text>
                        </Col>
                    </Row>
                    }
                    renderItem={item => {
                        const { total, cliente_id, fecha, ordenes_productos_ids, transaccion, id_transaction, id, referencia_numerica, _id, folio, shopify_cliente, total_productos } = item;
                        return <List.Item className="component-list-item">
                            <Tooltip trigger={(item.orden_id) ? ["hover"] : []} title=" Este pedido se clasificó previamente" placement="topLeft">
                                <Card
                                    style={{ ...(item.shopify_cancelled_at) ? { background: "rgba(0,0,0,0.1)" } : {} }}
                                    className="card-list">
                                    <Row className="width-100">
                                        <Col xs={12} md={6} lg={6} xl={3} className="center hover" onClick={() => this.setState({ modal_visible_detalle: true, orden_id: item._id })}>
                                            <Space>
                                                {item.bitacora_log ? <BiTask style={{ color: 'orange' }} /> : null}

                                                {!item.shopify_id && !item.shopify_draft_id ? <FaShopify style={{ color: "red" }} /> : null}
                                                <Text delete={item.shopify_cancelled_at}>{item.shopify_folio ?? folio}</Text>
                                                {item.shopify_draft_id && !item.shopify_id ? <Tag color="magenta">Draft</Tag> : null}
                                            </Space>
                                        </Col>
                                        <Col xs={12} md={6} lg={6} xl={3} className="center">
                                            <Text delete={item.shopify_cancelled_at}>{total_productos ?? 0} artículos</Text>
                                        </Col>
                                        <Col xs={12} md={6} lg={6} xl={5} className="center">
                                            <ClienteSubMenu
                                                delete_order={item.shopify_cancelled_at}
                                                cliente={cliente_id}
                                                shopify_cliente={shopify_cliente}
                                            />
                                        </Col>
                                        <Col xs={12} md={6} lg={6} xl={3} className="center">
                                            <Text strong delete={item.shopify_cancelled_at}>{moment(fecha).format('DD-MM-YYYY HH:mm')}</Text>
                                        </Col>
                                        <Col xs={12} md={6} lg={6} xl={3} className="center">
                                            {renderEstatusOrdenPago(item.shopify_financial_status)}
                                        </Col>
                                        <Col xs={12} md={6} lg={6} xl={3} className="center">
                                            {renderEstatusOrdenEnvio(item.shopify_fulfillment_status)}
                                        </Col>
                                        <Col xs={23} md={11} lg={11} xl={3} className="center">
                                            <Statistic
                                                delete={item.shopify_cancelled_at}
                                                value={total}
                                                valueStyle={{
                                                    fontSize: '16px',
                                                    fontWeight: 'bold',
                                                    color: item.shopify_financial_status == "paid" ? '#2c9d37' : "#ecc602"
                                                }}
                                                prefix={'$'} suffix={"MXN"} />
                                        </Col>
                                        <Col xs={1} md={1} lg={1} xl={1} className="center">
                                            <Space>
                                                <Dropdown
                                                    placement="bottomRight"
                                                    menu={{
                                                        items: this.renderMenuItems(item)
                                                    }}
                                                    trigger={['click']}
                                                >
                                                    <Button
                                                        type="ghost"
                                                        onClick={() => this.setState({ modalTransacDetalleVisible: true, transaccion_id: item._id })}
                                                        icon={<MoreOutlined style={{ color: "black" }} />}
                                                    />
                                                </Dropdown>

                                            </Space>
                                        </Col>

                                    </Row>
                                </Card>
                            </Tooltip>
                        </List.Item>

                    }}
                />
                <DrawerFiltros
                    ref={this.drawerFiltros}
                    title="Filtrar Pedidos"
                    updateFilters={filters => this.getCancelados({
                        page: 1,
                        filters
                    })}
                    onClose={() => this.setState({ visibleFilters: false })}
                    visible={this.state.visibleFilters}
                    fechas={true}
                    clientes={true}
                    productos={true}
                    shopify_financial_status={[
                        {
                            _id: "pending",
                            label: "Pago Pendiente"
                        }, {
                            _id: "partially_paid",
                            label: "Pago Parcial"
                        }, {
                            _id: "paid",
                            label: "Pagado"
                        }, {
                            _id: "voided",
                            label: "Pago Anulado"
                        }
                    ]}
                    shopify_fulfillment_status={
                        [
                            {
                                _id: "no_fulfilled",
                                label: "No Preparado"
                            }, {
                                _id: "fulfilled",
                                label: "Envio Preparado"
                            }
                        ]
                    }
                />

                <ModalOrdenes
                    open={this.state.modal_visible}
                    onClose={(flag) => {
                        this.setState({ modal_visible: false, orden_id: undefined })
                        if (flag === true)
                            this.getOrdenes();
                    }}
                    orden_id={this.state.orden_id}
                />

                <ModalOrdenesDetalles
                    open={this.state.modal_visible_detalle}
                    onClose={(flag) => {
                        this.setState({ modal_visible_detalle: false, orden_id: undefined })
                    }}
                    orden_id={this.state.orden_id}
                />

                <ModalTransacciones
                    open={this.state.modal_transacciones}
                    onClose={(flag) => {
                        this.setState({ modal_transacciones: false, orden_id: undefined })
                    }}
                    orden_id={this.state.orden_id}
                />

                <ModalCancelarOrden
                    open={this.state.modal_cancelar}
                    onClose={(flag) => {
                        this.setState({ modal_cancelar: false, orden_id: undefined })
                    }}
                    orden_id={this.state.orden_id}
                />

                <DrawerBitacora
                    open={this.state.drawer_bitacora}
                    onClose={() => {
                        this.setState({ drawer_bitacora: false, orden_id: undefined })
                    }}
                    orden_id={this.state.orden_id}
                />

                <ModalFulfilment
                    open={this.state.modal_fulfillment}
                    onClose={(flag) => {
                        this.setState({ modal_fulfillment: false, orden_id: undefined })
                        if (flag === true)
                            this.getOrdenes()
                    }}
                    orden_id={this.state.orden_id}

                />
            </>
        )
    }
}

const Vista = (props) => {
    const { setShow } = useSearch()
    const navigate = useNavigate()



    return <Ordenes {...props} setShowSearch={setShow} navigate={navigate} />
}

export default Vista;