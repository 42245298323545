import React from "react";
import {
  Card,
  Row,
  Col,
  Typography,
  Input,
  InputNumber,
  Button,
  Form,
  Avatar,
  message,
  Checkbox,
  Tooltip,
  Select,
  Empty,
  Space,
  Statistic,
  Image,
  Progress,
  Tag,
} from "antd";
import axios from "axios";
import "../../Styles/Global/cards.css";
import "./cards.css";
import { ButtonDelete, ButtonView, ButtonEdit } from "./Buttons";
import { FaArrowRight } from "react-icons/fa";
import { FiArrowDownRight, FiArrowUpRight } from "react-icons/fi";
import {
  CheckCircleFilled,
  DashboardOutlined,
  DollarOutlined,
  EyeOutlined,
  MenuOutlined,
} from "@ant-design/icons";
import { Link, useNavigate } from "react-router-dom";
import CustomAvatar from "./Avatar/Avatar";
import { LightenDarkenColor } from "lighten-darken-color";
import { IconMaster, IconVisa } from "./Iconos";
import { renderEstatusOrdenPago } from "../Utils";

const { Text, Title, Paragraph } = Typography;
const moment = require("moment");

/**
 *
 *
 * @const CardStatistics
 * @extends {React.Component}
 * @description Card para estadisticas del dashboard principal
 */
const CardStatistics = ({
  monto = 0,
  icon,
  color = "#fccb52",
  title,
  suffix,
  onClick,
  percent,
}) => {
  let aumento = percent !== undefined && !(percent < 0);

  return (
    <Card size="small" className="card-statistics">
      <Row align={"middle"} className="card-header">
        <Col flex="auto">
          <Text className="card-title">{title}</Text>
        </Col>
        <Col flex="none">
          {onClick && (
            <Button
              type="primary"
              className="btn-primary"
              icon={<FaArrowRight />}
              onClick={onClick}
            />
          )}
        </Col>
      </Row>
      <Row align={"middle"} className="card-content">
        <Col flex="auto">
          <Space size={2}>
            <Text className="card-monto">{monto}</Text>
            <Text className="card-suffix">{suffix}</Text>
          </Space>
        </Col>
        <Col flex="none" className="center">
          {icon && (
            <div
              className="icon-container"
              style={{
                backgroundColor: color + "B2",
              }}
            >
              {typeof icon === "function" ? icon() : icon}
            </div>
          )}
        </Col>
      </Row>
      {percent !== undefined && (
        <div className={`card-percent ${aumento ? "green" : "red"}`}>
          <Space align="center">
            {aumento ? (
              <FiArrowUpRight className="card-icon" color="#00CD98" />
            ) : (
              <FiArrowDownRight className="card-icon" color="#FF4D4F" />
            )}
            <Text className="card-monto">
              {aumento ? "+" : "-"} {Math.abs(percent)}%
            </Text>
          </Space>
        </div>
      )}
    </Card>
  );
};

/**
 *
 *
 * @export
 * @const CardPublicacion
 * @description Card para representar a una publicacion
 */
const CardPublicacion = (props) => {
  let ruta = "/img/placeholder.png";
  if (props?.banner)
    ruta = `${axios.defaults.baseURL}/publicacion/${props._id}?banner=true&Authorization=${sessionStorage.getItem("token")}`;

  return (
    <Card
      hoverable
      cover={
        <div className="card-rifa card-image">
          <img className="width-100" src={ruta} alt="Banner"></img>
        </div>
      }
      className="card-rifa"
    >
      <Row>
        <Col md={17} className="">
          <Tooltip placement="topLeft" title={props.nombre}>
            <Title level={3} className="title" ellipsis>
              {props.nombre}
            </Title>
          </Tooltip>
        </Col>
        <Col md={7} className="flex-right"></Col>
        <Col span={24}>
          <Paragraph className="descripcion"> {props.descripcion} </Paragraph>
        </Col>
        <Col span={24} className="center mb-1">
          <Text className="fecha">
            {" "}
            {moment(props.fecha_inicio).format(
              "dddd D [de] MMMM [del] YYYY",
            )} -{" "}
            {moment(props.fecha_finalizacion).format(
              "dddd D [de] MMMM [del] YYYY",
            )}
          </Text>
        </Col>
        <Col span={24} className="center">
          <Space>
            <ButtonView onClick={props?.onView} />
            <ButtonEdit disabled={!props.canEdit} onClick={props?.onEdit} />
            <ButtonDelete
              disabled={!props.canDelete}
              onConfirm={props?.onDelete}
            />
          </Space>
        </Col>
      </Row>
    </Card>
  );
};

/**
 * @const CardSaldo
 * @description Card que muetra el total de Saldo diponible entre todas las cuentas
 * @param num saldo suma de los saldos disponibles
 * @param num cuentas cantidad de cuentas registradas
 */
const CardSaldo = ({ saldo = 0, cuentas = 0 }) => {
  return (
    <Card className="card-shadow card-saldo">
      <Card.Meta
        className="card-saldo-main-meta"
        title="Saldo al Momento"
        description={
          <>
            <Text style={{ marginRight: "0.5rem" }}>
              $ {saldo?.toMoney ? saldo?.toMoney(true) : saldo}
            </Text>
            <small>MXN</small>
          </>
        }
      />
      <Card.Meta
        className="card-saldo-sub-meta"
        title="Cuentas Incluidas"
        description={<>{cuentas}</>}
      />
    </Card>
  );
};

const CardContabilidad = ({ color, icon, titulo, value, onClick = () => { } }) => {
  const newIcon = React.cloneElement(icon, {
    color: color,
    size: 25,
  });

  return (
    <Card className="card-shadow w-100" extra={<a onClick={onClick}><EyeOutlined style={{fontSize:20}}/> </a>}>
      <Row justify={"start"}>
        <div
          style={{
            backgroundColor: color + "33",
            padding: "1em",
            borderRadius: "12px",
          }}
          className="center"
        >
          {newIcon}
        </div>
      
      </Row>
      <Row className="mt-1">
        <Text style={{ fontFamily: "Poppins", fontSize: 20 }}> {titulo} </Text>
      </Row>
      <Row className="">
        <Text style={{ fontFamily: "Poppins", fontSize: 28, fontWeight: 850 }}>
          {value}
        </Text>
      </Row>
    </Card>
  );
};

/**
 * @const CardCuenta
 * @description Card de la descripcion del step del project manager
 * @param {*} step Object con la información del step
 * @param String color Color del step
 */
const CardCuenta = ({ cuenta = { saldo: 0 }, ver_saldo = false }) => {

  let logos = {
    1: <IconMaster />,
    2: <IconVisa />,
    3: <img src={"/img/AE.png"} alt="AE" height="30px" />,
  };

  const navigate = useNavigate();

  return (
    <Card
      bordered={false}
      className="card-cuenta"
      style={{
        background: `linear-gradient(244.64deg,
            ${LightenDarkenColor(cuenta.color ? cuenta.color : "#0022A9", -10)} 10%,
            ${LightenDarkenColor(cuenta.color ? cuenta.color : "#0022A9", 20)} 49.92%,
            ${LightenDarkenColor(cuenta.color ? cuenta.color : "#0022A9", -10)}  85.62%)`,
      }}
    >
      <Row style={{ marginBottom: "12px" }}>
        <Col span={4} className="center">
          <CustomAvatar
            name={[cuenta.nombre, cuenta.apellido]}
            image={cuenta.logo}
            color={cuenta.color}
          />
        </Col>
        <Col span={14} className="flex-left ">
          <Text className="cuenta-nombre" ellipsis>
            {cuenta.nombre}
          </Text>
        </Col>
        <Col span={6} className="center ">
          {cuenta.bxnk_cuenta_id ? (
            <Button
              className="btn-bxnk"
              onClick={() =>
                navigate(`/admin/finanzas/transacciones/bxnk/${cuenta._id}`)
              }
            >
              <DollarOutlined />
            </Button>
          ) : null}
        </Col>
      </Row>
      <Card.Meta
        className="card-saldo-sub-meta"
        title={
          <Row justify="space-between">
            <Col xs={18} lg={20}>
              <Text
                style={{ marginRight: "0.5rem" }}
                ellipsis
                className="text-white"
              >
                {`$ ${ cuenta.saldo && ver_saldo ? cuenta.saldo?.toMoney(true) + <small>MXN</small> : "Sin permisos" }` }
              </Text>
              
            </Col>
            <Col xs={6} lg={4}>
              {" "}
              <Image preview={false} src={"/img/Chip.png"} />
            </Col>
          </Row>
        }
      />
      <Row>
        <Col span={20} className="flex-left-column mt-2">
          <small strong style={{ paddingLeft: "7px" }} className="text-white">
            {cuenta.cuenta}
          </small>
          {cuenta.bxnk_cuenta_numero ? (
            <small strong style={{ paddingLeft: "7px" }} className="text-white">
              {cuenta.bxnk_cuenta_numero}
            </small>
          ) : null}
          {cuenta.bxnk_cuenta_nombre ? (
            <small strong style={{ paddingLeft: "7px" }} className="text-white">
              {cuenta.bxnk_cuenta_nombre}
            </small>
          ) : null}
        </Col>
        <Col span={4} className="center">
          {logos[cuenta.marca]}
        </Col>
      </Row>
    </Card>
  );
};

/**
 * @const CardProfit
 * @description Card de la descripcion del step del project manager
 * @param {*} step Object con la información del step
 * @param String color Color del step
 */
const CardGains = ({ title = "Profit", status = "success" }) => {
  return (
    <Card className="card-shadow  card-gains card-border-16">
      <Row align="middle" justify="center">
        <Col span={4} lg={4}>
          <div className={`${title === "Profit" ? "green-box" : "red-box"}`}>
            {/* {title === 'Profit' ? <IconProfit /> : <IconLoss />} */}
          </div>
        </Col>
        <Col span={16} lg={16}>
          <Text className="card-title center">{title}</Text>
        </Col>
        <Col span={4} lg={4} className=" dots">
          <Button ghost icon={<MenuOutlined />}></Button>
        </Col>
      </Row>
      <Row gutter={[8, 8]}>
        <Col span={24} className="center">
          <Text>{moment().format("DD/MM/YYYY")}</Text>
        </Col>
        <Col span={16} className="flex-left">
          <Text className="text-paid">Monto Pagado</Text>
        </Col>
        <Col span={8} className="flex-right">
          <Text className="text-paid">Total</Text>
        </Col>
        <Col span={24} className="center">
          <Progress percent={90} status={status} showInfo={false} />
        </Col>
      </Row>
      <Row>
        <Col span={24} className="center">
          <Text>{moment().format("DD/MM/YYYY")}</Text>
        </Col>
        <Col span={16} className="flex-left">
          <Text className="text-paid">Monto Pagado</Text>
        </Col>
        <Col span={8} className="flex-right">
          <Text className="text-paid">Total</Text>
        </Col>
        <Col span={24} className="center">
          <Progress percent={100} status={status} showInfo={false} />
        </Col>
      </Row>
    </Card>
  );
};

/**
 * @const CardTaskStep
 * @description Card de la descripcion del step del project manager
 * @param String title Titulo del step
 * @param String color Color del step
 * @param Number cantidad Numero o texto a mostrar
 */
const CardTaskStep = ({ title = "", color = "#bababa", cantidad = 0 }) => {
  return (
    <Card className="mb-1" style={{ borderBottom: `solid ${color} 8px` }}>
      <Row>
        <Col span={16}>
          <Title level={5} className="m-0" style={{ color: color }}>
            {title}
          </Title>
        </Col>
        <Col span={8} className="flex-right">
          <small style={{ color: color }}>{cantidad.toMoney(true, 0)}</small>
        </Col>
      </Row>
    </Card>
  );
};

/**
 * @const Navbar
 * @description Header del sistema
 * @param ObjectId id
 * @param Date fecha de la lista de task
 * @param String color del step
 * @param String description del task
 * @param Array Lista tasks
 * @param String Nombre del cliente
 *
 */
const CardTaskClient = ({
  folio = "#-------",
  color,
  total = 0,
  tags = [],
  articulos = 0,
  onClick = () => { },
  fecha,
  asesor_id = undefined,
  cliente_id = undefined,
  shopify_obj = {},
}) => {
  let dates = (now = moment(), date = fecha) => {
    let time_ago = "min";
    let diff_ago = now.diff(moment(date), "minutes");

    if (diff_ago > 120) {
      diff_ago = now.diff(moment(date), "hours");
      time_ago = "hrs";
      if (diff_ago > 23) {
        diff_ago = now.diff(moment(date), "days");
        time_ago = "days";
      }
    }

    return (
      <span>
        {diff_ago} {time_ago}
      </span>
    );
  };

  return (
    <Card className="card-task-client mb-1" onClick={onClick}>
      <Row gutter={[8, 8]}>
        <Col span={16}>
          <Title className="m-0" level={5}>
            {folio}
          </Title>
        </Col>
        <Col span={8} className="flex-right">
          <Tag color={color} style={{ fontSize: "10px" }}>
            {dates()}
          </Tag>
        </Col>
        <Col span={24} className="flex-right">
          <Text strong className="text-monto m-0" level={4}>
            $ {total.toMoney(true)}
          </Text>
        </Col>
        <Col span={24}>
          {renderEstatusOrdenPago(shopify_obj.financial_status, color)}
        </Col>
        <Col span={12}>
          <Text className="text-articulos">
            {shopify_obj?.line_items?.length} Articulos
          </Text>
        </Col>
        <Col span={12} className="flex-right">
          {
            <Text className="text-fecha">
              {moment(fecha).format("DD/MM/YYYY HH:mm")}
            </Text>
          }
        </Col>
        <Col span={24}>
          <Text className="text-cliente">
            Cliente: {cliente_id?.nombre ?? "-"}{" "}
            {cliente_id?.apellido_paterno ?? ""}
          </Text>
        </Col>
        <Col span={24}>
          {asesor_id ? (
            <>
              <CustomAvatar
                name={`${asesor_id?.nombre} ${asesor_id?.apellidos}`}
              />
              <Text className="text-usuario">
                {asesor_id?.nombre} {asesor_id?.apellidos}
              </Text>{" "}
            </>
          ) : (
            "Sin Asesor"
          )}
        </Col>
      </Row>
    </Card>
  );
};

export {
  CardStatistics,
  CardPublicacion,
  CardSaldo,
  CardCuenta,
  CardGains,
  CardTaskStep,
  CardTaskClient,
  CardContabilidad,
};
