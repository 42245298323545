import React, { Component } from "react";
import { Row, Col, Modal, Form, Input, message, Spin, Select, InputNumber, Button, Typography, Space, Divider, Tree } from 'antd';
import axios from "axios"
import { LinkOutlined, MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";

//componentes
import ProductoForm from './ProductoForm';
import SelectCliente from '../../Widgets/Inputs/SelectClientes';
import SelectProducto from '../../Widgets/Inputs/SelectProductos';
import SelectDireccion from '../../Widgets/Inputs/SelectDirecciones';
import InputTags from '../../Widgets/Inputs/InputTags';
import ImageProducto from "../../Widgets/ImageProducto"

import User from "../../../Hooks/Logged";
import { getResponseError } from "../../Utils";
import usePermissions from "../../../Hooks/usePermissions";
import { AvatarLoader, ImagesUploader } from "../../Widgets/Uploaders";


const { Option } = Select;
const { Text } = Typography
const { Search } = Input;

/**
 * @class ModalOrdenes
 * @description Modal para el CRUD de Productos
 */
class ModalOrdenes extends Component {

    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            inventario: {
                data: [],
                limit: 10,
                total: 0,
                page: 1,
                search: null
            },
            productos: [],
            clavesExpandidas: [],
        }
    }

    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token')
    }

    formRef = React.createRef();


    /**
    * @method onFinish
    * @description Se ejecuta al hacer submit al formulario
    */
    onFinish = (values) => {

        axios.get('/variantes', {
            params: {
                variantes: this.state.selectedVariants,
                new_orden_format: true,
            }
        }).then(response => {
            this.props.setVariantes(response.data)
            this.props.onClose();

        }).catch(error => {
            console.log("error", error);

        })
    }

    /**
    * @method onSearch
    * @description Se ejecuta al buscar productos
    */
    onSearch = ({target}) => {
        if(target.value)
            this.getProductos({ search: target?.value });
        else
           this.getProductos({search: "--"})
   }

    /**
    *
    * @memberof RazonesSociales
    * @method getRS
    * @description Obtiene la informacion de los Empresas y actualiza los valores locales de la lista.
    */
    getProductos = async ({
        page = this.state.productos.page,
        limit = this.state.productos.limit,
        search = this.props.search,
    } = this.state.productos) => {

        this.setState({ loading: true })
        axios.get('/productos', {
            params: {
                limit,
                page,
                search,
                inventario: true,
            }
        })
            .then(({ data }) => {
                this.setTree(data.data)
            })
            .catch(res => {
                message.error(res.response?.data?.message)
            })
            .finally(() => this.setState({ loading: false }))
    }

    /**
    * @method setTree
    * @description Se setea la informacion de los prodcutos para el el componente tree
    */
    setTree = (productos) => {
        let tree = [], clavesExpandidas = []

        for (let producto of productos) {

            let variantes = [];

    		for(let variante of producto.variantes){
    			variantes.push({
    				key: variante._id,
    				title: <Row className="width-100 flex-between">
                        <Col span={8}>
                            <Text>{variante.sabor}</Text>
                        </Col>
                        <Col span={8} className="center">
                            <Text>{variante.disponible} en stock</Text>
                        </Col>
                        <Col span={8} className="flex-right">
                            <Text>$ {variante.precio?.toMoney(true)} <small>MXN</small></Text>
                        </Col>
                    </Row>
    			})
    		}

    		tree.push({
    			key: producto._id,
    			title: <div><ImageProducto producto={producto} style={{marginLeft: "4px"}}/>{producto.modelo}</div>,
    			children: variantes
    		})

            clavesExpandidas.push(producto._id);

        }

        this.setState({ productos: tree, clavesExpandidas })
    }

    onCheckTree = (values) => {
        this.setState({ selectedVariants: values })
    }


    render() {

        const { orden } = this.state;

        return (
            <Spin spinning={this.state.loading}>
                <Row>
                    <Col span={24}>
                        <Search
                            className="width-100 mb-1"
                            placeholder="Buscar"
                            allowClear
                            onChange={this.onSearch}
                            style={{ width: 304 }}
                        />
                    </Col>
                </Row>
                <Form
                    id="form-variantes"
                    layout={"vertical"}
                    className="mt-2"
                    onFinish={this.onFinish}
                    ref={this.formRef}
                >
                    <Form.Item
                        label="Productos"
                        name="productos_ids"
                        style={{ minHeight: "250px" }}
                    >
                        <Tree
                            className="tree-productos"
                            checkable
                            autoExpandParent={true}
                            expandedKeys={this.state.clavesExpandidas}
                            treeData={this.state.productos}
                            onCheck={this.onCheckTree}
                        />
                    </Form.Item>
                </Form>
            </Spin>
        )
    }


}



export default function Modals(props) {

    const { open = false, onClose = () => { }, orden_id } = props

    const user = React.useContext(User)

    return <Modal
        open={open}
        onCancel={onClose}
        title={"Buscar productos"}
        closable={true}
        destroyOnClose={true}
        zIndex={1000}
        cancelText="Cancelar"
        okText="Guardar"
        okButtonProps={{ form: 'form-variantes', key: 'submit', htmlType: 'submit' }}
        width={700}
    >
        <ModalOrdenes  {...props} />
    </Modal>

}