import React, { Component } from 'react';
import { Row, Col, Typography, Spin, Layout, Space, message, Card, List, Tag } from 'antd'
import axios from 'axios';

import Logged, { User } from "../../../Hooks/Logged";
import FloatingButton from "../../Widgets/Floating Button/FloatingButton";

import ModalClientes from './ModalClientes'
import { ButtonDelete, ButtonEdit } from '../../Widgets/Buttons';
import usePermissions from '../../../Hooks/usePermissions';
import CustomAvatar from '../../Widgets/Avatar/Avatar';
import PageHeader from '../../Widgets/PageHeader/PageHeader';


const { Content } = Layout;
const { Text } = Typography

/**
 *
 *
 * @export
 * @class Clientes
 * @extends {Component}
 * @description Vista donde se listan todos los clientes
 */
class Clientes extends Component {
    static contextType = Logged;

    back = () => {
        this.props.history.goBack();
    }


    /**
     *Creates an instance of Clientes.
     * @param {*} props
     * @memberof Clientes
     */
    constructor(props) {
        super(props)
        this.state = {

            loading: false,
            clientes: {
                data: [],
                limit: 10,
                page: 1,
                total: 0,
                pages: 0,
                filters: []
            },
            cliente_id: null
        }
    }

    componentDidMount() {

        this.getClientes()
    }

    componentDidUpdate(prevProps) {
        if (this.props.search != prevProps.search) {
            this.getClientes()
        }
    }

    /**
    *
    * @memberof RazonesSociales
    * @method getRS
    * @description Obtiene la informacion de los Empresas y actualiza los valores locales de la lista.
    */
    getClientes = async ({
        page = this.state.clientes.page,
        limit = this.state.clientes.limit,
        search = this.props.search,
    } = this.state.clientes) => {

        this.setState({ loading: true })
        axios.get('/clientes', {
            params: {
                limit,
                page,
                search
            }
        })
            .then(({ data }) => {
                this.setState({ clientes: data })
            })
            .catch(res => {
                message.error(res.response.data.message)
            })
            .finally(() => this.setState({ loading: false }))
    }

    renderTipos = (status) => {

        switch (status) {
            case 1:
                return <Tag color="#FFE608">Normal</Tag>
            case 2:
                return <Tag color="#5465FF">Preferencial</Tag>

            default:
                return <Tag color="#FFE608">Normal</Tag>
        }
    }




    render() {

        return (
            <>
                <Spin spinning={this.state.loading}>
                    <PageHeader
                        className="admin-page-header"
                        title="Clientes"
                    >

                    </PageHeader>

                    <Content className="admin-content content-bg pd-1">
                        <List
                            className="component-list"
                            itemLayout="horizontal"
                            locale={{ emptyText: "Sin Clientes" }}
                            dataSource={this.state.clientes.data}
                            pagination={{
                                current: this.state.clientes.page,
                                pageSize: this.state.clientes.limit,
                                total: this.state.clientes.total,
                                hideOnSinglePage: false,
                                position: 'bottom',
                                onChange: (page, limit) => this.getClientes({ page, limit })

                            }}
                            header={<Row className="component-list-header width-100 pr-1 pl-1" >
                                <Col span={1} className="center">
                                </Col>
                                <Col span={8} className="center">
                                    <Text strong>Nombre</Text>
                                </Col>
                                <Col span={6} className="center">
                                    <Text strong>Teléfono</Text>
                                </Col>
                                <Col span={5} className="center">
                                    <Text strong>Tipo</Text>
                                </Col>

                            </Row>
                            }

                            renderItem={item => (
                                <List.Item className="component-list-item">
                                    <Card className="card-list">
                                        <Row className="width-100" gutter={[8, 8]}>
                                            <Col xs={12} md={1} className="center">
                                                <CustomAvatar
                                                    name={item.nombre}
                                                    url={item.avatar ? {
                                                        url: axios.defaults.baseURL + '/clientes/' + item._id,
                                                        avatar: item.avatar?.name,
                                                        logo: item.avatar.file
                                                    } : null}
                                                />
                                            </Col>
                                            <Col xs={12} md={8} className="center">
                                                <Text strong ellipsis>{item.nombre}</Text>
                                            </Col>
                                            <Col xs={12} md={6} className="center">
                                                <Text className="text-gray">{item.telefono}</Text>
                                            </Col>
                                            <Col xs={12} md={5} className="center">
                                                {this.renderTipos(item.tipo)}
                                            </Col>
                                            <Col xs={12} md={3} className="flex-right">
                                                <Space>
                                                    <ButtonEdit disabled={!this.props.canEdit} onClick={() => this.setState({ modalClientes: true, cliente_id: item._id })} />
                                                    <ButtonDelete disabled={!this.props.canDelete} onConfirm={() => axios.delete('/clientes/', { params: { cliente_id: item._id } }).then((response) => {
                                                        message.success(response?.data?.message)
                                                        this.getClientes()
                                                    })
                                                        .catch((error) => {
                                                            message.error(error?.response?.data?.message);
                                                            this.getClientes();
                                                        })
                                                    } />

                                                </Space>
                                            </Col>
                                        </Row>
                                    </Card>
                                </List.Item>
                            )}
                        />
                    </Content>

                    {this.props.canCreate && <FloatingButton title="Nuevo registro" onClick={() => this.setState({ modalClientes: true })} />}

                    <ModalClientes
                        visible={this.state.modalClientes}
                        cliente_id={this.state.cliente_id}
                        onClose={() => {
                            this.setState({ modalClientes: false, cliente_id: undefined })
                            this.getClientes()
                        }}
                    />
                </Spin>
            </>
        )
    }
}

export default function (props) {

    const user = React.useContext(User)
    const permisos = usePermissions(user?.rol_id?.permisos, {
        canEdit: ['clientes', 'edit'],
        canDelete: ['clientes', 'delete'],
        canCreate: ['clientes', 'create']
    })

    return <Clientes {...props} {...permisos} />
}