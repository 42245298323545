import React, { Component } from "react";
import { Row, Col, Modal, Form, message, Spin } from 'antd';

import axios from "axios"
import SelectCliente from '../../Widgets/Inputs/SelectClientes';
import SelectProveedor from '../../Widgets/Inputs/SelectProveedor';
import SelectAlmacenes from '../../Widgets/Inputs/SelectAlmacenes';
import SelectOrdenes from '../../Widgets/Inputs/SelectOrdenes';



class ModalCuentas extends Component {


    constructor(props) {
        super(props);
        this.state = {
            loading: false,
        }
    }

    refModalCompras = React.createRef();

    componentDidMount() {

        if (this.props.compra_id) {
            this.getCompra()
        }
    }

    /**
     * @memberof ModalCuentas
     * @method getCompra
     * @description Obtiene una compra de la DB
     *
     */
    getCompra = () => {
        this.setState({ loading: true })
        axios.get('/orden-compra/' + this.props.compra_id).then(response => {
            let compra = response.data;
            console.log("compra", compra);
            this.refModalCompras.current.setFieldsValue({
                proveedor_id: compra?.proveedor_id ? {
                    value: compra.proveedor_id?._id,
                    label: compra.proveedor_id?.nombre,
                } : null,
                cliente_id: compra?.cliente_id ? {
                    value: compra.cliente_id?._id,
                    label: compra.cliente_id?.nombre,
                } : null,
                almacen_id: compra?.almacen_id ? {
                    value: compra.almacen_id?._id,
                    label: compra.almacen_id?.nombre,
                } : null,
                orden_id: compra?.orden_id ? {
                    value: compra.orden_id?._id,
                    label: compra.orden_id?.folio,
                } : null,
            })

        }).catch(error => {
            message.error('Error al traer la cuenta')
            console.log(error)
        }).finally(() => this.setState({ loading: false }))
    }


    /**
     * @memberof ModalCuentas
     * @method onFinish
     * @description Se ejecuta al dar enter al formulario
     * 
     */
    onFinish = (values) => {

        if (this.props.compra_id) {
            this.updatecompra(values)
        } else {
            this.addCompra(values)
        }

    }

    /**
     * @memberof ModalCuentas
     * @method addCompra
     * @description Añade una nueva orden de compra a la BD
     *
     */
    addCompra = (values) => {
        this.setState({ loading: true })
        axios.post('/ordenes-compras', values)
            .then(response => {
                message.success('Compra creada')
                this.props.onClose(true, response.data._id)
            }).catch(error => {
                message.error('Error al crear la compra')
                console.log(error)
            }).finally(error => this.setState({ loading: false }))
    }

    /**
     * @memberof ModalCuentas
     * @method updatecompra
     * @description Actualiza la información de una compra
     * 
     */
    updatecompra = (values) => {
        values.compra_id = this.props.compra_id
        this.setState({ loading: true })
        axios.put('/ordenes-compras', values)
            .then(response => {
                message.success('Compra Actualizada')
                this.props.onClose()
            }).catch(error => {
                message.error('Error al actualizar la compra')
                console.log(error)
            }).finally(() => this.setState({ loading: false }))
    }


    render() {

        return (
            <Form
                layout="vertical"
                name="form-cuenta"
                id="form-cuenta"
                ref={this.refModalCompras}
                onFinish={this.onFinish}

            >
                <Spin spinning={this.state.loading}>
                    <Row justify="center" className="mt-2">
                        <Col xs={24} lg={20}>
                            <Form.Item
                                label="Proveedor"
                                name="proveedor_id"
                                rules={[{
                                    required: true,
                                    message: "Por favor, seleccione el proveedor"
                                }]}
                            >
                                <SelectProveedor />
                            </Form.Item>
                        </Col>
                        <Col xs={24} lg={20}>
                            <Form.Item
                                label="Cliente"
                                name="cliente_id"
                            >
                                <SelectCliente />
                            </Form.Item>
                        </Col>
                        <Col xs={24} lg={20}>
                            <Form.Item
                                label="Pedido"
                                name="orden_id"
                            >
                                <SelectOrdenes />
                            </Form.Item>
                        </Col>
                        <Col xs={24} lg={20}>
                            <Form.Item
                                label="Almacén"
                                name="almacen_id"
                            >
                                <SelectAlmacenes />
                            </Form.Item>
                        </Col>
                    </Row>
                </Spin>
            </Form>
        )
    }
}



export default function (props) {
    const { visible = false, onClose = () => { }, cuenta_id } = props

    return <Modal
        open={visible}
        onCancel={onClose}
        title={cuenta_id ? "Editar Compra" : "Crear Compra"}
        destroyOnClose={true}
        cancelText="Cancelar"
        okText="Guardar"
        okButtonProps={{ form: 'form-cuenta', key: 'submit', htmlType: 'submit' }}
    >
        <ModalCuentas {...props} />
    </Modal>

}