import React, { Component } from "react";
import { Row, Col, Modal, Form, message, Spin, InputNumber } from 'antd';
import axios from "axios"
import { getResponseError } from "../../../Utils";
import dayjs from "dayjs";



/**
 * @class ModalClientes
 * @description Modal para el CRUD de Clientes
 */
class ModalClientes extends Component {

    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            asesores: [],
            estados: [],
            paises: [],
            pais_id: null,
        }
    }

    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token')
        if (this.props.cliente_id)
            this.get()

    }

    formRef = React.createRef();

    /**
    * @method addUser
    * @description Obtener al cliente
    */
    get = () => {
        this.setState({ loading: true })
        axios.get('/clientes/' + this.props.cliente_id)
            .then(({ data }) => {
                console.log("data", data);
                data.fecha_nacimiento = dayjs(data.fecha_nacimiento)
                this.formRef.current.setFieldsValue({
                    ...data
                })
                this.setState({ cliente: data.cliente_id })
            }).catch(error => {
                message.error('Error al obtener el cliente')
            }).finally(() => this.setState({ loading: false }))
    }


    /**
    * @method updateUser
    * @description Añade un nuevo registro de cliente
    */
    update = (values) => {

        console.log('update', values)
        this.setState({ loading: true })
        axios.put('/clientes/credito', values)
            .then(response => {
                message.success('¡Cliente Actualizado!')
                this.props.onClose()
            }).catch(error => {
                console.log("error", error);
                message.error(getResponseError(error.response, 'Error al actualizar el Cliente'))
            }).finally(() => this.setState({ loading: false }))
    }

    /**
   * @method onFinish
   * @description Se ejecuta al hacer submit al formulario
   */
    onFinish = (values) => {

        if (this.props.cliente_id) {
            values.id = this.props.cliente_id
            this.update(values)
        }
    }

    render() {
        return (
            <Spin spinning={this.state.loading}>
                <Form
                    id="form-cliente"
                    layout={"vertical"}
                    onFinish={this.onFinish}
                    ref={this.formRef}
                >
                    <Row justify="center" className="center mt-2">
                        <Col span={20}>

                            <Form.Item
                                name="credito_limite"
                                label="Crédito"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Ingrese el Crédito',
                                    },
                                ]}
                            >
                                <InputNumber
                                    min={0}
                                    defaultValue={0}
                                    className="width-100"
                                    formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    parser={value => value ? value.replace(/\$\s?|(,*)/g, '') : 0}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Spin>
        )
    }


}



export default function Modals(props) {

    const { visible = false, onClose = () => { }, cliente_id } = props

    return <Modal
        open={visible}
        onCancel={onClose}
        title={cliente_id ? "Editar Cliente" : "Crear Cliente"}
        closable={true}
        destroyOnClose={true}
        zIndex={1000}
        cancelText="Cancelar"
        okText="Guardar"
        okButtonProps={{ form: 'form-cliente', key: 'submit', htmlType: 'submit' }}
    >
        <ModalClientes  {...props} />
    </Modal>

}