import React, { Component } from 'react';
import axios from 'axios';
import { PlusOutlined, } from '@ant-design/icons';
import { Row, Col, Typography, message, Card, Spin } from 'antd'

//componentes
import { ButtonDelete } from '../../../Widgets/Buttons';

//Modales
import ModalDirecciones from "./ModalDirecciones"

//scss
import '../../../../Styles/Modules/Admin/clientes.scss'


const { Text } = Typography


/**
 * @class Direcciones
 * @extends {Component}
 * @description Vista donde se listan las direcciones de un cliente
 */
class Direcciones extends Component {


    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            cliente: {

            },
            direcciones: []
        }
    }


    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token')
        if (this.props.cliente_id) this.getCliente()
    }

    /**
    *
    * @memberof Direcciones
    * 
    * @method getCliente
    * @description Obtiene informacion del Cliente
    */
    getCliente = () => {
        this.setState({ loading: true })
        axios.get('/clientes/' + this.props.cliente_id, {
        }).then(response => {

            let direcciones = response.data?.direcciones;
            if (direcciones.length > 0)
                direcciones.push({})
            else
                direcciones = [{}]

            this.setState({
                cliente: response.data,
                direcciones,
            })

        }).catch(error => {
            console.log("error", error);
            message.error("Error al obtener la información del cliente")
        }).finally(() => this.setState({ loading: false }))
    }


    render() {
        const { cliente, loading, direcciones } = this.state
        return (
            <>
                <Spin spinning={this.state.loading}>
                    <Row gutter={[12, 12]}>

                        {direcciones.map((direccion, index) => <Col xs={24} md={6}>
                            {!direccion._id ? <Card className="card-direccion new-direccion" onClick={() => this.setState({ modal_visible: true })}>
                                <PlusOutlined />
                            </Card> : <Card
                                className="card-direccion normal-direccion"
                                onClick={() => this.setState({ modal_visible: true, direccion_id: direccion._id, direccion })}
                                title={`${direccion.nombre ?? "Dirección " + index + 1} ${direccion.apellidos ?? ""}`}
                                extra={<ButtonDelete
                                    title={<Text>¿Deseas eliminar este registro?<br /> La direccion ser eliminada de <br />todos los pedidos relacionados.</Text>}
                                    onCancel={(e) => e.stopPropagation()}
                                    onConfirm={(e) => {
                                        e.stopPropagation()
                                        axios.delete('/cliente/direccion', { params: { id: direccion._id } }).then((response) => {
                                            message.success(response?.data?.message)
                                            this.getCliente()
                                        })
                                            .catch((error) => {
                                                console.log("error", error);
                                                message.error(error?.response?.data?.message);
                                                this.getCliente();
                                            })
                                    }} />}
                            >
                                <Row>
                                    <Col span={24} className="flex-left-column">
                                        <Text>{direccion.calles}</Text>
                                        <Text>{direccion.numero}</Text>
                                        <Text>{direccion.ciudad}, {direccion.estado_id?.nombre}</Text>
                                        <Text>{direccion.pais_id?.nombre_en}</Text>
                                        <Text>{direccion.codigo_postal}</Text>
                                        <Text>{direccion.telefono}</Text>
                                    </Col>
                                </Row>
                            </Card>}
                        </Col>)}

                    </Row>
                </Spin>

                <ModalDirecciones
                    visible={this.state.modal_visible}
                    onClose={(flag) => {
                        this.setState({ modal_visible: false, direccion_id: undefined, direccion: undefined })
                        if (flag === true) this.getCliente()
                    }}
                    cliente_id={this.state.cliente?._id}
                    direccion={this.state.direccion}
                    direccion_id={this.state.direccion_id}
                />
            </>
        )
    }
}



export default function (props) {

    return <Direcciones {...props}
    />
}       