import React from "react";
import { Route, Routes } from "react-router-dom";
import Vencidas from "../../Components/Admin/Contabilidad/Vencidas";
import Ventas from "../../Components/Admin/Contabilidad/Ventas/Ventas";

/**
 *
 * @export
 * @function RouterFinanzas
 * @description Router for Finanzas routes
 */
function RouterContabilidad(props) {
  return (
    <Routes>
      {/* <Route path="" element={<Finanzas {...props} />} /> */}
      <Route path="vencida" element={<Vencidas />} />
      <Route path="ventas" element={<Ventas {...props}/>} />
    </Routes>
  );
}

export default RouterContabilidad;
