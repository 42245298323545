import React, { Component } from "react";
import { Layout, Row, Col, Button, List, Space, Typography, Card, message, Popconfirm, } from "antd";

import axios from "axios"
import { Link, useNavigate } from "react-router-dom";
import { EditOutlined, DeleteOutlined, FileSearchOutlined, PlusOutlined, MinusOutlined, SwapOutlined, StopOutlined } from "@ant-design/icons";


//componentes
import Sort from '../../../Widgets/Sort'
import useSearch from "../../../../Hooks/useSearch";
import DrawerFiltros from "../../../Widgets/DrawerFiltros/DrawerFiltros";
import FloatingButton from "../../../Widgets/Floating Button/FloatingButton";

//Modales
import ModalTransaccion from '../../Finanzas/Transacciones/ModalTransaccion';
import ModalTransaccionDetalle from '../../Finanzas/Transacciones/ModalTransaccionDetalle';


const { Content } = Layout;
const { Text } = Typography;

const moment = require('moment')

/**
 *
 *
 * @export
 * @class Transacciones
 * @extends {Component}
 * @description Vista del listado de Transacciones
 */
class Transacciones extends Component {

	constructor(props) {
		super(props)
		this.state = {
			transacciones: {
				data: [],
				limit: 10,
				page: 1,
				total: 0,
				pages: 0,
				filters: [],
				sort: {},
				search: undefined
			}
		}
	}

	componentDidMount() {
		this.get();
	}

	componentDidUpdate(prevProps) {
		if (this.props.search !== prevProps.search) {
			this.get({ page: 1, search: this.props.search })
		}
	}

	/**
	*
	*
	* @memberof Transacciones
	* @description Obtiene todas todas las transacciones
	* @param page pagina actual
	* @param limit limite de objetos de una pagina
	*/
	get = ({
		page = this.state.transacciones.page,
		limit = this.state.transacciones.limit,
		filters = this.state.transacciones.filters,
		sort = this.state.transacciones.sort,
		search = this.state.transacciones.search

	} = this.state.transacciones) => {
		axios.get('/transacciones', {
			params: {
				page,
				limit,
				filters,
				sort,
				search,
				cliente_id: this.props.cliente_id,
				tipo_pago: [3]
			}
		})
			.then(({ data }) => {
				console.log("data", data);
				this.setState({
					transacciones: {
						...this.state.transacciones,
						...data,
						sort,
						search,
						filters,
						page
					}
				});
			})
			.catch(error => {
				message.error(error.response?.data?.message ?? 'No se pudieron cargar las transacciones')
			})
			.finally(() => this.setState({ loading: false }))
	}


	/**
	 *
	 *
	 * @param {*} key
	 * @memberof Transacciones
	 */
	setSort = (key) => {
		let value;
		switch (this.state.transacciones.sort[key]) {
			case 1:
				value = -1
				break;
			case -1:
				value = undefined
				break;
			default:
				value = 1
				break;
		}
		this.get({
			sort: {

				[key]: value
			}
		})
	}

	/**
	 * @param {*} tipo
	 * @memberof Transacciones
	 * @description Renderiza el icono según el estatus
	 */
	renderIconTransaccion = (tipo) => {
		switch (tipo) {
			case 1:
				return <PlusOutlined style={{ color: "currentColor" }} />
			case 2:
				return <MinusOutlined style={{ color: "currentColor" }} />
			case 3:
				return <SwapOutlined style={{ color: "currentColor" }} />
			case 4:
				return <StopOutlined style={{ color: "currentColor" }} />
		}
	}

	getTipoColor = tipo => {
		const colors = [
			'#36F905',
			'#EB5757',
			'#1890FF',
			'#000000'
		]

		return colors[tipo - 1]
	}

	deleteFilter = (event, filtro) => {
		event.preventDefault()
		let { _id, objectName } = filtro

		let form = this?.drawerFiltros?.formFiltros?.current

		try {
			let values = form?.getFieldValue(objectName)
			let index = values.findIndex(e => e === _id)
			values.splice(index, 1)

			let newValues = {}
			newValues[objectName] = values
			form?.setFieldsValue(newValues)
			form?.submit()
		} catch (error) {
			console.log('error', error)
			this.setState({ visibleFilters: true })
		}
	}

	render() {

		const { filters } = this.state.transacciones

		return (
			<>
				<Content>
					<List
						loading={this.state.loading}
						className="component-list"
						itemLayout="horizontal"
						locale={{ emptyText: "Sin Transacciones" }}
						dataSource={this.state.transacciones?.data}
						pagination={{
							current: this.state.transacciones.page,
							pageSize: this.state.transacciones.limit,
							total: this.state.transacciones.total,
							position: 'bottom',
							className: "flex-left",
							onChange: (page, limit) => this.get({ page, limit })
						}}
						header={<Row className="header-list width-100 pl-1 pr-1">
							<Col span={2} className="center ">
								<Sort
									sort={this.state.transacciones?.sort["fecha"]} onClick={() => this.setSort("fecha")} >
									<Text strong >FECHA</Text>
								</Sort>
							</Col>
							<Col xs={5} className="center ">
								<Sort
									sort={this.state.transacciones.sort["concepto"]} onClick={() => this.setSort("concepto")} >
									<Text strong >CONCEPTO</Text>
								</Sort>
							</Col>
							<Col xs={4} className="center ">
								<Sort
									sort={this.state.transacciones.sort["area_id.nombre"]} onClick={() => this.setSort("area_id.nombre")} >
									<Text strong >ÁREA</Text>
								</Sort>
							</Col>
							<Col xs={4} className="center ">
								<Sort
									sort={this.state.transacciones.sort["rubro_id.nombre"]}
									onClick={() => this.setSort("rubro_id.nombre")} >
									<Text strong >RUBRO</Text>
								</Sort>
							</Col>
							<Col xs={3} className=" center ">
								<Sort
									sort={this.state.transacciones.sort["cuenta_id.nombre"]}
									onClick={() => this.setSort("cuenta_id.nombre")} >
									<Text strong >CUENTA</Text>
								</Sort>
							</Col>
							<Col xs={4} className="center ">
								<Sort
									sort={this.state.transacciones.sort["monto"]}
									onClick={() => this.setSort("monto")} >
									<Text strong >MONTO</Text>
								</Sort>
							</Col>
						</Row>
						}

						renderItem={item => (
							<List.Item className="component-list-item">
								<Card className="card-list">
									<div className={`${item.bxnk_transaccion_id ? "badge-card-bxnk" : ""}`}>
									</div>
									<Row className="width-100" gutter={[4, 4]}>
										<Col span={2} className="center ">
											<Text strong>{moment(item.fecha).format('DD/MM/YYYY')}</Text>
										</Col>
										<Col xs={5} className="center ">
											<Text ellipsis className="text-gray-dark">{item.concepto}</Text>
										</Col>
										<Col xs={4} className="center ">
											<Text ellipsis className="text-gray-dark">{item.area_id?.nombre}</Text>
										</Col>
										<Col xs={4} className="center ">
											<Text ellipsis className="text-gray-dark">{item.rubro_id?.nombre}</Text>
										</Col>
										<Col xs={3} className=" center ">
											<Text className="text-gray-dark">{item.cuenta_id?.nombre}</Text>
										</Col>
										<Col xs={4} className="center ">
											<Text style={{ fontWeight: "bold", color: this.getTipoColor(item.tipo) }} >
												{this.renderIconTransaccion(item.tipo)} $ {item.monto.toMoney(true)} MXN</Text>
										</Col>
										<Col xs={2} className="center ">
											<Space>
												<Button
													icon={<FileSearchOutlined style={{ color: "currentcolor" }} />}
													type="primary"
													title="Ver Detalle"
													onClick={() => this.setState({ modalTransacDetalleVisible: true, transaccion_id: item._id })}>
												</Button>
												<Button
													disabled={item.bxnk_transaccion_id}
													icon={<EditOutlined style={{ color: "currentcolor" }} />}
													title="Editar"
													onClick={() => this.setState({ modalTransacVisible: true, transaccion_id: item._id })}>
												</Button>
												<Popconfirm
													placement="topRight"
													title="¿Deseas eliminar esta Transacción?"
													onConfirm={() => axios.delete('/transacciones/' + item._id).then((response) => {
														message.success('Transaccion Eliminada')
														this.props.updateCliente()
													}).catch((error) => {
														message.error('Transacción NO Eliminada')
													}).finally(() => {
														this.get();
													})
													}
													okText="Si"
													cancelText="No"
												>
													<Button type="primary" danger icon={<DeleteOutlined style={{ color: "currentcolor" }} />} title="Eliminar" />
												</Popconfirm>
											</Space>
										</Col>
									</Row>
								</Card>
							</List.Item>
						)}
					/>
				</Content>

				{/* <Tooltip title="Agregar una Nueva Transacción" > */}
				<FloatingButton title="Nuevo registro" onClick={() => this.setState({ modalTransacVisible: true })} />

				<ModalTransaccion
					visible={this.state.modalTransacVisible}
					transaccion_id={this.state.transaccion_id}
					onClose={(flag) => {
						this.setState({ modalTransacVisible: false, transaccion_id: undefined })
						if (flag === true) {
							this.get()
							this.props.updateCliente()
						}
					}}
					cliente={this.props.cliente}
				/>
				<ModalTransaccionDetalle
					visible={this.state.modalTransacDetalleVisible}
					onClose={() => this.setState({
						modalTransacDetalleVisible: false,
						transaccion_id: null
					})}
					id={this.state.transaccion_id}
				/>
				<DrawerFiltros
					title="Filtrar Transacciones"
					ref={ref => this.drawerFiltros = ref}
					updateFilters={filters => this.get({
						page: 1,
						filters
					})}
					onClose={() => this.setState({ visibleFilters: false })}
					visible={this.state.visibleFilters}
					clientes={true}
					fechas={true}
					cuentas={true}
				/>
			</>
		)
	}
}



/**
 *
 *
 * @export
 * @param {*} props
 * @returns
 */
export default function (props) {

	const { search, setShow } = useSearch()

	return (<Transacciones {...props} navigate={useNavigate()} search={search} setShowSearch={setShow} />)
}