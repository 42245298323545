import React, { Component } from "react";
import { Row, Col, Modal, Form, Spin, DatePicker, Space, Radio, Button } from 'antd';
import axios from "axios"


const { RangePicker } = DatePicker;

/**
 * @class ModalReporte
 * @description Modal para el CRUD de Clientes
 */
class ModalReporte extends Component {

    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            fechas: [],
            tipo_reporte: "all",
        }
    }


    formRef = React.createRef();


    /**
     *
     * @memberof ClientesDetalles
     * 
     * @method getPDFProductos
     * @description Generamos el PDF con los poductos mas comprados por el cliente
     */
    getPDFProductos = () => {

        let { fechas, tipo_reporte } = this.formRef.current.getFieldsValue()

        let url = new URL(axios.defaults.baseURL + '/reportes/clientes')
        url.searchParams.append('Authorization', sessionStorage.getItem('token'))
        if (fechas) {
            url.searchParams.append('fecha_inicial', fechas[0])
            url.searchParams.append('fecha_final', fechas[1])
        }
        url.searchParams.append('tipo_reporte', tipo_reporte)
        window.open(url, '_blank')
    }

    /**
    *
    * @memberof ClientesDetalles
    * 
    * @method getCSVPedidos
    * @description Generamos el csv de los pedidos
    */
    getCSVPedidos = () => {

        let { fechas, tipo_reporte } = this.formRef.current.getFieldsValue()

        let url = new URL(axios.defaults.baseURL + '/reportes/clientes')
        url.searchParams.append('Authorization', sessionStorage.getItem('token'))
        if (fechas) {
            url.searchParams.append('fecha_inicial', fechas[0])
            url.searchParams.append('fecha_final', fechas[1])
        }
        url.searchParams.append('tipo_reporte', tipo_reporte)
        url.searchParams.append('csv', true)
        window.open(url, '_blank')
    }


    render() {
        return (
            <Spin spinning={this.state.loading}>
                <Form
                    className="mt-3"
                    id="form-cliente"
                    layout={"vertical"}
                    ref={this.formRef}
                    initialValues={{
                        tipo_reporte: "all"
                    }}
                >
                    <Row gutter={[8, 8]}>
                        <Col span={22}>
                            <Form.Item
                                label="Fechas"
                                name={'fechas'}
                            >
                                <RangePicker />
                            </Form.Item>
                        </Col>
                        <Col span={22}>
                            <Form.Item
                                label="Selecciona una opción"
                                name={'tipo_reporte'}
                                rules={[{ required: true, message: 'Selecciona una opción' }]}
                            >
                                <Radio.Group>
                                    <Space direction="vertical">
                                        <Radio value="all">Todos los clientes</Radio>
                                        <Radio value="more">Clientes con pedidos múltiples (más de 10 pedidos)</Radio>
                                        <Radio value="minus">Clientes con pocos pedidos (10 o menos pedidos)</Radio>
                                    </Space>
                                </Radio.Group>
                            </Form.Item>
                        </Col>
                        <Col span={22}>
                            <Space>
                                <Button
                                    title="Reporte CSV"
                                    onClick={() => this.getCSVPedidos()}
                                >
                                    Reporte CSV
                                </Button>
                                <Button
                                    type="primary"
                                    title="Reporte PDF"
                                    onClick={() => this.getPDFProductos()}
                                >
                                    Reporte PDF
                                </Button>
                            </Space>
                        </Col>
                    </Row>
                </Form>
            </Spin>
        )
    }


}



export default function Modals(props) {

    const { visible = false, onClose = () => { }, direccion_id } = props

    return <Modal
        visible={visible}
        onCancel={onClose}
        title={"Generar reporte, pedidos por clientes"}
        closable={true}
        destroyOnClose={true}
        zIndex={1000}
        footer={null}
        width={600}
    >
        <ModalReporte  {...props} />
    </Modal>

}