import React, { Component, useContext } from 'react';
import { Row, Col, Spin, Layout, message, Space, List, Card, Typography, Tag, Tooltip } from 'antd'

import axios from 'axios';
import { Link } from "react-router-dom";

//Componentes
import User from "../../../Hooks/Logged";
import usePermissions from "../../../Hooks/usePermissions";
import FloatingButton from "../../Widgets/Floating Button/FloatingButton";
import PageHeader from '../../Widgets/PageHeader/PageHeader';
import { FaShopify } from "react-icons/fa";

//Modales
import ModalAlmacenes from './ModalAlmacenes'
import { ButtonDelete, ButtonEdit, ButtonView } from '../../Widgets/Buttons';
import useSearch from '../../../Hooks/useSearch';

const { Content } = Layout;
const { Text } = Typography
/**
 * @export
 * @class Almacenes
 * @extends {Component}
 * @description Vista de Almacenes
 */
class Almacenes extends Component {

    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            modalVisible: false,

            almacen_id: undefined,
            filtroSearch: '',
            searching: true,

            almacenes: {
                data: [],

                page: 1,
                limit: 10,
                total: 0,
                pages: 0,
            }
        }
    }

    componentDidMount() {
        this.props.setShowSearch(true)
        this.getAlmacenes();
    }

    componentDidUpdate(prevProps) {
        if (this.props.search !== prevProps.search) {
            this.getAlmacenes({ page: 1, search: this.props.search })
        }
    }

    componentWillUnmount() {
        this.props.setShowSearch(false)
    }

    /**
     *
     *
     * @memberof Almacenes
     * 
     * @method getAlmacenes
     * @description Obitiene las Almacenes
     */
    getAlmacenes = ({
        page = this.state.almacenes.page,
        limit = this.state.almacenes.limit,
        search = this.props.search
    } = this.state.almacenes) => {
        this.setState({ loading: true })
        axios.get('/almacenes', {
            params: { limit, page, search }
        })
            .then(({ data }) => {
                this.setState({ almacenes: data })
            })
            .catch(res => {
                message.error(res.response.data ?? "No fue posible obtener las áreas")
            })
            .finally(() => this.setState({ loading: false }))
    }


    render() {

        const { almacenes } = this.state;

        return (
            <>
                <Spin spinning={false}>
                    <PageHeader
                        className="admin-page-header"
                        title="Almacenes"
                    />
                    <Content className="admin-content content-bg pd-1">
                        <List
                            loading={this.state.loading}
                            className="component-list"
                            itemLayout="horizontal"
                            locale={{ emptyText: "Sin Almacenes" }}
                            dataSource={almacenes.data}
                            pagination={{
                                current: almacenes.page,
                                pageSize: almacenes.limit,
                                total: almacenes.total,
                                hideOnSinglePage: false,
                                position: 'bottom',
                                onChange: (page, limit) => this.getAlmacenes({ page, limit })
                            }}
                            header={<Row className="width-100 pr-1 pl-1" >
                                <Col span={5} className="center">
                                    <Text strong>Nombre</Text>
                                </Col>

                                <Col span={8} className="center">
                                    <Text strong>Calle</Text>
                                </Col>
                                <Col span={4} className="center">
                                    <Text strong>Colonia</Text>
                                </Col>
                                <Col span={3} className="center">
                                    <Text strong>Código Postal</Text>
                                </Col>
                                <Col span={4} className="center">
                                    <Text strong>Acciones</Text>
                                </Col>

                            </Row>
                            }

                            renderItem={item => (
                                <List.Item className="component-list-item">
                                    <Card className="card-list">
                                        <Row className="width-100 ">

                                            <Col span={5} className="flex-left">
                                                <Space>
                                                    <Text strong>{item.shopify_almacen_id ? <FaShopify /> : null} {item.nombre}</Text>
                                                    { item.default_retorno ? <Tooltip placement="topRight" title="Sucursal a la que se devolverán los productos al regresar desde el sistema">
                                                        <Tag color="purple">Default</Tag> 
                                                    </Tooltip> : null }
                                                </Space>
                                                    { item.tienda_rappi ? <img src="/img/Rappi.svg" style={{ height: 40 }}/> : null }
                                            </Col>
                                            <Col span={8} className="center">
                                                <Text className="text-gray">{item.direccion_id?.calles ?? "-"}</Text>
                                            </Col>
                                            <Col span={4} className="center">
                                                <Text className="text-gray">{item.direccion_id?.colonia ?? "-"}</Text>
                                            </Col>
                                            <Col span={3} className="center">
                                                <Text className="text-gray">{item.direccion_id?.codigo_postal ?? "-"}</Text>
                                            </Col>
                                            <Col span={4} className="center">
                                                <Space>
                                                    <Link title="Editar" to={`/admin/almacenes/ubicaciones/${item._id}`}>
                                                        <ButtonView title="Ver Ubicaciones" />
                                                    </Link>
                                                    <ButtonEdit
                                                        title="Editar"
                                                        onClick={() => { this.setState({ modalVisible: true, almacen_id: item._id }) }}
                                                        disabled={!this.props.canEdit}

                                                    />
                                                    <ButtonDelete
                                                        disabled={!this.props.canDelete}

                                                        onConfirm={() => {
                                                            this.setState({ loading: true })
                                                            axios.delete('/almacenes/' + item._id).then(() => {
                                                                this.getAlmacenes()
                                                                message.success('¡Almacén eliminado!')
                                                            }).catch(error => {
                                                                console.log(error)
                                                                this.setState({ loading: false })
                                                                message.error("Error al eliminar el almacén")
                                                            })
                                                        }
                                                        }
                                                        title="Eliminar"
                                                    />

                                                </Space>
                                            </Col>
                                        </Row>
                                    </Card>
                                </List.Item>
                            )}
                        />

                    </Content>

                    {this.props.canCreate && <FloatingButton title="Nuevo registro" onClick={() => this.setState({ modalVisible: true })} />}
                    <ModalAlmacenes
                        visible={this.state.modalVisible}
                        onClose={() => {
                            this.setState({ modalVisible: false, almacen_id: undefined })
                            this.getAlmacenes()
                        }}
                        almacen_id={this.state.almacen_id}
                    />
                </Spin>
            </>
        )
    }
}

export default function Vista(props) {

    const user = useContext(User);
    const { search, setShow } = useSearch()

    const permisos = usePermissions(user?.rol_id?.permisos, {
        canEdit: ["almacenes", "edit"],
        candDelete: ["almacenes", "delete"],
        canCreate: ["almacenes", "create"],
    });

    return <Almacenes {...props} {...permisos} search={search} setShowSearch={setShow} />

}