import React, { Component, useContext } from 'react';
import { Layout, Row, Col, Tooltip, Typography, List, Modal, message, Space, Collapse, Select, Pagination, DatePicker, Form, Button, Divider, Spin, Card } from "antd";
import axios from 'axios';
import dayjs from "dayjs";
import { GiCancel } from "react-icons/gi";
import { BsBoxSeam } from "react-icons/bs";
import ReactECharts from 'echarts-for-react';
import { AiFillDollarCircle } from "react-icons/ai";
import { useNavigate, useParams } from "react-router-dom";
import { FaShopify, FaTruck, FaSitemap } from "react-icons/fa";
import { FilePdfOutlined, FileExcelOutlined, MoreOutlined, RightOutlined } from '@ant-design/icons';

//Componentes
import User from "../../../../Hooks/Logged";
import { renderEstatusOrdenEnvio, renderEstatusOrdenPago } from "../../../Utils";
import usePermissions from "../../../../Hooks/usePermissions";
import PageHeader from '../../../Widgets/PageHeader/PageHeader';

//Modales
import SelectClientes from '../../../Widgets/Inputs/SelectClientes';
import SelectVariantes from '../../../Widgets/Inputs/SelectVariantes';



const { Content } = Layout;
const { confirm } = Modal;
const { Text } = Typography;
const { RangePicker } = DatePicker;

const moment = require('moment')

/**
 *
 *
 * @export
 * @class Ordenes
 * @extends {Component}
 * @description Vista del listado de Ordenes de Shopify
 */
class Ordenes extends Component {

    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            variante_id: null,
            cliente: {},
            total_venta: 0,
            seleccionadas: {},
            fechas: [dayjs().startOf('month'), dayjs().endOf("month")],
            pedidos_por_anio: [],
            totales: [],
            estadisticas: {
                enviados: 0,
                productos: 0,
                variantes: 0,
            },
            productos_comprados: [],
            productos_nombre: [],
            ordenes: {
                data: [],
                limit: 5,
                page: 1,
                total: 0,
            },
        }
    }

    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        this.getGrafica()
    }


    getGrafica = ({
        year = this.state.year,
        fecha_inicio = this.state.fecha_inicio,
        fecha_final =  this.state.fecha_final,
        page = this.state.ordenes.page,
        limit = this.state.ordenes.limit,
    } = this.state) => {
        this.setState({loading: true})
        axios.get("/cliente/analyitics/grafica",{
            params:{
                cliente_id: this.props.cliente_id,
                year,
                fecha_inicio,
                fecha_final,
                page,
                limit
            }
        })
        .then(({data}) => {
            console.log("data", data);

            let total_venta = 0;

            if(data.totales){
                for(let elem of data.totales){
                    total_venta += elem.value;
                }
            }

            this.setState({...data, total_venta, year})
        }).catch(error => {
            console.log("error", error.response);
        }).finally(()=>this.setState({loading: false}))
    }

    /**
     * @method renderOptions
     * @descripcion Renderiza las opciones del select 
     * */
    renderOptions = () => {
        const currentYear = dayjs().year();

        const yearsArray = [];
        for (let year = 2023; year <= currentYear; year++) {
            yearsArray.push({ value:year, label: year});
        }

        return yearsArray
    }

    /**
     * @memberof Dashboard
     * @method dates
     * @description Actualiza el rango de fechas para realizar la buequeda
     */
    onChangeDates = (dates) => {
        if (dates) {
            this.setState({
                fecha_inicio: dates[0],
                fecha_final: dates[1]
            }, () => {
                this.getGrafica()
            })
        }else{
            this.setState({
                fecha_inicio: undefined,
                fecha_final: undefined
            }, () => {
                this.getGrafica()
            })
        }
    }

    optionsGrafica = () => {

        let series = this.state.productos_comprados.length > 0 ? this.state.productos_comprados : Array(5).fill({
            data: [0],
            type: 'bar',
            stack: 'a',
            name: 'N/A'
          })
          
        const stackInfo = {};

        let options = {
            tooltip: {
                trigger: 'axis',
                axisPointer: {
                    type: 'shadow'
                },
                formatter: (params) => {
                    let text = `<strong>${params[0].axisValue}</strong><br>`
                    for(let par of params){
                        if(par?.data > 0)
                            text +=  par?.marker + " " + par?.seriesName + " " + par?.data + '<br/>'
                    }
                    return text;
                },
            },
            xAxis: {
                type: 'category',
                data: this.state.productos_nombre.length > 0 ? this.state.productos_nombre : []
            },
            yAxis: {
                type: 'value'
            },
            series: series
        };

        if(this.grafica)
            this.grafica.getEchartsInstance().setOption(options);

        return options
        
    }

    render() {

        let option = {
            xAxis: {
                type: 'category',
                data: [ "Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre" ],
                axisLabel: {
                    rotate: 45  // Aquí puedes ajustar el ángulo de rotación
                }
            },
            yAxis: {
                type: 'value'
            },
            series: [
                {
                    data: this.state.pedidos_por_anio?.map(e => e.pedidos ),
                    type: 'bar'
                }
            ],
            tooltip: {
                trigger: 'axis',
                axisPointer: {
                  type: 'shadow'
                }
            },
        };


        let option_pie = {
        
            tooltip: {
                trigger: 'item'
            },
            legend: {
                orient: 'vertical',
                left: 'left'
            },
            series: [
                {
                    name: 'Access From',
                    type: 'pie',
                    radius: '50%',
                    data: this.state.totales,
                    emphasis: {
                        itemStyle: {
                            shadowBlur: 10,
                            shadowOffsetX: 0,
                            shadowColor: 'rgba(0, 0, 0, 0.5)'
                        }
                    }
                }
            ]
        };
        return (
            <Spin spinning={this.state.loading}>
                <Row className="mb-1">
                    <Col span={12} className="flex-left">

                    </Col>
                    <Col span={12} className="flex-right">
                        <Space>
                            <Form>
                                <Form.Item
                                    name="fechas"
                                    className="m-0"
                                >
                                    <RangePicker
                                        style={{ width: "80%" }}
                                        onChange={this.onChangeDates}
                                    />
                                </Form.Item>
                            </Form>
                        </Space>
                    </Col>
                </Row>
                <Row gutter={[8,8]}>
                    <Col xs={24} lg={8}>
                        <Card 
                            title={'Pedidos por mes'}
                            style={{ boxShadow: '0px 10px 15px -3px rgba(0,0,0,0.1)' }}
                            extra={<Select 
                                options={this.renderOptions()} 
                                size="small"
                                defaultValue={dayjs().year()}
                                onSelect={(year)=>this.getGrafica({year})}
                            />}
                        >
                            <Row>
                                <Col span={24} className="center">
                                    <ReactECharts
                                        option={option}
                                        style={{ width: '100%', maxWidth: 800, height: "300px" }}
                                    />
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                    <Col xs={24} lg={8} className="grid ">
                        <Row gutter={[8,8]} className="">
                            <Col span={12} className="grid">
                                <Card size="small" style={{ boxShadow: '0px 10px 15px -3px rgba(0,0,0,0.1)' }}>
                                    <Row className="mt-1">
                                        <Col span={24} className="flex-left-column">
                                            <div className="icon-box red">
                                                <BsBoxSeam/>
                                            </div>
                                            <Text style={{fontSize: "16px"}}>Producto Vendidos</Text>
                                            <Text strong style={{fontSize: "20px"}}>{(this.state.estadisticas.productos).toMoney(true,0)}</Text>
                                        </Col>
                                    </Row>
                                </Card>
                            </Col>
                            <Col span={12} className="grid">
                                <Card size="small" style={{ boxShadow: '0px 10px 15px -3px rgba(0,0,0,0.1)' }}>
                                    <Row className="mt-1">
                                        <Col span={24} className="flex-left-column">
                                            <div className="icon-box orange">
                                                <FaTruck/>
                                            </div>
                                            <Text style={{fontSize: "16px"}}>Envios Realizados</Text>
                                            <Text strong style={{fontSize: "20px"}}>{(this.state.estadisticas.enviados).toMoney(true,0)}</Text>
                                        </Col>
                                    </Row>
                                </Card>
                            </Col>
                            <Col span={12} className="grid">
                                <Card size="small" style={{ boxShadow: '0px 10px 15px -3px rgba(0,0,0,0.1)' }}>
                                    <Row className="mt-1">
                                        <Col span={24} className="flex-left-column">
                                            <div className="width-100 flex-between">
                                                <div className="icon-box red">
                                                    <FaSitemap/>
                                                </div>
                                                <Text className="text-gray hover" onClick={()=>this.props.navigate("/admin/crm/cliente/sabores-vendidos/"+this.props.cliente_id)}>
                                                    Ver más <RightOutlined />
                                                </Text>
                                            </div>
                                            <Text style={{fontSize: "16px"}}>Sabores vendidos</Text>
                                            <Text strong style={{fontSize: "20px"}}>{(this.state.estadisticas.variantes).toMoney(true,0)}</Text>
                                        </Col>
                                    </Row>
                                </Card>
                            </Col>
                            <Col span={12} className="grid">
                                <Card size="small" style={{ boxShadow: '0px 10px 15px -3px rgba(0,0,0,0.1)' }}>
                                    <Row className="mt-1">
                                        <Col span={24} className="flex-left-column">
                                            <div className="icon-box orange">
                                                <GiCancel/>
                                            </div>
                                            <Text style={{fontSize: "16px"}}>Envios cancelados</Text>
                                            <Text strong style={{fontSize: "20px"}}>{(0).toMoney(true,0)}</Text>
                                        </Col>
                                    </Row>
                                </Card>
                            </Col>
                        </Row>
                    </Col>
                    <Col xs={24} lg={8}>
                        <Card 
                            title={`Total de ventas: $ ${this.state.total_venta?.toMoney(true)}`}
                            style={{ boxShadow: '0px 10px 15px -3px rgba(0,0,0,0.1)' }}>
                            <Row>
                                <Col span={24} className="center">
                                    <ReactECharts
                                        option={option_pie}
                                        style={{ width: '100%', maxWidth: 800, height: "300px" }}
                                    />
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                    <Col xs={24} lg={12}>
                        <Card 
                            title={`Productos más comprados`}
                            style={{ boxShadow: '0px 10px 15px -3px rgba(0,0,0,0.1)' }}>
                            <Row>
                                <Col span={24} className="center">
                                    <ReactECharts
                                        ref={(e) => { this.grafica = e; }}
                                        option={this.optionsGrafica()}
                                        style={{ width: '100%', maxWidth: 800, height: "300px" }}
                                    />
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                    <Col xs={24} lg={12}>
                        <List
                            className="component-list"
                            itemLayout="horizontal"
                            locale={{ emptyText: "Sin Clientes" }}
                            dataSource={this.state.ordenes.data}
                            pagination={{
                                current: this.state.ordenes.page,
                                pageSize: this.state.ordenes.limit,
                                total: this.state.ordenes.total,
                                hideOnSinglePage: false,
                                position: 'bottom',
                                onChange: (page, limit) => this.getGrafica({ page, limit })

                            }}
                            renderItem={item => (
                                <List.Item className="component-list-item">
                                    <Card className="card-list">
                                        <Row className="width-100" gutter={[8, 8]}>
                                            <Col xs={12} md={8} className="center">
                                                <Text strong ellipsis>{item.producto_id?.modelo}</Text>
                                            </Col>
                                            <Col xs={12} md={8} className="center">
                                                <Text className="text-gray">{item.variante_id?.sabor}</Text>
                                            </Col>
                                            <Col xs={12} md={8} className="center">
                                                <Text className="text-gray">{item.total_variantes}</Text>
                                            </Col>
                                        </Row>
                                    </Card>
                                </List.Item>
                            )}
                        />
                    </Col>
                </Row>
            </Spin>
        )
    }
}


export default function (params) {

    const user = useContext(User);

    const permisos = usePermissions(user?.rol_id?.permisos, {
        canEdit: ["ordenes", "edit"],
        candDelete: ["ordenes", "delete"],
        canCreate: ["ordenes", "create"],
    });

    return <Ordenes {...params} params={useParams()} navigate={useNavigate()} {...permisos} />
}