import React, { useState } from "react";
import { Row, Col, Form, Button, Space, Tooltip, } from 'antd';
import { PlusOutlined, QuestionCircleOutlined } from "@ant-design/icons"
import ReglaItem from "./ReglaItem"

/**
 *
 *
 * @const ReglaList
 */
function ReglaList(props) {

    const {
        name,
        errores,
        reglas = [],
        antiguedad_fecha,
        disabled
    } = props;

    const onRemove = () => {
        setTimeout(function () {
            props.verificarReglas()
        }, 500);

    }
    return (

        <>
            <Form.List
                name={name}
            >
                {(fields, functions) => {
                    const { add, remove } = functions
                    return (
                        <>
                            <Row
                                className="width-100"
                                gutter={[8, 24]}
                                align="center"
                            >
                                <Col span={10} className="center"> Desde</Col>
                                <Col span={10} className="center"> Hasta</Col>
                                <Col span={4} className="text-center ">
                                    <Space direction="horizontal">
                                        <Button
                                            onClick={() => add()}
                                            disabled={disabled}
                                            type="primary"
                                            className="mt-05"
                                            icon={<PlusOutlined />}  >
                                        </Button>
                                        <Tooltip title="Rangos de montos de venta total para pedidos">
                                            <QuestionCircleOutlined  className="text-gray center mt-05" style={{fontSize:20}}/>
                                        </Tooltip>
                                    </Space>
                                </Col>
                            </Row>
                            {
                                fields.map(({ key, name, ...restField }, index) => {
                                    return <ReglaItem
                                        name={name}
                                        key={key}
                                        antiguedad_fecha={antiguedad_fecha}
                                        index={index}
                                        {...restField}
                                        errores={errores}
                                        remove={(name) => {
                                            remove(name)
                                            onRemove()
                                        }}
                                        regla={reglas[index]}
                                    />

                                })
                            }
                        </>
                    )
                }}
            </Form.List>
        </>
    )
}




export default ReglaList