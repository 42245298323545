import React, { Component, } from 'react';
import { Row, Col, Layout, Card, message } from 'antd'
import ReactECharts from 'echarts-for-react';
import axios from 'axios';
import { BsBoxSeam } from "react-icons/bs"
import { IconGraphic, IconCuenta } from '../../Widgets/Iconos'
import { CardStatistics } from '../../Widgets/Cards'
import { useNavigate, useSearchParams } from 'react-router-dom';
import useSearch from '../../../Hooks/useSearch';
import PageHeader from '../../Widgets/PageHeader/PageHeader';
import { UserOutlined } from '@ant-design/icons';


import Ordenes from './Ordenes'
import Productos from './Productos'

const { Content } = Layout;


/**
 *
 *
 * @export
 * @class Dashboard
 * @extends {Component}
 * @description Vista donde se muestra un resumen de las estadisticas del sistema
 */
class Dashboard extends Component {

    back = () => {
        this.props.navigate(-1)
    }


    /**
     *Creates an instance of Usuarios.
     * @param {*} props
     * @memberof Usuarios
     */
    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            productos: 0,
            clientes: 0,
            ventas_canceladas: false,
            ventas_total: 0,
            ventas_mensuales: 0,
            fechas: [],
            ventas_por_año: {},
            years: [],
            ordenes: {
                data: [],
                limit: 50,
                page: 1,
                total: 0,
                filters: [],
                reglas: false
            },
            montos: {
                monto1: 0,
                monto2: 0,
                monto3: 0,
                monto4: 0

            },
            ventas: {
                totales: [{
                    "nombre": "Enero",
                    "monto": 123,

                }],
            },
            ventas_anuales: {
                totales: [{
                    "nombre": "2019",
                    "monto": 123,

                },
                {
                    "nombre": "2020",
                    "monto": 545,

                },
                {
                    "nombre": "2021",
                    "monto": 45
                    ,

                },
                {
                    "nombre": "2022",
                    "monto": 785,

                },
                {
                    "nombre": "2023",
                    "monto": 587,

                },
                ],

            },
            variantes_historicos: []
        }
    }


    componentDidMount() {
        this.props.setShowSearch(false)
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token')
        this.getInfo()
        this.getCancelados()
        this.getProductos()

    }

    /**
    * @memberof Ordenes
    * @description Obtiene una lista de ordenes provenientes directamente del api de shopify
    */
    getCancelados = ({
        limit = this.state.ordenes.limit,
        page = this.state.ordenes.page,
        reglas = this.state.ordenes?.reglas,
        filters = this.state.ordenes.filters,
        search = this.props.search,
    } = this.state.ordenes) => {

        this.setState({ loading: true })

        axios.get('/ordenes', {
            params: {
                limit,
                page,
                canceladas: true,
                orden_detalles: true,
            }
        }).then(({ data }) => {
            this.setState({
                ordenes: {
                    ...data,

                }
            })

        }).catch(error => {
            message.error(error.response?.data?.message ?? 'Error al obtener los pedidos cancelados')
        }).finally(() => this.setState({ loading: false }))

    }

    /**
   * @memberof Cuentas
   * @method getInfo
   * @description Trae infofmacion general
   * 
   */
    getInfo = () => {
        this.setState({ loading: true })
        axios.get('/dashboard', {})
            .then(response => {
                console.log("response", response.data);
                this.setState({
                    clientes: response.data.clientes,
                    productos: response.data.productos,
                    ventas_total: response.data.ventas_total,
                    ventas_mensuales: response.data.ventas_mensuales,

                    ventas_por_mes: response.data.ventas_por_mes,
                    fechas: response.data.fechas.reverse(),
                    ventas_por_año: response.data.ventas_por_año,
                    years: response.data.years.reverse(),

                })
            }).catch(error => {

            }).finally(() => this.setState({ loading: false }))
    }

    /**
  * @memberof Cuentas
  * @method getInfo
  * @description Trae infofmacion general
  * 
  */
    getProductos = () => {
        this.setState({ loading: true })
        axios.get('/dashboard/productos', {})
            .then(response => {
                console.log("response productos", response.data.variantes);

                this.setState({

                    variantes_historicos: response.data.variantes


                })
            }).catch(error => {

            }).finally(() => this.setState({ loading: false }))
    }

    toggleVentasCanceladas = () => {
        if (this.state.grafica_variantes) this.setState({ grafica_variantes: !this.state.grafica_variantes })
        this.setState({ ventas_canceladas: !this.state.ventas_canceladas })
    }
    toggleGraficaVariantes = () => {
        if (this.state.ventas_canceladas) this.setState({ ventas_canceladas: !this.state.ventas_canceladas })
        this.getProductos()
        this.setState({ grafica_variantes: !this.state.grafica_variantes })
    }


    render() {




        let option = {
            tooltip: {
                trigger: 'axis',
                axisPointer: {
                    type: 'shadow'
                }
            },
            xAxis: {
                type: 'category',
                data: this.state.fechas,
                axisLabel: {
                    rotate: 45
                }
            },
            yAxis: {
                type: 'value',
            },
            series: [{
                data: this.state.fechas?.map(fecha => this.state.ventas_por_mes[fecha] ?? 0),
                type: 'bar'
            }]
        };

        let option_2 = {
            tooltip: {
                trigger: 'axis',
                axisPointer: {
                    type: 'shadow' // 'shadow' as default; can also be 'line' or 'shadow'
                }
            },
            legend: {},
            grid: {
                left: '3%',
                right: '4%',
                bottom: '3%',
                containLabel: true
            },
            xAxis: {
                type: 'value'
            },
            yAxis: {
                type: 'category',
                data: this.state.years
            },
            series: [{
                data: this.state.years?.map(year => this.state.ventas_por_año[year] ?? 0),
                type: 'bar'
            }]

        };

        var option_3 = {
            grid: {
                containLabel: true
            },
            tooltip: {
                trigger: 'axis',

                axisPointer: {
                    type: 'shadow' // 'shadow' as default; can also be 'line' or 'shadow'
                },
                formatter: (params) => {
                    console.log(params)
                    let string_sabores = ""
                    for (let sabor of params[0].data.variantes) {

                        string_sabores += sabor.variante.sabor + "&emsp;" + sabor.cantidad + "<br/>"
                    }

                    return (
                        params[0].name + " - " + params[0].value.cantidad +
                        '<br/>' +

                        '<br/>' +
                        string_sabores


                    );
                },
            },
            dataset: {
                source: this.state.variantes_historicos
            },
            xAxis: {},
            yAxis: [{
                type: 'category', axisLine: {
                    lineStyle: {
                        width: 1,
                        color: 'black',
                        shadowColor: 'black',
                    }
                },
            }],
            series: [
                {
                    type: 'bar',
                    encode: {
                        // Map "amount" column to x-axis.
                        x: 'cantidad',
                        // Map "product" row to y-axis.
                        y: 'nombre'
                    }
                }
            ]
        }




        return (
            <>
                <PageHeader
                    className="admin-page-header"
                    title="Dashboard"
                />
                <Content className="admin-content content-bg pd-1">
                    <Row gutter={[16, 16]}>
                        <Col xs={24} sm={12} md={12} lg={6} className="grid">
                            <CardStatistics onClick={() => this.toggleGraficaVariantes()} title="Productos" suffix={""} monto={this.state.productos} icon={(color) => <BsBoxSeam color="white" />} />
                        </Col>
                        <Col xs={24} sm={12} md={12} lg={6} className="grid">
                            <CardStatistics onClick={() => this.toggleVentasCanceladas()} title="Ventas" monto={this.state.ventas_total} icon={(color) => <IconCuenta color={'#fff'} />} />
                        </Col>
                        <Col xs={24} sm={12} md={12} lg={6} className="grid">
                            <CardStatistics title="Pedidos Mensuales" suffix={""} monto={this.state.ventas_mensuales} icon={(color) => <IconGraphic />} />
                        </Col>
                        <Col xs={24} sm={12} md={12} lg={6} className="grid">
                            <CardStatistics title="Clientes Totales" suffix={""} monto={this.state.clientes} icon={(color) => <UserOutlined style={{ color: '#fff' }} />} />
                        </Col>
                        <Col xs={this.state.grafica_variantes ? 24 : 0}>
                           <Productos/>
                        </Col>
                        <Col xs={this.state.ventas_canceladas ? 24 : 0}>
                            <Ordenes/>
                            {/* <List
                                loading={this.state.loading}
                                className="component-list"
                                itemLayout="horizontal"
                                dataSource={this.state.ordenes.data}
                                pagination={{
                                    current: this.state.ordenes.page,
                                    pageSize: this.state.ordenes.limit,
                                    total: this.state.ordenes.total,
                                    position: 'bottom',
                                    className: "flex-left",
                                    pageSizeOptions: [10, 20, 50, 100],
                                    showSizeChanger: true,
                                    onChange: (page, limit) => this.getOrdenes({ page, limit })
                                }}
                                locale={{ emptyText: <Empty /> }}
                                header={<Row className="header-list width-100 pl-1 pr-1">
                                    <Col xs={24} className=" center">
                                        <Text strong>Pedidos cancelados</Text>
                                    </Col>
                                    <Col xs={12} md={6} lg={6} xl={3} className=" center">
                                        <Text strong>Folio</Text>
                                    </Col>
                                    <Col xs={12} md={6} lg={6} xl={3} className="center">
                                        <Text strong>Artículos</Text>
                                    </Col>
                                    <Col xs={12} md={6} lg={6} xl={5} className="center">
                                        <Text strong>Cliente</Text>
                                    </Col>
                                    <Col xs={12} md={6} lg={6} xl={3} className="center">
                                        <Text strong>Fecha</Text>
                                    </Col>
                                    <Col xs={12} md={6} lg={6} xl={3} className="center">
                                        <Text strong>Estatus Pago</Text>
                                    </Col>
                                    <Col xs={12} md={6} lg={6} xl={3} className="center">
                                        <Text strong>Estatus Pedido</Text>
                                    </Col>
                                    <Col xs={23} md={11} lg={6} xl={3} className="center">
                                        <Text strong>Monto</Text>
                                    </Col>
                                    <Col xs={1} md={1} lg={1} xl={1} className="center">
                                        <Text strong></Text>
                                    </Col>
                                </Row>
                                }
                                renderItem={item => {
                                    const { total, cliente_id, fecha, ordenes_productos_ids, transaccion, id_transaction, id, referencia_numerica, _id, folio, shopify_cliente, total_productos } = item;
                                    return <List.Item className="component-list-item">
                                        <Tooltip trigger={(item.orden_id) ? ["hover"] : []} title=" Este pedido se clasificó previamente" placement="topLeft">
                                            <Card
                                                style={{ ...(item.shopify_cancelled_at) ? { background: "rgba(0,0,0,0.1)" } : {} }}
                                                className="card-list">
                                                <Row className="width-100">
                                                    <Col xs={12} md={6} lg={6} xl={3} className="center hover" onClick={() => this.setState({ modal_visible_detalle: true, orden_id: item._id })}>
                                                        <Space>
                                                            {item.bitacora_log ? <BiTask style={{ color: 'orange' }} /> : null}

                                                            {!item.shopify_id && !item.shopify_draft_id ? <FaShopify style={{ color: "red" }} /> : null}
                                                            <Text delete={item.shopify_cancelled_at}>{item.shopify_folio ?? folio}</Text>
                                                            {item.shopify_draft_id && !item.shopify_id ? <Tag color="magenta">Draft</Tag> : null}
                                                        </Space>
                                                    </Col>
                                                    <Col xs={12} md={6} lg={6} xl={3} className="center">
                                                        <Text delete={item.shopify_cancelled_at}>{total_productos ?? 0} artículos</Text>
                                                    </Col>
                                                    <Col xs={12} md={6} lg={6} xl={5} className="center">
                                                        <ClienteSubMenu
                                                            delete_order={item.shopify_cancelled_at}
                                                            cliente={cliente_id}
                                                            shopify_cliente={shopify_cliente}
                                                        />
                                                    </Col>
                                                    <Col xs={12} md={6} lg={6} xl={3} className="center">
                                                        <Text strong delete={item.shopify_cancelled_at}>{moment(fecha).format('DD-MM-YYYY HH:mm')}</Text>
                                                    </Col>
                                                    <Col xs={12} md={6} lg={6} xl={3} className="center">
                                                        {renderEstatusOrdenPago(item.shopify_financial_status)}
                                                    </Col>
                                                    <Col xs={12} md={6} lg={6} xl={3} className="center">
                                                        {renderEstatusOrdenEnvio(item.shopify_fulfillment_status)}
                                                    </Col>
                                                    <Col xs={23} md={11} lg={11} xl={3} className="center">
                                                        <Statistic
                                                            delete={item.shopify_cancelled_at}
                                                            value={total}
                                                            valueStyle={{
                                                                fontSize: '16px',
                                                                fontWeight: 'bold',
                                                                color: item.shopify_financial_status == "paid" ? '#2c9d37' : "#ecc602"
                                                            }}
                                                            prefix={'$'} suffix={"MXN"} />
                                                    </Col>

                                                </Row>
                                            </Card>
                                        </Tooltip>
                                    </List.Item>
                                }}
                            /> */}
                        </Col>
                        <Col xs={24} md={24} lg={12}>
                            <Card title={'Ventas por mes'} style={{ boxShadow: '0px 10px 15px -3px rgba(0,0,0,0.1)' }}>
                                <Row>
                                    <Col span={24} className="center">
                                        <ReactECharts
                                            option={option}
                                            style={{ width: '100%', maxWidth: 400 }}
                                        />
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                        <Col xs={24} md={24} lg={12}>

                            <Card title={'Ventas anuales'} style={{ boxShadow: '0px 10px 15px -3px rgba(0,0,0,0.1)' }}>
                                <Row>
                                    <Col span={24} className="center">
                                        <ReactECharts
                                            option={option_2}
                                            style={{ width: '100%', maxWidth: 800 }}
                                        />
                                    </Col>
                                </Row>
                            </Card>
                        </Col>

                    </Row>
                </Content>

            </>
        )
    }
}

const Vista = props => {
    const { setShow } = useSearch()
    const navigate = useNavigate()
    const [params, setParams] = useSearchParams();


    return <Dashboard {...props} setShowSearch={setShow} navigate={navigate} params={params} />
}

export default Vista