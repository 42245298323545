import React, { Component } from "react";
import { Row, Col, Modal, Form, message, Spin, Typography, Input, Space, InputNumber, Divider, DatePicker } from 'antd';
import axios from "axios"
import SelectOrdenes from "../../../Widgets/Inputs/SelectOrdenes";
import SelectCuentas from "../../../Widgets/Inputs/SelectCuentas";
import SelectRubros from "../../../Widgets/Inputs/SelectRubros";
import SelectAreas from "../../../Widgets/Inputs/SelectAreas";


const Decimal = require("decimal.js");
const { Text } = Typography

/**
 * @class FormPagoPedidos
 * @description Modal para pagar pedidos con saldo a favor
 */
class FormPagoPedidos extends Component {

    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            saldo: 0,
            area_id: null,
            cuenta_id: null,
            ordenes: {
                data: [],
                page: 1,
                total: 0,
                limit: 10
            }
        }
    }

    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token')
        this.get()

    }

    formRef = React.createRef();

    /**
  * @memberof FormPagoPedidos
  * @description Obtiene una lista de ordenes provenientes directamente del api de shopify
  */
    get = ({
        limit = this.state.ordenes.limit,
        page = this.state.ordenes.page,
        reglas = this.state.ordenes.filters?.reglas
    } = this.state.ordenes) => {

        this.setState({ loading: true })
        axios.get('/ordenes', {
            params: {
                limit,
                page,
                reglas
            }
        }).then(({ data }) => {
            console.log("data", data);
            this.setState({
                ordenes: data
            })

        }).catch(error => {
            message.error(error.response?.data?.message ?? 'Error al obtener los pedidos')
        }).finally(() => this.setState({ loading: false }))

    }



    /**
     * @memberof FormPagoPedidos
     * @method onFinish
     * @description Se ejecuta al hacer submit al formulario
     */
    onFinish = (values) => {

        if (values.area_id?.value) values.area_id = values.area_id.value;
        if (values.rubro_id?.value) values.rubro_id = values.rubro_id.value;
        if (values.cuenta_id?.value) values.cuenta_id = values.cuenta_id.value;

        values.ordenes_id.forEach((orden, index) => {
            if (orden?.value) values.ordenes_id[index] = orden.value;
        })

        this.setState({ loading: true })
        axios.post('/ordenes/clientes/pagar/saldo', {
            cliente_id: this.props.cliente_id,
            ...values
        })
            .then(({ data }) => {
                message.success('Pedidos pagados')
                this.props.onClose()

            }).catch(error => {
                message.error('Error al pagar los pedidos')
            }).finally(() => this.setState({ loading: false }))

    }

    /**
      * @memberof FormPagoPedidos
      * @method validateSaldo
      * @description Se ejecuta al hacer submit al formulario
      */
    validateSaldo = (items) => {

        console.log('items',items)
        let monto = 0;
        items.forEach(item => {
            let total = parseFloat(item.label[1].props.children[1].props.children[1].replace(',', ''))
            monto += Decimal(total).toNumber()
        });

        this.setState({ saldo: monto })
    }

    /**
     * @memberof FormPagoPedidos
     * @method getTipoCambio
     * @description Trae el ulitmo tipo de cambio utilizado
     *
     */
    getTipoCambio = () => {
        this.formRef?.current?.setFieldsValue({
            tipo_cambio: 19.5,
        });
        this.setState({
            tipo_cambio: 19.5,
        });
    };
    render() {
        return (
            <Spin spinning={this.state.loading}>
                <Form
                    id="form-pago-pedidos"
                    layout={"vertical"}
                    onFinish={this.onFinish}
                    ref={this.formRef}
                >
                    <Row justify="center" className="center mt-2">
                        <Col span={20}>
                            <Space direction="vertical" className="mb-1">
                                <Text strong>Saldo disponible: ${this.props.saldo ?? 0}</Text>
                                <Text strong>Total : ${this.state.saldo ?? 0}</Text>
                            </Space>
                        </Col>
                        <Col span={20}>
                            <Form.Item
                                label="Pedidos"
                                name="ordenes_id"
                            >
                                <SelectOrdenes
                                    mode="multiple"
                                    // disabled={this.props.saldo == undefined || this.props.saldo <= 0}
                                    params={{
                                        cliente_id: this.props.cliente_id,
                                        pendientes: true
                                    }}
                                    labelInValue={true}
                                    onChange={(e) => this.validateSaldo(e)}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Divider className="modal-divider">
                            Información de la(s) Transacciones
                        </Divider>

                        <Row gutter={[16, 0]}>
                            <Col xs={24} lg={12}>
                                <Form.Item label="Concepto" name="concepto">
                                    <Input className="width-100" />
                                </Form.Item>
                            </Col>
                            <Col xs={24} lg={12}>
                                <Form.Item
                                    label="Fecha"
                                    name="fecha"
                                    rules={[
                                        {
                                            required: true,
                                            message: "Por favor, ingrese la fecha",
                                        },
                                    ]}
                                >
                                    <DatePicker className="width-100" />
                                </Form.Item>
                            </Col>

                            <Col xs={24} lg={12}>
                                <Form.Item label="Área" name="area_id" rules={[
                                    {
                                        required: true,
                                        message: "Por favor, ingrese el área",
                                    },
                                ]}>
                                    <SelectAreas
                                        onSelect={(area_id) => this.setState({ area_id })}
                                    />
                                </Form.Item>
                            </Col>
                            <Col xs={24} lg={12}>
                                <Form.Item label="Rubro" name="rubro_id" rules={[
                                    {
                                        required: true,
                                        message: "Por favor, ingrese el rubro",
                                    },
                                ]}>
                                    <SelectRubros
                                        params={{
                                            area_id: this.state.area_id,
                                        }}
                                    />
                                </Form.Item>
                            </Col>

                            <Col xs={24} lg={12}>
                                <Form.Item label="Cuenta" name="cuenta_id" rules={[
                                    {
                                        required: true,
                                        message: "Por favor, ingrese la cuenta",
                                    },
                                ]}>
                                    <SelectCuentas />
                                </Form.Item>
                            </Col>

                            <Col xs={24} lg={12}>
                                <Form.Item
                                    label="Tipo Cambio"
                                    name="tipo_cambio"
                                >
                                    <InputNumber
                                        min={1}
                                        defaultValue={0}
                                        className="width-100"
                                        formatter={(value) =>
                                            `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                        }
                                        parser={(value) =>
                                            value ? value.replace(/\$\s?|(,*)/g, "") : 0
                                        }
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Row>
                </Form>
            </Spin>
        )
    }


}



export default function Modals(props) {

    const { visible = false, onClose = () => { } } = props

    return <Modal
        open={visible}
        onCancel={onClose}
        title={"Pagar Pedidos"}
        closable={true}
        destroyOnClose={true}
        zIndex={1000}
        cancelText="Cancelar"
        okText="Guardar"
        okButtonProps={{ form: 'form-pago-pedidos', key: 'submit', htmlType: 'submit' }}
    >
        <FormPagoPedidos  {...props} />
    </Modal>

}