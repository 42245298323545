import React, { Component } from 'react';
import { Row, Col, Typography, Button, Popconfirm, Modal, Spin, Layout, Space, message, Card, Image, Tag, Collapse, Pagination, Empty, DatePicker } from 'antd'
import { CopyOutlined, MailOutlined, PlusOutlined } from '@ant-design/icons';
import { FaShopify } from "react-icons/fa";
import { useNavigate, useParams } from "react-router-dom";
import axios from 'axios';

import Logged, { User } from "../../../../Hooks/Logged";
import FloatingButton from "../../../Widgets/Floating Button/FloatingButton";
import Avatar from '../../../Widgets/Avatar/Avatar';


import useSearch from '../../../../Hooks/useSearch';
import CustomAvatar from '../../../Widgets/Avatar/Avatar';
import ImageProducto from "../../../Widgets/ImageProducto";
import usePermissions from '../../../../Hooks/usePermissions';
import PageHeader from '../../../Widgets/PageHeader/PageHeader';
import SelectClientes from '../../../Widgets/Inputs/SelectClientes';
import { ButtonDelete, ButtonEdit, ButtonView } from '../../../Widgets/Buttons';


const { Content } = Layout;
const { Text, Title } = Typography;
const { RangePicker } = DatePicker;

/**

 * @export
 * @class SaboresVendidos
 * @extends {Component}
 * @description Vista donde se listan los productos comprados por un cliente en especifico
 */
class SaboresVendidos extends Component {
    
    static contextType = Logged;


    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            cliente_id: this.props.params.cliente_id,
            productos: {
                data: [],
                limit: 20,
                page: 1,
                total: 0,
                pages: 0,
                filters: []
            },
            producto_id: null
        }
    }

    componentDidMount() {
        this.props.setShowSearch(false)
        this.getProductos();
        if (this.props.params.cliente_id) {
            this.getCliente()
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.search !== prevProps.search) {
            this.getProductos({ page: 1, search: this.props.search })
        }
    }

    componentWillUnmount() {
        this.props.setShowSearch(false)
    }

     /**
     *
     * @memberOf SaboresVendidos
     * 
     * @method getCliente
     * @description Obtiene informacion del Cliente
     */
    getCliente = (id = this.state.cliente_id) => {
        this.setState({ loading: true })
        axios.get('/clientes/' + id)
        .then(response => {
            this.setState({
                cliente: response.data,
            })

        }).catch(error => {
            console.log("error", error);
            message.error(error?.response?.data?.message ?? "Error al obtener la información del cliente")
        }).finally(() => this.setState({ loading: false }))
    }


    /**
    *
    * @memberof RazonesSociales
    * @method getRS
    * @description Obtiene la informacion de los Empresas y actualiza los valores locales de la lista.
    */
    getProductos = async ({
        page = this.state.productos.page,
        limit = this.state.productos.limit,
        fecha_inicio = this.state.fecha_inicio,
        fecha_final =  this.state.fecha_final,
        cliente_id = this.state.cliente_id
    } = this.state.productos) => {

        this.setState({ loading: true })
        axios.get('/crm/sabores-vendidos', {
            params: {
                limit,
                page,
                cliente_id,
                fecha_inicio,
                fecha_final,
            }
        })
            .then(({ data }) => {
                console.log("data", data);
                this.setState({ productos: data })
            })
            .catch(res => {
                message.error(res.response?.data?.message)
            })
            .finally(() => this.setState({ loading: false }))
    }

    /**
     * @memberof Dashboard
     * @method dates
     * @description Actualiza el rango de fechas para realizar la buequeda
     */
    onChangeDates = (dates) => {
        if (dates) {
            this.setState({
                fecha_inicio: dates[0],
                fecha_final: dates[1]
            }, () => {
                this.getProductos()
            })
        }else{
            this.setState({
                fecha_inicio: undefined,
                fecha_final: undefined
            }, () => {
                this.getProductos()
            })
        }
    }




    render() {

    	let { cliente } = this.state;

        return (
            <>
                <Spin spinning={this.state.loading}>
                    <PageHeader
                        className="admin-page-header"
                        breadcrumb={{
                        	items: [
	                        	{	
	                        		title: "Sabores vendidos a: "
	                        	},
	                        	{
	                                title: <SelectClientes
	                                    style={{minWidth: "240px"}}
	                                    className="select-transparent mt-0" 
	                                    disabled={this.state.loading} 
	                                    onSelect={(e) => { this.getCliente(e); this.getProductos({ cliente_id: e }) }} 
	                                    value={cliente?._id ? { value:cliente?._id, label: `${cliente.nombre} ${cliente.apellido_paterno}`  } : null } />,
	                            },
                        ]}}
                       	backIcon={true}
                        extra={<RangePicker
                            style={{ width: "80%" }}
                            onChange={this.onChangeDates}
                        />}
                    >

                    </PageHeader>

                    <Content className="admin-content content-bg pd-1 mb-3">
                    	{this.state.productos.data?.length === 0 ? <Empty/> : null }
                        <Collapse
                        	className="collapse-vapemate"
                            items={this.state.productos?.data?.map((item, index) => ({
                                key: index,
                                label: <>
                                    <Row className="width-100">
                                        <Col xs={21} className="center">
                                            <Row gutter={[8, 8]} className="width-100">
                                                <Col xs={12} md={4} className="center">
                                                 	<ImageProducto producto={item.producto_id}/>  
                                                </Col>
                                                <Col xs={12} md={8} className="flex-left">
                                                    <Text strong ellipsis> {item.producto_id?.shopify_id ? <FaShopify /> : null} {item.producto_id?.modelo ?? '-'}</Text>
                                                </Col>
                                                <Col xs={12} md={8} className="center">
                                                    <Text ellipsis> <strong>{item.cantidad_total}</strong> sabores vendidos</Text>
                                                </Col>
                                                
                                            </Row>
                                        </Col>
                                        <Col xs={3} className="flex-right">
                                            <Space>
                                            </Space>
                                        </Col>
                                    </Row>
                                </>,
                                children: item.variantes?.map(variante => <Row className="width-100 mb-1">
                                    <Col xs={24}>
                                        <Row gutter={[8, 8]}>
                                            <Col xs={12} md={3} className="center">
                                                <ImageProducto variante={variante.variante_id}/>  
                                            </Col>
                                            <Col xs={12} md={6} className="flex-left">
                                                <Text ellipsis>{variante?.variante_id?.shopify_variante_id ? <FaShopify /> : null} {variante?.variante_id?.sabor ?? "-"}</Text>
                                            </Col>
                                            <Col xs={12} md={6} className="center">
                                                <Text ellipsis>$ {variante?.variante_id?.precio?.toMoney(true) ?? '-'} MXN</Text>
                                            </Col>
                                            <Col xs={12} md={9} className="center">
                                                <Text ellipsis> <strong>{variante.cantidad}</strong> vendidos</Text>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>),
                            }))}
                        />
                        <Pagination
                            className="mt-1"
                            showSizeChanger
                            current={this.state.productos?.page}
                            total={this.state.productos?.total}
                            pageSize={this.state.productos?.limit}
                            onChange={(page, limit) => this.getProductos({ page, limit })}
                        />
                    </Content>

                    {this.props.canCreate && <>
                        <FloatingButton title="Nuevo registro" onClick={() => this.setState({ modalProductos: true })} />
                        <FloatingButton
                            buttonStyle={{
                                width: "40px",
                                height: "40px",
                                bottom: "42px",
                                right: "125px",
                            }}
                            icon={<CopyOutlined style={{ color: "white", fontSize: "20px", left: -6, top: 2, position: "relative" }} />}
                            title="Crear productos por CSV"
                            onClick={() => this.setState({ modalCSV: true })}

                        />
                    </>}



                    
                </Spin>
            </>
        )
    }
}

export default function (props) {

    const user = React.useContext(User)
    const { search, setShow } = useSearch()
    const permisos = usePermissions(user?.rol_id?.permisos, {
        canEdit: ['productos', 'edit'],
        canDelete: ['productos', 'delete'],
        canCreate: ['productos', 'create']
    })

    return <SaboresVendidos {...props} {...permisos} search={search} params={useParams()} setShowSearch={setShow}  navigate={useNavigate()}/>
}