import React, { Component } from "react";
import { Row, Col, Space, Modal, List, Typography, Form, DatePicker, Input, InputNumber, Checkbox, Select, message, Spin, Cascader, Tag, AutoComplete, Divider } from 'antd';
import axios from "axios"

//Componentes
import SelectClientes from "../../Widgets/Inputs/SelectClientes";
import SelectUsuarios from "../../Widgets/Inputs/SelectUsuarios";
import SelectDireccion from '../../Widgets/Inputs/SelectDirecciones';
import { renderEstatusOrdenPago, renderEstatusOrdenEnvio } from "../../Utils";

const { Title, Text, Paragraph } = Typography;
const { Option } = Select;
const moment = require('moment');

const Decimal = require('decimal.js');

class ModalTransacciones extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            seleccionadas: Object.values(this.props.seleccionadas || {}),


            filters: {},
            tipo_cambio: 0,
            cuentaSeleccionada: '',
            disabled: false,
            transaccion: {},
            cliente_id: undefined,
            concepto_unico: false,

        }
    }

    modalRef = React.createRef();

    componentDidMount() {
        console.clear()
        console.log(Object.values(this.props.seleccionadas))
    }

    /**
     * @memberof ModalTransacciones
     * @method onFinish
     * @description Se ejecuta al dar enter al formulario
     * 
     */
    onFinish = (values) => {

        if(values.cliente_id?.value) values.cliente_id =  values.cliente_id.value

        values.ordenes = this.state.seleccionadas;

        this.addOrdenes(values)
    }


    /**
     * @memberof ModalTransacciones
     * @method addOrdenes
     * @description Crea nuevas transacciones con las seleccionadas obtenidas de BXNK 
     * 
     */
    addOrdenes = (values) => {
        this.setState({ loading: true })
        axios.post('/ordenes/clasificar', values)
        .then(response => {
            message.success('Pedidos clasificadas')
            this.props.onClose(true)
        }).catch(error => {
            console.log(error)
            message.error('Error al clasificar los pedidos')
        }).finally(() => this.setState({ loading: false }))
    }


    render() {

        const { disabled } = this.state;
        const { clasificacion } = this.props;
        let { seleccionadas } = this.state

        return (
            <Spin spinning={this.state.loading}>
                <Form
                    layout="vertical"

                    name="form-transaccion"
                    id="form-transaccion"
                    ref={this.modalRef}
                    onFinish={this.onFinish}
                >
                   {
                   		this.state.seleccionadas.map(orden => {
                   			return <Row>
                   				<Col span={24}>
                   					<Space>
                   						<Title level={4} className="m-0">{orden.name}</Title>
                   						{renderEstatusOrdenPago(orden.financial_status)}
                   						{renderEstatusOrdenEnvio(orden.fulfillment_status)}
                   					</Space>
                   				</Col>
                   				<Col span={24}>
                   					<Space>
                   						<small className="text-gray">{moment(orden.created_at).format("DD/MM/YYYY HH:mm")}</small>
                   					</Space>
                   				</Col>
                   				
                   					{
                   						orden.line_items.map(item => {
                   							return <Row className="width-100 mt-1 pl-1 pr-1" style={{borderBottom: "solid 1px #c6c6c6",}}>
                   								<Col className="" span={10}>
                   									<Text>{item.name}</Text>
                   								</Col>
                   								<Col className=" center" span={5}>
                   									<Text>$ {item.price} <small>{orden.currency}</small></Text>
                   								</Col>
                   								<Col className=" center" span={4}>
                   									<Text>{item.quantity}</Text>
                   								</Col>
                   								<Col className=" flex-right" span={5}>
                   									<Text>$ {(item.quantity * item.price).toMoney(true)} <small>{orden.currency}</small></Text>
                   								</Col>
                   							</Row>
                   						})
                   					}
                   				
                   				<Row className="width-100 mt-1 pl-1 pr-1">
       								<Col className="flex-right" span={18}>
       									<Text strong>Sub-Total:</Text>
       								</Col>
       								<Col className=" flex-right" span={6}>
       									<Text>$ {orden.total_line_items_price} <small>{orden.currency}</small></Text>
       								</Col>
       								{ parseFloat(orden.total_tax) > 0 ? <> <Col className="flex-right" span={18}>
                                        <Text strong>IVA:</Text>
                                    </Col>
                                    <Col className=" flex-right" span={6}>
                                        <Text>$ {orden.total_tax} <small>{orden.currency}</small></Text>
                                    </Col> </> : null }
                                    { parseFloat(orden?.total_shipping_price_set?.shop_money?.amount) > 0 ? <> <Col className="flex-right" span={18}>
                                        <Text strong>Shipping:</Text>
                                    </Col>
                                    <Col className=" flex-right" span={6}>
                                        <Text>$ {orden?.total_shipping_price_set?.shop_money?.amount} <small>{orden?.total_shipping_price_set?.shop_money?.currency_code}</small></Text>
                                    </Col> </> : null }
                                    { parseFloat(orden?.total_discounts) > 0 ? <> <Col className="flex-right" span={18}>
                                        <Text strong>Descuento:</Text>
                                    </Col>
                                    <Col className=" flex-right" span={6}>
                                        <Text>- $ {orden?.total_discounts} <small>{orden?.currency}</small></Text>
                                    </Col> </> : null }
       								<Col className="flex-right" span={18}>
       									<Text strong>Total:</Text>
       								</Col>
       								<Col className=" flex-right" span={6}>
       									<Text strong>$ {orden.total_price?.toMoney(true)} <small>{orden.currency}</small></Text>
       								</Col>
                   				</Row>
                   				{orden.customer?.first_name ? <Row className="width-100">
                   					<Col span={24}>
                   						<Text>Cliente: {orden?.customer?.first_name} {orden?.customer?.last_name}</Text>
                   					</Col>
                   				</Row> : null}
                   				{orden.note ? <Row className="width-100">
                   					<Col span={24}>
                   						<Paragraph><Text className="text-gray">Notas:</Text> {orden.note}</Paragraph>
                   					</Col>
                   				</Row> : null}
                   				<Divider/>
                   			</Row>
                   		})
                   }

                    <Row gutter={[16,16]}>
                        <Col xs={24} md={12}>
                            <Form.Item
                                label="Asesor"
                                name="asesor_id"
                            >
                                <SelectUsuarios/>
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={12}>
                         	<Form.Item
                                label="Cliente"
                                name="cliente_id"
                            >
                                <SelectClientes
                                    onSelect={(cliente_id)=>{
                                        this.setState({cliente_id})
                                        this.modalRef.current.setFieldsValue({direccion_id: null})
                                    }}
                                    onClear={()=>{
                                        this.setState({cliente_id: undefined})
                                        this.modalRef.current.setFieldsValue({direccion_id: null})
                                    }}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item
                                name="direccion_id"
                                label="Dirección"
                            >
                                <SelectDireccion
                                    disabled={!this.state.cliente_id}
                                    params={{ cliente_id: this.state.cliente_id }}
                                />
                            </Form.Item> 
                        </Col>
                    </Row>
                    
                </Form>
            </Spin>
        )
    }
}



export default function (props) {
    const { visible = false, onClose = () => { }, transaccion_id, clasificacion } = props

    return <Modal
       open={visible}
        onCancel={onClose}
        title={"Clasificar Pedidos"}
        closable={true}
        maskClosable={true}
        destroyOnClose={true}
        zIndex={1000}
        okButtonProps={{ form: 'form-transaccion', key: 'submit', htmlType: 'submit' }}
        width={800}
    >
        <ModalTransacciones {...props} />
    </Modal>

}